import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setGrid } from '../../utils/slice'
import { RootState } from '../../../../../store/app'
import { useSelector } from 'react-redux'

const Listing: React.FC<any> = ({
	rowData,
	onRowClick
}) => {

	const { DEPARTMENT_TYPE } = useSelector(
		(state: RootState) => state
	);
	return (
		<>
			<AgGridWrapper
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setGrid}
				onRowClicked={onRowClick}
				paginationPageSize={DEPARTMENT_TYPE?.perPageSize}
				cacheBlockSize={DEPARTMENT_TYPE?.perPageSize}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
