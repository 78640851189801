const baseUrl = '/v1/admin/qa-element'

export const apiUrls = {
    list: `${baseUrl}/list`,
    create: `${baseUrl}/create`,
    update: (id: number): string => `${baseUrl}/${id}/edit`,
    delete: (id: number): string => `${baseUrl}/${id}/delete`,
    read: (id: number): string => `${baseUrl}/${id}/read`,
    departmentList:  `/v1/admin/project/department`,
    subDeptList:`/v1/admin/qa-element/5/get-sub-type`
}
