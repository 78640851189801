import React, { useEffect, useState } from "react";

import ToolBar from "../../../components/ToolBar";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { setPerPageSize, details, setDetails, deleteRecord, setRoleData, setIsDelete, refreshGrid } from "./utils/slice";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import AddComponent from "./components/Form/AddComponent";
import EditComponent from "./components/Form/EditComponent";
import { RoleBreadcrumb } from "../../../config/BreadcrumbConfig";
import { Button } from "antd";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import WarningModal from "../../../components/Modals/WarningModal";

const moduleName = "Roles";

const RoleManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { ROLE, AUTH } = useSelector((state: RootState) => state);
	const [isCreateVisible, setIsCreateVisible] = useState(false);
	const [deleteUser, setDeleteUser] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	const onRowClick = (data?: any) => {
		if (data.data.name == "Super Admin") {
			dispatch(setIsDelete(false))
			dispatch(setRoleData(data.data));
		} else if (data?.data?.user_count > 0) {
			dispatch(setIsDelete(false))
			dispatch(setRoleData(data.data));
		} else {
			dispatch(setRoleData(data.data));
			dispatch(setIsDelete(true))
		}

	};

	const getDetails = (data: any) => {
		dispatch(details(data.id)).then(() => {
			// 
		}).catch(() => {
			// 
		});
	}

	const closeDeleteModal = () => {
		dispatch(refreshGrid())
		setDeleteUser(false)
		dispatch(setRoleData(null))
	}
	useEffect(() => {
		dispatch(setRoleData(null))
	}, [])
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={RoleBreadcrumb} >
						<CreateButton
							action={() => setIsCreateVisible(true)}
							permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
						/>
						<RecordPerPage
							defaultValue={ROLE.perPage + " per page"}
							onChange={(perPageSize: number) => {
								dispatch(setPerPageSize(perPageSize))
							}}
						/>
					</ToolBar>
					<ContentBox>
					<div className="listWrapper">
						<div className="list">
							<Listing
								moduleName={moduleName}
								handleViewClick={getDetails}
								handleEditClick={getDetails}
								// handleDeleteClick={setDeleteValue}
								onRowClick={onRowClick}
							/>
						</div>
						
						<AddComponent
							titleName={moduleName}
							open={isCreateVisible}
							close={() => setIsCreateVisible(false)}
						/>
						{/* can't remove this code <ViewDrawer
							titleName={moduleName}
							close={() => dispatch(setDetails(null))}
						/> */}
						<EditComponent
							titleName={moduleName}
							close={() => dispatch(setDetails(null))}
						/>
						{
							ROLE?.isDelete && <DeleteModal
								title={"role"}
								deleteValues={ROLE.roleData}
								callApi={deleteRecord}
								close={closeDeleteModal}
								open={deleteUser}
							/>
						}
						{
							!ROLE.isDelete && <WarningModal 
							title="Role" 
							close={() => {setOpenModal(false); dispatch(refreshGrid());dispatch(setRoleData(null))}} 
							open={openModal} />
						}
						<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
							<div className="actionBtn">
								{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
									<Button
										onClick={() => {
											dispatch(setDetails(ROLE.roleData));
											dispatch(refreshGrid())
											//setEditForm(true);
										}}
										disabled={ROLE.roleData ? false : true}
										className={ROLE.roleData ? "activeBtn" : "disableBtn"}
									>
										Edit
										<SVGIcon icon="edit" />
									</Button>}
								{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
									<div>
										<Button
											onClick={() => {
												//dispatch(setRead(ROLE.roleData));
												ROLE?.isDelete && setDeleteUser(true);
												!ROLE.isDelete && setOpenModal(true)
											}}
											disabled={ROLE.roleData ? false : true}
											className={ROLE.roleData ? "deleteBtn" : "disableDelBtn disableBtn"}
										>
											Delete
											<SVGIcon icon="delete" />

										</Button>
									</div>}
							</div>
						</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</>
	);
};

export default RoleManagement;
