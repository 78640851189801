import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import { Button, Form } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../../assets/images/Logo.png";
import LoginBg from "../../../assets/images/bg.png";
import LoginDesign from "../../../assets/images/LoginDesign.png";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/app';
import { forgotPassword } from '../../../store/AuthSlice';
import { assignErrorToInput } from '../../../store/api';
import { LoginRequest } from './Validation';

const ForgetPassword: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleSubmit = (data: any) => {
		setLoading(true);
		dispatch(forgotPassword(data)).then(() => {
			setLoading(false)
			form.resetFields()
			navigate('/login');
		}).catch((error: any) => {
			assignErrorToInput(form, error?.STATUS);
		}).finally(() => setLoading(false))
	};

	return (
		<section className="loginSection">
			<div className="loginWrap" style={{ backgroundImage: `url(${LoginBg})` }}>
				<div className="loginLogo">
					<div className="logo">
						<img src={Logo} alt="Logo" />
					</div>
				</div>
				<div className="loginForm" >
					<div className="loginFormWrap">
						<div className="formTitle">
							<h2>Forgot Password</h2>
							<span>Welcome Ahmedabad Municipal Corporation</span>
						</div>
						<FormBox form={form} onFinish={handleSubmit}>
							<div>
								<InputBox.Text
									name="email"
									placeholder="example@domain.com"
								rules={LoginRequest().email("It")}
								// addonAfter={<Email />}
								/>
							</div>
							<div className="text-center signBtn">
								<Button
									// loading={Auth.isLoading}
									type="primary"
									htmlType="submit"
									style={{ background: "#3e97ff" }}
									loading={loading}
								>
									Forget Password
								</Button>
							</div>
							<div
								className="mt-10 font-medium login_extra_fields"
								style={{ display: "flex", justifyContent: "flex-end" }}
							>
								<Link to={"/login"}>Back to login</Link>
							</div>
						</FormBox>
					</div>
				</div>
				<div className="loginDesign">
					<img src={LoginDesign} alt="Logo" />
				</div>
			</div>
		</section>
	)
}

export default ForgetPassword;