import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn, idColumn,
} from '../../../../../utils/commonColumns'
import { renderNA } from '../../../../../utils/commonFunctions'
import { listStatus, typeStatus } from '../../../../../config/Global'
import { setFilter } from '../../utils/slice'
import DisabledContext from 'antd/es/config-provider/DisabledContext'
import SwitchCellRenderer from '../../../../../components/Switch'
import { ValueFormatterParams } from 'ag-grid-community'

const PileNameColumn: ColDef = {
	field: 'input_value',
	headerName: 'Pile No',
	valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
	tooltipValueGetter: (params: any) => params.data.input_value,
}

const StatusColumn: ColDef = {
	field: 'is_submit',
	headerName: 'Status',
	cellRenderer: 'statusRender',
	filter: "agSetColumnFilter",
	filterParams: {
		values: (props: any) => {
			if (listStatus) {
				setFilter(listStatus)
				props.success(listStatus.map(x => x.name));
			}
		},
	},
}
const TypeColumn: ColDef = {
	field: 'type_of_pile_value',
	headerName: 'Type of Pile',
	// filter: "agSetColumnFilter",
	filterParams: {
		values: (props: any) => {
			if (typeStatus) {
				setFilter(typeStatus)
				props.success(typeStatus.map(x => x.name));
			}
		},
	},
	floatingFilterComponentParams: {
		suppressFilterButton: true,
	},
	suppressMenu: true,
	sortable: false,
	filter: DisabledContext,
	// filter: 'agNumberColumnFilter' ,
	valueGetter: (props: any) => props.data.type_of_pile_value ?? renderNA(props.data.type_of_pile_value)
	// cellRenderer: (props: any) => {
	//     return renderNA(props.data?.type_of_pile_value)
	// },
}

const ActiveColumn: ColDef = {
	field: "status",
	headerName: "Is Active?",
	sortable: false,
	maxWidth: 200,
	minWidth: 120,
	cellRenderer: SwitchCellRenderer,
	filter: "agSetColumnFilter",
	filterParams: {
		values: [0, 1],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Active" && value === 1) || (filter === "InActive" && value === 0)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Active" : params.value === 0 ? "InActive" : params.value;
		},
	},
	tooltipValueGetter: (params: any) => {
		// Add tooltip content for the status column
		return params.data.status === 1 ? "Active" : "Inactive"
	},
};

const columnDefs: ColDef[] = [
	idColumn,
	PileNameColumn,
	TypeColumn,
	createdAtColumn,
	ActiveColumn,
	StatusColumn,
]
export default columnDefs
