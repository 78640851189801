import React from "react";
import { Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { UploadInputBoxProps } from "./interface";
import { validations } from "../../../../config/validations/validations";
import { IMAGE_TYPE } from "../../../../config/Constant";

const UploadInputBox: React.FC<UploadInputBoxProps> = (props) => {
	const {
		formProps: { rules = [], ...formProps },
		inputProps,
	} = SplitInputWrapperProps(props);
	const {
		accept = ".png,.jpeg,.jpg,.pdf,.doc,.docx",
		maxCount = 5,
		showUploadList = true,
		multiple = true,
		listType = "picture-card",
		fileList,
		onChange,
		beforeUpload,
		disabled,
		onRemove,
		...inputPropsRest
	} = inputProps;

	const [newFileList, setNewFileList] = useState<any>([]);
	const [checkFileType, setCheckFileType] = useState<boolean>(false)
	useEffect(() => {
		setNewFileList([])
		if (fileList) {
			let tempFileList = fileList;
			if (!Array.isArray(fileList)) {
				tempFileList = [fileList];
			}

			tempFileList = tempFileList.map((file: any) => {
				const name=file?.name
				if (file && typeof file === "string" && !name?.endsWith(".gif")) {
					return {
						status: "done",
						url: file,
					};
				} else if(!name?.endsWith(".gif")){
					return file;
				}
			});
			setNewFileList(tempFileList?.filter((d:any)=>d!=undefined));
		}
	}, [fileList]);

	const uploadButton = (
		<div>
			{<PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const onPreview = async (file: any) => {
		let src = file.url as string;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj as RcFile);
				reader.onload = () => resolve(reader.result as string);
			});
		}
		if (file.type === 'application/pdf' || src.includes(".pdf") || src.includes(".PDF")) {
			const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj)
			window.open(url)
		} else if (src.includes(".doc")) {
			window.open(src);
		} else {
			const image = new Image();
			image.src = src;
			const imgWindow = window.open(src);
			imgWindow?.document.write(image.outerHTML);
		}
	};

	const wrapperOnChange = (e: any) => {
		const arr = e.fileList.map((data: any) => {
			return data?.size;
		});
		const sum = arr.reduce(
			(accumulator: any, currentValue: any) =>
				accumulator + currentValue,
			0
		);

		const filteredFileList = e.fileList.slice(-10)
		if (
			filteredFileList.length !== e.fileList.length ||
			sum > 1000000000
		) {
			message.destroy()
			message.warning(
				'You can upload a maximum of 10 files, with a total size limit of 1GB'
			)
		} else if (checkFileType == true) {
			message.destroy()
			message.error(
				'Invalid image format, it allows PNG, JPG , JPEG, PDF, DOC, DOCX only'
			)
		} else {
			setNewFileList(e.fileList)
		}
		setCheckFileType(false);
		onChange && onChange()
	}

	const wrapperBeforeUpload = (file: RcFile) => {
		const isJpgOrPng =
			file.type === IMAGE_TYPE.jpeg ||
			file.type === IMAGE_TYPE.png ||
			file.type === IMAGE_TYPE.jpg ||
			file.type === IMAGE_TYPE.pdf ||
			file.type === IMAGE_TYPE.doc ||
			file.type === IMAGE_TYPE.docx
		setCheckFileType(!isJpgOrPng)
		if (!isJpgOrPng) {
			message.error(
				'Invalid image format, it allows PNG, JPG , JPEG, PDF, DOC, DOCX only'
			)
		}
		return false
	};

	const getValueFromEvent = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		if (maxCount == 1) {
			return e && e.fileList[0];
		} else {
			return e && e.fileList;
		}
	};

	if (formProps?.required) {
		rules.filter((rule: any) => rule.required).length === 0 && rules.push(validations.required.other());
	}

	return (
		<InputWrapper
			rules={rules}
			getValueFromEvent={getValueFromEvent} {...formProps}
			className="input-height-auto"
		>
			<Upload
				disabled={disabled}
				name={formProps.name}
				accept={accept}
				maxCount={maxCount}
				onPreview={onPreview}
				fileList={newFileList && newFileList}
				showUploadList={newFileList && showUploadList}
				multiple={multiple}
				listType={listType}
				onChange={wrapperOnChange}
				beforeUpload={beforeUpload ? beforeUpload : wrapperBeforeUpload}
				onRemove={onRemove}
				{...inputPropsRest}
			>
				{newFileList.length < maxCount && uploadButton}
			</Upload>
		</InputWrapper>
	);
};

export default UploadInputBox;