import React, { useEffect, useState } from 'react'
// import ToolBar from '../../../../../../../../components/ToolBar'
// import { Report } from '../../../../../../../../config/BreadcrumbConfig'
import ContentBox from '../../../../../../../../components/ContentBox/ContentBox'
import Listing from './Listing/Listing'
import { Button } from 'antd'
import { ReactComponent as PlusIcon } from '../../../../../../../../assets/images/icon/plusIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from '../../../../../../../../components/ToolBar';
import { BindingWireReportBreadcrumb } from '../../../../../../../../config/BreadcrumbConfig';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../../../store/app';
import { setPerPage, setReportId, setBindingReportId, setLocation, deleteReport, setId, setReadReport, setDateRange, getDateRangeData } from '../../utils/slice';
import RecordPerPage from '../../../../../../../../components/ToolBar/Dropdown/RecordPerPage';
import SVGIcon from '../../../../../../../../utils/SVGIcon';
import { ModuleInfoProps } from '../../../../../../../../config/InterfacesAndTypes';
import { checkPrivileges } from '../../../../../../../../config/Global';
import PageSpinner from '../../../../../../../../components/PageSpinner/PageSpinner';
import DeleteModal from '../../../../../../../../components/Modals/DeleteModal';
import { InputBox } from '../../../../../../../../components/AntdAddons';
import { RangePickerProps } from 'antd/es/date-picker';
import { CONSTANT } from '../../../../../../../../config/Constant';
import dayjs from 'dayjs';
import { ReactComponent as RestoreIcon } from "../../../../../../../../assets/images/icon/refresh.svg";
import { setTab } from '../../../../../utils/slice';
// import { fetchReportList } from '../../utils/slice';

const moduleName = 'Report'

const AddWireReportComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { BINDINGWIRE, AUTH } = useSelector((state: RootState) => state);
	const dispatch: AppDispatch = useDispatch();
	const { bindWiid }: { projectId?: any; bindWiid?: any } =
		useParams();
	const navigate = useNavigate();
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const [selectedDate, setSelectedDate] = useState(null)

	const handleViewClick = () => {
		dispatch(setReadReport(null))
		bindWiid ? navigate(`/projects/raw-material/${bindWiid}/${BINDINGWIRE?.bindingReport}/view-binding-wire-report`) : navigate(`/projects/raw-material/${BINDINGWIRE?.bindingReport}/view-binding-wire-report`)

	}
	const onRowClick = (data: any) => {
		dispatch(setBindingReportId(data?.data?.id))
		setDeleteData(data?.data)

	}
	const handleClick = () => {
		navigate(`/projects/raw-material/${bindWiid}/add-binding-wire-report`)
	}
	useEffect(() => {
		dispatch(setBindingReportId(null))
		dispatch(setReportId(bindWiid ? { id: bindWiid } : { project_id: localStorage.getItem('projectId') }))
		dispatch(setLocation("/binding-wire-report"))
		dispatch(setId(localStorage.getItem("projectId")));
		dispatch(setTab(5))
	}, [])

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setBindingReportId(null))
	}
	const onChangeDateRange: RangePickerProps["onChange"] = (date: any) => {
		setSelectedDate(date)
		date?dispatch(setDateRange({ start_date: dayjs(date[0]).format(CONSTANT.POST_DATE_FORMAT), end_date: dayjs(date[1]).format(CONSTANT.POST_DATE_FORMAT) })):
		dispatch(setDateRange({start_date:null,end_date:null}))
		dispatch(getDateRangeData())
	}
	const resetFilter = () => {
		dispatch(setDateRange(null))
		setSelectedDate(null)
		dispatch(getDateRangeData())
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar breadcrumbs={BindingWireReportBreadcrumb(bindWiid)}>
						<div className='listBtn'>
							{bindWiid && checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) && <Button className='secondaryBtn' onClick={handleClick}>
								Add
								<span className='btnIcon'><PlusIcon /></span>
							</Button>}
							<RecordPerPage
								defaultValue={BINDINGWIRE?.reportPerPageSize + " Per Page "}
								onChange={(perpageSize: number) => {
									dispatch(setPerPage(perpageSize))
								}}
							/>
						</div>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							<div className="auditListWrapper reportList">
								<div className="btnList">

									<div className="listRangePicker">
										<InputBox.DateRangePicker value={selectedDate} onChange={onChangeDateRange} />
									</div>
									<Button onClick={resetFilter}>
										<RestoreIcon height={22} width={22} />
									</Button>
								</div>
								<div className="auditList">

									<Listing
										rowData={BINDINGWIRE.reportRowData}
										moduleName={moduleName}
										//  handleViewClick={handleViewClick}
										onRowClick={onRowClick} />
								</div>

								<DeleteModal
									title={"binding wire report"}
									deleteValues={deleteData}
									callApi={deleteReport}
									close={closeDeleteModal}
									open={deleteUser}
								/>
							</div>
							<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow borderActionRow" : ""}>
								<div className="actionBtn">
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
										<Button
											onClick={handleViewClick}
											disabled={BINDINGWIRE?.bindingReport === null}
											className={BINDINGWIRE?.bindingReport ? "activeBtn" : "disableBtn"}
										>
											View
											<SVGIcon icon="view" />
										</Button>}
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
										<Button
											onClick={() => setDeleteUser(true)}
											disabled={BINDINGWIRE?.bindingReport === null}
											className={BINDINGWIRE?.bindingReport ? "deleteBtn" : "disableDelBtn disableBtn"}
										>
											Delete
											<SVGIcon icon="delete" />
										</Button>}
								</div>
							</div>
						</div>
					</ContentBox></>}
		</>
	)
}

export default AddWireReportComponent;
