import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import {
    createdAtColumn,
    idColumn,
} from '../../../../../utils/commonColumns'
import { renderNA } from '../../../../../utils/commonFunctions'
import { listStatus } from '../../../../../config/Global'
import { setFilter } from '../../utils/slice'
import SwitchCellRenderer from '../../../../../components/Switch'

const SolidSlabNameColumn: ColDef = {
    field: 'input_value',
    headerName: 'Solid Slab No',
    valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
    tooltipValueGetter: (params: any) => params.data.input_value,
}

const StatusColumn: ColDef = {
    field: 'is_submit',
    headerName: 'Status',
    cellRenderer: 'statusRender',
    filter: "agSetColumnFilter",
    filterParams: {
        values: (props: any) => {
            if (listStatus) {
                setFilter(listStatus)
                props.success(listStatus.map(x => x.name));
            }
        },
    },
}

const ActiveColumn: ColDef = {
	field: "status",
	headerName: "Is Active?",
	sortable: false,
	width: 100,
	minWidth: 100,
	cellRenderer: SwitchCellRenderer,
	filter: "agSetColumnFilter",
	filterParams: {
		values: [0, 1],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Active" && value === 1) || (filter === "InActive" && value === 0)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Active" : params.value === 0 ? "InActive" : params.value;
		},
	},
	tooltipValueGetter: (params: any) => {
		// Add tooltip content for the status column
		return params?.data?.role_name ? params.data.status === 1 ? "Active" : "Inactive" : "Please assign role"
	},
};

const columnDefs: ColDef[] = [
    idColumn,
    SolidSlabNameColumn,
    createdAtColumn,
    ActiveColumn,
    StatusColumn,
]
export default columnDefs
