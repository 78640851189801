import React, { useEffect, useRef, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { QuestionMasterBreadcrumb } from "../../../config/BreadcrumbConfig";
import { Button, Popover, Segmented } from "antd";
import { ReactComponent as AdvanceFilterIcon } from "../../../assets/images/icon/advanceFilterIcon.svg";
import FilterComponent from "./components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSequence,
  setAdvanceFilterValues,
  setElementId,
  // setElementId,
  setTab,
  setupGrid,
} from "./utils/slice";
import TabList from "./components/QuestionTabElements/TabListing/Listing";
import ChangeSequence from "./components/Forms/ChangeSequence";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import { getDepartmentList } from "../Project/utils/slice";
import { ReactComponent as ArrowIcon } from '../../../assets/images/icon/arrowIcon.svg';

const QuestionMaster: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const queryLocation = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [seqDrawer, setSeqDrawer] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const { QUESTIONMASTER, AUTH } = useSelector((state: RootState) => state);
  const contentWrapper = useRef<HTMLDivElement | null>(null);
  const items = QUESTIONMASTER.count.categoryList;
  const navigate = useNavigate();
  const [total, setTotal] = useState(0)

  //open change sequence modal
  const handleSequClick = () => {
    dispatch(getSequence());
    setSeqDrawer(true);
  };

  //close the sequence drawer
  const closeSeqDrawer = () => {
    setSeqDrawer(false);
  };

  //open add question page
  const handleAddClick = () => {
    navigate("/add-question");
  };

  //filter data
  const openFilterModal = (
    <div className="filterPopoverRow">
      <FilterComponent setFilterVisible={setFilterVisible} />
    </div>
  );

  useEffect(() => {
    if (queryLocation.state?.department) {
      const { department } = queryLocation.state;
      dispatch(
        setAdvanceFilterValues({
          department: {
            id: department.department_id,
            department_name: department.department_name,
          },
        })
      );
    }
    // dispatch(setupGrid(QUESTIONMASTER.agGrid));
    // return () => dispatch(setAdvanceFilterValues(null));
  }, [queryLocation  /*setAdvanceFilterValues */]);

  const toggleAdvanceFilter = (open: any) => {
    setFilterVisible(open);
  };

  useEffect(() => {
    dispatch(getDepartmentList());
    dispatch(
      setAdvanceFilterValues({
        department: {
          id: 1,
          department_name: "Bridge",
        },
      })
    );
    dispatch(getSequence());
  }, []);

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handlePrev = () => {
    dispatch(setTab((Number(QUESTIONMASTER?.tab) - 1)))
    if (contentWrapper.current) {
      sideScroll(contentWrapper.current, 20, 110, -10);
    }

  }
  const handleNext = () => {
    setTotal((prev: any) => Number(prev))
    if (items.length === total + 1) {
      dispatch(setTab((Number(0))))
    }
    else {
      dispatch(setTab((Number(QUESTIONMASTER?.tab) + 1)))
    }
    if (contentWrapper.current) {
      sideScroll(contentWrapper.current, 20, 110, 10);
    }

  }

  return (
    <>
      {!AUTH?.userDetail ? (
        <PageSpinner />
      ) : (
        checkPrivileges(
          AUTH.userDetail,
          `${moduleInfo.permissionPrefix}_INDEX`
        ) && (
          <>
            <ToolBar breadcrumbs={QuestionMasterBreadcrumb()}>
              <Popover
                placement="bottomLeft"
                content={openFilterModal}
                open={filterVisible}
                onVisibleChange={toggleAdvanceFilter}
                trigger={"click"}
                overlayClassName="filterPopover"
              >
                <Button key="1" className="filterBtn">
                  <AdvanceFilterIcon />
                </Button>
              </Popover>
              {checkPrivileges(
                AUTH.userDetail,
                `${moduleInfo.permissionPrefix}_CREATE`
              ) && (
                  <CreateButton
                    action={() => handleAddClick()}
                    permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                  />
                )}
              {checkPrivileges(
                AUTH.userDetail,
                `${moduleInfo.permissionPrefix}_CHANGE_SEQUENCE`
              ) && (
                  <Button className="secondaryBtn" onClick={handleSequClick}>
                    Change Sequence
                  </Button>
                )}
            </ToolBar>
            <ContentBox>
              <ChangeSequence sequVisible={seqDrawer} close={closeSeqDrawer} />
              <div className='tabElementWrapper questionWrapper'>
                <div className="headerTabs questionTabs">
                  {
                    items?.length > 0 && (
                      <Button onClick={handlePrev} className='leftArrow' disabled={QUESTIONMASTER?.tab === 1}><ArrowIcon /></Button>
                    )
                  }
                  <Segmented
                    options={items}
                    value={QUESTIONMASTER?.tab}
                    onChange={(tab: any) => {
                      dispatch(setElementId(tab));
                      setTab(tab);
                      dispatch(setTab(tab));
                      dispatch(setupGrid(QUESTIONMASTER.agGrid))
                    }}
                    ref={contentWrapper}
                  />
                  {
                    items?.length > 0 &&
                    (
                      <Button onClick={handleNext} className='rightArrow' disabled={QUESTIONMASTER?.tab === items?.length}><ArrowIcon /></Button>
                    )
                  }

                </div>
                <div className="mainWrapper">
                  {QUESTIONMASTER.elementId && (
                    <TabList
                      rowData={QUESTIONMASTER.rowData}
                      moduleInfo={moduleInfo}
                    />
                  )}
                </div>
              </div>
            </ContentBox>
          </>
        )
      )}
    </>
  );
};

export default QuestionMaster;
