import React, { useEffect, useState } from 'react'
import Listing from './Listing/Listing'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'antd'
import { ReactComponent as PlusIcon } from '../../../../../../assets/images/icon/plusIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../store/app'
import {
	setProjectId,
	setLocation,
	setPerPage,
	setPolymerId,
	deleteRecord,
	setRead,
} from './utils/slice'
import RecordPerPage from '../../../../../../components/ToolBar/Dropdown/RecordPerPage'
import SVGIcon from '../../../../../../utils/SVGIcon'
import RestrictedAccessPage from '../../../../../errors/RestrictedAccessPage'
import PageSpinner from '../../../../../../components/PageSpinner/PageSpinner'
import { checkPrivileges } from '../../../../../../config/Global'
import DeleteModal from '../../../../../../components/Modals/DeleteModal'

const PolymerSluryTab: React.FC<any> = ({ permissionPrefix }) => {
	const navigate = useNavigate()
	const { projectId } = useParams()
	const dispatch: AppDispatch = useDispatch()
	const { POLYMERSLURRYTAB, AUTH } = useSelector(
		(state: RootState) => state
	)
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData,setDeleteData]=useState()

	useEffect(() => {
		dispatch(setProjectId(localStorage.getItem('projectId')))
		dispatch(setLocation(''))
		dispatch(setPolymerId(null))
	}, [projectId])

	const handleView = () => {
		dispatch(setRead(null))
		navigate(
			`/projects/raw-material/${POLYMERSLURRYTAB?.polymerId}/view-polymer-slurry`
		)
	}
	const handleClick = () => {
		dispatch(setRead(null))
		navigate(`/projects/raw-material/add-polymer-slurry`)
	}

	const onRowClick = (data?: any) => {
		dispatch(setPolymerId(data?.data?.id))
		setDeleteData(data?.data)
	}

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setPolymerId(null))
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) ? <>
				<div className="rawListWrapper">
					<div className="rawList">
						<div className="btnWrap">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn steelBtn" onClick={handleClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={POLYMERSLURRYTAB.perPageSize + ' per page'}
								onChange={(perPageSize: number) =>
									dispatch(setPerPage(perPageSize))
								}
							/>
						</div>
						<Listing
							rowData={POLYMERSLURRYTAB.rowData}
							onRowClick={onRowClick}
						/>
						<DeleteModal
							title={"polymer slury"}
							deleteValues={deleteData}
							callApi={deleteRecord}
							close={closeDeleteModal}
							open={deleteUser}
						/>
					</div>

					<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}}_DELETE`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`)?"actionRow  rawmaterialActionRow":""}>

						<div className="actionBtn">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
								<Button
									onClick={handleView}
									disabled={POLYMERSLURRYTAB?.polymerId === null}
									className={
										POLYMERSLURRYTAB?.polymerId
											? 'activeBtn'
											: 'disableBtn'
									}
								>
									View
									<SVGIcon icon="view" />
								</Button>}

							{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}}_DELETE`) &&
							<div>
								<Button
									disabled={POLYMERSLURRYTAB?.polymerId ? false : true}
									onClick={() => setDeleteUser(true)}
									className={POLYMERSLURRYTAB?.polymerId ? "deleteBtn" : "disableDelBtn disableBtn"}
								>
									Delete
									<SVGIcon icon="delete" />
								</Button>
							</div>}
						</div>
					</div>
					</div>
				</> : <RestrictedAccessPage />}
		</>
	)
}

export default PolymerSluryTab
