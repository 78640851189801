import { Checkbox } from "antd";
import React from "react";
import { defaultOptionKeys, defaultSeparator, InputWrapper, SplitInputWrapperProps } from "../../functions";
import { CheckboxInputBoxProps } from "./interface";
// import { toLowerCaseWithUnderscore } from "../../../AgGridWrapper/utils/commonFunctions";
import { validations } from "../../../../config/validations/validations";

const CheckboxInputBox: React.FC<CheckboxInputBoxProps> = (props) => {
	const {
		formProps: { rules = [], ...formProps },
		inputProps,
	} = SplitInputWrapperProps(props);
	const { options, ...rest } = inputProps;

	const valueKey = options?.valueKey || defaultOptionKeys.value;
	const textKey = options?.textKey || defaultOptionKeys.text;
	const separator = options?.separator || defaultSeparator;
	let textKeys: any[] = [];
	if (Array.isArray(options?.textKey)) {
		textKeys = options?.textKey;
	}

	const renderOptions = options?.list?.map((option: any) => {
		if (
			options?.rejectedValues &&
			options?.rejectedValues.includes(option[valueKey]) &&
			!options?.requiredValues.includes(option[valueKey])
		) {
			return null;
		}
		const renderOptionText =
			textKeys.length <= 0 ? option[textKey] : textKeys.map((text_key) => option[text_key] || text_key).join(separator);

		return (
			<Checkbox key={option[valueKey]} value={option[valueKey]}>
				{renderOptionText}
			</Checkbox>
		);
	});

	if (formProps?.required) {
		rules.filter((rule: any) => rule.required).length === 0 && rules.push(validations.required.select());
	}

	const newFormProps = Object.assign({}, formProps);
	!options?.list && newFormProps.label && delete newFormProps.label;
	!options?.list && (newFormProps.valuePropName = "checked");

	return (
		<InputWrapper
			//   name={formProps?.label && toLowerCaseWithUnderscore(formProps?.label)}
			rules={rules}
			{...newFormProps}
		>
			{options?.list ? (
				<Checkbox.Group {...rest}>
					{renderOptions}
				</Checkbox.Group>
			) : (
				<Checkbox {...rest}>
					{formProps.label}
				</Checkbox>
			)}
		</InputWrapper>
	);
};

export default CheckboxInputBox;
