import { Button, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import {
  createRecord,
  getDynamicConcreatData,
  getMixDesignData,
} from "../../../utils/slice";
import { assignErrorToInput } from "../../../../../../store/api";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import {
  dataToFormDataConverter,
  validateFields,
} from "../../../../../../config/Global";
import DynamicField from "./DynamicField";

interface ComponentProps {
  form: any;
  // dynamicConcreatData: any;
  onChange: any;
  handleSubmit: any;
}

const FirstStep: React.FC<ComponentProps> = () => {
  const navigate = useNavigate();
  const { mappingId, structureId, mName } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getDynamicConcreatData(mappingId));
  }, [mappingId]);

  const dynamicConcreatData = useSelector(
    (state: RootState) => state.CONCRETING.dynamicConcreatData
  );

  const [categoryData, setCategoryData] = useState<any>([]);

  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const pro_id: any = localStorage.getItem("projectId");
  const [formGroups, setFormGroups] = useState<any[]>([]);

  useEffect(() => {

    // if (!dynamicConcreatData?.concreting_data) {
    if (dynamicConcreatData?.concreting_data?.length <= 0 || dynamicConcreatData?.concreting_data == null) {
      dispatch(getMixDesignData(pro_id, structureId)).then(
        (response: { data: any }) => {
          setCategoryData(response.data);
        }
      );
    }
    form.resetFields();
    const groups: any = {};
    dynamicConcreatData?.data?.forEach((item: any) => {
      if (!groups[item.qa_params_group_id]) {
        groups[item.qa_params_group_id] = [];
      }
      groups[item.qa_params_group_id].push(item);
    });
    setFormGroups(Object.values(groups));

    if (dynamicConcreatData?.concreting_data) {

      dynamicConcreatData.concreting_data.forEach((val: any, index :any) => {
        const list = val.project_document?.map(
          (image: { id: any; document: any }) => {
            return {
              name: image?.document,
              status: "done",
              url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
              thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
            };
          }
        );
        if (list.length > 0) {
          // form.setFieldValue(
          //   [`${val.qa_params_id}`, "value"],
          //   val?.input_value
          // );

          if (
            dynamicConcreatData.data[index].input_type == "Date Picker" ||
            dynamicConcreatData.data[index].input_type == "Date"
          ) {
            form.setFieldValue(
              [`${val.qa_params_id}`, "value"],
              dayjs(val?.input_value)
            );
          } else {
            form.setFieldValue(
              [`${val.qa_params_id}`, "value"],
              val?.input_value
            );
          }
  

          form.setFieldValue(
            [`${val.qa_params_id}`, "remark"],
            val?.remark_notes
          );
          form.setFieldValue([`${val.qa_params_id}`, val.qa_params_id], list);
        }

        if (
          val.qa_params_id == 27 &&
          val.qa_params_value_mapping_id == mappingId
        ) {
          form.setFieldValue(
            [`${val.qa_params_id}`, "value"],
            val?.input_value
          );

          const datas: any = [];
          dispatch(getMixDesignData(pro_id, structureId)).then(
            (response: { data: any }) => {
              datas.push(response.data);
              response?.data?.map((data?: any) => {
                if (data?.id == val?.remark_notes) {
                  form.setFieldValue(
                    [`${val.qa_params_id}`, "remark"],
                    data?.name
                  );
                }
              });
            }
          );
        } else {
          // form.setFieldValue(
          //   [`${val.qa_params_id}`, "value"],
          //   val?.input_value
          // );
          if (
            dynamicConcreatData.data[index].input_type == "Date Picker" ||
            dynamicConcreatData.data[index].input_type == "Date"
          ) {
            form.setFieldValue(
              [`${val.qa_params_id}`, "value"],
              dayjs(val?.input_value)
            );
          } else {
            form.setFieldValue(
              [`${val.qa_params_id}`, "value"],
              val?.input_value
            );
          }
          form.setFieldValue(
            [`${val.qa_params_id}`, "remark"],
            val?.remark_notes
          );
        }
      });
    }
  }, [dynamicConcreatData]);

  const handleCancel = () => {
    navigate(`/projects/${structureId}/${mName}`);
  };

  //format date
  const formatDate = (date: any) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  // Function to traverse the nested data structure and format dates
  const formatDatesInData = (data: any) => {
    for (const key in data) {
      if (typeof data[key] === "object" && data[key] !== null) {
        if (data[key].value && data[key].value.$isDayjsObject) {
          data[key].value = formatDate(data[key].value);
        } else {
          formatDatesInData(data[key]);
        }
      }
    }
  };

  const onFinish = (data: any) => {
    formatDatesInData(data);
    const payload = {
      project_id: parseInt(pro_id ?? ""),
      qa_category_id: 11,
      structure_id: structureId,
      data: data,
      qa_params_value_mapping_id: parseInt(mappingId ?? ""),
    };
    const formData = dataToFormDataConverter(payload);
    setLoading(true);
    setDisable(true);
    dispatch(createRecord(payload.qa_params_value_mapping_id, formData))
      .then(() => {
        setLoading(false);
        setDisable(false);
        navigate(`/projects/${structureId}/${mName}`);
      })
      .catch((error: any) => {
        setLoading(false);
        setDisable(false);
        assignErrorToInput(form, error?.STATUS);
      });
  };

  const validateForm = () => {
    validateFields(form, setDisable);
  };

  return (
    dynamicConcreatData?.data && (
      <>
        <Form
          form={form}
          onFinish={onFinish}
          className="Concform"
          onValuesChange={validateForm}
        >
          {formGroups.map((groupItems: any[], groupId: number) => (
            <div key={groupId}>
              <div className="projectTitle">
                <h2>
                  <span>{groupItems[0]?.qa_params_group_name}</span>
                </h2>
              </div>
              <Row gutter={30}>
                {groupItems.map((item: any, index: number) => (
                  <DynamicField
                    key={index}
                    item={item}
                    dynamicConcreat={dynamicConcreatData}
                    form={form}
                    projectDocumentImages={(item?.project_document || []).map(
                      (image: any) => ({
                        name: image.document,
                        status: "done",
                        url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
                        thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
                      })
                    )}
                    categoryData={categoryData}
                  />
                ))}
              </Row>
            </div>
          ))}
          <Row justify={"end"}>
            <Button
              htmlType="submit"
              className="secondaryBtn"
              loading={loading}
              disabled={disable}
            >
              Save
            </Button>
            <Button className="secondaryBtn" onClick={handleCancel}>
              Back
            </Button>
          </Row>
        </Form>
      </>
    )
  );
};

export default FirstStep;
