import React from "react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import columnDefs from "./columnDefs";
import { setGrid } from "../../utils/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/app";

const Listing: React.FC<any> = ({
	rowData,
	onRowClick
}) => {
	const { QA_PARAMS_GROUP } = useSelector((state: RootState) => state)
	return (
		<>
			<AgGridWrapper
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setGrid}
				rowSelection={"single"}
				onRowClicked={onRowClick}
				paginationPageSize={QA_PARAMS_GROUP?.perPageSize}
				cacheBlockSize={QA_PARAMS_GROUP?.perPageSize}
			/>
		</>
	);
};

export default Listing;
