import React, { useEffect } from "react";
import ToolBar from "../../../components/ToolBar";
import { MixDesign } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
// import Listing from './Listing/Listing'
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";
import { ReactComponent as DataNotFound } from "../../../assets/images/icon/datanotFound.svg";
import { useNavigate } from "react-router-dom";
import TableList from "./Element/TableList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import {
	fetchList,
	setFormData,
	setViewData,
} from "../RawMaterial/utils/slice";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";

const MixDesignComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { RAWMATERIAL, AUTH } = useSelector((state: RootState) => state);
	const [saving, setSaving] = React.useState<boolean>(false);

	const handleClick = () => {
		dispatch(setViewData(null));
		dispatch(setFormData(null))
		navigate(`/projects/mix-design/add-mixdesign`);
	};
	useEffect(() => {
		dispatch(fetchList(localStorage.getItem('projectId')));
	}, []);

	useEffect(() => {
		RAWMATERIAL.rowData == null ? setSaving(false) : setSaving(true);
	}, []);

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar breadcrumbs={MixDesign()}>
						<div className="diableBtnWrap"></div>
					</ToolBar>
					{saving == false && RAWMATERIAL.rowData == null ? (
						<PageSpinner />
					) : (
						<ContentBox>

							{RAWMATERIAL.rowData?.length > 0 ? (
								<div className="mixListTable">
									{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
										<div className="mixBtn">
											<Button
												className="secondaryBtn"
												onClick={handleClick}
												//disabled={!RAWMATERIAL?.isDisabled}
											>
												Add
												<span className="btnIcon">
													<PlusIcon />
												</span>
											</Button>
										</div>
									}
									<TableList rowData={RAWMATERIAL?.rowData} projectId={localStorage.getItem('projectId')} permission={moduleInfo.permissionPrefix} />
								</div>
							) : (
								<div className="listing">
									{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
										<div className="listBtn">
											<Button
												className="secondaryBtn"
												onClick={handleClick}
												//disabled={!RAWMATERIAL?.isDisabled}
											>
												Add
												<span className="btnIcon">
													<PlusIcon />
												</span>
											</Button>
										</div>}
									<div className="dataNotFoundBg">
										<span>
											<DataNotFound />
										</span>
										<p>No Data Found, Please Add Mix Design</p>
									</div>
								</div>
							)}

						</ContentBox>
					)}</>}
		</>
	);
};

export default MixDesignComponent;
