import React from "react";
import ToolBar from "../../../components/ToolBar";
import { RawMaterial } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import TabElements from "./components/TabElements";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";

// const moduleName = 'Row Material'

const RowMaterial: React.FC<ModuleInfoProps> = (moduleInfo) => {
  //const { projectId } = useParams();
  return (
    <>
      <ToolBar breadcrumbs={RawMaterial()}>
        <div className="diableBtnWrap"></div>
      </ToolBar>
      <ContentBox>
        <TabElements moduleInfo={moduleInfo} />
      </ContentBox>
    </>
  );
};

export default RowMaterial;
