import React, { useEffect } from "react";
import { Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { rules } from "./rules";
import { getDepartmentList } from "../../utils/slice";

interface FormComponentProps {
  form: any;
  id: string;
  handleSubmit: any;
  onValuesChange: any;
  editValues?: any;
}

const FormComponent: React.FC<FormComponentProps> = ({
  form,
  id,
  handleSubmit,
  onValuesChange,
  editValues,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { DEPARTMENT_TYPE } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getDepartmentList());
  }, []);

  useEffect(() => {
    if (editValues) {
      form.setFieldsValue({
        department_id: editValues.department_id,
        type_name: editValues.type_name,
      });
    }
  }, [editValues, form]);

  const onFinish = (data: any) => {
    handleSubmit(data);
  };

  return (
    <>
      <FormBox
        form={form}
        id={id}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row gutter={25}>
          <InputBox.Select
            label="Name of Department"
            name="department_id"
            required
            options={{
              list: DEPARTMENT_TYPE.departmentList,
              valueKey: "id",
              textKey: "department_name",
            }}
            rules={rules.selectFields()}
          />
        </Row>

        <Row gutter={25}>
          <InputBox.Text
            label="Department type"
            name="type_name"
            rules={rules.dynamicFields()}
            onChange={onValuesChange}
          />
        </Row>
      </FormBox>
    </>
  );
};

export default FormComponent;
