export const CONSTANT = {
  DATE_FORMAT: "DD-MM-YYYY",
  TIME_FORMAT: "HH:mm",
  DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
  POST_DATE_FORMAT: "YYYY-MM-DD",
  POST_TIME_FORMAT: "HH:mm",
  POST_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm",

  PER_PAGE_RECORD: [
    { id: "2", value: "2 Per Page" },
    // { id: "10", value: "10 Per Page" },
    { id: "50", value: "50 Per Page" },
    { id: "100", value: "100 Per Page" },
    { id: "500", value: "500 Per Page" },
    { id: "1000", value: "1000 Per Page" },
  ],
};

export const IMAGE_TYPE = {
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  png: "image/png",
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword"
};

export const TYPE_DEPT_DASHBOARD = {
  Bridge: "Bridge",
  Building: "Building",
  Road: "Road",
  Water: "Water",
  Drainage: "Drainage"
}