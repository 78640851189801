import { validations } from '../../../../../../../config/validations/validations'

export const rules = {
    dynamicFields: (field?: string) => [
        validations.required.text(field),
        validations.minMax()
    ],
    numericFields: (field?: any) => [
        validations.required.other(field),
        validations.pattern.decimal(2),
    ],
    FileUpload: (field?: any) => [
        validations.required.text(field),
    ],
    selectFields: (field?: any) => [
		validations.required.select(field),
	],
}
