import React from 'react'
import ContentBox from '../../../components/ContentBox/ContentBox'
import AddCubeTesting from './Forms/AddCubeTesting'

const CubeTesting: React.FC<any> = () => {
	return (
		<div className="mainDetailsWrapper">
			<ContentBox>
				<AddCubeTesting />
			</ContentBox>
		</div>
	)
}

export default CubeTesting
