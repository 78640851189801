import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { TestTypeBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./Components/Listing/Listing";
import { Button } from "antd";
import { deleteRecord, fetchList, setTestTypeData, setPerPageSize, setRead, refreshGrid } from "./utils/slice";
import AddComponent from "./Components/Forms/AddComponent";
import EditComponent from "./Components/Forms/EditComponent";
import DeleteModal from "../../../components/Modals/DeleteModal";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";

const moduleName = "Test Type";

const TestType: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { TESTTYPE, AUTH } = useSelector((state: RootState) => state);
	const [addForm, setAddForm] = useState<any>(false);
	const [editForm, setEditForm] = useState<any>(false);
	const [deleteModal, setDeleteModal] = useState<any>(false)

	const onRowClick = (data?: any) => {
		dispatch(setTestTypeData(data.data));
	};

	useEffect(() => {
		dispatch(fetchList());
		dispatch(setTestTypeData(null));
	}, []);

	const handleClick = () => {
		setAddForm(true);
	};

	const closeModal = () => {
		setAddForm(false);
		setEditForm(false);
		dispatch(refreshGrid)
	};

	const closeDeleteModal = () => {
		setDeleteModal(false)
		dispatch(setTestTypeData(null))
		dispatch(refreshGrid)
	}

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={TestTypeBreadcrumb}>
						<>
							{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
								<CreateButton
									action={() => handleClick()}
									permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
								/>}
							<RecordPerPage
								defaultValue={TESTTYPE.perPageSize + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setPerPageSize(perPageSize));
								}}
							/>{" "}
						</>
					</ToolBar>
					<ContentBox>
						<AddComponent
							titleName={moduleName}
							open={addForm}
							close={closeModal}
						/>

						<EditComponent
							titleName={moduleName}
							open={editForm}
							close={closeModal}
						/>
						<DeleteModal
							title={moduleName}
							deleteValues={TESTTYPE?.testTypeData}
							callApi={deleteRecord}
							close={() => closeDeleteModal()}
							open={deleteModal}
						/>
						<div className="listWrapper">
						<div className="list">
							<Listing
								moduleName={moduleName}
								handleEditClick={(data: any) => {
									dispatch(setRead(data));
									setEditForm(true);
								}}
								rowData={TESTTYPE.rowData}
								onRowClick={onRowClick}
							/>
						</div>
						<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
							<div className="actionBtn">
								{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
									<Button
										onClick={() => {
											dispatch(setRead(TESTTYPE.testTypeData));
											setEditForm(true);
										}}
										disabled={TESTTYPE.testTypeData ? false : true}
										className={TESTTYPE.testTypeData ? "activeBtn" : "disableBtn"}
									>
										Edit
										<SVGIcon icon="edit" />
									</Button>}
								{/* <Button
							disabled={TESTTYPE.testTypeData ? false : true}
							onClick={() => {
								dispatch(setTestTypeData(TESTTYPE.testTypeData))
							}}
							className={TESTTYPE.testTypeData ? "activeBtn deleteBtn" : "disableBtn deleteBtn"}
						>
							Delete
							<SVGIcon icon="delete" />

						</Button> */}
							</div>
						</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</>
	);
};

export default TestType;