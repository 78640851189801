import React from "react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import columnDefs, { drainageColumnDefs } from "./columnDefs";
import {  setupGrid } from "../utils/slice";

const Listing: React.FC<any> = ({
	rowData,
	onRowClick
}) => {
	const deptId:any=localStorage.getItem("department_id")
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={deptId==1?columnDefs:drainageColumnDefs}
				onGridReadyWithDispatch={setupGrid}
				onRowClicked={onRowClick}
				rowSelection={"single"}
			/>
		</>
	);
};

export default Listing;
