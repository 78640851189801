import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../../../components/AntdAddons";
import ToolBar from "../../../../../../../../../components/ToolBar";
import { AddCementReportBreadcrumb } from "../../../../../../../../../config/BreadcrumbConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { getGradeOfconcreteList } from "../../../../../../utils/slice";
import {
	dataToFormDataConverter,
	validateFields,
} from "../../../../../../../../../config/Global";
import {
	createReport,
	getCementTestTypes,
	getLaboratoryName,
	readReport,
	setCementReportId,
	setReportId,
} from "../../../utils/slice";
import dayjs from "dayjs";
import { rules } from "./rules";
import { assignErrorToInput } from "../../../../../../../../../store/api";
import { CONSTANT } from "../../../../../../../../../config/Constant";

const AddCementReportComponent: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const { RAWMATERIAL } = useSelector((state: RootState) => state);
	const { CEMENTINVENTORY } = useSelector((state: RootState) => state);
	const {
		cementId,
		reportId,
	}: { projectId?: any; cementId?: any; reportId?: any } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const [testType, setTestType] = useState<any>([])
	const [disabled, setDisabled] = useState(true);
	const [, setState] = useState(false);
	const laboratoryList = form.getFieldValue("laboratory_type")
	const handleSubmit = (data?: any) => {
		setDisabled(true);
		setLoading(true);
		const obj = data?.observed_value
		delete data?.observed_value
		delete data?.test_type_id
		delete data?.expected_value
		const payload = {
			...data,
			date_of_testing: dayjs(data?.date_of_testing).format(CONSTANT?.POST_DATE_FORMAT),
			project_id: localStorage.getItem('projectId'),
			mst_cement_inventory_id: cementId,
			observed_value: JSON.stringify(Object.values(obj))
		};

		const formdata = dataToFormDataConverter(payload);
		try {
			form.validateFields();
			dispatch(createReport(cementId, formdata))
				.then(() => {
					setLoading(false);
					setTimeout(() => {
						form.resetFields();
						dispatch(setCementReportId(null));
						navigate(
							`/projects/raw-material/${cementId}/cement-report`
						);
					}, 1000);
				})
				.catch((error: any) => {
					setDisabled(true);
					setLoading(false);
					assignErrorToInput(form, error.STATUS);
				});
		} catch (error) {
			console.error("Error submitting data:", error);
		}
	};

	useEffect(() => {
		if (location.pathname.includes("/view-cement-report")) {
			dispatch(setReportId(cementId));
			dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 2 }));
			dispatch(getCementTestTypes(2));
		}
	}, []);
	useEffect(() => {
		if (reportId) {
			dispatch(readReport(reportId)).then(() => {
				//
			});
		}
	}, []);

	useEffect(() => {
		CEMENTINVENTORY?.readReportData &&
			CEMENTINVENTORY?.readReportData?.map((data?: any) => {
				if (data?.id == reportId) {
					CEMENTINVENTORY.cementTestType?.filter((item?: any) => {
						if (item?.id == data?.test_type_id) {
							setTestType([{ ...item }])
						}
					})
					form.setFieldValue([`observed_value`, `${data?.test_type_id}`, "observed_value"], data?.observed_value)
					form.setFieldsValue({
						mst_cement_grade_id: data?.mst_cement_grade_id,
						test_type_id: data?.test_type_id,
						laboratory_type: data?.laboratory_type,
						laboratory_name: data?.laboratory_original_name,
						test_passed: data?.test_passed,
						date_of_testing: dayjs(data?.date_of_testing),
						attach_report: data?.attached_documnent?.map(
							(image: { id: any; document_name: any }) => {
								return {
									// uid: image.id,
									name: image?.document_name,
									status: "done",
									url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
									thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								};
							}
						),
						data_sheet: data?.data_sheet_documnent?.map(
							(image: { id: any; document_name: any }) => {
								return {
									// uid: image.id,
									name: image?.document_name,
									status: "done",
									url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
									thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								};
							}
						)
					});
				}
				setState(prevState => !prevState);
			});
	}, [CEMENTINVENTORY?.readReportData, setState]);
	const handleCancel = () => {
		dispatch(setCementReportId(null));
		cementId ? navigate(`/projects/raw-material/${cementId}/cement-report`) : navigate(`/projects/raw-material/cement-report`);
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};
	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes("/view-cement-report")
						? AddCementReportBreadcrumb(cementId, "View")
						: AddCementReportBreadcrumb(cementId, "Add")
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<div className="formHeading">
				<h3>
					{location.pathname.includes("/view-cement-report")
						? "View "
						: location.pathname.includes("/add-cement-report")
							? "Add "
							: ""}
					Report
				</h3>
			</div>
			<Form
				className="addReportForm"
				onFinish={handleSubmit}
				form={form}
				onValuesChange={validateForm}
			>
				<div>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								disabled={
									location.pathname.includes("/view-cement-report")
										? true
										: false
								}
								name="mst_cement_grade_id"
								className="select textField"
								onFocus={() =>
									dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 2 }))
								}
								options={{
									list: RAWMATERIAL.cementGrageList,
									valueKey: "id",
									textKey: "name",
								}}
								label="Select Quality"
								mode={location.pathname.includes("/add-cement-report") ? "multiple" : undefined}
								allowClear
								rules={rules.selectFields()}
							/>
						</Col>

						<Col span={12}>
							<InputBox.Radio
								name="laboratory_type"
								label="Laboratory Type"
								rules={rules.radioButton()}
								onChange={(e) => {
									form.setFieldValue('laboratory_name', [])
									dispatch(getLaboratoryName(e.target.value))
								}
								}
								disabled={
									location.pathname.includes("/view-cement-report")
								}
								options={{
									list: [
										{
											id: 1,
											value: "NABL Lab",
											name: "NABL Lab",
										},
										{
											id: 2,
											value: "Government lab",
											name: "Government Lab",
										},
										{
											id: 3,
											value: "On-site Lab",
											name: "On-site Lab",
										},
									]
								}}
							/></Col>
						{laboratoryList &&
							<Col span={12}>
								<InputBox.Select
									className="select textField"
									options={{
										list: CEMENTINVENTORY.laboratoryName,
										valueKey: "id",
										textKey: "laboratory_name",
									}}
									disabled={
										location.pathname.includes("/view-cement-report")
											? true
											: false
									}
									name="laboratory_name"
									label="Name of Laboratory"
									rules={rules.selectFields()}
								/>
							</Col>}
						<Col span={12}>
							<InputBox.DatePicker
								disabled={
									location.pathname.includes("/view-cement-report")
										? true
										: false
								}
								name="date_of_testing"
								label="Date of Testing"
								rules={rules.dateFields()}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								disabled={
									location.pathname.includes("/view-cement-report")
										? true
										: false
								}
								name="test_passed"
								label="All Tests Passed As Per Code :"
								rules={rules.radioButton()}
								options={{
									list: [
										{
											id: 1,
											value: "yes",
											name: "Yes",
										},
										{
											id: 2,
											value: "no",
											name: "No",
										},
									],
								}}
							/>
						</Col>
					</Row>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								disabled={
									location.pathname.includes("/view-cement-report")
								}
								name="test_type_id"
								className="select textField"
								mode={location.pathname.includes("/add-cement-report") ? "multiple" : undefined}
								allowClear
								onFocus={() => dispatch(getCementTestTypes(2))}
								options={{
									list: CEMENTINVENTORY?.cementTestType,
									valueKey: "id",
									textKey: "name",
								}}
								onChange={(d: any) => {
									d?.map((item?: any) => {
										CEMENTINVENTORY?.cementTestType?.filter((data: any) => {
											if (item == data?.id) {
												setTestType([...testType, data])
											}
										});
									})
								}}
								onDeselect={(data?: any) => {
									const d = testType?.filter((item: any) => {
										if (data == item?.id) {
											form.setFieldValue([`observed_value`, `${item?.id}`, "observed_value"], "")
										}
										return data !== item?.id
									})
									setTestType(d)
								}
								}
								onClear={() => setTestType([])}
								label="Test Type"
								rules={rules.selectFields()}
							/>
						</Col>
						<Col span={12}></Col>
						{
							testType && testType?.map((data?: any) => {
								form.setFieldValue([`expected_value`, `${data?.id}`], data?.expected_value ? data?.expected_value : "N/A")
								form.setFieldValue([`observed_value`, `${data?.id}`, "test_type_id"], data?.id)
								return <>
									<InputBox.Text
										name={[`observed_value`, `${data?.id}`, "test_type_id"]}
										label={`Observed Value - ${data.name}`}
										disabled={
											location.pathname.includes("/view-steel-report")
										}
										className="textField"
										hidden
									/>
									<Col span={12}>
										<InputBox.Text
											name={[`observed_value`, `${data?.id}`, "observed_value"]}
											label={`Observed Value - ${data.name}`}
											disabled={
												location.pathname.includes("/view-cement-report")
											}
											rules={rules.dynamicFields()}
											className="textField"
										//Don't remove this code
										// rules={[
										//   {
										//     validator: validatevalue,
										//   },
										//   {
										//     required:true,
										//     message:"It is required."
										//   }
										// ]}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Text
											name={[`expected_value`, `${data?.id}`]}
											label={`Expected Value - ${data.name}`}
											disabled={true}
											className="textField"
										/>
									</Col>
								</>
							})
						}
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="attach_report"
								required={true}
								label="Attach Report"
								fileList={form.getFieldValue('attach_report')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-cement-report")
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="data_sheet"
								required={true}
								label="Data Sheet"
								fileList={form.getFieldValue('data_sheet')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-cement-report")
								}
							/>
						</Col>

					</Row>
					<Row>
						<div className="ReportBtn">
							<Button
								className="secondaryBtn"
								htmlType="submit"
								loading={loading}
								disabled={disabled}
							>
								Submit
							</Button>
							<Button className="secondaryBtn cancelBtn" onClick={handleCancel}>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	);
};

export default AddCementReportComponent;
