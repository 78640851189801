import React, { useEffect } from "react";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { AppDispatch, RootState } from "../../../store/app";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { checkPrivileges } from "../../../config/Global";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import SVGIcon from "../../../utils/SVGIcon";
import { useNavigate } from "react-router-dom";
import ToolBar from "../../../components/ToolBar";
import { WorkCompletedBreadcrumb } from "../../../config/BreadcrumbConfig";
import { setPerPageSize, setPlanningId, setRead, setWorkPlanId } from "./utils/slice";

const moduleName = "Work completed";

const WorkCompletedComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { AUTH, WORK_COMPLETED } = useSelector((state: RootState) => state);
	const plan_id: any = localStorage.getItem('projectId')

	const onRowClick = (data?: any) => {
		dispatch(setWorkPlanId(data?.data?.id));
	};

	const handleAddClick = () => {
		setRead(null);
		navigate(`/projects/work-completed/add`)
	}

	const handleViewClick = () => {
		dispatch(setRead(null))
		navigate(`/projects/work-completed/${WORK_COMPLETED?.workPlanId}/view`)
	}

	useEffect(() => {
		if (plan_id) {
			dispatch(setPlanningId(plan_id));
		}
	}, []);

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar breadcrumbs={WorkCompletedBreadcrumb}>

						<div className="listBtn">
							{
								checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn" onClick={handleAddClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={WORK_COMPLETED.perPage + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setPerPageSize(perPageSize));
								}}
							/>{" "}
						</div>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							{WORK_COMPLETED.planningId && 
							// <div className="auditListWrapper reportList">
								<div className="auditList workList">

									<Listing
										rowData={WORK_COMPLETED?.rowData}
										moduleName={moduleName}
										handleViewClick={handleViewClick}
										onRowClick={onRowClick}
									/>
								</div>

							// </div>
							
							}
							<div className={"actionRow borderActionRow"}>
								<div className="actionBtn">
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
										<Button
											onClick={handleViewClick}
											disabled={WORK_COMPLETED?.workPlanId ? false : true}
											className={WORK_COMPLETED?.workPlanId ? "activeBtn" : "disableBtn"}

										>
											View
											<SVGIcon icon="view" />
										</Button>}
								</div>
							</div>
						</div>
					</ContentBox></>}
		</>
	);
};

export default WorkCompletedComponent;
