const baseUrl = '/v1/admin/cubetest'

export const apiUrls = {
	list: `${baseUrl}/list`,
	cubeTestingData: (id: number): string => `${baseUrl}/${id}/data`,
	create: (id: number): string => `${baseUrl}/${id}/create`,
	update: (id: number): string => `${baseUrl}/${id}/edit`,
	delete: (id: number): string => `${baseUrl}/${id}/delete`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	createTestReport: (id: number): string => `${baseUrl}/${id}/report-add`,
	gradeOfConcreteList: (id: number) =>
		`/v1/admin/mixdesign/${id}/cementgrade-list`,
}
