import React from 'react';
import { Table } from 'antd';
// import { ReactComponent as ProjectDashboardIcon } from '../../../../../assets/images/icon/ProjectDashboard.svg';
// import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icon/deleteIcon.svg';

interface DataType {
    key: React.Key;
    name: any;
    address: string;
    age: any;
    content: any;
}

// ====== do not remove below code this is for table collapse
// start---------------------------
// interface ExpandedDataType {
//     key: React.Key;
//     totalNumber: string;
// }
// const ExpandIcon = ({ expanded, onExpand, record }: any) => (
//     <Button
//         type="link"
//         className={expanded ? 'expand-icon expanded' : 'expand-icon'}
//         onClick={(e) => onExpand(record, e)}
//     >
//         {expanded ? (
//             <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
//                 <path d="M1.5 1.5L6.5 6.5L1.5 11.5" stroke="#182125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
//             </svg>
//         ) : (
//             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
//                 <path d="M11 0.999999L6 6L1 1" stroke="#182125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
//             </svg>
//         )}
//     </Button>
// );
// ---------end

const BuildingDepartment: React.FC = () => {
    // const rowData = [
    //     {
    //         title: 'Bridge Name',
    //         width: 200,
    //         dataIndex: 'name',
    //         key: 'name',
    //         fixed: 'left',
            
    //     },
    //     {
    //         title: 'Ghodasar Flyover Bridge',
    //         dataIndex: 'address',
    //         width: 200,
    //         key: 'address',
    //     },
    //     {
    //         title: ' LC 11/B Hebatpur(ROB)',
    //         width: 200,
    //         dataIndex: 'content',
    //         key: 'content',
    //         // fixed: 'left',
    //     },
    //     {
    //         title: 'LC 21 Butbhavani(ROB)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'LC 24 Makarba(ROB)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'Naroda patiya Flyover Bridge',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'Vadaj Bridge(Three Layer Bridge)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    
    //     {
    //         title: 'Ghodasar Flyover Bridge',
    //         dataIndex: 'address',
    //         width: 200,
    //         key: 'address',
    //     },
    //     {
    //         title: ' LC 11/B Hebatpur(ROB)',
    //         width: 200,
    //         dataIndex: 'content',
    //         key: 'content',
    //         // fixed: 'left',
    //     },
    //     {
    //         title: 'LC 21 Butbhavani(ROB)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'LC 24 Makarba(ROB)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'Naroda patiya Flyover Bridge',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    //     {
    //         title: 'Vadaj Bridge(Three Layer Bridge)',
    //         dataIndex: 'age',
    //         width: 200,
    //         key: 'age',
    //     },
    // ];

    // const data: DataType[] = [
    //     {
    //         key: '1',
    //         name: 'Design Consultant',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design',
    //     },
    //     {
    //         key: '2',
    //         name: 'Design Code',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design',
    //     },
    //     {
    //         key: '3',
    //         name: 'Contractor',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design',
    //     },
    //     {
    //         key: '4',
    //         name: 'PMC',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design',
    //     },
    //     {
    //         key: '5',
    //         name: 'Work Order Date',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design',
    //     },
    //     // {
    //     //     key: '6',
    //     //     name: 'Actual Start Date ',
    //     //     address: '123',
    //     //     age: 'data',
    //     //     content: 'new Design',
    //     // },
    //     {
    //         key: '6',
    //         name:"Pile",
    //         address: '',
    //         age: '',
    //         content: ''
        
    //     },
    //     {
    //         key: '7',
    //         name: 'Pile Cap',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '8',
    //         name: 'Pier',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '9',
    //         name: 'Pier Cap',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '10',
    //         name: 'Bearing ',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '11',
    //         name: 'Solid Slab ',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '12',
    //         name: 'Girder (PSC or Cast in Situ) + Deck Slab ',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '13',
    //         name: 'Box Girder Span/Voided Girder (Obligatory) ',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },
    //     {
    //         key: '14',
    //         name: 'RT Wall ',
    //         address: '123',
    //         age: 'data',
    //         content: 'new Design'
    //     },

    // ];


    // const columns1 = rowData.map((key?: any) => ({
    //     title: (
    //         <div className="departmentTableTitle">
    //             <span>{key?.title}</span>
    //             {key?.dataIndex !== 'name' && ( // Conditionally render iconCol except for 'name' column
    //             <div className="iconCol">
    //                 <Button className='deparmentDashboardIcon'><ProjectDashboardIcon /></Button>
    //                 <Button className='deparmentDeleteIcon'><DeleteIcon /></Button>
    //             </div>
    //         )}
    //         </div>
    //     ),
    //     dataIndex: key.dataIndex,
    //     key: key.dataIndex,
    //     fixed: key.fixed,

    // }));

// ====== do not remove below code this is for table collapse
// start---------------------------
    // const expandedRowRender = () => {
    //     const columns: TableColumnsType<ExpandedDataType> = [
    //         { dataIndex: 'totalNumber', key: 'totalNumber', width: 96, fixed: 'left' },
    //         { dataIndex: 'name', key: 'name', width: 200 },
    //         { dataIndex: 'content', key: 'content', width: 200 },
    //         { dataIndex: 'age', key: 'age', width: 200 },
    //     ];

    //     const data = [];
    //     for (let i = 0; i < 6; ++i) {
    //         data.push({
    //             key: i.toString(),
    //             totalNumber: 'Total Nos...',
    //             name: 'Submitted ',
    //             age: '100',
    //             content: "Approved ",
    //             address: '120',
    //         });
    //     }
    //     return <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 1600, y: 290 }} className="innerTable" />;
    // };
// ------------------------End
    return (
        <Table
            //columns={columns1}
            //dataSource={data}
           // scroll={{ x: 1600, y: 700 }}
            // expandable={{
            //     expandedRowRender,
            //     expandIcon: ExpandIcon,
            //     // rowExpandable: (record) => record.name !== 'Not Expandable'
            // }}
            // expandIconColumnIndex={1}
            rowClassName={(record: DataType) => (record.key === '6' ? 'titleRow' : '')}
            className='departmentTable'
            pagination={false}
        />
    );
};

export default BuildingDepartment;