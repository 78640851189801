import React, { useState } from "react";
import { Form } from "antd";
import FormComponent from "./FormComponent";
import { validateFields } from "../../../../../config/Global";
import { updateRecord } from "../../utils/slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { assignErrorToInput } from "../../../../../store/api";
import { useNavigate } from "react-router-dom";

const EditComponent: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const { read } = useSelector((state: RootState) => state.QUESTIONMASTER);
	const [saving, setSaving] = useState(false);
	const [inputData, setInputData] = useState([]);
	const selectInputType = form.getFieldValue("input_type") && [1, 2].includes(form.getFieldValue("input_type")) && inputData.length === 0 
	const navigate = useNavigate()

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleSave = async (data: any) => {
		setSaving(true)
		setDisabled(true)
		const filterInputData = inputData.filter((option :any) => option.name != '' )
		const inputDataIndex = filterInputData.map((option :any , index :any) => ({
			...option,
			id : String(index +1)
		}))
		data.input_option = inputDataIndex
		dispatch(updateRecord(read.id, data))
			.then(() => {
				setSaving(false)
				setDisabled(false)
				setInputData([])
				navigate("/master-data/question-master")
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() =>
				setSaving(false),
				setDisabled(false));
	};

	return (
		<>

			<FormComponent
				form={form}
				id="UserEdit"
				handleSubmit={handleSave}
				onValueChange={validateForm}
				inputData={inputData}
				setInputData={setInputData}
				editValues={read}
				saving={saving}
				disabled={disabled || selectInputType }
			/>


		</>
	);
};

export default EditComponent;