import React, { useEffect, useState } from "react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import columnDefs from "./columnDefs";
import { Tag } from "antd";
import { ChangeStatus, setupGrid } from "../../utils/slice";
import ApproveModal from "../../ApproveModal";

const Listing: React.FC<any> = ({
	rowData,
	onRowClick,
	moduleInfo,
}) => {

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [statusData, setStatusData] = useState<any>();
	useEffect(() => {
		setIsDropdownOpen(!isDropdownOpen);
	}, [setIsDropdownOpen]);

	useEffect(() => {
		localStorage.removeItem("mappingId");
		localStorage.removeItem("isTouched");
	});

	const statusRender = (props: any) => {
		return (
			<Tag color={props.data.is_submit === 1 ? "green" : "orange"}>
				{props.data.is_submit == 1 ? "Submitted" : "Pending"}
			</Tag>
		);
	};
	return (
		<>
			<ApproveModal
				titleName={moduleInfo}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={ChangeStatus}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					statusRender,
				}}
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setupGrid}
				onRowClicked={onRowClick}
				rowSelection={"single"}
				context={{
					setStatusData,
				}}
			/>
		</>
	);
};

export default Listing;
