import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import {
	AddMixDesign,
	EditMixDesign,
	ViewMixDesign,
} from "../../../../config/BreadcrumbConfig";
import ToolBar from "../../../../components/ToolBar";
import {
	dataToFormDataConverter,
	validateFields,
} from "../../../../config/Global";
import { rules } from "./rules";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
	createCement,
	createRecord,
	fetchList,
	getCementBrandName,
	getGradeOfconcreteList,
	getLaboratoryBrandName,
	imageDelete,
	qacategory,
	setCategoryData,
	setCementGrageList,
	setCementName,
	setFormData,
	setGradeOfconcreteList,
	setLaboratoryName,
	updateRecord,
} from "../../RawMaterial/utils/slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { assignErrorToInput } from "../../../../store/api";
import { regexPatterns } from "../../../../config/validations/validations";

const AddMixDesignComponent: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const { RAWMATERIAL } = useSelector((state: RootState) => state);
	const { mixDesignId } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [viewData, setViewData] = useState<any>();
	const location = useLocation();
	const [cementsName, setCementsName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const pro_id: any = localStorage.getItem('projectId')


	useEffect(() => {
		if (!location.pathname.includes("/add-mixdesign") && !RAWMATERIAL?.formData) {
			dispatch(fetchList(pro_id)).then((data?: any) => {
				data?.rows?.map((item: any) => {
					if (item?.id == mixDesignId) {
						dispatch(setFormData(item))
					}
				})
			})
		}
	}, [])

	useEffect(() => {
		if (RAWMATERIAL?.formData && RAWMATERIAL?.formData) {
			const data = RAWMATERIAL?.formData
			setViewData(data);
			dispatch(setGradeOfconcreteList([{ id: data?.cement_grade_id, name: data?.name }]))
			dispatch(setCementGrageList([{ id: data?.cement_grade, name: data?.cement_grade_name }]))
			dispatch(
				setCementName([
					{ id: data?.name_of_cement_brand, cement_name: data?.cement_name },
				])
			);
			dispatch(setCategoryData([data?.qa_category]))
			dispatch(setLaboratoryName([{ id: data?.laboratory_name, laboratory_name: data?.laboratory_original_name }]))
			form.setFieldsValue({
				cement_grade_id: data?.cement_grade_id,
				name_of_cement_brand: data?.name_of_cement_brand,
				mm_20: data?.mm_20,
				mm_10_20: data?.mm_10_20,
				sand: data?.sand,
				type_of_cement: data?.type_of_cement,
				cement_grade: data?.cement_grade,
				admixture_one: data?.admixture_one,
				admixture_two: data?.admixture_two,
				cement_in_kg: data?.cement_in_kg,
				water_in_ltr: data?.water_in_ltr,
				w_c_ratio: data?.w_c_ratio,
				max_slump_mm: data?.max_slump_mm,
				min_slump_mm: data?.min_slump_mm,
				qa_category_id: data?.qa_category_id,
				laboratory_name: data?.laboratory_name,
				finalize_mix_design_date: dayjs(data?.finalize_mix_design_date),
				attach_mix_design: data?.project_document?.map(
					(image: { id: any; document: any }) => {
						return {
							uid: image.id,
							name: image?.document,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
						};
					}
				),
			});
		}
	}, [RAWMATERIAL?.formData, form]);

	const handleSubmit = (data?: any) => {
		setDisabled(true)
		setLoading(true);
		data.attach_mix_design = data?.attach_mix_design?.map((file: any) => {
			if (file?.originFileObj && file?.originFileObj) {
				return file;
			} else {
				return file?.url;
			}
		});
		const payload = {
			...data,
			finalize_mix_design_date: dayjs(data?.finalize_mix_design_date)?.format("YYYY-MM-DD"),
			project_id: parseInt(pro_id),
		};
		const formdata = dataToFormDataConverter(payload);
		try {
			form.validateFields();
			delete formdata.cement_name;
			dispatch(
				location.pathname.includes("/edit-mixdesign")
					? updateRecord(viewData?.id, formdata)
					: createRecord(parseInt(pro_id), formdata)
			)
				.then(() => {
					setLoading(false);
					form.resetFields();
					navigate(`/projects/mix-design`);
				})
				.catch((error: any) => {
					setDisabled(false)
					setLoading(false)
					assignErrorToInput(form, error.STATUS);
				});
		} catch (error: any) {
			setDisabled(false)
			assignErrorToInput(form, error.NOTIFICATION);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		navigate(`/projects/mix-design`);
	};

	const addCement = () => {
		dispatch(createCement({ cement_name: cementsName }))
			.then(() => {
				setCementsName("");
			})
			.catch((error?: any) => {
				if (error.STATUS?.cement_name) {
					setErrorMessage(error.STATUS?.cement_name[0]);
				}
			});
	};

	//check cement name validation
	const validateInput = (value?: any) => {
		if (value.length < 1) {
			setErrorMessage("The input must be a minimum of 1 and a maximum of 100.");
			return false;
		} else if (value.length > 100) {
			setErrorMessage("The input must be a minimum of 1 and a maximum of 100.");
			return false;
		} else if (/^\s*$/.test(value)) {
			setErrorMessage("It is required.");
			return false;
		}
		// Clear the error message if validation passes
		setErrorMessage("");
		return true;
	};

	//check min and max slump value
	const checkValue = (rule?: any, value?: any, callback?: any) => {
		const pattern = parseFloat(form.getFieldValue("min_slump_mm"));
		if (pattern > parseFloat(value)) {
			callback("It should be equal to or greater than the Minimum Slump.");
		} else {
			callback();
		}
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes("/add-mixdesign")
						? AddMixDesign()
						: location.pathname.includes("/edit-mixdesign")
							? EditMixDesign()
							: location.pathname.includes("/view-mixdesign")
								? ViewMixDesign()
								: AddMixDesign()
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<div className="formMainWrapper">
				<div className="formHeading">
					<h3>
						{location.pathname.includes("/view-mixdesign")
							? "View "
							: location.pathname.includes("/add-mixdesign")
								? "Add "
								: location.pathname.includes("/edit-mixdesign")
									? "Edit "
									: ""}
						Mix Design
					</h3>
				</div>
				<FormBox
					form={form}
					onFinish={handleSubmit}
					onValuesChange={validateForm}
					className="addReportForm"
				>
					<div>
						<Row className="concreteForm mixDesignForm" gutter={40}>
							<Col span={12}>
								<InputBox.Select
									disabled={
										//location.pathname.includes("/view-mixdesign") || !RAWMATERIAL?.isDisabled
										location.pathname.includes("/view-mixdesign")
									}
									label="Grade of Concrete"
									className="select"
									name="cement_grade_id"
									style={{ width: "100%" }}
									onFocus={() =>
										dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 1 }))
									}
									options={{
										list: RAWMATERIAL.gradeOfconcreteList,
										valueKey: "id",
										textKey: "name",
									}}
									rules={rules.selectFields()}
									onChange={() => {
										if (location.pathname.includes("/add-mixdesign")) {
											form.setFieldValue("qa_category_id", [])
											dispatch(setCategoryData([]))
										}
									}}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									disabled={
										location.pathname.includes("/view-mixdesign") || location.pathname.includes("/edit-mixdesign")
									}
									label="Structural Element"
									className="select"
									mode={location.pathname.includes("/add-mixdesign") ? "multiple" : undefined}
									allowClear
									name="qa_category_id"
									style={{ width: "100%" }}
									onFocus={() => {
										if (form.getFieldValue("cement_grade_id")) {
											dispatch(qacategory({ cement_grade_id: form.getFieldValue("cement_grade_id"), project_id: pro_id }))
										} else {
											dispatch(setCategoryData([]))
											form.setFields([
												{
													name: `qa_category_id`,
													errors: ["Please select the Grade of Concrete."],
												},
											]);
										}
									}}
									options={{
										list: RAWMATERIAL.categoryData?.map(
											(category: { id: any; name: any }) => ({
												id: category.id,
												name: category.name,
											})
										),
									}}
									rules={rules.selectFields()}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									disabled={
										location.pathname.includes("/view-mixdesign")
									}
									label="Name of Cement Brand"
									className="select"
									name="name_of_cement_brand"
									style={{ width: "100%" }}
									onFocus={() => {
										dispatch(getCementBrandName());
										setErrorMessage("");
										setCementsName("");
									}}
									dropdownRender={(menu) => (
										<>
											{menu}
											<Divider
												style={{
													margin: "8px 0",
												}}
											/>
											<div className="cementBrandDropdown">
												<InputBox.Text
													placeholder="Enter new cement brand name"
													value={cementsName}
													onChange={(e: any) => {
														setCementsName(e.target.value);
														validateInput(e.target.value);
													}}
													validateStatus={errorMessage ? "error" : ""}
													help={errorMessage}
													rules={rules.dynamicFields()}
												/>
												<div className="disableBtnWrap">
													<Button
														type="text"
														icon={<PlusOutlined />}
														onClick={addCement}
														disabled={!errorMessage ? false : true}
														className={`${errorMessage
															? "cementDropDisableBtn"
															: "cementDropdownBtn"
															}`}
													>
														Add item
													</Button>
												</div>
											</div>
										</>
									)}
									options={{
										list: RAWMATERIAL.cementName,
										valueKey: "id",
										textKey: "cement_name",
									}}
									rules={rules.selectFields()}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="20 mm"
									name="mm_20"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign")
									}
								/>
							</Col>

							<Col span={12}>
								<InputBox.Text
									label="10 - 20 mm "
									name="mm_10_20"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign")
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Sand "
									name="sand"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign")
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									label="Type Of Cement "
									name="type_of_cement"
									className="select"
									rules={rules.selectFields()}
									disabled={
										location.pathname.includes("/view-mixdesign")
									}
									options={{
										list: [
											{ id: 1, name: "OPC" },
											{ id: 2, name: "PPC" },
										],
									}}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									label="Cement Grade"
									className="select"
									name="cement_grade"
									rules={rules.selectFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
									onFocus={() =>
										dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 2 }))
									}
									options={{
										list: RAWMATERIAL.cementGrageList,
										valueKey: "id",
										textKey: "name",
									}}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Cement (In KG)"
									name="cement_in_kg"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Water (In Litre)"
									name="water_in_ltr"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Admixture 1"
									name="admixture_one"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Admixture 2"
									name="admixture_two"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>

							<Col span={12}>
								<InputBox.Text
									label="W/C Ratio "
									name="w_c_ratio"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									label="Name of Laboratory "
									name="laboratory_name"
									rules={rules.selectFields()}
									onFocus={() => dispatch(getLaboratoryBrandName())}
									options={{
										list: RAWMATERIAL.laboratoryName,
										valueKey: "id",
										textKey: "laboratory_name",
									}}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Minimum Slump mm "
									name="min_slump_mm"
									rules={rules.numericFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Maximum Slump mm "
									name="max_slump_mm"
									rules={[
										{
											validator: checkValue,
										},
										{
											required: true,
											message: "It is required.",
										},
										{
											pattern: regexPatterns.twoDecimal,
											message: `Maximum 4 digits & decimal value up to 2 digits.`,
										},
									]}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.DatePicker
									label="Date of Finalization of Mix Design "
									name="finalize_mix_design_date"
									rules={rules.dateFields()}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Upload
									name='attach_mix_design'
									required={true}
									label="Attach Mix Design"
									fileList={form.getFieldValue('attach_mix_design')}
									maxCount={10}
									disabled={
										location.pathname.includes("/view-mixdesign") ? true : false
									}
									onRemove={(e) => { e && !e.size && e?.uid && dispatch(imageDelete(e?.uid)) }}
								/>
							</Col>
						</Row>
						<Row>
							<div className="ReportBtn">
								<Button
									className="secondaryBtn"
									htmlType="submit"
									loading={loading}
									disabled={
										disabled
									}
								>
									Submit
								</Button>
								<Button className="cancelBtn" onClick={handleCancel}>
									Cancel
								</Button>
							</div>
						</Row>
					</div>
				</FormBox>
			</div>
		</>
	);
};

export default AddMixDesignComponent;
