import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../components/AntdAddons";
import TableComponent from "./TableComponent";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import {
	dataToFormDataConverter, validateFields,
} from "../../../../../../../config/Global";
import { AppDispatch, RootState } from "../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getSteelDiameterList, getTestTypes, read, setSteelId } from "../utils/slice";
import { CONSTANT } from "../../../../../../../config/Constant";
import ToolBar from "../../../../../../../components/ToolBar";
import { AddSteelBreadcrumb } from "../../../../../../../config/BreadcrumbConfig";
import dayjs from "dayjs";
import { rules } from "./rules";
import { assignErrorToInput } from "../../../../../../../store/api";

const AddSteelComponent: React.FC = () => {
	const [form] = useForm();
	const navigate = useNavigate();
	const { STEELDIAMETER } = useSelector((state: RootState) => state);
	const [saving, setSaving] = React.useState<boolean>(false);
	const { id }: { id?: any, projectId?: any } = useParams();
	const dispatch: AppDispatch = useDispatch();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [diameterList, setDiameterList] = useState<any>([])
	const [, setState] = useState(false);

	useEffect(() => {
		// setTimeout(() => {
			if (id && !STEELDIAMETER.read) {
				dispatch(read(id)).then(() => {
					//
				});
			}
		// }, 500)
	}, []);
	useEffect(() => {
		if (STEELDIAMETER.read && id && location.pathname.includes("/view-steel")) {
			form.setFieldsValue({
				supplier_name: STEELDIAMETER?.read.first_section.supplier_name,
				invoice_number: STEELDIAMETER?.read.first_section.invoice_number,
				invoice_date: dayjs(STEELDIAMETER?.read.first_section.invoice_date),
				total_weight: STEELDIAMETER?.read.first_section.total_weight,
				receipt_date: dayjs(STEELDIAMETER?.read.first_section.receipt_date),
				document_name:
					STEELDIAMETER?.read?.first_section.attached_documnent?.map(
						(image: { id: any; document_name: any }) => {
							return {
								// uid: image.id,
								name: image?.document_name,
								status: "done",
								url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							};
						}
					),
				mtc_attachment: STEELDIAMETER?.read?.first_section.mtcattached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				)
			});

			var pending_drs: any = [];
			var data:any=[];
			STEELDIAMETER?.read?.second_section.map((item: any) => {
				pending_drs[item.steel_diameter_id] = {};
				pending_drs[item.steel_diameter_id]["test_types"] = [];

				pending_drs[item.steel_diameter_id].received_weight =
					item.received_weight;
				pending_drs[item.steel_diameter_id]["test_types"] = item.test_types;

				setDiameterList([])
				data.push({id:item?.steel_diameter_id,name:item?.steel_diameter_name,received_weight:item?.received_weight})
			});
			setDiameterList(data)
			form.setFieldsValue({
				pending_drs,
			});
			setState(prevState => !prevState);
		}
	}, [STEELDIAMETER.read, form, setState]);

	const handleCancel = () => {
		dispatch(setSteelId(null))
		navigate(`/projects/raw-material`);
	};

	const onFinish = (data: any) => {
		setDisabled(true)
		setSaving(true);
		data.project_id = localStorage.getItem("projectId");
		data.invoice_date = dayjs(data.invoice_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.receipt_date = dayjs(data.receipt_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.diameter_wise_receipt = JSON.stringify({ data: data.pending_drs });
		delete data.pending_drs;

		const formData = dataToFormDataConverter(data);

		dispatch(createRecord(data.project_id, formData)).then(() => {
			setSaving(false)
			form.resetFields();
			dispatch(setSteelId(null))
			return navigate(`/projects/raw-material`);
		})
			.catch((error: any) => {
				setDisabled(true)
				setSaving(false)
				assignErrorToInput(form, error?.STATUS);
			});
	};

	useEffect(() => {
		location.pathname.includes("/add-steel") && dispatch(getSteelDiameterList());
		dispatch(getTestTypes(1));
	}, [form])

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const checkValue = (rule?: any, value?: any, callback?: any) => {
		if (parseFloat(value) <= 0) {
			callback("Value should be more than 0.");
		} else {
			callback();
		}
	};
	return (
		<div className="rawMaterialFormWrapper">
			<ToolBar breadcrumbs={
				location.pathname.includes("add-steel") ?
					AddSteelBreadcrumb("Add") : AddSteelBreadcrumb("View")}>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<Form
				form={form}
				onFinish={onFinish}
				className="formLayout"
				onValuesChange={validateForm}
				id="addForm" 
			>
				<div className="formHeading">
					<h3>{location.pathname.includes("/view-steel") ? "View " : "Add "}Steel</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Text
								label="Name of Supplier"
								required={true}
								name="supplier_name"
								rules={rules.dynamicFields()}
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Invoice No."
								required={true}
								name="invoice_number"
								rules={rules.dynamicFields()}
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								format={CONSTANT.DATE_FORMAT}
								label="Invoice Date"
								rules={rules.dateFields()}
								name="invoice_date"
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								type="text"
								maxLength={4}
								label="Total Weight(MT)"
								name="total_weight"
								disabled={true}
								rules={[{
									validator: checkValue
								},
								{
									required: true,
									message: "It is required."
								}
								]}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								format={CONSTANT.DATE_FORMAT}
								label="Date of Receipt of Steel"
								rules={rules.dateFields()}
								name="receipt_date"
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={true}
								label="Attach Invoice "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="mtc_attachment"
								required={true}

								label="Attach MTC "
								fileList={form.getFieldValue('mtc_attachment')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-steel") ? true : false
								}
							/>
						</Col>
					</Row>
				</div>

				<TableComponent form={form} 
				diameter={STEELDIAMETER?.steelDiameterList} 
				testType={STEELDIAMETER?.testTypes} 
				diameterList={diameterList}
				setDiameterList={setDiameterList}
				validateForm={validateForm}
				/>
				<div className="steelBtn">
					<Button
						className="secondaryBtn"
						htmlType="submit"
						disabled={disabled}
						form="addForm"
						loading={saving}
					>
						Submit
					</Button>
					<Button
						className="secondaryBtn"
						htmlType="submit"
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default AddSteelComponent;
