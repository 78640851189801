import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Drawer, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { changeSequence } from "../../utils/slice";

interface DataType {
  qa_params_group_name: any;
  key: string;
  question: string;
  category: string;
  name: any;
}
interface AddProps {
  close: () => void;
  sequVisible?: boolean;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: 50,
  },
  {
    dataIndex: "params_label_full_text",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const App: React.FC<AddProps> = ({ sequVisible, close }) => {
  const dispatch: AppDispatch = useDispatch();
  const QUESTIONMASTER = useSelector(
    (state: RootState) => state.QUESTIONMASTER
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [saving] = useState(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [formateData, setFormateData] = useState<DataType[]>([]);
 

  useEffect(() => {
    // if (sequVisible) {
      const categories =
        QUESTIONMASTER.questionsequence &&
        Object.keys(QUESTIONMASTER?.questionsequence);
      const filteredData: DataType[] = [];
      categories?.forEach((categoryKey: any) => {
        const data =
          QUESTIONMASTER?.questionsequence &&
          QUESTIONMASTER?.questionsequence?.[categoryKey];

        data.forEach((item: any) => {
          if (item.qa_category_id === QUESTIONMASTER.elementId) {
            filteredData.push(item);
          }
        });
      });
      setDataSource(filteredData);
    // }
  }, [QUESTIONMASTER]);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const updatedData = arrayMove(previous, activeIndex, overIndex);
        setFormateData(updatedData);
        setDisabled(false);
        return updatedData;
      });
    }
  }, []);

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    const data = formateData.map((item: any) => {
      return {
        id: item.id,
        key: item.key,
        sequence: item.sequence,
      };
    });
    dispatch(changeSequence(data));
    close();
    setDisabled(true);
  };

  const groupedData: { [key: string]: DataType[] } = {};

  dataSource &&
    dataSource.forEach((item) => {
      if (!groupedData[item?.qa_params_group_name]) {
        groupedData[item?.qa_params_group_name] = [];
      }
      groupedData[item?.qa_params_group_name].push(item);
    });

  return (
    <>
      <Drawer
        title="Change Sequence"
        placement="right"
        open={sequVisible}
        footer={[
          <div key={1}>
            <Button
              className="secondaryBtn"
              htmlType="button"
              form="UserAdd"
              disabled={disabled}
              loading={saving}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button className="cancelBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>,
        ]}
        rootClassName="QuestionDrawer"
        width="55%"
        onClose={() => {
          handleClose();
        }}
      >
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          {Object.entries(groupedData).map(([qa_params_group_name, data]) => {
            return (
              <div key={qa_params_group_name}>
                <h4
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: "10px 0px",
                  }}
                >
                  {qa_params_group_name}
                </h4>
                <SortableContext
                  items={data.map((i) => i.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={data}
                    className="sequenceTable"
                    pagination={false}
                  />
                </SortableContext>
              </div>
            );
          })}
        </DndContext>
      </Drawer>
    </>
  );
};

export default App;
