import React from "react";
import { Form, DrawerProps, Button } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/app";
import { validateFields } from "../../../../../config/Global";
import { assignErrorToInput } from "../../../../../store/api";
import { createRecord, setDesCodeData } from "../../utils/slice";
import FormComponent from "./FormComponent";
import Modal from "antd/es/modal/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface AddDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean
	close: () => void;
}

const AddComponent: React.FC<AddDrawerProps> = ({
	titleName,
	open,
	close,
	// ...rest
}) => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [saving, setSaving] = React.useState<boolean>(false);

	const drawerClose = () => {
		close();
		form.resetFields();
		dispatch(setDesCodeData(null))
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleFormSubmit = (data: any) => {
		setSaving(true);
		dispatch(createRecord(data))
			.then(() => {
				drawerClose();
			}).catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false));
	};

	return (
		<Modal
			centered
			open={open}
			className="commanModal"
			title={`Add ${titleName}`}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={drawerClose}
			destroyOnClose
			footer={
				<>
					<div className="signBtn">
						<Button
							htmlType="submit"
							className="secondaryBtn"
							disabled={disabled}
							form="addForm"
							loading={saving}
						>
							Submit
						</Button>
						<Button onClick={drawerClose} className='cancelBtn'>
							Cancel
						</Button>

					</div>
				</>
			}
		>

			<FormComponent
				form={form}
				id="addForm"
				handleSubmit={handleFormSubmit}
				onValuesChange={validateForm}
			/>

		</Modal>
	);
};

export default AddComponent;