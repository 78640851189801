import { Regex } from "../../../../../config/Validators";
import { validations } from "../../../../../config/validations/validations";

export const rules: any = {

  dynamicFields: (field?: string) => [
    validations.required.text(field),
    validations.minMax(),
  ],
  numberFields: [
		validations.pattern.decimal(2),
		{
			pattern: Regex.digits,
			message: "only digits value allowed here",
		},
	],
  
  dateFields: (field?: string) => [validations.required.text(field)],
  selectFields: (field?: string) => [validations.required.text(field)],
};
