import React from "react";
import { Form, DrawerProps, Button, Row } from "antd";
import { useDispatch } from "react-redux";
import Modal from "antd/es/modal/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { AppDispatch } from "../../../store/app";
import { validateFields } from "../../../config/Global";
import { assignErrorToInput } from "../../../store/api";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { changePassword } from "../../../store/AuthSlice";
import { LoginRequest } from "./Validation";

interface AddDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean
	close: () => void;
}

const ChangePassword: React.FC<AddDrawerProps> = ({
	titleName,
	open,
	close,
	// ...rest
}) => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [saving, setSaving] = React.useState<boolean>(false);

	const drawerClose = () => {
		close();
		form.resetFields();
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleFormSubmit = (data: any) => {
		setSaving(true);
		dispatch(changePassword(data))
			.then(() => {
				drawerClose();
				form.resetFields()
			}).catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false));
	};

	return (
		<Modal
			centered
			open={open}
			className="commanModal"
			title={`${titleName}`}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={drawerClose}
			destroyOnClose
			// onOk={handleOk}
			footer={
				<>
					<div className="signBtn">
						<Button
							htmlType="submit"
							className="secondaryBtn"
							disabled={disabled}
							form="ChangePassword"
							loading={saving}
						>
							Submit
						</Button>
						<Button onClick={drawerClose} className='cancelBtn'>
							Cancel
						</Button>

					</div>
				</>
			}
		>
			<FormBox
				form={form}
				id="ChangePassword"
				onFinish={handleFormSubmit}
				onValuesChange={validateForm}
			>
				<Row gutter={25}>
					<InputBox.Password
						label="Current Password"
						name="current_password"
						rules={LoginRequest().password()}
						onChange={validateForm}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Password
						label="New Password"
						name="password"
						rules={LoginRequest().newPassword()}
						onChange={validateForm}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Password
						label="Confirm Password"
						name="password_confirmation"
						rules={LoginRequest().confirmPassword()}
						onChange={validateForm}
					/>
				</Row>
			</FormBox>
		</Modal>
	);
};

export default ChangePassword;
