import React, { useEffect, useState } from "react";
import { Button } from "antd";
import Listing from "./Listing/Listing";
import { ReactComponent as PlusIcon } from "../../../../../../assets/images/icon/plusIcon.svg";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { deleteRecord, setCementId, setId, setLocation, setPerPageSize, setRead } from "./utils/slice";
import RecordPerPage from "../../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../../utils/SVGIcon";
import { ReactComponent as ReportIcon } from "../../../../../../assets/images/icon/ReportIcon.svg";
import { checkPrivileges } from "../../../../../../config/Global";
import PageSpinner from "../../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../../components/Modals/DeleteModal";

const Cement: React.FC<any> = ({ permissionPrefix }) => {
	const dispatch: AppDispatch = useDispatch();
	const { CEMENTINVENTORY, AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()

	const moduleName = "Steel";
	const navigate = useNavigate();

	const onRowClick = (data?: any) => {
		dispatch(setCementId(data?.data?.id));
		setDeleteData(data?.data)
	};
	const reportData = () => {
		navigate(
			`/projects/raw-material/${CEMENTINVENTORY.cementId}/cement-report`
		);
	};
	const handleView = () => {
		dispatch(setRead(null))
		navigate(
			`/projects/raw-material/${CEMENTINVENTORY.cementId}/view-cement`
		);
	};
	const handleClick = () => {
		navigate(`/projects/raw-material/add-cement`);
	};
	useEffect(() => {
		dispatch(setId(localStorage.getItem('projectId')));
		dispatch(setLocation(""));
	}, []);


	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setCementId(null))
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) && <>
							<div className="rawListWrapper">
					<div className="rawList">
						<div className="btnWrap">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn steelBtn" onClick={handleClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={CEMENTINVENTORY.perPage + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setPerPageSize(perPageSize));
								}}
							/>{" "}
						</div>
						<Listing
							rowData={CEMENTINVENTORY?.rowData}
							moduleName={moduleName}
							handleReportClick={reportData}
							handleViewClick={handleView}
							onRowClick={onRowClick}
						/>
						<DeleteModal
							title={"cement"}
							deleteValues={deleteData}
							callApi={deleteRecord}
							close={closeDeleteModal}
							open={deleteUser}
						/>
					</div>
					<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) || checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) ? "actionRow rawmaterialActionRow" : ""}>
						<div className="actionBtn">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
								<Button
									onClick={handleView}
									disabled={CEMENTINVENTORY.cementId ? false : true}
									className={CEMENTINVENTORY.cementId ? "activeBtn" : "disableBtn"}
								>
									View
									<SVGIcon icon="view" />
								</Button>}
							{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) &&
								<div>
									<Button
										disabled={CEMENTINVENTORY.cementId ? false : true}
										onClick={() => setDeleteUser(true)}
										className={CEMENTINVENTORY.cementId ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>
								</div>}
							{
								checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) &&
								<Button
									disabled={CEMENTINVENTORY.cementId ? false : true}
									onClick={reportData}
									className={CEMENTINVENTORY.cementId ? "activeBtn" : "disableBtn"}
								>
									Report
									<ReportIcon />
								</Button>}
						</div>
						</div>
					</div></>}
		</>
	);
};

export default Cement;
