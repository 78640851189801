const baseUrl = "/v1/admin/project";

export const apiUrls = {
  list: `${baseUrl}/structure-element-list`,
  qacategory : (id: number): string => `${baseUrl}/${id}/qa-category`,
  create: `${baseUrl}/structure-element-save`,
  update: (id: number): string => `${baseUrl}/${id}/structure-element-edit`,
  delete: (id: number): string => `${baseUrl}/${id}/delete`,
  details: (id: number): string => `${baseUrl}/${id}/details`,
  bulkUpdate: `${baseUrl}/bulk-update`,
  changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
  deleteElement:`${baseUrl}/structure-element-delete`
};
