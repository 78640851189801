import React from 'react'
import AgGridWrapper from '../../../../../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setUpGrid } from '../../../utils/slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../../store/app'

const Listing: React.FC<any> = ({
    rowData,
    onRowClick
}) => {
    const { CONCRETEADMIXTURE } = useSelector((state: RootState) => state);
    return (
        <>
            <AgGridWrapper
                type='serverSide'
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReadyWithDispatch={setUpGrid}
                onRowClicked={onRowClick}
                paginationPageSize={CONCRETEADMIXTURE.reportPerPageSize}
                cacheBlockSize={CONCRETEADMIXTURE.reportPerPageSize}
            />
        </>
    )
}

export default Listing
