import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../components/AntdAddons";
import TableComponent from "./TableComponent";
import ToolBar from "../../../../../../../components/ToolBar";
import { AddCementBreadcrumb } from "../../../../../../../config/BreadcrumbConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CONSTANT } from "../../../../../../../config/Constant";
import { rules } from "./rules";
import dayjs from "dayjs";
import {
	dataToFormDataConverter, validateFields,
} from "../../../../../../../config/Global";
import { AppDispatch, RootState } from "../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { createRecord, getCementTestTypes, read, setCementId } from "../utils/slice";
import { getGradeOfconcreteList, setTab } from "../../../../utils/slice";
import { assignErrorToInput } from "../../../../../../../store/api";

const AddCementComponent: React.FC = () => {
	const { cementId }: { projectId?: any; cementId?: any } =
		useParams();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState(true)
	const [, setState] = useState(false);
	const location = useLocation();
	const [diameterList, setDiameterList] = useState<any>([])
	const { CEMENTINVENTORY,RAWMATERIAL } = useSelector((state: RootState) => state);


	const onFinish = (data: any) => {
		setDisabled(true)
		setLoading(true);
		data.project_id = localStorage.getItem('projectId');
		data.invoice_date = dayjs(data.invoice_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.receipt_date = dayjs(data.receipt_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.grade_wise_receipt = JSON.stringify({
			data: data.grade_wise_receipt,
		});

		const formData = dataToFormDataConverter(data);

		dispatch(createRecord(data.project_id, formData))
			.then(() => {
				setLoading(false)
				form.resetFields();
				dispatch(setTab(1));
				dispatch(setCementId(null))
				return navigate(`/projects/raw-material`);
			})
			.catch((error: any) => {
				setDisabled(true)
				setLoading(false)
				assignErrorToInput(form, error.STATUS);
			});
	};

	useEffect(() => {
		if (cementId) {
			dispatch(read(cementId)).then(() => {
				//
			});
		}
	}, []);
	useEffect(() => {
		const data = CEMENTINVENTORY?.read?.first_section;
		const data1 = CEMENTINVENTORY?.read?.second_section;
		if (data?.id == cementId && location.pathname.includes("/view-cement")) {
			form.setFieldsValue({
				supplier_name: data?.supplier_name,
				invoice_number: data?.invoice_number,
				invoice_date: dayjs(data?.invoice_date),
				receipt_date: dayjs(data?.receipt_date),
				total_weight: data?.total_weight,
				document_name: data?.attached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				),
				mtc_attachment: data?.mtcattached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				)
			});

			var grade_wise_receipt: any = [];
			var diameter_data:any=[];
			data1?.map((item: any) => {
				grade_wise_receipt[item?.cement_grade_id] = {};
				grade_wise_receipt[item?.cement_grade_id]["test_types"] = [];

				grade_wise_receipt[item?.cement_grade_id].received_weight =
					item.received_weight;
				grade_wise_receipt[item.cement_grade_id]["test_types"] =
					item.test_types;

					setDiameterList([])
					diameter_data.push({id:item?.cement_grade_id,name:item?.cement_grade_name,received_weight:item?.received_weight})
			});
			setDiameterList(diameter_data)
			form.setFieldsValue({
				grade_wise_receipt,
			});
			setState(prevState => !prevState);
		}
	}, [CEMENTINVENTORY?.read, setState]);

	const handleCancel = () => {
		dispatch(setTab(1));
		dispatch(setCementId(null))
		navigate(`/projects/raw-material`);
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	useEffect(() => {
		location.pathname.includes("/add-cement") && dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 2 }));
		dispatch(getCementTestTypes(2));
	}, [form]);


	const checkValue = (rule?: any, value?: any, callback?: any) => {
		if (parseFloat(value) <= 0) {
			callback("Value should be more than 0.");
		} else {
			callback();
		}
	};

	return (
		<>
			<ToolBar
				breadcrumbs={AddCementBreadcrumb(
					location.pathname.includes("/view-cement") ? "View" : "Add"
				)}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<Form
				form={form}
				onFinish={onFinish}
				className="formLayout"
				id="addForm"
				onValuesChange={validateForm}
			>
				<div className="formHeading">
					<h3>
						{location.pathname.includes("/view-cement") ? "View" : "Add"} Cement
					</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Text
								label="Name of Supplier:"
								name="supplier_name"
								rules={rules.dynamicFields()}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Invoice No.:"
								name="invoice_number"
								rules={rules.dynamicFields()}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								format={CONSTANT.DATE_FORMAT}
								label="Invoice Date :"
								name="invoice_date"
								rules={rules.dateFields()}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Total Weight :"
								name="total_weight"
								disabled={true}
								rules={[{
									validator: checkValue
								},
								{
									required: true,
									message: "It is required."
								}
								]}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								format={CONSTANT.DATE_FORMAT}
								label="Date of Receipt of Cement :"
								name="receipt_date"
								rules={rules.dateFields()}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={true}
								label="Attach Invoice "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="mtc_attachment"
								required={true}
								label="Attach MTC "
								fileList={form.getFieldValue('mtc_attachment')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-cement")
								}
							/>
						</Col>
					</Row>
				</div>

				<TableComponent 
				form={form} 
				diameter={RAWMATERIAL?.cementGrageList} 
				testType={CEMENTINVENTORY?.cementTestType}
				diameterList={diameterList}
				setDiameterList={setDiameterList}
				validateForm={validateForm}
				/>

				<div className="steelBtn">
					<Button
						className="secondaryBtn"
						htmlType="submit"
						form="addForm"
						loading={loading}
						disabled={disabled}
					>
						Submit
					</Button>
					<Button className="secondaryBtn cancelBtn" onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	);
};

export default AddCementComponent;
