import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import {
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import Config, { getServerListPayload } from "../../../../config/Config";
import { apiUrls } from "./apiUrls";

interface InitialState {
  isLoading: boolean;
  agGrid: any;
  perPage: any;
  details: any;
  dynamicConcreatData: any;
  mixDesignData: any;
  categoryData: any;
  categoryData1: any;
}

const initialState = {
  isLoading: false,
  agGrid: null,
  perPage: Config.grid.server.gridOptions?.paginationPageSize,
  details: null,
  dynamicConcreatData: null,
  mixDesignData: null,
} as InitialState;

const ConcretingSlice = createSlice({
  name: "Concreting",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
    },
    failure: (state) => {
      state.isLoading = false;
    },
    setGrid: (state, action: PayloadAction<any>) => {
      state.agGrid = action?.payload;
    },
    setDynamicConcreatData: (state, action: PayloadAction<any>) => {
      state.dynamicConcreatData = action?.payload;
    },
    setPerPage: (state, action: PayloadAction<any>) => {
      state.perPage = action?.payload;
      if (state.agGrid) {
        state.agGrid.api.paginationSetPageSize(Number(state.perPage));
        state.agGrid.api.setCacheBlockSize(state.perPage);
      }
    },
    setDetails: (state, action: PayloadAction<any>) => {
      state.details = action?.payload;
    },
    setMixDesignData: (state, action: PayloadAction<any>) => {
      state.mixDesignData = action?.payload;
    },
    setCategory: (state, action: PayloadAction<any>) => {
      state.categoryData = action?.payload;
    },
    setCategory1: (state, action: PayloadAction<any>) => {
      state.categoryData1 = action?.payload;
    },
  },
});

export const {
  start,
  success,
  failure,
  setGrid,
  setPerPage,
  setDetails,
  setDynamicConcreatData,
  setMixDesignData,
  setCategory,
  setCategory1,
} = ConcretingSlice.actions;

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
  return await api.post(apiUrls.list, payload).then(({ data }) => {
    return data;
  });
};

export const setPerPageSize =
  (size: number): AppThunk<any> =>
  async (dispatch, getState: () => RootState) => {
    dispatch(setPerPage(size));
    dispatch(setupGrid(getState().USER.agGrid));
  };

export const setupGrid =
  (params: GridReadyEvent): AppThunk<any> =>
  async (dispatch, getState: () => RootState) => {
    try {
      dispatch(setGrid(params));
      const dataSource = await createDataSource(
        getState,
        Config.grid.server.gridOptions
      );
      params.api.setServerSideDatasource(dataSource);
    } catch (error: any) {
      //
    }
  };

const changeFilterAndSort = (params: any) => {
  params;
};

const createDataSource = (
  getState: () => RootState,
  gridOptions?: GridOptions
) => {
  return {
    gridOptions,
    getRows: (params: IServerSideGetRowsParams) => {
      changeFilterAndSort(params.request);
      const payload = getServerListPayload(params);
      fetchList(payload).then((data) => {
        params.success({ rowData: data?.rows, rowCount: data?.count });
        if (data.count <= 0) {
          params.api.showNoRowsOverlay();
        } else {
          params.api.hideOverlay();
        }
      });
    },
  };
};

/** For Listing:End */

export const getDynamicConcreatData =
  (mappingId: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.post(apiUrls.concreatData(mappingId));
      dispatch(setDynamicConcreatData(response.data));
      dispatch(success());
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.reject(error.data);
    }
  };

export const getMixDesignData =
  (projectId: any, structureId:any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.post(apiUrls.mixDesignData(projectId,structureId));
      dispatch(setMixDesignData(response.data.data));
      dispatch(success());
      return Promise.resolve(response.data);
    } catch (error: any) {
      dispatch(failure());
      return Promise.reject(error.data);
    }
  };

export const createRecord =
    (qa_params_value_mapping_id: any, action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(
          apiUrls.create(qa_params_value_mapping_id),
          action
        );
        dispatch(success(response.data));
        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure(error.data));
        return Promise.reject(error.data);
      }
    };

const ConcretingSliceReducer = ConcretingSlice.reducer;
export default ConcretingSliceReducer;
