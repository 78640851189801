import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { DepartmentTypeBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { Button } from "antd";
import AddComponent from "./components/Forms/AddComponent";
import EditComponent from "./components/Forms/EditComponent";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { deleteRecord, fetchList, refreshGrid, setDepTypeData, setPerPageSize, setRead } from "./utils/slice";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import WarningModal from "../../../components/Modals/WarningModal";

const moduleName = "Department Type";

const DepartmentType: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const dispatch: AppDispatch = useDispatch();
  const { DEPARTMENT_TYPE, AUTH } = useSelector(
    (state: RootState) => state
  );

  const [addForm, setAddForm] = useState<any>(false);
  const [editForm, setEditForm] = useState<any>(false);
  const [deleteModal, setDeleteModal] = useState<any>(false)
  const [disabled, setDisabled] = useState<any>();
  const [openModal, setOpenModal] = useState(false)

  const onRowClick = (data?: any) => {
    dispatch(setDepTypeData(data.data));

    const count_keys = Object.entries(data?.data)?.filter((value: any) => {
      if (value[0].search("count") != -1) {
        return value
      }
    });
    const result = count_keys?.every((data: any) => {
      return data[1] == 0
    })

    if (result == true) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }
  };
  const closeDeleteModal = () => {
    setDeleteModal(false)
    dispatch(setDepTypeData(null))
    dispatch(refreshGrid)
  }

  useEffect(() => {
    dispatch(fetchList());
    dispatch(setDepTypeData(null));
  }, []);

  const handleClick = () => {
    setAddForm(true);
  };

  const closeModal = () => {
    setAddForm(false);
    setEditForm(false);
    dispatch(refreshGrid)
  };


  return (
    <>
      {
        !AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
          <ToolBar breadcrumbs={DepartmentTypeBreadcrumb}>
            <>
              {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
                <CreateButton
                  action={() => handleClick()}
                  permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                />}
              <RecordPerPage
                defaultValue={DEPARTMENT_TYPE.perPageSize + " per page"}
                onChange={(perPageSize: number) => {
                  dispatch(setPerPageSize(perPageSize));
                }}
              />{" "}
            </>
          </ToolBar>
          <ContentBox>
            <AddComponent
              titleName={moduleName}
              open={addForm}
              close={closeModal}
            />

            <EditComponent
              titleName={moduleName}
              open={editForm}
              close={closeModal}
            />
            {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
              <DeleteModal
                title={moduleName.toLowerCase()}
                deleteValues={DEPARTMENT_TYPE.DepTypeData}
                callApi={deleteRecord}
                close={() => closeDeleteModal()}
                open={deleteModal}
              />
            }
            <WarningModal
              title={moduleName}
              close={() => { setOpenModal(false);  dispatch(refreshGrid);dispatch(setDepTypeData(null))}}
              open={openModal} />
            <div className="listWrapper">
              <div className="list">
                <Listing
                  moduleName={moduleName}
                  handleEditClick={(data: any) => {
                    dispatch(setRead(data));
                    setEditForm(true);
                  }}
                  rowData={DEPARTMENT_TYPE.rowData}
                  onRowClick={onRowClick}
                />
              </div>
              <div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
                <div className="actionBtn">
                  {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
                    <Button
                      onClick={() => {
                        dispatch(setRead(DEPARTMENT_TYPE.DepTypeData));
                        setEditForm(true);
                      }}
                      disabled={DEPARTMENT_TYPE.DepTypeData ? false : true}
                      className={DEPARTMENT_TYPE.DepTypeData ? "activeBtn" : "disableBtn"}
                    >
                      Edit
                      <SVGIcon icon="edit" />
                    </Button>}
                  {
                    checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&

                    <Button
                      disabled={DEPARTMENT_TYPE.DepTypeData ? false : true}
                      onClick={() => {
                        dispatch(setDepTypeData(DEPARTMENT_TYPE.DepTypeData))
                        disabled == true ? setOpenModal(true) : setDeleteModal(true)
                      }}
                      className={DEPARTMENT_TYPE.DepTypeData ? "deleteBtn" : "disableDelBtn disableBtn"}
                    >
                      Delete
                      <SVGIcon icon="delete" />
                    </Button>
                  }
                </div>
              </div>
              </div>
          </ContentBox></> : <RestrictedAccessPage />}
    </>
  );
};

export default DepartmentType;
