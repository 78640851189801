
import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import { Button, Form } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from "../../../assets/images/Logo.png";
import LoginBg from "../../../assets/images/bg.png";
import LoginDesign from "../../../assets/images/LoginDesign.png";
import { LoginRequest } from './Validation';
import { AppDispatch } from '../../../store/app';
import { updatePassword } from '../../../store/AuthSlice';
import { assignErrorToInput } from '../../../store/api';
import { useDispatch } from 'react-redux';
import { validateFields } from '../../../config/Global';


const ResetPassword: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const navigate = useNavigate();
	const { otp }: any = useParams();
	
	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const resetPassword = (data: any) => {
		localStorage.removeItem('token')
		localStorage.clear()
		setSaving(true)
		const payload = {
			...data,
			otp
		}
		dispatch(updatePassword(otp, payload)).then(() => {
			navigate('/login');
		}).catch((error: any) => {
			assignErrorToInput(form, error?.STATUS);
		}).finally(() => setSaving(false))
	}

	// const onChange = () => {
	// 	form.validateFields(['password']).then(() => {
	// 		""
	// 	}).catch(() => {
	// 	""
	// 	})
	// }


	return (
		<section className="loginSection">
			<div className="loginWrap" style={{ backgroundImage: `url(${LoginBg})` }}>
				<div className="loginLogo">
					<div className="logo">
						<img src={Logo} alt="Logo" />
					</div>
				</div>
				<div className="loginForm" >
					<div className="loginFormWrap">
						<div className="formTitle">
							<h2>Reset Password</h2>
							<span>Welcome Ahmedabad Municipal Corporation</span>
						</div>
						<FormBox form={form}
							onFinish={resetPassword}
						// onChange={onChange}
						>
							<InputBox.Password
								name="password"
								placeholder="New Password"
								rules={LoginRequest().newPassword()}
								onChange={validateForm}
							/>
							<div>
								<InputBox.Password
									name="password_confirmation"
									placeholder="Confirm Password"
									rules={LoginRequest().confirmPassword()}
									onChange={validateForm}
								/>
							</div>
							<div className="text-center signBtn">
								<Button
									type="primary"
									htmlType="submit"
									style={{ background: "#3e97ff" }}
									loading={saving}
									disabled={disabled}
								>
									Reset Password
								</Button>
							</div>
							<div
								className="mt-10 font-medium login_extra_fields"
								style={{ display: "flex", justifyContent: "flex-end" }}
							>
								<Link to={"/login"}>Back to login</Link>
							</div>
						</FormBox>
					</div>
				</div>
				<div className="loginDesign">
					<img src={LoginDesign} alt="Logo" />
				</div>
			</div>
		</section>
	)
}

export default ResetPassword;