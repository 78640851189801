const baseUrl = '/v1/admin/qa-parameter'

export const apiUrls = {
    list: `${baseUrl}/list`,
    qaParams: `${baseUrl}/qa-params`,
    create: `${baseUrl}/create2`,
    update: (mapping_id: number): string => `${baseUrl}/${mapping_id}/update`,
    read: (mapping_id: number): string => `${baseUrl}/${mapping_id}/read`,
    removeImage: `${baseUrl}/remove-document`,
    change_status: (mapping_id: number): string => `${baseUrl}/${mapping_id}/change-status`,
    node_delete:`${baseUrl}/node-delete`
}
