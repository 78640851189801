import { ColDef } from 'ag-grid-community'
import {  createdAtColumn, idColumn } from "../../../../../../../utils/commonColumns";
import dayjs from 'dayjs';
import { CONSTANT } from '../../../../../../../config/Constant';

const SupplierColumn: ColDef = {
	field: 'supplier_name',
	headerName: 'Name of Supplier',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.supplier_name,
}
const InvoiceNumberColumn: ColDef = {
	field: 'invoice_number',
	headerName: 'Invoice Number ',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.invoice_number,
}
const InvoiceDateColumn: ColDef = {
	field: 'invoice_date',
	headerName: 'Invoice Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
        return dayjs(data.data.invoice_date).format(CONSTANT.DATE_FORMAT)
    },
	// tooltipValueGetter: (params: any) => params.data.invoice_date,
}

const columnDefs: ColDef[] = [
	idColumn,
	SupplierColumn,
	InvoiceNumberColumn,
	InvoiceDateColumn,
    createdAtColumn
]
export default columnDefs
