import { validations } from "../../../../../config/validations/validations";

export const rules = {
  name: (field?: string) => [
    validations.required.text(field),
    {
      min: 2,
      message: "It must have at least 2 characters.",
    },
    {
      max: 50,
      message: "Maximum length is 50 characters.",
    },
    // {
    //     pattern:/^(?!\s+$).+/,
    //     message:"It is required"
    // }
  ],
	permissions: [
		({ getFieldValue }: any) => ({
			validator() {
				const permissionsArray: any = getFieldValue('permissions');
				if (permissionsArray) {
					let verified = false
					permissionsArray.map((item: any) => {
						if (item.actions.length > 0) {
							const is_selected_index: any = item.actions.filter((x: any) => x.is_selected === 1)
							if (is_selected_index.length > 0) {
								verified = true
							}
						}
						return null
					})
					if (verified) {
						return Promise.resolve();
					} else {
						return Promise.reject('Atleast one permission should be selected.');
					}
				} else {
					return Promise.resolve();
				}
			}
		})
	]
};
