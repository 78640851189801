import { validations } from '../../../../../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	selectFields: (field?: any) => [
		validations.required.select(field),
	],
	radioButton: (field?: any) => [
		validations.required.other(field),
	],
	dateFields: (field?: string) => [
		validations.required.select(field),
	],
	FileUpload: (field?: any) => [
		validations.required.text(field),
	]
}