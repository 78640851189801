import React, { useEffect, useState } from "react";
import { Card, Checkbox, Col, Divider, Row, Skeleton } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";
import { fetchPrivilegesList } from "../../../../../store/CommonSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/app";

const FormComponent: React.FC<any> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const [privilegesList, setPrivilegesList] = useState<any[]>([]);

	const include = ["INVENTORY", "MIXDESIGN", "STRUCTURE_ELEMENT", "CONSOLIDATE_REPORT", "PROGRESS_REPORT"]
	const childInclude = ["PROJECTS", "INVENTORY", "REPORT", "MIXDESIGN", "STRUCTURE_ELEMENT", "CONSOLIDATE_REPORT", "PROGRESS_REPORT"]
	const report = ["REPORT", "INVENTORY"]

	useEffect(() => {
		form.setFieldsValue({ showChild: true, showReport: true })
	}, [])

	const onChangeContent = (
		checked: boolean,
		id: number,
		parentId: number,
		type: string
	) => {
		const newPrivilegesList: any[] = privilegesList.map((item: any) => {
			let isSelected = item.selected;
			form.setFieldValue("checkParent", true)
			const child = item.child.map((childItem: any) => {
				let childSelected = childItem.selected;
				if (parentId && id == childItem.id) {
					childSelected = checked;
				}
				if (type == "parentId" && childItem.parent_id == id) {
					childSelected = checked;
				}
				if (isSelected === false && childSelected) {
					isSelected = childSelected;
				}

				return { ...childItem, selected: childSelected };
			});

			if (item.id === id) {
				isSelected = checked;
			}

			const d1 = { ...item, selected: isSelected, child: child }

			d1?.child?.map((d: any) => {
				if (parentId == 10001 && id == d?.id && d?.name == "List") {
					if ((d?.selected || d1?.selected) && checked) {
						form.setFieldValue("showChild", false)
					} else if (!checked) {
						form.setFieldValue("showChild", true)
						form.setFieldValue("showReport", true)

					}
					return d
				} else {
					return d
				}
			})

			if (d1?.selected && id == d1?.id && d1?.permission_key == "PROJECTS") {
				form.setFieldValue("showChild", false)
			} else if (!d1?.selected && id == d1?.id && d1?.permission_key == "PROJECTS") {
				form.setFieldValue("showChild", true)
				form.setFieldValue("showReport", true)
			}

			const f_child = d1?.child?.map((d: any) => {
				if (form.getFieldValue("showChild") == true && childInclude?.includes(d1?.permission_key)) {
					return ({ ...d, selected: false })
				} else {
					return d
				}
			})


			d1?.child?.map((d: any) => {
				if (parentId == 10006 && id == d?.id && d?.name == "List") {
					if ((d?.selected || d1?.selected) && checked) {
						form.setFieldValue("showReport", false)
					} else if (!checked) {
						form.setFieldValue("showReport", true)
					}
					return d
				} else {
					return d
				}
			})
			if (d1?.selected && id == d1?.id && d1?.permission_key == "INVENTORY") {
				form.setFieldValue("showReport", false)
			} else if (!d1?.selected && id == d1?.id && d1?.permission_key == "INVENTORY") {
				form.setFieldValue("showReport", true)
			}

			const final_child = f_child?.map((d: any) => {
				if (form.getFieldValue("showReport") == true && report?.includes(d1?.permission_key)) {
					return ({ ...d, selected: false })
				} else {
					return d
				}
			})

			const final_item = final_child?.map((d: any) => {
				if (d?.name == "List" && !d?.selected && item?.id == parentId) {
					form.setFieldValue("checkParent", false)
				}
				if (form.getFieldValue("checkParent") == false) {
					return { ...d, selected: false }
				} else {
					return d
				}
			})

			return { ...item, selected: form.getFieldValue("showChild") == true ? false : isSelected, child: final_item }
		});
		onValuesChange && onValuesChange();
		setPrivilegesList(newPrivilegesList);
	};


	useEffect(() => {
		dispatch(fetchPrivilegesList()).then((data: any) => {
			const oldPrivileges =
				(editValues?.privileges && editValues?.privileges.split("#")) ?? [];
			const privileges = data && data?.data?.map((item: any) => {

				let childIsSelected = false;
				let selected = false;
				const child = item.child.map((childItem: any) => {
					let childSelected = false;
					if (!childIsSelected) {
						childIsSelected = oldPrivileges.includes(childItem.id.toString());
					}
					childSelected = oldPrivileges.includes(childItem.id.toString());

					return { ...childItem, selected: childSelected };
				});

				if (
					childIsSelected ||
					(item.child.length == 0 &&
						oldPrivileges.includes(item.id.toString()))
				) {
					selected = true;
				} else {
					selected = false;
				}

				const d1 = { ...item, selected: selected, child: child };

				if (d1?.id == 10001) {
					if (d1?.selected) { form.setFieldValue("showChild", false) } else if (d1?.id == 10001 && !d1?.selected) {
						form.setFieldValue("showChild", true)
					}
					d1?.child?.map((d: any) => {
						if (d?.name == "List" && d?.selected) {
							form.setFieldValue("showChild", false)
						} else if (d?.name == "List" && !d?.selected) {
							form.setFieldValue("showChild", true)
						}
					})
				}
				if (d1?.id == 10006) {
					if (d1?.selected) { form.setFieldValue("showReport", false) } else if (d1?.id == 10001 && !d1?.selected) {
						form.setFieldValue("showReport", true)
					}
					d1?.child?.map((d: any) => {
						if (d?.name == "List" && d?.selected) {
							form.setFieldValue("showReport", false)
						} else if (d?.name == "List" && !d?.selected) {
							form.setFieldValue("showReport", true)
						}
					})
				}

				return { ...item, selected: selected, child: child };
			});
			setPrivilegesList(privileges);
		});
	}, []);

	useEffect(() => {
		if (editValues) {
			form.setFieldsValue(editValues);
		}
	}, [editValues, form]);

	useEffect(() => {
		//
	}, [privilegesList]);


	const onFinish = (data: any) => {
		const selectedPrivileges: any = [];
		var selected:any=[];
		privilegesList.map((privilege: any) => {
			//privilege.selected && selectedPrivileges.push(privilege.id);
			privilege.child.map((childPrivilege: any) => {
				selected?.push(childPrivilege?.selected)
				!privilege.selected && childPrivilege.selected && childPrivilege?.name == "List" && selectedPrivileges.push(privilege.id);
				childPrivilege.selected && selectedPrivileges.push(childPrivilege.id);
				childPrivilege?.actions?.map((act: any) => {
					act?.selected && selectedPrivileges?.push(act?.id)
				})
			});
			selected?.includes(true) && privilege.selected && selectedPrivileges.push(privilege.id);
			selected=[]
		});
		data.privileges =
			selectedPrivileges.length > 0
				? "#" + selectedPrivileges.join("#") + "#"
				: null;
		handleSubmit(data);
	};

	return (
		<>
			<FormBox
				form={form}	
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={15}>
					<Col xs={{ span: 24 }} md={{ span: 12 }}>
						<InputBox.Text
							required
							name="name"
							label="Role Name"
							rules={rules.name()}
						/>
					</Col>
					<Col xs={{ span: 24 }}>
						<Row gutter={15}>
							<Col xs={{ span: 4 }}>Features</Col>
							<Col xs={{ span: 20 }}>Actions</Col>
						</Row>
						{privilegesList.length === 0 ? (
							<Skeleton active />
						) : (
							privilegesList.map((item: any) => {

								let isDisabled = false
								item?.child.map((action: any) => {
									if (action.permission_key === `${item.permission_key}_INDEX` && action.selected === false) {
										isDisabled = true

									}
								})

								let allChecked = 0
								item?.child.map((action: any) => {
									if (action.selected) {
										allChecked++
									}
								})

								return (
									<Row gutter={15} key={item.id} className="roleRow">
										<Card className="mt-10" style={{ width: "100%" }}>
											<Row gutter={15} key={item.id}>
												<Col
													xs={{ span: 4 }}
													style={{ borderRight: "1px solid #f0f0f0" }}
												>
													<b>{item.name == "Dashboard" ? "Dashboard / Projects" : item?.name}</b>
												</Col>
												<Col xs={{ span: 20 }}>
													<div style={{ display: "inline" }}>
														{
															item?.permission_key !== "REPORT" && <Checkbox
																className="commanCheckbox"
																checked={allChecked === item?.child?.length}
																value={item.id}
																onChange={e =>
																	onChangeContent(
																		e.target.checked,
																		item.id,
																		0,
																		"parentId"
																	)
																}
																disabled={(form.getFieldValue("showChild") && include?.includes(item?.permission_key))}
															>
																Check All
															</Checkbox>
														}
														{
															item?.permission_key == "REPORT" && <Checkbox
																className="commanCheckbox"
																checked={allChecked === item?.child?.length}
																value={item.id}
																onChange={e =>
																	onChangeContent(
																		e.target.checked,
																		item.id,
																		0,
																		"parentId"
																	)
																}
																disabled={form.getFieldValue("showReport")}
															>
																Check All
															</Checkbox>
														}

													</div>
													<Divider type="vertical" />
													{item?.child.map(
														(subPrivilege: any, index: number) => {
															return (
																<>
																	<div
																		key={index}
																		style={{ display: "inline" }}
																	>
																		{item?.permission_key !== "REPORT" && <Checkbox
																			className="commanCheckbox"
																			checked={subPrivilege.selected}
																			onChange={e =>
																				onChangeContent(
																					e.target.checked,
																					subPrivilege.id,
																					subPrivilege.parent_id,
																					"childId"
																				)
																			}
																			disabled={include?.includes(item?.permission_key) && subPrivilege?.permission_key?.includes("_INDEX") ? form.getFieldValue("showChild") : isDisabled && !subPrivilege.permission_key.includes("_INDEX")}
																		>
																			{subPrivilege.name}
																		</Checkbox>}
																		{
																			item?.permission_key == "REPORT" && <Checkbox
																				className="commanCheckbox"
																				checked={subPrivilege?.selected}
																				onChange={e =>
																					onChangeContent(
																						e.target.checked,
																						subPrivilege.id,
																						subPrivilege.parent_id,
																						"childId"
																					)
																				}
																				disabled={item?.permission_key == "REPORT" && subPrivilege?.permission_key?.includes("_INDEX") ? form.getFieldValue("showReport") : isDisabled && !subPrivilege.permission_key.includes("_INDEX")}
																			>
																				{subPrivilege.name}
																			</Checkbox>
																		}
																	</div>
																	<Divider type="vertical" />
																</>
															);
														}
													)}
												</Col>
											</Row>
										</Card>
										<InputBox.Text hidden name={"showChild"} />
										<InputBox.Text hidden name={"showReport"} />
										<InputBox.Text hidden name={"checkParent"} />
									</Row>
								);
							}))}
					</Col>
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;
