import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../components/AntdAddons";
import TableComponent from "./TableComponent";
import { rules } from "./rules";
import ToolBar from "../../../../../../../components/ToolBar";
import { AddBindingWireBreadcrumb } from "../../../../../../../config/BreadcrumbConfig";
import { useNavigate, useParams } from "react-router-dom";
import { CONSTANT } from "../../../../../../../config/Constant";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { dataToFormDataConverter, validateFields } from "../../../../../../../config/Global";
import { createRecord, setreadResetBindingWire, read } from "../utils/slice";
import { assignErrorToInput } from '../../../../../../../store/api'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../store/app";

const AddBindingWire: React.FC = () => {

	const { id }: { id?: any, projectId?: any } = useParams();
	const [form] = useForm();
	const navigate = useNavigate();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const dispatch: AppDispatch = useDispatch()
	const { BINDINGWIRE } = useSelector((state: RootState) => state)
	const [loading, setLoading] = useState(false);
	const [, setState] = useState(false);


	const onFinish = (data: any) => {
		data.project_id = localStorage.getItem('projectId');
		data.invoice_date = dayjs(data.invoice_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.receipt_date = dayjs(data.receipt_date).format(
			CONSTANT.POST_DATE_FORMAT
		);
		data.binding_wire_quality = JSON.stringify({ data: data.pending_drs });
		delete data.pending_drs;

		const formData: any = dataToFormDataConverter(data)
		try {
			setDisabled(true)
			setLoading(true)
			dispatch(createRecord(Number(localStorage.getItem('projectId')), formData))
				.then(() => {
					setLoading(false)
					setDisabled(false)
					form.resetFields()
					return navigate(`/projects/raw-material`)
				}).catch((error: any) => {
					setLoading(false)
					setDisabled(false)
					assignErrorToInput(form, error?.STATUS)
				})
		} catch (error) {
			console.error('Error submitting data:', error)
		}

	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	useEffect(() => {
		if (id && location.pathname.includes("/view-binding-wire")) {
			dispatch(setreadResetBindingWire())
			form.resetFields()
			dispatch(read(id)).catch((error: any) => error);
		}
	}, []);

	useEffect(() => {

		if (location.pathname.includes("/view-binding-wire")) {
			const data = BINDINGWIRE?.read?.first_section;
			const data1 = BINDINGWIRE?.read?.second_section;
			if (location.pathname.includes("/view-binding-wire") && data?.id == id) {
				form.setFieldsValue({
					supplier_name: data?.supplier_name,
					invoice_number: data?.invoice_number,
					invoice_date: dayjs(data?.invoice_date),
					receipt_date: dayjs(data?.receipt_date),
					total_liters: data?.total_liters,
					document_name: data?.attached_documnent?.map(
						(image: { id: any; document_name: any }) => {
							return {
								// uid: image.id,
								name: image?.document_name,
								status: "done",
								url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							};
						}
					)
				});
				var pending_drs: any = [];
				data1 && data1[0]['test_types']?.map((item: any, subIndex: number) => {
					pending_drs[item?.id] = {};

					form.setFieldValue(
						[
							"pending_drs",
							data1[0]['binding_wire_testing_name_id']?.toString(),
							"test_types",
							subIndex,
							"test_type_value",
						],
						item.test_type_value
					);

				});
				setState(prevState => !prevState);

			}
		}
	}, [BINDINGWIRE?.read, id, setState]);

	const handleCancel = () => {
		navigate(`/projects/raw-material`);
	};
	return (
		<>
			<ToolBar
				breadcrumbs={AddBindingWireBreadcrumb(
					location.pathname.includes("/view-binding-wire") ? "View" : "Add"
				)}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>

			<Form

				form={form}
				onFinish={onFinish}
				className="formLayout"
				onValuesChange={validateForm}
				id="addForm"
			>
				<div className="formHeading">
					<h3>{location.pathname.includes("/add-binding-wire") ? "Add " : "View "} Binding Wire</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}
						>
							<InputBox.Text
								label="Name of Supplier"
								required={true}
								rules={rules.dynamicFields()}
								name="supplier_name"
								disabled={
									location.pathname.includes("/view-binding-wire")
								}
							/>
						</Col>
						<Col span={12} >
							<InputBox.Text
								label="Invoice No"
								rules={rules.dynamicFields()}
								required={true}
								name="invoice_number"
								disabled={
									location.pathname.includes("/view-binding-wire")
								}

							/>

						</Col>
						<Col span={12} >
							<InputBox.DatePicker
								format={CONSTANT.DATE_FORMAT}
								label="Invoice Date"
								rules={rules.dateFields()}
								name="invoice_date"
								disabled={
									location.pathname.includes("/view-binding-wire")
								}
							/>

						</Col>
						<Col span={12} >
							<InputBox.Text
								label="Total Weight"
								name="total_liters"
								rules={rules.numberFields()}
								disabled={
									location.pathname.includes("/view-binding-wire")
								}
							/>

						</Col>
						<Col span={12} >
							<InputBox.DatePicker

								name="receipt_date"
								format={CONSTANT.DATE_FORMAT}
								rules={rules.dateFields()}
								label="Date of Receipt of Binding wire"
								disabled={
									location.pathname.includes("/view-binding-wire")
								}
							/>

						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={true}
								label="Attach Invoice "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-binding-wire") ? true : false
								}
							/>

						</Col>
					</Row>
				</div>

				<TableComponent form={form} />

				<div className="steelBtn">
					<Button
						className="secondaryBtn"
						htmlType="submit"
						disabled={location.pathname.includes("/view-binding-wire") ? true : disabled}
						loading={loading}
						form="addForm"
					>
						Submit
					</Button>
					<Button
						className="secondaryBtn"
						htmlType="submit"
						onClick={handleCancel}
					>
						Cancel
					</Button>



				</div>
			</Form>
		</>
	);
};

export default AddBindingWire;

