import React, { useEffect, useRef, useState } from 'react';
import { Pagination, Table } from 'antd';

interface DataType {
	[key: string]: any
}

const WaterDepartment: React.FC<any> = ({ perPage, currentPage, handlePageChange, columns1, transformedData, rowData, finalData }) => {
	const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);
	const tableContainerRef: any = useRef();
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const isSingleProject = finalData?.length === 1;
	const tableScroll = isSingleProject
		? { x: 'max-content', y: 'auto' }
		: windowWidth <= 991
			? { x: '100%', y: "65vh" }
			: { x: 1440, y: '65vh' };

	return (
		<div ref={tableContainerRef} className="test">
			<div className='verticalPagination'>
				<Pagination
					current={currentPage}
					total={rowData?.count}
					onChange={handlePageChange}
					pageSize={perPage}
					showSizeChanger={false}
					showLessItems={true}
				/>
			</div>
			<Table
				columns={finalData?.length > 0 ? columns1 : []}
				dataSource={finalData?.length > 0 ? transformedData : ''}
				scroll={tableScroll}
				ref={tblRef}
				pagination={false}
				rowClassName={(record: DataType) => {
					if (
						record.first_col == 'Pile' ||
						record.first_col === 'Pile Cap' ||
						record.first_col === 'Pier' ||
						record.first_col === 'Pier Cap' ||
						record.first_col === "Bearing" ||
						record.first_col === "Grider" ||
						record.first_col === "Box Grider" ||
						record.first_col === "Rt Wall" ||
						record.first_col === "Solid Slab"
					) {
						return 'titleRow';
					} else {
						return '';
					}
				}}
				className={finalData?.length > 3 ? "departmentTable" : "moreDepartmentTable"}
			/>
		</div>
	);
};

export default WaterDepartment;