import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	pmcAddress: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	mobileFields: (field?: any) => [validations.required.text(field),
	validations.pattern.phone],
	EmailField: (field?: string) => [validations.required.text(field),
	validations.email(field),
	//validations.minMax(1,70)
	{
		min:1,
		max:70,
		message:"Email should contain maximum 70 characters."
	}
	]

}