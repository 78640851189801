import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../components/AntdAddons";
import ToolBar from "../../../../../../components/ToolBar";
import {
	AddReportBreadcrumb,
	ViewReportBreadcrumb,
} from "../../../../../../config/BreadcrumbConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import {
	createReport,
	getLaboratoryName,
	getSteelDiameterList,
	getTestTypes,
	readReport,
	setLocation,
	setReportId,
	setSteelReportId,
} from "../../TabElements/Steel/utils/slice";
import { dataToFormDataConverter, validateFields } from "../../../../../../config/Global";
import dayjs from "dayjs";
import { rules } from "./rules";
import { assignErrorToInput } from "../../../../../../store/api";
import { CONSTANT } from "../../../../../../config/Constant";


const AddReportComponent: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const {
		steelId,
		reportId,
	}: { projectId?: any; steelId?: any; reportId?: any } = useParams();
	const [form] = Form.useForm();
	const dispatch: AppDispatch = useDispatch();
	const { STEELDIAMETER } = useSelector((state: RootState) => state);
	const navigate = useNavigate();
	const location = useLocation();
	const [testType, setTestType] = useState<any>([])
	const [disabled, setDisabled] = useState(true)
	const [, setState] = useState(false);
	const laboratoryList = form.getFieldValue("laboratory_type")
	const handleSubmit = (data?: any) => {
		setDisabled(true)
		setLoading(true)
		const obj = data?.observed_value
		delete data?.observed_value
		delete data?.test_type_id
		delete data?.expected_value
		const payload = {
			...data,
			date_of_testing: dayjs(data?.date_of_testing).format(CONSTANT?.POST_DATE_FORMAT),
			project_id: localStorage.getItem('projectId'),
			mst_steel_inventory_id: steelId,
			observed_value: JSON.stringify(Object.values(obj))

		};

		const formdata = dataToFormDataConverter(payload);
		try {
			form.validateFields();
			dispatch(createReport(steelId, formdata))
				.then(() => {
					setLoading(true);
					setTimeout(() => {
						setLoading(false)
						form.resetFields();
						dispatch(setSteelReportId(null))
						navigate(
							`/projects/raw-material/${steelId}/steel-report`
						);
					}, 500);
				})
				.catch((err: any) => {
					setDisabled(true)
					setLoading(false)
					assignErrorToInput(form, err.STATUS);
				});
		} catch (error) {
			console.error("Error submitting data:", error);
		}
	};
	
	useEffect(() => {
		if (location.pathname.includes("/view-steel-report")) {
			dispatch(setReportId(steelId))
			dispatch(setLocation("/steel-report"))
			dispatch(getTestTypes(1));
			dispatch(getSteelDiameterList());
		}
	}, []);
	useEffect(() => {
		if (reportId) {
			dispatch(readReport(reportId)).then(() => {
				//
			});
		}
	}, []);
	useEffect(() => {
		STEELDIAMETER?.readReportData && STEELDIAMETER?.readReportData?.map((data?: any) => {
			if (data?.id == reportId) {
				STEELDIAMETER.testTypes?.filter((item?: any) => {
					if (item?.id == data?.test_type_id) {
						setTestType([{ ...item }])
					}
				})
				form.setFieldValue([`observed_value`, `${data?.test_type_id}`, "observed_value"], data?.observed_value)
				form.setFieldsValue({
					mst_steel_diameter_id: data?.mst_steel_diameter_id,
					test_type_id: data?.test_type_id,
					laboratory_type: data?.laboratory_type,
					laboratory_name: data?.laboratory_original_name,
					test_passed: data?.test_passed,
					date_of_testing: dayjs(data?.date_of_testing),
					attach_report: data?.attached_documnent?.map(
						(image: { id: any; document_name: any }) => {
							return {
								// uid: image.id,
								name: image?.document_name,
								status: "done",
								url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							};
						}
					),
					data_sheet: data?.data_sheet_documnent?.map(
						(image: { id: any; document_name: any }) => {
							return {
								// uid: image.id,
								name: image?.document_name,
								status: "done",
								url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							};
						}
					)
				});
			}
			setState(prevState => !prevState);
		});
	}, [STEELDIAMETER?.readReportData, setState]);

	const handleCancel = () => {
		dispatch(setSteelReportId(null))
		steelId ? navigate(`/projects/raw-material/${steelId}/steel-report`) : navigate(`/projects/raw-material/steel-report`)
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes(
						`/view-steel-report`
					)
						? steelId ? ViewReportBreadcrumb(steelId, "Raw Material Testing - Steel") : ViewReportBreadcrumb(steelId, "Consolidate Report")
						: AddReportBreadcrumb(steelId)
				}
			></ToolBar>
			<div className="formHeading">
				<h3>
					{location.pathname.includes("/view-steel-report")
						? "View "
						: location.pathname.includes("/add-steel-report")
							? "Add "
							: ""}
					Report
				</h3>
			</div>
			<Form className="addReportForm" onFinish={handleSubmit} form={form} onValuesChange={validateForm}>
				<div>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								name="mst_steel_diameter_id"
								className="select textField"
								onFocus={() => dispatch(getSteelDiameterList())}
								options={{
									list: STEELDIAMETER?.steelDiameterList,
									valueKey: "id",
									textKey: "name",
								}}
								label="Select Diameter"
								mode={location.pathname.includes("/add-steel-report") ? "multiple" : undefined}
								allowClear
								disabled={
									location.pathname.includes("/view-steel-report")
								}
								rules={rules.selectFields()}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								name="laboratory_type"
								label="Laboratory Type"
								rules={rules.radioButton()}
								onChange={(e) => {
									dispatch(getLaboratoryName(e.target.value))
									form.setFieldValue('laboratory_name', [])
								}
								}
								disabled={
									location.pathname.includes("/view-steel-report")
								}
								options={{
									list: [
										{
											id: 1,
											value: "NABL Lab",
											name: "NABL Lab",
										},
										{
											id: 2,
											value: "Government lab",
											name: "Government Lab",
										},
										{
											id: 3,
											value: "On-site Lab",
											name: "On-site Lab",
										},
									]
								}}
							/></Col>
						{laboratoryList &&
							<Col span={12}>
								<InputBox.Select
									name="laboratory_name"
									className="select textField"
									label="Name of Laboratory"
									options={{
										list: STEELDIAMETER.laboratoryName,
										valueKey: "id",
										textKey: "laboratory_name",
									}}
									disabled={
										location.pathname.includes("/view-steel-report")
									}
									rules={rules.selectFields()}
								/>
							</Col>}
						<Col span={12}>
							<InputBox.DatePicker
								label="Date of Testing "
								name="date_of_testing"
								disabled={
									location.pathname.includes("/view-steel-report")
								}
								rules={rules.dateFields()}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								name="test_passed"
								label="All Tests Passed As Per Code :"
								options={{
									list: [
										{
											id: 1,
											value: "yes",
											name: "Yes",
										},
										{
											id: 2,
											value: "no",
											name: "No",
										},
									],
								}}
								disabled={
									location.pathname.includes("/view-steel-report")
								}
								rules={rules?.radioButton()}
							/>
						</Col>
					</Row>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								name="test_type_id"
								className="select textField"
								mode={location.pathname.includes("/add-steel-report") ? "multiple" : undefined}
								allowClear
								onFocus={() => dispatch(getTestTypes(1))}
								options={{
									list: STEELDIAMETER?.testTypes,
									valueKey: "id",
									textKey: "name",
								}}
								label="Test Type"
								disabled={
									location.pathname.includes("/view-steel-report")
								}
								rules={rules.selectFields()}
								onChange={(d: any) => {
									d?.map((item?: any) => {
										STEELDIAMETER?.testTypes?.filter((data: any) => {
											if (item == data?.id) {
												setTestType([...testType, data])
											}
										});
									})
								}}
								onDeselect={(data?: any) => {
									const d = testType?.filter((item: any) => {
										if (data == item?.id) {
											form.setFieldValue([`observed_value`, `${item?.id}`, "observed_value"], "")
										}
										return data !== item?.id
									})
									setTestType(d)
								}
								}
								onClear={() => {
									setTestType([])
									form.setFieldValue("observed_value", "")
								}}
							/>
						</Col>
						<Col span={12}></Col>
						{
							testType && testType?.map((data?: any) => {
								form.setFieldValue([`expected_value`, `${data?.id}`], data?.expected_value ? data?.expected_value : "N/A")
								form.setFieldValue([`observed_value`, `${data?.id}`, "test_type_id"], data?.id)
								return <>

									<InputBox.Text
										name={[`observed_value`, `${data?.id}`, "test_type_id"]}
										label={`Observed Value - ${data.name}`}
										disabled={
											location.pathname.includes("/view-steel-report")
										}
										className="textField"
										hidden
									/>

									<Col span={12}>
										<InputBox.Text
											name={[`observed_value`, `${data?.id}`, "observed_value"]}
											label={`Observed Value - ${data.name}`}
											disabled={
												location.pathname.includes("/view-steel-report")
											}
											rules={rules.dynamicFields()}
											className="textField"
										//Don't remove this code
										// rules={[
										//   {
										//     validator: validatevalue,
										//   },
										//   {
										//     required:true,
										//     message:"It is required."
										//   }
										// ]}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Text
											name={[`expected_value`, `${data?.id}`]}
											label={`Expected Value - ${data.name}`}
											disabled={true}
											className="textField"
										/>
									</Col>
								</>
							})
						}
					</Row>
					<Row gutter={40}>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="attach_report"
								required={true}
								label="Attach Report"
								fileList={form.getFieldValue('attach_report')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-steel-report")
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="data_sheet"
								required={true}
								label="Data Sheet"
								fileList={form.getFieldValue('data_sheet')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-steel-report")
								}
							/>
						</Col>
					</Row>

					<Row>
						<div className="ReportBtn">
							<Button
								className="secondaryBtn"
								htmlType="submit"
								loading={loading}
								disabled={disabled
								}
							>
								Submit
							</Button>
							<Button className="cancelBtn" onClick={handleCancel}>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	);
};

export default AddReportComponent;
