import React, { useState } from 'react'
import columnDefs, { statusColumnDefs } from './columnDefs'
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper'
import { changeStatus, setPerPage, setRead, setSelectedData, setupGrid } from '../../../utils/slice'
import { Button } from 'antd'
import { AppDispatch, RootState } from '../../../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import SVGIcon from '../../../../../../utils/SVGIcon'
import ChangeStatusModal from '../../../../../../components/ChangeStatus'
import { checkPrivileges } from '../../../../../../config/Global'
import { useNavigate } from 'react-router-dom'
import RecordPerPage from '../../../../../../components/ToolBar/Dropdown/RecordPerPage'
const Listing: React.FC<any> = ({
	rowData,
	// statusSwitch,
	// setEditDrawer,
	moduleInfo
}) => {
	const dispatch: AppDispatch = useDispatch();
	const { QUESTIONMASTER, AUTH } = useSelector((state: RootState) => state);
	const [statusData, setStatusData] = useState<any>();

	const onRowClick = (data?: any) => {
		dispatch(setSelectedData(data?.data))
	};
	const navigate = useNavigate();
	const statusPermission: any = checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_STATUS`)
	return (
		<>
			<div className='questionListWrap'>
				<div className='listPage'>
					<RecordPerPage
						defaultValue={QUESTIONMASTER.perPage + " per page"}
						onChange={(perPageSize: number) => {
							dispatch(setPerPage(perPageSize));
						}}
					/>
				</div>
				<ChangeStatusModal
					titleName={moduleInfo.title}
					data={statusData}
					close={() => setStatusData(null)}
					callApi={changeStatus}
				//keyName={"name"}
				/>
				<div className='questionList'>
					<AgGridWrapper
						type="serverSide"
						rowData={rowData}
						columnDefs={statusPermission == true ? columnDefs : statusColumnDefs}
						onGridReadyWithDispatch={setupGrid}
						onRowClicked={onRowClick}
						rowSelection={"single"}
						context={{
							setStatusData,

						}}
					/>
				</div>
			</div>
			{/* <EditComponent visible={editDrawer} close={closeEditDrawer} /> */}
			{/* <Button
             onClick={() => {
              // dispatch(setDetails(QUESTIONMASTER.selectedData))
              dispatch(setRead(QUESTIONMASTER.selectedData))
              setEditDrawer(true)
            }}
            disabled={QUESTIONMASTER.selectedData == null ? true : false}
            className={QUESTIONMASTER.selectedData ? "activeBtn" : "disableBtn"}
          >
            View
            <SVGIcon icon="view" />
          </Button> */}
			{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) && <div className="actionRow rawmaterialActionRow">
				<div className="actionBtn">

					<Button
						disabled={QUESTIONMASTER.selectedData == null ? true : false}
						onClick={() => {
							// dispatch(setDetails(QUESTIONMASTER.selectedData))
							dispatch(setRead(QUESTIONMASTER.selectedData))
							navigate(`/${QUESTIONMASTER?.selectedData?.id}/edit-question`)
						}}
						className={QUESTIONMASTER.selectedData ? "activeBtn" : "disableBtn"}
					>
						Edit
						<SVGIcon icon="edit" />
					</Button>
				</div>
			</div>}

		</>
	)
}

export default Listing
