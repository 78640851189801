import React, { useEffect, useState } from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import ActionButtons from '../../../../../components/ActionButtons'
import columnDefs from './columnDefs'
import { Button, Divider, Dropdown, MenuProps, Tag, Tooltip } from 'antd'
import SVGIcon from '../../../../../utils/SVGIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { ChangeStatus, setupGrid } from '../../utils/slice'
import ApproveModal from '../../ApproveModal'

const Listing: React.FC<any> = ({
    moduleName,
    handleViewClick,
    handleDeleteClick,
    handleEditClick,
    rowData,
    onRowClick,
    moduleInfo,
}) => {
    const navigate = useNavigate()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [statusData, setStatusData] = useState<any>();

    useEffect(() => {
        setIsDropdownOpen(!isDropdownOpen)
    }, [setIsDropdownOpen])
    const { projectId, structureId, mName } = useParams()

    useEffect(() => {
        //localStorage.removeItem('mappingId')
        localStorage.removeItem('isTouched')
        //localStorage.removeItem('firstSaved')
        localStorage.removeItem('mapId')
    })

    // useEffect(() => {
    //     return (() => {
    //         dispatch(resetAgGrid())
    //     })
    // },[])

    const handleConClick = (data: any) => {
        navigate(
            `/projects/${projectId}/${structureId}/${mName}/${data?.data?.qa_params_value_mapping_id}/concreting`
        )
    }
    const handleReiClick = (data: any) => {
        navigate(
            `/projects/${projectId}/${structureId}/${mName}/${data?.data?.qa_params_value_mapping_id}/reinforcement`
        )
    }
    const handleCubeClick = (data: any) => {
        //data
        // navigate("/projects/pile/reinforcement")
        localStorage.setItem('pileNo', data?.data?.input_value)
        navigate(
            `/projects/${projectId}/${structureId}/${mName}/${data?.data?.qa_params_value_mapping_id}/add-cubetesting`
        )
    }
    const handlepolyClick = (data: any) => {
        localStorage.setItem('pileNo', data?.data?.input_value)
        navigate(
            `/projects/${projectId}/${structureId}/${mName}/${data?.data?.qa_params_value_mapping_id}/add-polymer`
        )
    }
    const ActionRenderer = (props: any) => {
        const moreData = [
            {
                label: (
                    <>
                        <Button
                            disabled={props.data.is_submit == 0 ? true : false}
                            onClick={() => handleReiClick(props)}
                        >
                            {' '}
                            Reinforcement
                        </Button>
                        <Divider />
                    </>
                ),
                key: '1',
            },
            {
                label: (
                    <Tooltip
                        title={
                            props.data.is_reinforcement == 0
                                ? 'Please add reinforcement first'
                                : ''
                        }
                    >
                        <Button
                            disabled={
                                props.data.is_reinforcement == 0 ? true : false
                            }
                            onClick={() => handleConClick(props)}
                        >
                            Concreting
                        </Button>
                        <Divider />
                    </Tooltip>
                ),
                key: '0',
            },
            {
                label: (
                    <Tooltip
                        title={
                            props.data.is_concreating == 0
                                ? 'Please add concreating  first'
                                : ''
                        }
                    >
                        <Button
                            disabled={
                                props.data.is_concreating == 0 ? true : false
                            }
                            onClick={() => handleCubeClick(props)}
                        >
                            Cube Testing
                        </Button>
                        <Divider />
                    </Tooltip>
                ),
                key: '2',
            },
        ]
        const menuItem = [
            ...moreData,
            {
                label: (
                    <>
                        <Button onClick={() => handlepolyClick(props)}>
                            Polymer Slurry Report
                        </Button>
                        <Divider />
                    </>
                ),
                key: '3',
            },
        ]

        const items: MenuProps['items'] =
            props.data?.type_of_pile_value == 'Wet' ? menuItem : moreData

        return (
            <>
                {props.data.is_submit == 1 ? (
                    <ActionButtons
                        data={props}
                        view={{
                            action: handleViewClick,
                            permissionKey: `${moduleName.toUpperCase()}_DETAILS`,
                        }}
                        deleteButton={{
                            action: handleDeleteClick,
                            permissionKey: `${moduleName.toUpperCase()}_DELETE`,
                        }}
                    />
                ) : (
                    <ActionButtons
                        data={props}
                        edit={{
                            action: handleEditClick,
                            permissionKey: `${moduleName.toUpperCase()}_UPDATE`,
                        }}
                        deleteButton={{
                            action: handleDeleteClick,
                            permissionKey: `${moduleName.toUpperCase()}_DELETE`,
                        }}
                    />
                )}
                <div>
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        overlayClassName="menuOptions"
                    >
                        <Button
                            title={''}
                            className="menuIcon"
                            onClick={(e) => e.preventDefault()}
                        >
                            <SVGIcon icon="menuIcon" width={20} />
                        </Button>
                    </Dropdown>
                </div>
            </>
        )
    }

    const statusRender = (props: any) => {
        return (
            <Tag color={props.data.is_submit === 1 ? 'green' : 'orange'}>
                {props.data.is_submit == 1 ? 'Submitted' : 'Pending'}
            </Tag>
        )
    }
    return (
        <>
            <ApproveModal
                titleName={moduleInfo}
                data={statusData}
                close={() => setStatusData(null)}
                callApi={ChangeStatus}
            />
            <AgGridWrapper
                type="serverSide"
                components={{
                    ActionRenderer,
                    statusRender,
                }}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReadyWithDispatch={setupGrid}
                onRowClicked={onRowClick}
                rowSelection={"single"}
                context={{
                    setStatusData,

                }}
            />
        </>
    )
}

export default Listing
