import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import { ReactComponent as CloudIcon } from "../../../../assets/images/icon/cloudIcon.svg";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { addApproval } from "../../RawMaterial/utils/slice";
import { dataToFormDataConverter, validateFields } from "../../../../config/Global";
import { useNavigate } from "react-router-dom";
import { assignErrorToInput } from "../../../../store/api";
interface AddProps {
	close: () => void;
	open?: boolean;
	projectId?: any;
}

const ApproveModal: React.FC<AddProps> = ({ open, close }) => {
	const [form] = Form.useForm();
	const dispatch: AppDispatch = useDispatch();
	const { RAWMATERIAL } = useSelector((state: RootState) => state);
	const navigate = useNavigate();
	const [disabled, setDisabled] = useState(true)

	const handleClose = () => {
		form.setFieldValue('approval_latter',[])
		close();
	};

	const handleSubmit = (data?: any) => {
		setDisabled(true)
		const formdata = dataToFormDataConverter(data);
		try {
			form.validateFields();
			dispatch(addApproval(RAWMATERIAL?.viewData, formdata))
				.then(() => {
					form.resetFields();
					setDisabled(false)
					navigate(
						`/projects/mix-design/${RAWMATERIAL?.viewData}/edit-mixdesign`
					);
				})
				.catch((error: any) => {
					// Handle error
					assignErrorToInput(form, error.STATUS)
				});
		} catch (error) {
			//console.error("Error submitting data:", error);
		}
	};


	const validateForm = () => {
		validateFields(form, setDisabled);
	};
	return (
		<>
			<Modal
				className="approveModal"
				open={open}
				onCancel={handleClose}
				footer={
					<>
						<Button
							className="secondaryBtn"
							htmlType="submit"
							form="ApprovalAdd"
							disabled={disabled}
						>
							Submit
						</Button>
						<Button className="cancelBtn" onClick={handleClose}>
							Cancel
						</Button>
					</>
				}
			>
				<FormBox onFinish={handleSubmit} form={form} id="ApprovalAdd" onValuesChange={validateForm}>
					<CloudIcon />
					<InputBox.Upload
					name='approval_latter'
					required={true}
					label="Please Attach Letter of Approval Here."
					fileList={form.getFieldValue('approval_latter')}
					maxCount={10}
					/>
				</FormBox>
			</Modal>
		</>
	);
};

export default ApproveModal;
