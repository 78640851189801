/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-inferrable-types */

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Row } from "antd";
import { AppDispatch, RootState } from "../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { assignErrorToInput } from "../../../../../store/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createRecord, getQaParams, readRecord, setQaParams, setRead } from "../../utils/slice";
import DynamicForm from "./DynamicForm";
import {
	Notification,
	dataToFormDataConverter,
	objectBlank,
	singleObjectBlank,
	validateFields,
} from "../../../../../config/Global";
import dayjs from "dayjs";
import moment from "moment";
import { regexPatterns } from "../../../../../config/validations/validations";

const FormComponent: React.FC = () => {
	const PILE_CAP = useSelector((state: RootState) => state.PILE_CAP);
	const dispatch: AppDispatch = useDispatch();
	const steps: any = PILE_CAP?.qaParams;
	const keys = steps && Object.keys(steps);
	const lastKey = Number(keys?.[keys?.length - 1]);
	const firstKey = Number(keys?.[0]);
	const [current, setCurrent] = useState<number>(firstKey);
	const [stepData, setStepData] = useState<any>({});
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { projectId, structureId, mName } = useParams();
	const [checkValue, setCheckValue] = useState(true);
	const [navigateId, setNavigateId] = useState<any>();
	const [stepsD, setStepsD] = useState<number>(0);
	const [searchParams] = useSearchParams();
	const [saveDisable, setSaveDisable] = useState<any>(true);
	const [multipleSection, setMultipleSection] = useState<any>([])
	const [isMultiple, setIsMultiple] = useState(false)
	const value = localStorage.getItem("mapId");
	const [mapId, setMapId] = useState<any>(
		value ? value : searchParams.get("mapId")
	);
	const { mappingId } = useParams();
	const [saving, setSaving] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(true)
	const [radioButton, setRadioButton] = useState("2")
	const [catRadioButton, setCatRadioButton] = useState("2")
	const [typeOfSelect, setTypeOfSelect] = useState<any>()
	const [multipleRadio, setMultipleRadio] = useState("2")
	const [requiredRadio,setRequiredRadio]=useState<any>("2")
	const pro_id: any = localStorage.getItem("projectId");
	const deptId: any = localStorage.getItem("department_id")

	useEffect(() => {
		setMultipleSection([])
		dispatch(setRead([]))
		setStepData([])
		setDynamicField();
	}, []);


	const setDynamicField = () => {
		dispatch(setQaParams(null))
		setStepData({})
		form.resetFields()
		//structure element payload
		const payload = {
			qa_category_id: structureId,
			department_id: deptId,
		};
		//checklist payload
		const drainage_payload = {
			qa_category_id: structureId,
			department_id: deptId,
			department_type_id: localStorage.getItem("department_type_id")
		}
		dispatch(getQaParams(deptId == 1 ? payload : drainage_payload)).then((data: any) => {
			setCurrent(data[0]);
			let readId = mappingId;
			let addId = mapId;
			let readPayload;
			if (location.pathname.includes("/add")) {
				readId = "0";
				addId = 0;
			}
			if (!location.pathname.includes("/add")) {
				if (deptId == 5) {
					readPayload = {
						project_id: pro_id,
						qa_params_value_mapping_id: readId ? readId : addId,
						qa_category_id: structureId
					};
				} else {
					readPayload = {
						project_id: pro_id,
						qa_category_id: structureId,
						qa_params_value_mapping_id: readId ? readId : addId,
					};
				}

			} else {
				setMapId(0);
				readPayload = {
					project_id: 0,
					qa_category_id: 0,
					qa_params_value_mapping_id: addId ?? 0,
				};
			}
			//   if (addId) {
			if (!location.pathname.includes("/add")) {
				dispatch(readRecord(readPayload));
			}
			//   }
		});
	};

	useEffect(() => {
		//if (!location.pathname.includes("/add")) {
		if (stepData) {
			//form.setFieldsValue(stepData);
			stepData && Object?.entries(stepData)?.forEach((entry: any) => {
				//debugger;
				const [key, value] = entry;
				Object?.entries(value)?.forEach((childEntry: any) => {
					const [childKey, childValue] = childEntry;
					if (childValue?.value && Array?.isArray(childValue?.value)) {
						childValue?.value?.map((data: any, index: any) => {
							childValue?.value && data && Object?.keys(data)?.map((keys: any) => {
								form.setFieldValue([`${key}`, `${childKey}`, "value", index + 1, keys], data[keys])

							})

						})
					}
					else if (childValue?.value !== null && childValue?.value != undefined && regexPatterns?.dateReg?.test(childValue?.value)) {
						form.setFieldValue([`${key}`, `${childKey}`, "value"], dayjs(childValue?.value))
						form.setFieldValue([`${key}`, `${childKey}`, "remark"], childValue?.remark)
						form.setFieldValue([`${key}`, `${childKey}`, "file"], childValue?.file)

					} else {
						form.setFieldValue([`${key}`, `${childKey}`, "value"], childValue?.value)
						form.setFieldValue([`${key}`, `${childKey}`, "remark"], childValue?.remark)
						form.setFieldValue([`${key}`, `${childKey}`, "file"], childValue?.file)
					}
				})
			})
		}
		//}
	}, [form, current, stepData]);

	useEffect(() => {
		if (PILE_CAP.read) {
			const dataToSet: any = {};
			Object?.entries(PILE_CAP?.read)?.forEach((entry: any) => {
				const [key, value] = entry;
				const modValue: any = {};
				const setData: any = [];
				Object?.entries(value)?.forEach((childEntry: any) => {

					const [childKey, childValue] = childEntry;


					if (childValue?.input_type == "Date Picker" || childValue?.input_type == "Date") {
						if (childValue?.data?.remark_display == 0) {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									//value: childValue?.value?dayjs(childValue?.value):[],
									value: childValue?.value,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
								// childValue?.value && form.setFieldValue(
								// 	[`${key}`, `${childKey}`, "value"],
								// 	dayjs(childValue?.value)
								// );
							} else {
								modValue[childKey] = {
									value: childValue?.value,
								}
								// childValue?.value && form.setFieldValue(
								// 	[`${key}`, `${childKey}`, "value"],
								// 	dayjs(childValue?.value)
								// );
							}

						} else {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									value: childValue?.value,
									remark: childValue?.remark,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
								// childValue?.value && form.setFieldValue(
								// 	[`${key}`, `${childKey}`, "value"],
								// 	dayjs(childValue?.value)
								// );
							} else {
								modValue[childKey] = {
									value: childValue?.value,
									remark: childValue?.remark,
								}
								// childValue?.value && form.setFieldValue(
								// 	[`${key}`, `${childKey}`, "value"],
								// 	dayjs(childValue?.value)
								// );
							}

						}
					} else if (childValue?.input_type == "Radio Button") {
						if (childValue?.params_key == "identification_of_damage_line_or_manhole") {
							setMultipleRadio(childValue?.value)
						}
						if (childValue?.data?.remark_display == 0) {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									value: childValue.value == null ? "2" : childValue?.value,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
							} else {
								modValue[childKey] = {
									value: childValue.value == null ? "2" : childValue?.value,
								}
							}
						} else {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									value: childValue.value == null ? "2" : childValue?.value,
									remark: childValue?.remark,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
							} else {
								modValue[childKey] = {
									value: childValue.value == null ? "2" : childValue?.value,
									remark: childValue?.remark,
								}
							}
						}
					} else if (childValue?.input_type == "Multiple") {
						var id: any;
						const dd = [JSON.parse(childValue?.value)]
						const attr_json = childValue?.data
						form.setFieldValue([key, childKey, "value"], null)
						form.setFieldValue([key, childKey, "remark"], null)
						form.setFieldValue([key, childKey, "file"], [])

						JSON.parse(childValue.value)?.length == 0 && JSON.parse(childValue.value) == null && setIsMultiple(true)

						if (!setData[`${childKey}`]) {
							setData[`${childKey}`] = [];
						}
						id = attr_json?.map((d: any) => {
							if (d?.type == "File") {
								return d?.id
							}
						})?.filter((d: any) => d != undefined)
						modValue[childKey] = {
							value: [JSON.parse(childValue.value)],
							remark: childValue?.remark,
							file: childValue?.attachment?.map(
								(image: { id: any; document: any }) => {
									return {
										uid: image.id,
										name: image?.document,
										status: "done",
										url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
									};
								}
							),
						};
						const list = childValue?.attachment?.map(
							(image: { id: any; document: any }) => {
								return {
									uid: image?.id,
									name: image?.document,
									status: "done",
									url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
									thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
								};
							},
						)

						dd?.map((data: any, index: any) => {
							data && Object?.keys(data)?.map((keys) => {
								form.setFieldValue([key, childKey, "value", index + 1, keys], data[keys])
								form.setFieldValue([`${key}`, `${childKey}`, "file", `${index + 1}`, `${id[0]}`], list)
							})
						})
						form.setFieldValue([key, childKey, "remark"], childValue?.remark)


						const multiple = dd?.map((data: any, index: any) => {
							return {
								qa_param_id: childKey,
								id: index + 1,
								att_json: attr_json,
								type: "read",
								node_id: childValue?.id,
								qa_params_value_mapping_id: childValue?.qa_params_value_mapping_id
							}
						})
						multiple?.map((multipleItem: any) => {
							setData[`${childKey}`]?.push(multipleItem)
						})

					} else if (childValue?.input_type == "Single_Multiple") {
						var id: any;
						const dd = [JSON.parse(childValue?.value)]
						const attr_json = childValue?.data?.multiple
						const validation = childValue?.data?.data[0]

						form.setFieldValue([key, childKey, "value"], null)
						form.setFieldValue([key, childKey, "remark"], null)
						form.setFieldValue([key, childKey, "file"], [])

						JSON.parse(childValue.value)?.length == 0 && JSON.parse(childValue.value) == null && setIsMultiple(true)

						if (!setData[`${childKey}`]) {
							setData[`${childKey}`] = [];
						}
						id = attr_json?.map((d: any) => {
							if (d?.type == "File") {
								return d?.id
							}
						})?.filter((d: any) => d != undefined)

						if (validation?.remark_display == 0) {
							if (validation?.photo_required == 1) {
								modValue[childKey] = {
									value: [JSON.parse(childValue.value)],
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								const list = childValue?.attachment?.map(
									(image: { id: any; document: any }) => {
										return {
											uid: image?.id,
											name: image?.document,
											status: "done",
											url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										};
									},
								)

								dd?.map((data: any, index: any) => {
									Object.keys(data).map((keys) => {
										form.setFieldValue([key, childKey, "value", index + 1, keys], data[keys])
										form.setFieldValue([`${key}`, `${childKey}`, "file", `${index + 1}`, `${id[0]}`], list)
									})
								})
							} else {
								modValue[childKey] = {
									value: [JSON.parse(childValue.value)],
								};

								const list = childValue?.attachment?.map(
									(image: { id: any; document: any }) => {
										return {
											uid: image?.id,
											name: image?.document,
											status: "done",
											url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										};
									},
								)

								dd?.map((data: any, index: any) => {
									Object.keys(data).map((keys) => {
										//form.setFieldValue([key, childKey, "value", index + 1, keys], data[keys])
										form.setFieldValue([`${key}`, `${childKey}`, "file", `${index + 1}`, `${id[0]}`], list)
									})
								})

							}
						} else {
							if (validation?.photo_required == 1) {
								modValue[childKey] = {
									value: [JSON.parse(childValue.value)],
									remark: childValue?.remark,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								const list = childValue?.attachment?.map(
									(image: { id: any; document: any }) => {
										return {
											uid: image?.id,
											name: image?.document,
											status: "done",
											url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										};
									},
								)

								dd?.map((data: any, index: any) => {
									Object.keys(data).map((keys) => {
										form.setFieldValue([key, childKey, "value", index + 1, keys], data[keys])
										form.setFieldValue([`${key}`, `${childKey}`, "file", `${index + 1}`, `${id[0]}`], list)
									})
								})
								form.setFieldValue([key, childKey, "remark"], childValue?.remark)

							} else {
								modValue[childKey] = {
									value: [JSON.parse(childValue.value)],
									remark: childValue?.remark
								};
								form.setFieldValue([key, childKey, "remark"], childValue?.remark)
							}
						}

						const multiple = dd?.map((data: any, index: any) => {
							return {
								qa_param_id: childKey,
								id: index + 1,
								att_json: attr_json,
								type: "read",
								node_id: childValue?.id,
								qa_params_value_mapping_id: childValue?.qa_params_value_mapping_id
							}
						})
						multiple?.map((multipleItem: any) => {
							setData[`${childKey}`]?.push(multipleItem)
						})

					} else if (childValue && Array?.isArray(childValue)) {
						//
						const val: any = []
						let id: any;
						const node_id: any = []

						form.setFieldValue([key, childKey, "value"], null)
						form.setFieldValue([key, childKey, "remark"], null)
						form.setFieldValue([key, childKey, "file"], [])
						childValue?.map((att: any, indx: any) => {
							let list: any = []
							node_id.push({ id: att?.id, map_id: att?.qa_params_value_mapping_id })
							if (att?.input_type == "Multiple") {
								id = att?.data?.map((d: any) => {
									if (d?.type == "File") {
										return d?.id
									}
								})?.filter((d: any) => d != undefined)
							} else {
								id = att?.data?.multiple?.map((d: any) => {
									if (d?.type == "File") {
										return d?.id
									}
								})?.filter((d: any) => d != undefined)
							}


							list = att?.attachment?.map(
								(image: { id: any; document: any }) => {
									return {
										uid: image?.id,
										name: image?.document,
										status: "done",
										url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
									};
								},
							),
								form.setFieldValue([`${key}`, `${childKey}`, "file", `${indx + 1}`, `${id[0]}`], list),
								val.push(JSON?.parse(att?.value))
							form.setFieldValue([key, childKey, "remark"], att?.remark)
						})
						modValue[childKey] = {
							value: val
						};
						val?.map((data: any, index: any) => {
							Object.keys(data)?.map((keys) => {
								form.setFieldValue([`${key}`, `${childKey}`, "value", `${index + 1}`, `${keys}`], data[keys])
							})
						})

						val?.length == 0 && val == null && setIsMultiple(true)
						if (!setData[`${childKey}`]) {
							setData[`${childKey}`] = [];
						}
						const attr_json = childValue?.[0]?.input_type == "Multiple" ? childValue?.[0]?.data : childValue?.[0]?.data?.multiple
						const multiple = val?.map((data: any, index: any) => {
							return {
								qa_param_id: childKey,
								id: index + 1,
								att_json: attr_json,
								type: "read",
								node_id: node_id[index]?.id,
								qa_params_value_mapping_id: node_id[index]?.map_id

								//file:list
							}
						})
						multiple?.map((multipleItem: any) => {
							setData[`${childKey}`]?.push(multipleItem)
						})
					} else if (childValue && childValue?.input_type == "Single") {
						const val = JSON?.parse(childValue?.value)
						modValue[childKey] = {
							value: [JSON?.parse(childValue?.value)],
						};
						// val && childValue?.data?.multiple?.map((att: any, childIndex?: any) => {
						// 	form.setFieldValue([`${key}`, `${childKey}`, ["value"], "1", att?.id], val[att?.id])
						// 	if (att?.required_if?.key == "type_of_encroachment") {
						// 		setTypeOfSelect(val[att?.id - 1])
						// 	}
						// })
						val && childValue?.data?.map((att: any, childIndex?: any) => {
							form.setFieldValue([`${key}`, `${childKey}`, ["value"], "1", att?.id], val[att?.id])
							if (att?.required_if?.key == "type_of_encroachment") {
								setTypeOfSelect(val[att?.id - 1])
							}
						})
					}
					else {
						if (childValue?.data?.remark_display == 0) {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									value: childValue.value,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
							} else {
								modValue[childKey] = {
									value: childValue.value,
								}
							}
						} else {
							if (childValue?.data?.photo_required == 1) {
								modValue[childKey] = {
									value: childValue.value,
									remark: childValue?.remark,
									file: childValue?.attachment?.map(
										(image: { id: any; document: any }) => {
											return {
												uid: image.id,
												name: image?.document,
												status: "done",
												url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
											};
										}
									),
								};
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["file"]],
									modValue[childKey]?.file
								);
							} else {
								modValue[childKey] = {
									value: childValue.value,
									remark: childValue?.remark,
								}
								form.setFieldValue(
									[`${key}`, `${childKey}`, ["remark"]],
									childValue?.remark
								);
							}
						}
					}
					if (childValue?.params_key == "overall_progress_is_as_per_schedule") {
						if (childValue?.value == 1) {
							setRadioButton("1")
						} else {
							setRadioButton("2")
						}
					}
					if (childValue?.params_key == "has_the_plot_possession_been_cleared_") {
						setCatRadioButton(childValue?.value)
					}
				});

				setMultipleSection(setData)
				dataToSet[key] = modValue;
			});
			setStepData(dataToSet);
			//const dd = formatDatesInData(dataToSet)
			const emptyChecker = hasAllBlankValue(dataToSet);
			const d1 = convertUndefinedToNull(dataToSet)
			const an = renderData(dataToSet)
			//const an = renderData(d1)
			const ans = findAndReplace(an)
			const save_draft = findTrueValues(ans)
			const save_draft_pay = isEmpty(stepData)
			const final_data = save_draft_pay?.map((d: any) => {
				if (d?.length > 0) {
					return true
				} else {
					return false
				}
			}).includes(false)

			setDisableSubmit(save_draft == false && final_data == false ? false : true)
		}
	}, [PILE_CAP.read, form]);
	useEffect(() => {
		setStepsD(Number(stepsD));
	}, [navigateId]);



	const handleReadPrvClick = () => {
		modifyFormData(form.getFieldsValue());
		const data: any = steps && Object.keys(steps);
		const curtrentIndex = data.indexOf(current && current.toString());
		const prevIndex = curtrentIndex !== -1 ? curtrentIndex - 1 : 0;
		const prevStepId = data[prevIndex];
		if (!location.pathname.includes("/read")) {
			setCheckValue(true);
		}
		if (location.pathname.includes("/update")) {
			navigate(
				`/projects/${structureId}/qa-parameter/${mName}/${mappingId}/update?step=${prevStepId}`
			);
		} else if (location.pathname.includes("/read")) {
			navigate(
				`/projects/${structureId}/qa-parameter/${mName}/${mappingId}/read?step=${prevStepId}`
			);
		} else {
			navigate(`/projects/${structureId}/${mName}/add?step=${prevStepId}`);
		}
		if (stepContainerRef.current) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	};

	useEffect(() => {
		if (checkValue) {
			form
				.validateFields()
				.then((d) => {
					setSaveDisable(false);
				})
				.catch((d) => {
					setSaveDisable(true);
				});
		}
	}, [current]);

	//can't remove this code
	// const prev = () => {
	// 	modifyFormData(form.getFieldsValue());
	// 	setCurrent(current - 1);
	// };
	const stepContainerRef = useRef<HTMLDivElement>(null);

	//don't remove
	// const hasBlankValuea = (obj: any): boolean => {
	// 	if (typeof obj !== "object" || obj === null || obj === undefined) {
	// 		return obj === "" || obj === null || obj === undefined;
	// 	}

	// 	for (const key in obj) {
	// 		if (
	// 			Object.prototype.hasOwnProperty.call(obj, key) &&
	// 			key === "value" &&
	// 			(obj[key] === "" || obj[key] === null || obj[key] === undefined)
	// 		) {
	// 			return true;
	// 		}

	// 		if (hasBlankValue(obj[key])) {
	// 			return true;
	// 		}
	// 	}

	// 	return false;
	// };
	const handleFirstNextClick = () => {
		const valuesForm = modifyFormData(form.getFieldsValue());
		const data: any = steps && Object.keys(steps);
		const curtrentIndex = data.indexOf(current && current.toString());
		const nextIndex = curtrentIndex + 1;
		const nextStepId = data[nextIndex];
		let checkForm;
		if (current === firstKey && !location.pathname.includes("/read")) {
			// form.validateFields();
			// setCheckValue(true);
			// const val = valuesForm[firstKey];
			// Object.keys(val).forEach((key) => {
			//   const innerObject = val[key];
			//   const trimmedValue = innerObject?.value?.trim();
			//   // Convert blank spaces and "   " to null
			//   const finalValue =
			//     trimmedValue === "" || trimmedValue === undefined ? "" : trimmedValue;
			//   val[key] = { value: finalValue };
			// });
			// checkForm = singleObjectBlank(val);
			form.validateFields();
			setCheckValue(true);
			checkForm = singleObjectBlank(valuesForm[firstKey]);
		} else {
			checkForm = false;
			setCheckValue(true);
		}
		if (!checkForm) {
			if (location.pathname.includes("/update")) {
				navigate(
					`/projects/${structureId}/qa-parameter/${mName}/${mappingId}/update?step=${nextStepId}`
				);
			} else if (location.pathname.includes("/read")) {
				navigate(
					`/projects/${structureId}/qa-parameter/${mName}/${mappingId}/read?step=${nextStepId}`
				);
			} else {
				navigate(`/projects/${structureId}/${mName}/add?step=${nextStepId}`);
			}
		}
		if (stepContainerRef.current) {
			window.scrollTo({
				top: stepContainerRef.current.offsetTop,
				behavior: "smooth",
			});
		}
	};

	//can't remove this code
	//   const next = () => {
	//     modifyFormData(form.getFieldsValue());
	//     setCurrent(current + 1);
	//   };

	useEffect(() => {
		setCurrent(Number(searchParams.get("step") ?? firstKey));
	}, [current, searchParams]);

	useEffect(() => {
		const handleReload = () => {
			if (
				performance.navigation.type === 1 &&
				location.pathname.includes("/add")
			) {
				// Type 1 indicates a hard refresh
				navigate(`/projects/${structureId}/${mName}`);
			}
		};

		// Attach the event listener to the 'load' event to check for a hard refresh
		window.addEventListener("load", handleReload);

		return () => {
			window.removeEventListener("load", handleReload);
		};
	}, [history]);

	const modifyFormData = (data: any) => {
		const tempData: any = stepData;
		steps && Object?.keys(steps)?.forEach((key: any) => {
			if (Number(key) === current) {
				Object.values(data).forEach((item: any) => {
					tempData[current] = item;
				});
			} else {
				tempData[Number(key)] = stepData?.[Number(key)] ?? {};
			}
		});
		return tempData;
	};

	//set single page data to check blank or not
	function hasBlankValue(obj: any): boolean {
		let result = false;
		Object.entries(obj).forEach((entry: any) => {
			const [key, value] = entry;
			Object.entries(value).forEach((childEntry: any) => {
				const [childKey, childValue] = childEntry;
				if (!childValue?.value) {
					result = true;
				}
			});
		});
		return result;
	}

	const isEmptyObject = (obj: any) => {
		return Object.keys(obj).length === 0;
	};
	//multiple object
	function hasAllBlankValue(obj: any): boolean {
		let result = false;
		Object.entries(obj).some((entry: any) => {
			const [key, value] = entry;
			if (isEmptyObject(value)) {
				result = true;
			} else {
				Object.entries(value).forEach((childEntry: any) => {
					const [childKey, childValue] = childEntry;
					if (!childValue?.value) {
						result = true;
					}
				});
			}
		});
		return result;
	}
	const convertBlankToNull = (obj: any): any => {
		if (obj === null || obj === undefined) {
			return null;
		}

		return typeof obj === "object"
			? Array.isArray(obj)
				? obj.map(convertBlankToNull)
				: Object.fromEntries(
					Object.entries(obj).map(([k, v]) => [k, convertBlankToNull(v)])
				)
			: (typeof obj === "string" && obj.trim() === "") || /^\s*$/.test(obj)
				? null
				: obj;
	};

	//can't remove this code
	// typeof obj === 'object'
	// 	? Array.isArray(obj)
	// 		? obj.map(convertBlankToNull)
	// 		: Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, convertBlankToNull(v)]))
	// 	: typeof obj === 'string' && obj.trim() === '' || /^\s*$/.test(obj) ? '' : obj;

	//empty value check
	const isValueNull = (value: any) => value === null || value == "";

	// check all step data null or not
	const renderData = (data: any) => {
		return Object?.keys(data)?.map((key) => {
			return Object?.keys(data[key])?.map((innerKey) => {
				const value = data[key][innerKey]?.value;
				const remark = data[key][innerKey]?.remark;
				const file = data[key][innerKey]?.file && data[key][innerKey]?.file
				//can't remove
				// if(Array.isArray(data[key][innerKey]?.value)){
				// 	console.log(data[key][innerKey]?.value)
				// }

				return isValueNull(remark) || isValueNull(value) || isValueNull(file)
			});
		});
	};

	//return true when step fields null or empty
	const findTrueValues = (data: any) => {
		for (let i = 0; i < data.length; i++) {
			if (data[i].includes(true)) {
				return true;
			}
		}
		return false;
	};


	//check the whole object (save as draft)
	const isEmpty = (obj: any) => {
		return Object.keys(obj).map((key) => {
			return Object.keys(obj[key]).map((innerKey) => {
				//
			})
		})
	};

	//format date
	const formatDate = (date: any) => {
		return dayjs(date).format('YYYY-MM-DD');
	};

	// const toStringify = (data: any) => {
	// 	//
	// 	return JSON.stringify(data)
	// }

	// Function to traverse the nested data structure and format dates
	const formatDatesInData = (data: any) => {
		for (const key in data) {
			if ((typeof data[key] === 'object') && data[key] !== null) {

				if (data[key].value && (data[key].value.$isDayjsObject || data[key].value.$isAMomentObject)) {
					// If value is a date object, format it
					data[key].value = formatDate(data[key].value);
				} else if (data[key]?.value && Array.isArray(data[key]?.value) && location?.pathname?.includes("/add")) {
					// data[key].value = toStringify(data[key]?.value?.filter((item: any) => item != null || item != undefined))
					data[key].value = data[key]?.value?.filter((item: any) => item != null || item != undefined)

				} else {
					// If value is another object, recursively call formatDatesInData
					formatDatesInData(data[key]);
				}
			}
		}
	};
	const convertUndefinedToNull = (data?: any) => {
		for (const key in data) {
			if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
				convertUndefinedToNull(data[key]);
			} else if (Array.isArray(data[key]) && data[key].length === 0) {
				data[key] = null;
			} else if (data[key] === undefined) {
				data[key] = null;
			}
		}
		return data;
	};

	const reIndexObject = (originalObj: any) => {
		let newIndex = 0; // Starting index for reIndexing
		const reindexedObject: any = {};

		// Iterate over each key-value pair in the original object
		originalObj && Object?.values(originalObj)?.forEach((arr: any) => {
			// Assign the item to the new index
			reindexedObject[newIndex] = arr;
			newIndex++;
		});

		return reindexedObject;
	};
	const findAndReplace = (data?: any) => {
		const que = reIndexObject(PILE_CAP?.qaParams)
		const dd = data
		Object.keys(data)?.map((d: any) => {
			que[d]?.map((item: any, key: any) => {
				const a = JSON.parse(item.data)
				if (a.required == false && data[d]?.[key] == true) {
					return dd[d][key] = false
				}
			})
		})
		return dd
	}
	const onSubmitSteps = async (data: any, type?: any) => {
		let first_form;
		if (type === "submit") {
			if (current === firstKey) {
				setCheckValue(true);
				const convertedData = convertBlankToNull(data);
				first_form = singleObjectBlank(convertedData);
			} else {
				setCheckValue(true);
				first_form = hasBlankValue(data);
			}
		} else {
			if (current === firstKey && deptId != "5") {
				setCheckValue(true);
				const convertedData = convertBlankToNull(data);
				first_form = singleObjectBlank(convertedData);
			} else if (current == firstKey && deptId == "5") {
				setCheckValue(true);
				const convertedData = convertBlankToNull(data);
				//first_form = singleObjectBlank(convertedData);
			} else {
				first_form = false;
				setCheckValue(false);
			}
		}

		let saveButtonDisable = true;

		try {
			// Validate the form fields
			await form.validateFields();
			saveButtonDisable = false;
			// If validation is successful, update saveButtonDisable
		} catch (error) {
			// If validation fails, saveButtonDisable remains true
			saveButtonDisable = true;
		}
		if (
			(type === "submit" && !saveButtonDisable) ||
			(type === "save" && !first_form)
		) {
			// if (!first_form) {
			const tempData = modifyFormData(data);
			let modify: any = null;
			Object.entries(tempData).some((val: any) => {
				const [key, vals] = val;
				Object.entries(vals).forEach((childEntry: any) => {
					const [childKey, childValue] = childEntry;
					if (childValue) {
						delete childValue?.max;
						return (modify = tempData);
					}
				});
			});
			setStepData(modify);
			const dd = formatDatesInData(modify)
			const emptyChecker = hasAllBlankValue(modify);
			const d1 = convertUndefinedToNull(modify)
			//const an = renderData(modify)
			const an = renderData(d1)
			const ans = findAndReplace(an)
			const save_draft = findTrueValues(ans)
			const save_draft_pay = isEmpty(stepData)
			const final_data = save_draft_pay?.map((d: any) => {
				if (d?.length > 0) {
					return true
				} else {
					return false
				}
			}).includes(false)

			let payload: any;
			if (type === "submit") {
				if (deptId == 5) {
					payload = {
						data: modify,
						project_id: pro_id,
						qa_category_id: structureId,
						qa_params_groups_id: current + 1,
						final_submit: emptyChecker && save_draft == true && final_data == true ? 0 : 1, // 1 for final submit and 0 for save as draft
						type,
						department_type_id: localStorage.getItem("department_type_id"),
						qa_params_value_mapping_id: mappingId
							? mappingId
							: mapId
								? mapId
								: "0",
					};
				} else {
					payload = {
						data: modify,
						project_id: pro_id,
						qa_category_id: structureId,
						qa_params_groups_id: current + 1,
						final_submit: emptyChecker && save_draft == true && final_data == true ? 0 : 1,
						type,
						qa_params_value_mapping_id: mappingId
							? mappingId
							: mapId
								? mapId
								: "0",
					};
				}
			} else {
				if (deptId == 5) {
					payload = {
						data: modify,
						project_id: pro_id,
						qa_category_id: structureId,
						qa_params_groups_id: current + 1,
						final_submit: 0,
						type,
						department_type_id: localStorage.getItem("department_type_id"),
						qa_params_value_mapping_id: mappingId
							? mappingId
							: mapId
								? mapId
								: "0",
					};
				} else {
					payload = {
						data: modify,
						project_id: pro_id,
						qa_category_id: structureId,
						qa_params_groups_id: current + 1,
						final_submit: 0,
						type,
						qa_params_value_mapping_id: mappingId
							? mappingId
							: mapId
								? mapId
								: "0",
					};
				}
			}

			const formData = dataToFormDataConverter(payload);
			setSaving(true);
			setDisableSubmit(true)
			dispatch(createRecord(formData))
				.then((dataGet: any) => {
					setSaving(false);
					setMapId(dataGet.qa_params_value_mapping_id);
					dispatch(setRead(dataGet?.attachment))
					localStorage.setItem("mapId", dataGet.qa_params_value_mapping_id);
					//set mapping id for drainage checklist
					localStorage.setItem("isCheckListFill", dataGet.qa_params_value_mapping_id)
					setSaveDisable(false);
					if (current === firstKey) {
						if (location.pathname.includes("/update") && save_draft == true && final_data == true) {
							navigate(
								`/projects/${structureId}/qa-parameter/${mName}/${dataGet.qa_params_value_mapping_id}/update?step=${current}`
							);
						} else if (location.pathname.includes("/checklist")) {
							navigate(`/projects/project-dashboard`)
						} else if (save_draft == false && final_data == false && deptId == 1) {
							navigate(`/projects/${structureId}/${mName}`);
						} else if (saveButtonDisable == false && deptId == 5) {
							navigate(`/projects/${structureId}/${mName}`);
						} else if (location.pathname.includes("/update") && saveButtonDisable && deptId == 5) {
							navigate(
								`/projects/${structureId}/qa-parameter/${mName}/${dataGet.qa_params_value_mapping_id}/update?step=${current}`
							);
						} else {
							navigate(`/projects/${structureId}/${mName}/add?step=${current}`);
						}
					} else if (
						current === lastKey &&
						type === "submit" &&
						dataGet.redirect_stop !== false
					) {
						navigate(`/projects/${structureId}/${mName}`);

					} else if (dataGet.redirect_stop === true && type === "submit") {
						navigate(`/projects/${structureId}/${mName}`);
					} else if (final_data == false && save_draft == false) {
						navigate(`/projects/${structureId}/${mName}`);
					} else if (location.pathname.includes("/update")) {
						navigate(
							`/projects/${structureId}/qa-parameter/${mName}/${dataGet.qa_params_value_mapping_id}/update?step=${current}`
						);
					} else {
						navigate(`/projects/${structureId}/${mName}/add?step=${current}`);
					}
					//   if (type === "submit") {
					//     Notification.warning({ message: dataGet.STATUS.NOTIFICATION[0] });
					//   } else {
					//     Notification.success({ message: dataGet.STATUS.NOTIFICATION[0] });
					//   }
					//setDisableSubmit(false)
				})
				.catch((err: any) => {
					setSaving(false);
					setMapId(mappingId);
					assignErrorToInput(form, err?.STATUS);
				});
		}
	};

	const validateForm = (data: any) => {
		const modify: any = modifyFormData(form.getFieldsValue())
		const dd = formatDatesInData(modify)
		const emptyChecker = hasAllBlankValue(modify);
		const d1 = convertUndefinedToNull(modify)
		const an = renderData(modify)
		const ans = findAndReplace(an)
		const save_draft = findTrueValues(ans)
		const save_draft_pay = isEmpty(stepData)
		const final_data = save_draft_pay?.map((d: any) => {
			if (d?.length > 0) {
				return true
			} else {
				return false
			}
		}).includes(false)
		if (deptId == 1) {
			setDisableSubmit(save_draft == false && final_data == false ? false : true)
			if (current === firstKey) {
				form
					.validateFields()
					.then((d) => {
						setSaveDisable(false);
					})
					.catch((d) => {
						setSaveDisable(true);
					});
			}
			save_draft == false && final_data == false && validateFields(form, setDisableSubmit)
		} else {
			if (current === firstKey) {
				validateFields(form, setDisableSubmit)
				form
					.validateFields()
					.then((d) => {
						setSaveDisable(false);
						//setDisableSubmit(false)

					})
					.catch((d) => {
						setSaveDisable(true);
						setDisableSubmit(true)
					});
			}
		}
	};

	useEffect(() => {
		validateForm(form.getFieldsValue())
	}, [handleFirstNextClick])

	return (
		PILE_CAP?.qaParams && (
			<>
				{
					parseInt(deptId) == 1 && <div className="mainStep">
						{keys?.map((item: any, key: any) => {
							return (
								<>
									<span className="steps">
										<h1
											className={
												current >= Number(item)
													? "title activeSteps"
													: "title currentSteps"
											}
										>
											<span className="key">0{key + 1}</span>
										</h1>
									</span>
								</>
							);
						})}
					</div>
				}
				<div className="stepContainer" ref={stepContainerRef}>
					<Form
						form={form}
						onFinish={onSubmitSteps}
						id={"create"}
						onChange={validateForm}
					>
						<Row gutter={30}>
							<DynamicForm
								dynamicFieldType={PILE_CAP?.qaParams[`${current}`]}
								onChange={validateForm}
								form={form}
								checkValue={checkValue}
								current={current}
								setDisableSubmit={setDisableSubmit}
								multipleSection={multipleSection}
								setMultipleSection={setMultipleSection}
								isMultiple={isMultiple}
								radioButton={radioButton}
								setRadioButton={setRadioButton}
								catRadioButton={catRadioButton}
								setCatRadioButton={setCatRadioButton}
								typeOfSelect={typeOfSelect}
								SetTypeOfSelect={setTypeOfSelect}
								multipleRadio={multipleRadio}
								setMultipleRadio={setMultipleRadio}
								requiredRadio={requiredRadio}
								setRequiredRadio={setRequiredRadio}
							/>
						</Row>
					</Form>
					<div className="stepPileBtn">
						{/* <div className="prevSaveBtn"> */}
						{current > firstKey ? (
							<Button
								className="prevBtn secondaryBtn"
								onClick={handleReadPrvClick}
								htmlType="button"
								form={"create"}
							>
								Previous
							</Button>
						) : null}

						{!location.pathname.includes("/read") && (
							<Button
								className="secondaryBtn"
								htmlType="button"
								form={"create"}
								onClick={() => {
									onSubmitSteps(form.getFieldsValue(), "save");
								}}
								loading={saving}
							>
								Save as Draft
							</Button>
						)}
						{/* </div> */}
						{/* <div className="prevSaveBtn"> */}
						{current < lastKey ? (
							<Button
								className="secondaryBtn"
								htmlType="button"
								form={"create"}
								onClick={handleFirstNextClick}
							>
								Next
							</Button>
						) : null}
						{!location.pathname.includes("/read") && (
							<Button
								className="secondaryBtn"
								htmlType="button"
								form={"create"}
								onClick={() => onSubmitSteps(form.getFieldsValue(), "submit")}
								loading={saving}
								disabled={disableSubmit}
							>
								Submit
							</Button>
						)}
						{location.pathname.includes("/read") && (
							<Button
								className="secondaryBtn"
								htmlType="button"
								form={"create"}
								onClick={() => {
									if (deptId == 5) {
										navigate(`/projects/${structureId}/${mName}`)
									} else {
										navigate(`/projects/${structureId}/${mName}`);
									}
								}}
							>
								Cancel
							</Button>
						)}
					</div>

					{/* </div> */}
				</div>
			</>
		)
	);
};
export default FormComponent;
