import React, { Dispatch, SetStateAction, useState } from "react";
import { Dropdown, Layout } from "antd";
import Avatar from "../../../../src/assets/images/user.png";
import { DownOutlined, } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import { useNavigate } from "react-router-dom";
import { doLogout } from "../../../store/AuthSlice";
import ChangePassword from "../../modules/Auth/ChangePassword";

interface HeaderViewProps {
	collapsed: boolean;
	SetCollapsed: Dispatch<SetStateAction<boolean>>;
}

const HeaderView: React.FC<HeaderViewProps> = ({ collapsed, SetCollapsed }) => {
	const dispatch: AppDispatch = useDispatch();
	const { AUTH } = useSelector((state: RootState) => state);
	const navigate = useNavigate();
	const [addForm, setAddForm] = useState<any>(false);

	// call logout function
	const logout = () => {
		dispatch(doLogout()).then(() => {
			navigate("/");
		});
	};
	const changePassword = () => {
		setAddForm(true);
	};

	const closeModal = () => {
		setAddForm(false);
	};
	const items = [
		{ label: <div onClick={() => changePassword()}>Change Password</div>, key: "changePassword" },
		{ label: <div onClick={() => logout()}>Logout</div>, key: "Logout" },
	];



	return (
		<Layout.Header className="main__page__appheader">
			<div className="headerWrap">
				<div className="appheader__left">
					<div className="menuToggle">
						<div
							className={`${collapsed ? "icon right" : "icon"}`}
							onClick={() => SetCollapsed(!collapsed)}
						>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				</div>
				<div className='projectLabel'>
					<div className='projectName'>{localStorage.getItem('projectId') && location.pathname.includes("/projects/") ? localStorage.getItem("projectName") : ""}</div>
					<div className='projectDate'>{location.pathname.includes("/projects/") && localStorage.getItem("startDate") ? "Start Date : " : ""}<span>{location.pathname.includes("/projects/") && localStorage.getItem("startDate")}</span></div>
				</div>
				<div className="appheader__right">
					<div className="userDropdown">
						<Dropdown
							menu={{ items }}
							trigger={["click"]}
							overlayClassName="userMenuList"
						>
							<div className="userMenuWrap">
								<span className="avatar">
									<img src={Avatar} alt="Avatar" />
								</span>
								<span className="userName">{AUTH?.userDetail?.first_name}</span>
								<DownOutlined />
							</div>

						</Dropdown>
					</div>
				</div>
				<ChangePassword
					titleName="Change Password"
					open={addForm}
					close={closeModal}
				/>
			</div>
		</Layout.Header>
	);
}

export default HeaderView;
