import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import dayjs from 'dayjs';
import { createdAtColumn, idColumn } from '../../../../../../utils/commonColumns';
import { CONSTANT } from '../../../../../../config/Constant';


const DataEntryColumn: ColDef = {
    field: 'steel_diameter_name',
    headerName: 'Diameter',
    filter: 'agNumberColumnFilter',
    tooltipValueGetter: (params: any) => params.data.steel_diameter_name,
}
const SupplierColumn: ColDef = {
    field: 'mst_test_type_name',
    headerName: 'Report Type',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.mst_test_type_name,
}
const InvoiceNumberColumn: ColDef = {
    field: 'date_of_testing',
    headerName: 'Date of Testing ',
    filter: 'agDateColumnFilter',
    cellRenderer: (data?: any) => {
        return dayjs(data.data.date_of_testing).format(CONSTANT.DATE_FORMAT)
    },
}
const InvoiceDateColumn: ColDef = {
    field: 'test_passed',
    headerName: 'Test Passed',
    filter: 'agSetColumnFilter',
    filterParams: {
		values: [1, 2],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Yes" && value === 1) || (filter === "No" && value === 2)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Yes" : params.value === 2 ? "No" : params.value;
		},
	},
    cellRenderer: (data: any) => {
        if (data.data.test_passed == 1) {
            return "Yes"
        } else if (data.data.test_passed == 2) {
            return "No"
        }
    },
}



const columnDefs: ColDef[] = [
    idColumn,
    DataEntryColumn,
    SupplierColumn,
    InvoiceNumberColumn,
    InvoiceDateColumn,
    createdAtColumn

]
export default columnDefs
