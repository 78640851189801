import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { ProjectAttributeGroupBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { Button } from "antd";
import {
  deleteRecord,
  fetchList,
  refreshGrid,
  setPerPageSize,
  setProjectData,
  setRead,
} from "./utils/slice";
import AddComponent from "./components/Forms/AddComponent";
import EditComponent from "./components/Forms/EditComponent";
import DeleteModal from "../../../components/Modals/DeleteModal";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";

const moduleName = "Project Attribute Group";

const ProjectAttributeGroup: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const dispatch: AppDispatch = useDispatch();
  const { PROJECT_ATTRIBUTE_GROUP, AUTH } = useSelector(
    (state: RootState) => state
  );
  const [addForm, setAddForm] = useState<any>(false);
  const [editForm, setEditForm] = useState<any>(false);

  const [deleteModal, setDeleteModal] = useState<any>(false)


  const onRowClick = (data?: any) => {
    dispatch(setProjectData(data.data));
  };

  useEffect(() => {
    dispatch(fetchList());
    dispatch(setProjectData(null));
  }, []);

  const handleClick = () => {
    setAddForm(true);
  };

  const closeModal = () => {
    setAddForm(false);
    setEditForm(false);
    dispatch(refreshGrid)
  };

  const closeDeleteModal = () => {
    setDeleteModal(false)
    dispatch(setProjectData(null))
    dispatch(refreshGrid)
  }
  return (
    <>
      {
        !AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
          <ToolBar breadcrumbs={ProjectAttributeGroupBreadcrumb}>
            <>
              {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
                <CreateButton
                  action={() => handleClick()}
                  permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                />}
              <RecordPerPage
                defaultValue={PROJECT_ATTRIBUTE_GROUP.perPageSize + " per page"}
                onChange={(perPageSize: number) => {
                  dispatch(setPerPageSize(perPageSize));
                }}
              />{" "}
            </>
          </ToolBar>
          <ContentBox>
            <AddComponent
              titleName={moduleName}
              open={addForm}
              close={closeModal}
            />

            <EditComponent
              titleName={moduleName}
              open={editForm}
              close={closeModal}
            />
            <DeleteModal
              title={moduleName}
              deleteValues={PROJECT_ATTRIBUTE_GROUP.rowData}
              callApi={deleteRecord}
              close={() => closeDeleteModal()}
              open={deleteModal}
            />
               <div className="listWrapper">
            <div className="list">
              <Listing
                moduleName={moduleName}
                handleEditClick={(data: any) => {
                  dispatch(setRead(data));
                  setEditForm(true);
                }}
                rowData={PROJECT_ATTRIBUTE_GROUP.rowData}
                onRowClick={onRowClick}
              />
            </div>
            <div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow borderActionRow" : ""}>
              <div className="actionBtn">
                {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
                  <Button
                    onClick={() => {
                      dispatch(setRead(PROJECT_ATTRIBUTE_GROUP.projectData));
                      setEditForm(true);
                    }}
                    disabled={PROJECT_ATTRIBUTE_GROUP.projectData ? false : true}
                    className={PROJECT_ATTRIBUTE_GROUP.projectData ? "activeBtn" : "disableBtn"}
                  >
                    Edit
                    <SVGIcon icon="edit" />
                  </Button>}
                  {/*  */}
                {/* <Button
              disabled={PROJECT_ATTRIBUTE_GROUP.projectData?false:true}
              onClick={()=>{
                dispatch(setProjectData(PROJECT_ATTRIBUTE_GROUP.projectData))
                setDeleteModal(true)
              }}
              className={PROJECT_ATTRIBUTE_GROUP.projectData  ? "activeBtn" : "disableBtn"}
            >
              Delete
              <SVGIcon icon="delete" />

            </Button> */}
              </div>
            </div>
            </div>
          </ContentBox></> : <RestrictedAccessPage />}
    </>
  );
};

export default ProjectAttributeGroup;
