import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../../../../components/AntdAddons";
import { FormInstance } from "antd/lib/form";
import { rules } from "./rules";
import { blockInvalidChar, validateFields } from "../../../../../../../config/Global";
import { regexPatterns } from "../../../../../../../config/validations/validations";
import { useForm } from "antd/es/form/Form";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MinusOutlined } from "@ant-design/icons";

interface TableComponentProps {
	form: FormInstance;
	coarseQualityList: any;
	testType: any;
	diameterList: any;
	setDiameterList: any;
	validateForm?: any
}

const TableComponent: React.FC<TableComponentProps> = ({ form, coarseQualityList, testType, diameterList, setDiameterList, validateForm }) => {
	const [receivedWeight, setReceivedWeight] = React.useState<[]>([]);
	const [openModal, setOpenModal] = useState<any>(false)
	const [filteredDiameter, setFilteredDiameter] = useState<any>([])
	const [form1] = useForm();
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		//location.pathname.includes("/view-coarse-aggregate") && setDiameterList(coarseQualityList)
		location.pathname.includes("/add-coarse-aggregate") && (
			setTimeout(() => {
				setFilteredDiameter(coarseQualityList)
				coarseQualityList &&
					coarseQualityList?.map((field: any) => {
						testType && testType?.map((data?: any, subIndex?: number) => {
							form.getFieldsValue();
							form.setFieldValue(
								[
									"quality_wise_receipt",
									field.id?.toString(),
									"test_types",
									subIndex,
									"test_type_value",
								],
								1
							);
							form.setFieldValue(
								[
									"quality_wise_receipt",
									field.id?.toString(),
									"test_types",
									subIndex,
									"mst_test_type_id",
								],
								data?.id
							);
						});
					});
			}, 500)
		)
	}, [coarseQualityList, testType]);


	const handleReceivedWeightChange = (id: string) => {
		const pendingDRS = form.getFieldValue("quality_wise_receipt");
		let sum = 0;
		setReceivedWeight(pendingDRS);

		const value = parseFloat(
			form.getFieldValue(["quality_wise_receipt", id, "received_weight"]) ?? 0
		);
		if (value == 0) {
			testType?.map((data?: any, subIndex?: number) => {
				form.setFieldValue(
					[
						"quality_wise_receipt",
						id,
						"test_types",
						subIndex,
						"test_type_value",
					],
					0
				);
				form.setFieldValue(
					[
						"quality_wise_receipt",
						id,
						"test_types",
						subIndex,
						"mst_test_type_id",
					],
					data?.id
				);
			});
		} else if (value > 0) {
			testType?.map((data?: any, subIndex?: number) => {
				if (
					form.getFieldValue([
						"quality_wise_receipt",
						id,
						"test_types",
						subIndex,
						"test_type_value",
					]) == 0
				) {
					form.setFieldValue(
						[
							"quality_wise_receipt",
							id,
							"test_types",
							subIndex,
							"test_type_value",
						],
						1
					);
					form.setFieldValue(
						[
							"quality_wise_receipt",
							id,
							"test_types",
							subIndex,
							"mst_test_type_id",
						],
						data?.id
					);
				}
			});
		}

		Object.values(pendingDRS)?.map((item: any) => {
			if (item?.received_weight) {
				sum =
					sum +
					parseFloat(item?.received_weight == "" ? 0 : item?.received_weight);
			}
		});
		form.setFieldsValue({ total_weight: sum.toFixed(2) ?? 0 });
	};

	useEffect(() => {
		//
	}, [receivedWeight]);


	const drawerClose = () => {
		setOpenModal(false)
		form1.setFieldValue("id", "")
		form1.setFieldValue("received_weight", "")
		setDisabled(true)
	}

	const handleSubmitDiameter = (data: any) => {
		setDiameterList((pre?: any) => {
			const newData = [...pre, data];

			newData.map((item: any) => {
				form.setFieldValue([
					"quality_wise_receipt",
					item.id?.toString(),
					"received_weight",
				], item?.received_weight)
			});

			return newData
		})
		setOpenModal(false)
		const diamtr = filteredDiameter?.filter((d?: any) => d?.id !== data?.id)

		let sum = 0
		if (form.getFieldValue("total_weight") == undefined) {
			sum = 0 + parseFloat(form1.getFieldValue("received_weight"))
		} else {
			sum = parseFloat(form.getFieldValue("total_weight")) + parseFloat(form1.getFieldValue("received_weight"))
		}
		form.setFieldsValue({ total_weight: sum.toFixed(2) ?? 0 })

		form1.setFieldValue("id", "")
		form1.setFieldValue("received_weight", "")
		setFilteredDiameter(diamtr)
		validateForm()
	}

	const validateForm1 = () => {
		validateFields(form1, setDisabled);
	};

	const handleRemove = (field?: any) => {
		const data = diameterList?.filter((data?: any) => data?.id !== field?.id)
		setDiameterList(data)

		const filter = coarseQualityList?.filter((data?: any) => data?.id == field?.id)
		const final = [...filteredDiameter, ...filter]
		const numAscending = [...final].sort((a, b) => a.id - b.id);
		setFilteredDiameter(numAscending)

		let sum = 0
		if (form.getFieldValue("total_weight") == undefined) {
			sum = 0 + parseFloat(form1.getFieldValue("received_weight"))
		} else {
			sum = parseFloat(form.getFieldValue("total_weight")) - parseFloat(form.getFieldValue([
				"quality_wise_receipt",
				field.id?.toString(),
				"received_weight",
			]))
		}
		form.setFieldValue([
			"quality_wise_receipt",
			field.id?.toString(),
			"received_weight",
		], "")

		testType && testType?.map((data?: any, subIndex?: number) => {
			form.getFieldsValue();
			form.setFieldValue(
				[
					"quality_wise_receipt",
					field.id?.toString(),
					"test_types",
					subIndex,
					"test_type_value",
				],
				1
			);
			form.setFieldValue(
				[
					"quality_wise_receipt",
					field.id?.toString(),
					"test_types",
					subIndex,
					"mst_test_type_id",
				],
				data?.id
			);
		});
		form.setFieldsValue({ total_weight: sum.toFixed(2) ?? 0 })
		validateForm()

	}

	const checkValue = (rule?: any, value?: any, callback?: any) => {
		if (parseFloat(value) <= 0) {
			callback("Value should be more than 0.");
		} else {
			callback();
		}
	};

	return (
		<>
			<div className="testTable">
				<h2>Diameter Wise Receipt & No. Of Test Required {location.pathname.includes("/add-coarse-aggregate") &&
					<span className="titleBtn"><Button onClick={() => setOpenModal(true)} className="addIcon" disabled={filteredDiameter?.length <= 0}>
						<PlusOutlined /></Button></span>}</h2>
				<div className="table-wrapper">
					<table>
						{
							diameterList?.length > 0 && <>
								<thead>
									<tr>
										<td rowSpan={2} width="7%">
											Diameter Wise Receipt
										</td>
										<td rowSpan={2} width="3%">
											Received Weight
										</td>
										<td colSpan={9} style={{ textAlign: "center" }}>
											No. Of Test required As Per QAP
										</td>
									</tr>
									<tr className="subTitle">
										{testType?.map((data: any) => {
											return (
												<td width="3%" key={data?.id}>
													{data?.name}
												</td>
											);
										})}
									</tr>
								</thead>
								<tbody>
									{diameterList &&
										diameterList?.map((field: any) => {
											return (
												<tr key={field?.id}>
													<td className="diaCol">
														<div className="diaAlign">
															{location.pathname.includes("/add-coarse-aggregate") && <Button onClick={() => handleRemove(field)}
															>
																<MinusOutlined />
															</Button>}{field.name}
														</div>
													</td>
													<td key={field?.id}>
														<InputBox.Text
															min={0}
															//type="Number"
															name={[
																"quality_wise_receipt",
																field.id?.toString(),
																"received_weight",
															]}
															onKeyDown={blockInvalidChar}
															//rules={rules.numericFields()}
															onChange={() => {
																handleReceivedWeightChange(field.id?.toString());
															}}
															disabled={
																location.pathname.includes("/view-coarse-aggregate")
																	? true
																	: false
															}
															rules={[{
																validator: checkValue
															},
															{
																required: true,
																message: "It is required."
															}, {
																pattern: regexPatterns.twoDecimal,
																message: "Maximum 4 digits & decimal value up to 2 digits."
															}
															]}
														/>
													</td>
													{testType && testType?.map(
														(data: any, subIndex: number) => {
															return (
																<td key={data?.id}>
																	<InputBox.Text
																		name={[
																			"quality_wise_receipt",
																			field.id?.toString(),
																			"test_types",
																			subIndex,
																			"mst_test_type_id",
																		]}
																		initialValue={data.id}
																		hidden
																	/>
																	<InputBox.Text
																		min={0}
																		type="Number"
																		name={[
																			"quality_wise_receipt",
																			field.id?.toString(),
																			"test_types",
																			subIndex,
																			"test_type_value",
																		]}
																		onKeyDown={blockInvalidChar}
																		rules={[() => ({
																			validator(_, value, callback) {
																				if (value.toString() == "0" && receivedWeight?.[field.id?.toString()]?.[
																					"received_weight"
																				] != 0) {
																					callback('Minimum 1 test required');
																				} else if (value?.toString().length > 4) {
																					callback("Maximum 4 digits allowed")
																				} else {
																					callback();
																				}
																			},
																		}),
																		{
																			required: true,
																			message: "It is required."
																		}, {
																			pattern: regexPatterns.numeric,
																			message: "Decimal digits not allowed"
																		}
																		]}
																		disabled={
																			// receivedWeight?.[field.id?.toString()]?.[
																			// 	"received_weight"
																			// ] == 0 ||
																			// 	receivedWeight?.[field.id?.toString()]?.[
																			// 	"received_weight"
																			// 	] == undefined ||
																			location.pathname.includes("/view-coarse-aggregate")
																				? true
																				: false
																		}
																	/>
																</td>
															);
														}
													)}
												</tr>
											);
										})}
								</tbody>
							</>
						}
					</table>
					{diameterList?.length <= 0 && <div style={{ color: "red" }}>Atleast 1 Diameter Wise Receipt & No. Of Test Required</div>}
				</div>
				<Modal
					centered
					open={openModal}
					className="commanModal"
					//title={`Add ${titleName}`}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onCancel={drawerClose}
					destroyOnClose
					footer={
						<>
							<div className="signBtn">
								<Button
									htmlType="submit"
									className="secondaryBtn"
									form="diameterForm"
									disabled={disabled}
								>
									Add
								</Button>
								<Button onClick={drawerClose} className='cancelBtn'>
									Cancel
								</Button>
							</div>
						</>
					}
				>
					<FormBox onFinish={handleSubmitDiameter} id="diameterForm" form={form1} onValuesChange={validateForm1}>
						<InputBox.Select
							name="id"
							className="select textField"
							//onFocus={() => filterDiamter()}
							options={{
								list: filteredDiameter,
								valueKey: "id",
								textKey: "name",
							}}
							label="Select Diameter"
							rules={rules.selectFields()}
							onChange={(id: any) => {
								coarseQualityList?.map((item: any) => {
									if (item.id == id) {
										form1.setFieldValue("name", item?.name)
									}
								})
							}}
						/>
						<InputBox.Text
							label="Received Weight"
							name="received_weight"
							// rules={rules.numberFields()}
							onKeyDown={blockInvalidChar}
							//onChange={}
							rules={[{
								validator: checkValue
							},
							{
								required: true,
								message: "It is required."
							}, {
								pattern: regexPatterns.twoDecimal,
								message: "Maximum 4 digits & decimal value up to 2 digits."
							}
							]}
						/>
						<InputBox.Text
							hidden
							name="name"
						/>
					</FormBox>
				</Modal>
			</div>
		</>
	);
};
export default TableComponent;
