import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../store/api'
import { AppThunk } from '../../../../store/app'
import { apiUrls } from './apiUrls'
import Config from '../../../../config/Config'

interface InitialState {
    isLoading: boolean
    rowData: any
    read: any
    dynamicFieldType: any
    departmentList: any
    departmentTypeList: any
}

const initialState = {
    isLoading: false,
    rowData: null,
    read: null,
    dynamicFieldType: null,
    departmentList: null,
    departmentTypeList: null,
} as InitialState

const DepartmentSlice = createSlice({
    name: 'DEPARTMENT',
    initialState,
    reducers: {
        start: (state) => {
            state.isLoading = true
        },
        success: (state) => {
            state.isLoading = false
        },
        failure: (state) => {
            state.isLoading = false
        },
        setDynamicFieldType: (state, action: PayloadAction<any>) => {
            state.dynamicFieldType = action?.payload
        },
        setRowData: (state, action: PayloadAction<any>) => {
            state.rowData = action?.payload
        },
        setRead: (state, action: PayloadAction<any>) => {
            state.read = action?.payload
        },
        setDepartmentList: (state, action: PayloadAction<any>) => {
            state.departmentList = action?.payload
        },
    },
})

export const {
    start,
    success,
    failure,
    setDynamicFieldType,
    setRowData,
    setRead,
    setDepartmentList,
} = DepartmentSlice.actions

// export const getDynamicFieldType =
//   (payload: any): AppThunk<any> =>
//   async (dispatch) => {
//     try {
//       dispatch(start());
//       const response = await api.post(apiUrls.dynamicFieldType, payload);
//       dispatch(setDynamicFieldType(response.data.data));
//       dispatch(success());
//       return Promise.resolve(response.data);
//     } catch (error: any) {
//       dispatch(failure());
//       return Promise.reject(error.data);
//     }
//   };
/** For Listing:Start */
export const fetchList = (): AppThunk<any> => async (dispatch) => {
    dispatch(start())
    // const modifiedAction = {
    //   project_id: projectId, // Your static project_id value
    // };
    try {
        dispatch(start())
        const response = await api.post(apiUrls.list)
        dispatch(setRowData(response.data.rows))
        dispatch(success())
        return Promise.resolve(response.data)
    } catch (error: any) {
        dispatch(failure())
        return Promise.reject(error.data)
    }
}

export const createRecord =
    (action: any): AppThunk<any> =>
        async (dispatch) => {
            try {
                dispatch(start())
                const response = await api.post(apiUrls.create, action)
                dispatch(success(response.data))
                dispatch(fetchList())
                return Promise.resolve(response.data)
            } catch (error: any) {
                dispatch(failure(error.data))
                return Promise.reject(error.data)
            }
        }

// export const createDynamicRecord =
//   (action: any): AppThunk<any> =>
//   async (dispatch) => {
//     try {
//       dispatch(start());
//       const response = await api.post(apiUrls.dynamicFieldSave, action);
//       dispatch(success(response.data));
//       dispatch(fetchList());
//       return Promise.resolve(response.data);
//     } catch (error: any) {
//       dispatch(failure(error.data));
//       return Promise.reject(error.data);
//     }
//   };
export const read =
    (id: number): AppThunk<any> =>
        async (dispatch) => {
            try {
                const response = await api.get(apiUrls.read(id))
                dispatch(setRead(response.data.data))
                return Promise.resolve(response.data)
            } catch (error: any) {
                return Promise.reject(error.data)
            }
        }

export const updateRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch) => {
            try {
                dispatch(start())
                const response = await api.post(apiUrls.update(id), action)
                dispatch(success(response.data))
                dispatch(fetchList())
                return Promise.resolve(response.data)
            } catch (error: any) {
                dispatch(failure(error.data))
                return Promise.reject(error.data)
            }
        }

export const deleteRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch) => {
            try {
                dispatch(start())
                const response = await api.get(apiUrls.delete(id), action)
                dispatch(success(response.data))
                dispatch(fetchList())
                return Promise.resolve(response.data)
            } catch (error: any) {
                dispatch(failure(error.data))
                return Promise.reject(error.data)
            }
        }
export const refreshGrid = () => {
    var gridApi: any = Config.grid.local.gridOptions?.api;
    gridApi.deselectAll();
}

const DepartmentSliceReducer = DepartmentSlice.reducer
export default DepartmentSliceReducer
