import { ColDef } from "ag-grid-community";
import {
  idColumn,
  nameColumn,
  //actionColumn,
} from "../../../../../utils/commonColumns";

const countColumn: ColDef = {
  field: "user_count",
  headerName: "Number of Users",
  filter: "agNumberColumnFilter",
  tooltipValueGetter: (params: any) => params.data.user_count,
};

const columnDefs: ColDef[] = [
  idColumn,
  nameColumn,
  countColumn
  //actionColumn,
];
export default columnDefs;
