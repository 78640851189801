const baseUrl = "/v1/admin/coarse-aggregate-inventory";
const reportUrl = "/v1/admin/coarse-aggregate-test-perform";
const lab = "/v1/admin/laboratory";
const deleteUrl = `/v1/admin`
export const apiUrls = {
	create: (id: number): string => `${baseUrl}/${id}/create`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	list: (id: number): string => `${baseUrl}/${id}/list`,
	delete: `${deleteUrl}/row-material-inventory/delete`,
	deleteReport:`${deleteUrl}/row-material-report/delete`,
	createReport: (id: any): string => `${reportUrl}/${id}/create`,
	reportList: `${reportUrl}/list`,
	readReport: (id: any): string => `/v1/admin/coarse-aggregate-test-perform/${id}/read`,
	getQuality: `${baseUrl}/getCoarseAggregateQuality`,
	testType: (id: any): string => `/v1/admin/steel-inventory/${id}/getTestType`,
	laboratory: `${lab}/laboratorylist`,

};
