import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon/editMixdesign.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/images/icon/view.svg";
import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { deleteRecord, getGradeOfconcreteList, setFormData, setProjectId, setViewData } from "../../RawMaterial/utils/slice";
import ApproveModal from '../Forms/ApproveModal'
import dayjs from "dayjs";
import { checkPrivileges } from "../../../../config/Global";
import SVGIcon from "../../../../utils/SVGIcon";
import DeleteModal from "../../../../components/Modals/DeleteModal";

interface DataType {
	//key: React.Key;
	cement_grade_id: string;
	M10: any;
	M15: any
	M20?: any
	M25?: any
	M30?: any
	M35?: any
	M40?: any
	M45?: any
	M50?: any
}


const TableList: React.FC<any> = ({ rowData, permission }) => {
	const navigate = useNavigate()
	const dispatch: AppDispatch = useDispatch();
	const { AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	//view mix design
	const handleClick = (key?: any) => {
		const f_data = { ...key, name: key?.name.split('(')[0] }
		dispatch(setFormData(f_data))
		dispatch(setViewData(key?.id))
		navigate(`/projects/mix-design/${key?.id}/view-mixdesign`)
	}

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const openModal = (key: any) => {
		showModal()
		const f_data = { ...key, name: key?.name.split('(')[0] }
		dispatch(setFormData(f_data))
		dispatch(setViewData(key?.id))
	}

	//clear state and close delete popup
	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(getGradeOfconcreteList(localStorage.getItem('projectId'), { mix_design: 1 }));
	}

	//open delete popup
	const handleDelete = (data?: any) => {
		setDeleteData(data)
		setDeleteUser(true)
		dispatch(setProjectId(localStorage.getItem('projectId')))
	}

	const columns: ColumnsType<DataType> = [
		{
			title: "Grade of Concrete",
			dataIndex: "cement_grade_id",
			key: "name",
			fixed: "left",
			width: 250,
			className: "nameCol"
		},
	];

	//Concat the grade name and QA category name
	const destructure_data = rowData?.map((d?: any) => {

		// const categoryName = d.qa_category.name;
		// const projectName = d.name;

		// if (!categoryCount[projectName]) {
		// 	categoryCount[projectName] = {};
		// }

		// if (categoryCount[projectName][categoryName]) {
		// 	categoryCount[projectName][categoryName] += 1;
		// } else {
		// 	categoryCount[projectName][categoryName] = 1;
		// }
		return { ...d, name: d?.name + " (" + d?.qa_category?.name + ")"}
	})

	// set dynamic row and column
	const columns1 = destructure_data.map((key?: any) => ({
		title: (
			<div className="tableTitle">
				<span>{key?.name.replace(/\(.*\)/, '').trim()}</span>
				<div className="subTitle" style={{ textAlign: "left" }}>{`(${key?.qa_category?.name})`}</div>
				<div className="iconCol">
					{checkPrivileges(AUTH.userDetail, `${permission}_UPDATE`) &&
						<Tooltip title="Edit" placement="top">
							<Button>
								<EditIcon onClick={() => openModal(key)} />
							</Button>
						</Tooltip>}
					{checkPrivileges(AUTH.userDetail, `${permission}_DETAILS`) &&
						<Tooltip title="View" placement="top">
							<Button>
								<ViewIcon height={20} width={20} onClick={() => handleClick(key)} />
							</Button>
						</Tooltip>}
					{checkPrivileges(AUTH.userDetail, `${permission}_DELETE`) &&
						<Tooltip title="Delete" placement="top">
							<Button className="mixDelete" onClick={() => handleDelete(key)}>
								<SVGIcon icon="delete" height={19} width={19} />
							</Button>
						</Tooltip>}
				</div>
			</div>
		), // Use the key as the column title
		dataIndex: key.name, // Use the key to access data in each dataSource item
		key: key.name,
		// width: 150,

	}));

	const transformedData: any = [];


	const uniqueKeys = [
		"cement_name",
		"mm_20",
		"mm_10_20",
		"sand",
		"type_of_cement_name",
		"cement_grade_name",
		"cement_in_kg",
		"water_in_ltr",
		"admixture_one",
		"admixture_two",
		"w_c_ratio",
		"min_slump_mm",
		"max_slump_mm",
		"laboratory_original_name",
		"finalize_mix_design_date",
		"qa_category_name"
	];

	//Convert the Grade of Concrete to first capital letter
	function capitalize(str: string): string {
		if (str == "mm_20") {
			return "20 mm"
		} else if (str == "mm_10_20") {
			return "10-20 mm"
		} else if (str == "w_c_ratio") {
			return "W/C Ratio"
		} else if (str == "min_slump_mm") {
			return "Minimum Slump mm"
		} else if (str == "max_slump_mm") {
			return "Maximum Slump mm"
		} else if (str == "name_of_cement_brand") {
			return "Name of Cement Brand"
		} else if (str == "finalize_mix_design_date") {
			return "Date of Finalize of Mix Design"
		} else if (str == "type_of_cement_name") {
			return "Type of Cement Name"
		} else if (str == "laboratory_original_name") {
			return "Laboratory Name"
		} else if (str == "qa_category_name") {
			return "Structural Element"
		} else if (str == "admixture_one") {
			return "Admixture 1"
		} else if (str == "admixture_two") {
			return "Admixture 2"
		} else if (str == "cement_in_kg") {
			return "Cement (In KG)"
		} else if (str == "water_in_ltr") {
			return "Water (In Litre)"
		}
		else {
			return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
		}
	}
	uniqueKeys.forEach(key => {
		const newData: any = {
			cement_grade_id: capitalize(key)
		};
		const data = destructure_data?.map((data?: any) => {
			return { ...data, finalize_mix_design_date: dayjs(data?.finalize_mix_design_date)?.format("DD-MM-YYYY"), qa_category_name: data.qa_category?.name }
		})
		data.forEach((item: any) => {
			newData['M' + item.name.replace('M', '')] = item[key];
		});

		transformedData.push(newData);
	});

	//merge static and dynamic columns
	const finalColumns = [...columns, ...columns1];

	return (

		<>
			{checkPrivileges(AUTH.userDetail, `${permission}_INDEX`) &&
				<Table
					columns={finalColumns}
					dataSource={transformedData}
					scroll={{ x: 1300 }}
					pagination={false}
					tableLayout="fixed"
					className="mixDesignTable"
				></Table>}
			<DeleteModal
				title={"mix design"}
				deleteValues={deleteData}
				callApi={deleteRecord}
				close={closeDeleteModal}
				open={deleteUser}
			/>
			<ApproveModal open={isModalOpen} close={handleCancel} projectId={localStorage.getItem('projectId')} />
		</>

	);
};

export default TableList;
