import React, { useEffect, useState } from "react";
import Listing from "./Listing/Listing";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../../assets/images/icon/plusIcon.svg";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { deleteRecord, setCoarseId, setId, setLocation, setPerPage, setRead } from "./utils/slice";
import RecordPerPage from "../../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../../utils/SVGIcon";
import { ReactComponent as ReportIcon } from "../../../../../../assets/images/icon/ReportIcon.svg";
import { checkPrivileges } from "../../../../../../config/Global";
import PageSpinner from "../../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../../components/Modals/DeleteModal";

const CoarseAggregate: React.FC<any> = ({ permissionPrefix }) => {
	const moduleName = "Coarse Aggregate";
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { COARSEAGGREGATE, AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setCoarseId(null))
	}

	const onRowClick = (data?: any) => {
		dispatch(setCoarseId(data?.data?.id));
		setDeleteData(data?.data)
	};

	const reportData = () => {
		navigate(
			`/projects/raw-material/${COARSEAGGREGATE.coarseId}/coarse-aggregate-report`
		);
	};
	const handleView = () => {
		dispatch(setRead(null))
		navigate(
			`/projects/raw-material/${COARSEAGGREGATE.coarseId}/view-coarse-aggregate`
		);
	};
	const handleClick = () => {
		navigate(`/projects/raw-material/add-coarse-aggregate`);
	};

	useEffect(() => {
		dispatch(setId(localStorage.getItem('projectId')));
		dispatch(setLocation(""));
	}, []);

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) && <>
					<div className="rawListWrapper">
						<div className="rawList">
							<div className="btnWrap">
								{
									checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
									<Button className="secondaryBtn steelBtn" onClick={handleClick}>
										Add
										<span className="btnIcon">
											<PlusIcon />
										</span>
									</Button>}
								<RecordPerPage
									defaultValue={COARSEAGGREGATE.perPage + " per page"}
									onChange={(perPageSize: number) => {
										dispatch(setPerPage(perPageSize));
									}}
								/>{" "}
							</div>
							<Listing
								rowData={COARSEAGGREGATE?.rowData}
								moduleName={moduleName}
								handleReportClick={reportData}
								handleViewClick={handleView}
								onRowClick={onRowClick}
							/>
							<DeleteModal
								title={"coarse aggregate"}
								deleteValues={deleteData}
								callApi={deleteRecord}
								close={closeDeleteModal}
								open={deleteUser}
							/>
						</div>
						<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) || checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) ? "actionRow rawmaterialActionRow" : ""}>
							<div className="actionBtn">
								{
									checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
									<Button
										onClick={handleView}
										disabled={COARSEAGGREGATE.coarseId ? false : true}
										className={COARSEAGGREGATE.coarseId ? "activeBtn" : "disableBtn"}
									>
										View
										<SVGIcon icon="view" />
									</Button>}
								{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) &&
									<div>
										<Button
											disabled={COARSEAGGREGATE.coarseId ? false : true}
											onClick={() => setDeleteUser(true)}
											className={COARSEAGGREGATE.coarseId ? "deleteBtn" : "disableDelBtn disableBtn"}
										>
											Delete
											<SVGIcon icon="delete" />
										</Button>
									</div>}
								{
									checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) &&
									<Button
										disabled={COARSEAGGREGATE.coarseId ? false : true}
										onClick={reportData}
										className={COARSEAGGREGATE.coarseId ? "activeBtn" : "disableBtn"}
									>
										Report
										<ReportIcon />
									</Button>}
							</div>
						</div>
					</div></>}
		</>
	);
};

export default CoarseAggregate;
