import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./config/RouterConfig";
import { initialAppOptions, resetAuthStore } from "./store/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/app";
import PageSpinner from "./components/PageSpinner/PageSpinner";

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const AUTH = useSelector((state: RootState) => state.AUTH);
  const handleStorageEvents = (e: any) => {
	if (e.key === 'token') {
			if (e.oldValue && !e.newValue) {
				window.location.href = "/";
				dispatch(resetAuthStore());
			} else if (!e.oldValue && e.newValue) {
				window.location.reload();
			}
		}
		if (e.key === 'initialData') {
			if (e.oldValue !== e.newValue) {
				window.location.reload();
			}
		}
	}
  useEffect(() => {
    dispatch(initialAppOptions());
    window.addEventListener('storage', handleStorageEvents);


  }, [dispatch]);
  return (
    <BrowserRouter>
      {AUTH.appLoading ? <PageSpinner /> : <Router />}
    </BrowserRouter>
  );
};

export default App;
