import React, { useEffect, useState } from 'react'
import Listing from './Listing/Listing'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { ReactComponent as PlusIcon } from '../../../../../../assets/images/icon/plusIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../store/app'
import {
	setProjectId,
	setLocation,
	setPerPage,
	setConcreteMixId,
	deleteRecord,
	setRead,
} from './utils/slice'
import RecordPerPage from '../../../../../../components/ToolBar/Dropdown/RecordPerPage'
import SVGIcon from '../../../../../../utils/SVGIcon'
import { ReactComponent as ReportIcon } from '../../../../../../assets/images/icon/ReportIcon.svg'
import { checkPrivileges } from '../../../../../../config/Global'
import PageSpinner from '../../../../../../components/PageSpinner/PageSpinner'
import DeleteModal from '../../../../../../components/Modals/DeleteModal'

const ConcreteAdmixture: React.FC<any> = ({ permissionPrefix }) => {
	const moduleName = 'Concrete Admixture'
	const dispatch: AppDispatch = useDispatch()
	const { CONCRETEADMIXTURE, AUTH } = useSelector((state: RootState) => state)
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()

	const navigate = useNavigate()

	useEffect(() => {
		dispatch(setProjectId(localStorage.getItem('projectId')))
		dispatch(setLocation(''))
		dispatch(setConcreteMixId(null))
	}, [])

	const reportData = () => {
		navigate(
			`/projects/raw-material/${CONCRETEADMIXTURE?.concreteMixId}/concrete-mixture-report`
		)
	}

	const handleView = () => {
		dispatch(setRead(null))
		navigate(
			`/projects/raw-material/${CONCRETEADMIXTURE?.concreteMixId}/view-concrete-admixture`
		)
	}

	const handleClick = () => {
		dispatch(setRead(null))
		navigate(`/projects/raw-material/add-concrete-admixture`)
	}

	const onRowClick = (data?: any) => {
		dispatch(setConcreteMixId(data?.data?.id))
		setDeleteData(data?.data)
	}

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setConcreteMixId(null))
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) && <>
					<div className="rawListWrapper">
					<div className="rawList">
						<div className="btnWrap">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn steelBtn" onClick={handleClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={CONCRETEADMIXTURE.perPageSize + ' per page'}
								onChange={(perPageSize: number) =>
									dispatch(setPerPage(perPageSize))
								}
							/>
						</div>
						<Listing
							rowData={CONCRETEADMIXTURE.rowData}
							moduleName={moduleName}
							handleReportClick={reportData}
							onRowClick={onRowClick}
						/>
						<DeleteModal
							title={"concrete admixture"}
							deleteValues={deleteData}
							callApi={deleteRecord}
							close={closeDeleteModal}
							open={deleteUser}
						/>
					</div>
					<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) || checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) ? "actionRow borderActionRow" : ""}>
						<div className="actionBtn">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
								<Button
									onClick={handleView}
									disabled={
										CONCRETEADMIXTURE?.concreteMixId == null
											? true
											: false
									}
									className={
										CONCRETEADMIXTURE?.concreteMixId
											? 'activeBtn'
											: 'disableBtn'
									}
								>
									View
									<SVGIcon icon="view" />
								</Button>}
							{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) &&
								<div>
									<Button
										disabled={CONCRETEADMIXTURE?.concreteMixId ? false : true}
										onClick={() => setDeleteUser(true)}
										className={CONCRETEADMIXTURE?.concreteMixId ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>
								</div>}
							{
								checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) &&
								<Button
									disabled={
										CONCRETEADMIXTURE?.concreteMixId == null
											? true
											: false
									}
									onClick={reportData}
									className={
										CONCRETEADMIXTURE?.concreteMixId
											? 'activeBtn'
											: 'disableBtn'
									}
								>
									Report
									<ReportIcon />
								</Button>}
						</div>
					</div>
					</div>
				</>
				}
		</>
	)
}

export default ConcreteAdmixture
