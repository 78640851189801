import { ColDef } from 'ag-grid-community'
import {
    createdAtColumn,
    idColumn,
    updatedAtColumn,
} from '../../../../../utils/commonColumns'

const labNameColumn: ColDef = {
    field: 'laboratory_name',
    headerName: 'Laboratory Name',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.laboratory_name,
}

const columnDefs: ColDef[] = [
    idColumn,
    labNameColumn,
    createdAtColumn,
    updatedAtColumn,
]
export default columnDefs
