const baseUrl = '/v1/admin/concrete-admixture-inventory'
const baseUrl1 = '/v1/admin/concrete-admixture-test-perform'
const deleteUrl = `/v1/admin`
export const apiUrls = {
	create: (id: number): string => `${baseUrl}/${id}/create`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	list: (id: number): string => `${baseUrl}/${id}/list`,
	delete: `${deleteUrl}/row-material-inventory/delete`,
	deleteReport:`${deleteUrl}/row-material-report/delete`,
	createReport: (id: any): string => `${baseUrl1}/${id}/create`,
	reportList: `${baseUrl1}/list`,
	readReport: (id: any): string => `${baseUrl1}/${id}/read`,
	testType: (id: any): string =>
		`/v1/admin/steel-inventory/${id}/getTestType`,
	nameOfLaboratory: "v1/admin/laboratory/laboratorylist"
}
