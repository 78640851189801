import { ColDef } from 'ag-grid-community'
//import {actionColumn } from "../../../../../../../utils/commonColumns";
import { createdAtColumn, idColumn } from '../../../../../../../utils/commonColumns'

const SourceColumn: ColDef = {
	field: 'source',
	headerName: 'Source',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.source,
}
const TotalCMTColumn: ColDef = {
	field: 'total_cmt',
	headerName: 'Total CMT',
	filter: 'agNumberColumnFilter',
	tooltipValueGetter: (params: any) => params.data.total_cmt,
}

const columnDefs: ColDef[] = [
	idColumn,
	SourceColumn,
	TotalCMTColumn,
    createdAtColumn,
]
export default columnDefs
