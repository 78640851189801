const baseUrl = '/v1/admin/design-consultant'

export const apiUrls = {
	list: `${baseUrl}/list`,
	create: `${baseUrl}/create`,
	update: (id: number): string => `${baseUrl}/${id}/edit`,
	delete: `${baseUrl}/delete`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	designConsultantList : `${baseUrl}/designconsultantlist`,
}
