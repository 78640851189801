import React, { useEffect } from "react";
import { FormBox } from "../../../../../components/AntdAddons";
import dayjs from "dayjs";
import { CONSTANT } from "../../../../../config/Constant";


interface FormComponentProps {
	form: any
	id: string
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	editValues,
}) => {

	useEffect(() => {
		if (editValues) {
			form.setFieldsValue(editValues);
		}
	}, [editValues, form]);
	const new_value = editValues?.new_values && JSON.parse(editValues?.new_values)
	const old_value = editValues?.old_values && JSON.parse(editValues?.old_values)
	
	return (
		<>
			<FormBox
				form={form}
				id={id}
			>
				<div className="auditTable">
					<table className="oldTable">
						<tr className="updateHeader">
							<th colSpan={2}>Old Values</th>
						</tr>
						<tr>
							<td colSpan={2}>Updated At {dayjs(editValues?.created_at).format(CONSTANT?.DATE_FORMAT)}</td>
						</tr>
						{old_value && Object.entries(old_value)?.map(([key, value]) => (
							<tr className="subHeader" key={key}>
								<td>{key}</td>
								<td>{String(value)}</td>
							</tr>
						))}
					</table>
					<table className="newTable">
						<tr className="updateHeader">
							<th colSpan={2}>New Values</th>
						</tr>
						<tr>
							<td colSpan={2}>Updated At {dayjs(editValues?.updated_at).format(CONSTANT?.DATE_FORMAT)}</td>
						</tr>
						{new_value && Object.entries(new_value)?.map(([key, value]) => (
							<tr className="subHeader" key={key}>
								<td>{key}</td>
								<td>{String(value)}</td>
							</tr>
						))}
					</table>
				</div>

			</FormBox>
		</>
	);
};

export default FormComponent;
