import React, { useEffect, useState } from "react";

import ToolBar from "../../../components/ToolBar";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { setPerPageSize, setDetails, deleteRecord, setIsUser, fetchRoleList, setisTerminated, setArchive, restoreUser, refreshGrid } from "./utils/slice";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import AddComponent from "./components/Form/AddComponent";
import EditComponent from "./components/Form/EditComponent";
import { UserBreadcrumb } from "../../../config/BreadcrumbConfig";
import SVGIcon from "../../../utils/SVGIcon";
import { Button } from "antd";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { ReactComponent as ArchiveIcon } from "../../../assets/images/icon/archiveIcon.svg";
import { ReactComponent as ResetIcon } from "../../../assets/images/icon/refresh.svg";
import { ReactComponent as ListIcon } from "../../../assets/images/icon/listIcon.svg";
import CommonModal from "../../../components/Modals/CommonModal";
import WarningModal from "../../../components/Modals/WarningModal";

const moduleName = "User";
const UserManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { USER, AUTH } = useSelector((state: RootState) => state);
	const [isCreateVisible, setIsCreateVisible] = useState(false);
	const [editForm, setEditForm] = useState(false)
	const [deleteUser, setDeleteUser] = useState(false)
	const [archivedList, setArchivedList] = useState(false);
	const [restoreModal, setRestoreModal] = useState(false)
	const [saving, setSaving] = React.useState<boolean>(false);
	const [isDelete, setIsDelete] = useState<any>(false)
	const [openModal, setOpenModal] = useState(false)

	const onRowClick = (data?: any) => {
		if (data?.data?.role_name == "Super Admin") {
			setIsDelete(false)
			dispatch(setIsUser(data.data))
		} else {
			dispatch(setIsUser(data.data))
			setIsDelete(true)
		}
		dispatch(setDetails(data.data))
	};

	const closeModal = () => {
		dispatch(refreshGrid())
		setIsCreateVisible(false);
		setEditForm(false);
		dispatch(setIsUser(null))
	};

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setIsUser(null))
		dispatch(refreshGrid())
	}

	useEffect(() => {
		dispatch(fetchRoleList())
		dispatch(setisTerminated(0))
		dispatch(setIsUser(null))
	}, [])

	const openArchived = () => {
		setArchivedList(true)
		dispatch(setisTerminated(1))
		dispatch(setArchive())
	}
	const closeArchived = () => {
		setArchivedList(false)
		dispatch(setisTerminated(0))
		dispatch(setArchive())
	}

	const restoreData = () => {
		dispatch(restoreUser(USER?.isUser?.id)).then(() => {
			closeRestoreModal()
		}).catch(() => {
			setSaving(false)
		})
			.finally(() => setSaving(false));
	}

	const closeRestoreModal = () => {
		setRestoreModal(false)
		dispatch(setIsUser(null));
		dispatch(refreshGrid())
	}
	const deleteUserModal = () => {
		setDeleteUser(true)
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={UserBreadcrumb(archivedList ? "User Management - Archive" : "User Management")} >
						{!archivedList ? <Button key="3" onClick={openArchived} className="secondaryBtn archiveBtn">
							Archive
							<ArchiveIcon />
						</Button> : <Button key="4" onClick={closeArchived} className="secondaryBtn listBtn">
							<ListIcon />
							Back To List
						</Button>}
						{!archivedList && <CreateButton
							action={() => setIsCreateVisible(true)}
							permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
						/>}
						<RecordPerPage
							defaultValue={USER.perPage + " per page"}
							onChange={(perPageSize: number) => {
								dispatch(setPerPageSize(perPageSize))
							}}
						/>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							<div className="list">
								<Listing
									moduleName={"user"}
									onRowClick={onRowClick}
									archivedList={archivedList}
									moduleInfo={moduleInfo}
									user={AUTH.userDetail}
								/>
								<AddComponent
									titleName={moduleName}
									open={isCreateVisible}
									close={() => setIsCreateVisible(false)}
								/>
								{/* can't remove this code <ViewDrawer
									titleName={moduleName}
									close={() => dispatch(setDetails(null))}
								/> */}
								<EditComponent
									titleName={moduleName}
									open={editForm}
									close={closeModal}
								/>
								{
									isDelete && <DeleteModal
										title={"user"}
										deleteValues={USER.isUser}
										callApi={deleteRecord}
										close={closeDeleteModal}
										open={deleteUser}
									/>
								}
								{
									!isDelete && <WarningModal
										title="Role"
										close={() => { setOpenModal(false); dispatch(refreshGrid());dispatch(setIsUser(null)) }}
										open={openModal} 
										message={"It cannot be deleted"}
										/>
								}
							</div>
							{!archivedList && <div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}
							>
								<div className="actionBtn">
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) && !archivedList &&
										<Button
											disabled={USER.isUser == null ? true : false}
											onClick={() => {
												setEditForm(true);
											}}
											className={USER.isUser ? "activeBtn" : "disableBtn"}
										>
											Edit
											<SVGIcon icon="edit" />
										</Button>
									}
									{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) && !archivedList &&
											<div>
												<Button
													disabled={USER.isUser ? false : true}
													onClick={()=>{isDelete?deleteUserModal():setOpenModal(true)}}
													className={USER.isUser ? "deleteBtn" : "disableDelBtn disableBtn"}
												>
													Delete
													<SVGIcon icon="delete" />
												</Button>
											</div>}

								</div>
							</div>
							}
							{archivedList && checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_RESTORE`) && <div className="actionRow">
								<div className="actionBtn">
									<Button
										disabled={USER.isUser == null ? true : false}
										onClick={() => setRestoreModal(true)}
										className={USER.isUser ? "activeBtn" : "disableBtn"}
									> Restore
										<ResetIcon height={22} width={22} />

									</Button>
									<CommonModal
										className="commanModal"
										title={"Restore User"}
										open={restoreModal}
										onCancel={closeRestoreModal}
										footer={[
											<Button
												key="1"
												htmlType="button"
												loading={saving}
												onClick={restoreData}
												className="secondaryBtn"
											>
												Yes
											</Button>,
											<Button key="2" htmlType="button" className="cancelBtn" onClick={closeRestoreModal}>
												No
											</Button>,

										]}
									//{...rest}
									>
										<div className="deleteNote">
											{USER?.details?.role_id ? "Are you sure you want to restore user?" : "The selected user does not have any assigned role. Are you sure want to restore user?"}
										</div>
									</CommonModal>
								</div>
							</div>
							}

						</div>


					</ContentBox>
				</> : <RestrictedAccessPage />
			}

		</>
	);
};

export default UserManagement;
