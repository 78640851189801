import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { DutyAssignmentBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import AddComponent from "./components/Forms/AddComponent";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import WarningModal from "../../../components/Modals/WarningModal";
import { fetchList, refreshGrid, setPerPageSizes } from "./utils/slice";

const moduleName = "Duty Assignment";

const DutyAssignment: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { DUTY_ASSIGNMENT, AUTH } = useSelector((state: RootState) => state);
	const [addForm, setAddForm] = useState<any>(false);
	const [openModal, setOpenModal] = useState(false)
	const pro_id: any = localStorage.getItem('projectId')


	useEffect(() => {
		dispatch(fetchList({ "planning_id": pro_id }));
	}, []);

	const handleClick = () => {
		setAddForm(true);
	};

	const closeModal = () => {
		setAddForm(false);
		dispatch(refreshGrid)
	};


	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={DutyAssignmentBreadcrumb}>
						<>
							{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
								<CreateButton
									action={() => handleClick()}
									permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
									name="Add Duty"
								/>
							}
							<RecordPerPage
								defaultValue={DUTY_ASSIGNMENT.perPageSize + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setPerPageSizes(perPageSize));
								}}
							/>{" "}
						</>
					</ToolBar>
					<ContentBox>
						<AddComponent
							titleName={moduleName}
							open={addForm}
							close={closeModal}
						/>
						<WarningModal
							title={moduleName}
							close={() => {
								setOpenModal(false);
							}}
							open={openModal} />
						<div className="listWrapper">
							<div className="list">
								<Listing
									moduleName={moduleName}
									rowData={DUTY_ASSIGNMENT.rowData}
								/>
							</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</>
	);
};

export default DutyAssignment;
