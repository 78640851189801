import React, { useEffect, useState } from 'react'
import Listing from './Listing/Listing'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../../assets/images/icon/plusIcon.svg';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../store/app'
import { setRead, setId, setLocation, setBindingId, setPerPage, deleteRecord } from './utils/slice'
import SVGIcon from '../../../../../../utils/SVGIcon';
import { ReactComponent as ReportIcon } from "../../../../../../assets/images/icon/ReportIcon.svg";
import RecordPerPage from '../../../../../../components/ToolBar/Dropdown/RecordPerPage';
import { checkPrivileges } from '../../../../../../config/Global';
import PageSpinner from '../../../../../../components/PageSpinner/PageSpinner';
import DeleteModal from '../../../../../../components/Modals/DeleteModal';



const BindingWire: React.FC<any> = ({ permissionPrefix }) => {
	const moduleName = "Binding Wire";
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { BINDINGWIRE, AUTH } = useSelector((state: RootState) => state)
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()

	useEffect(() => {
		dispatch(setId(localStorage.getItem('projectId')));
		dispatch(setLocation(""));
		dispatch(setBindingId(null))
	}, [])


	const onRowClick = (data: any) => {
		dispatch(setBindingId(data?.data?.id))
		setDeleteData(data?.data)
	}

	const reportData = () => {
		navigate(`/projects/raw-material/${BINDINGWIRE.bindingId}/binding-wire-report`);
	};

	const handleView = () => {
		dispatch(setRead(null))
		navigate(`/projects/raw-material/${BINDINGWIRE.bindingId}/view-binding-wire`)

	}

	const handleClick = () => {
		setRead(null)
		navigate(`/projects/raw-material/add-binding-wire`)
	}

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setBindingId(null))
	}

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) && <>
					<div className="rawListWrapper">
					<div className='rawList'>
						<div className="btnWrap">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn steelBtn" onClick={handleClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={BINDINGWIRE?.perPageSize + " Per Page "}
								onChange={(perpageSize: number) => {
									dispatch(setPerPage(perpageSize))
								}}
							/>
						</div>
						<Listing
							owData={BINDINGWIRE.rowData} moduleName={moduleName}
							handleReportClick={reportData}
							handleViewClick={handleView}
							onRowClick={onRowClick}
						/>
						<DeleteModal
							title={"binding wire"}
							deleteValues={deleteData}
							callApi={deleteRecord}
							close={closeDeleteModal}
							open={deleteUser}
						/>
					</div>
					<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) || checkPrivileges(AUTH.userDetail, `REPORT`) ? "actionRow rawmaterialActionRow" : ""}>
						<div className="actionBtn">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
								<Button
									onClick={handleView}
									disabled={BINDINGWIRE.bindingId === null}
									className={BINDINGWIRE.bindingId ? "activeBtn" : "disableBtn"}
								>
									View
									<SVGIcon icon="view" />
								</Button>}
							{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) &&
								<div>
									<Button
										disabled={BINDINGWIRE.bindingId ? false : true}
										onClick={() => setDeleteUser(true)}
										className={BINDINGWIRE.bindingId ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>
								</div>}
							{
								checkPrivileges(AUTH.userDetail, `REPORT`) &&
								<Button
									disabled={BINDINGWIRE.bindingId === null}
									onClick={reportData}
									className={BINDINGWIRE.bindingId ? "activeBtn" : "disableBtn"}
								>
									Report
									<ReportIcon />
								</Button>}
						</div>
					</div>
					</div>
				</>}
		</>
	)
}

export default BindingWire;
