import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { InputBox } from '../../../../../../../components/AntdAddons'
import { rules } from './rules'
import ToolBar from '../../../../../../../components/ToolBar'
import { AddPolymerslurryTab } from '../../../../../../../config/BreadcrumbConfig'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../../store/app'
import { setResetRead, read, createRecord } from '../utils/slice'
import { setTab } from '../../../../utils/slice'
import {
	dataToFormDataConverter,
	validateFields,
} from '../../../../../../../config/Global'
import { assignErrorToInput } from '../../../../../../../store/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { CONSTANT } from '../../../../../../../config/Constant'
dayjs.extend(customParseFormat)

const AddPolymer: React.FC = () => {
	const [form] = useForm()
	const { polymerSlurryid } = useParams()
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [, setState] = useState(false);
	const dispatch: AppDispatch = useDispatch()
	const navigate = useNavigate()
	const POLYMERSLURRYTAB = useSelector(
		(state: RootState) => state.POLYMERSLURRYTAB
	)

	useEffect(() => {
		if (location.pathname.includes('/view-polymer-slurry')) {
			dispatch(setResetRead())
			form.resetFields()
			dispatch(read(Number(polymerSlurryid)))
		}
	}, [])
	useEffect(() => {
		if (location.pathname.includes('/view-polymer-slurry')) {
			const details = POLYMERSLURRYTAB.read
			form.setFieldsValue({
				supplier_name: details?.supplier_name,
				invoice_number: details?.invoice_number,
				invoice_date: dayjs(details?.invoice_date),
				total_weight: details?.total_weight,
				receipt_date: dayjs(details?.receipt_date),
				document_name: details?.attached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							//uid: image.id,
							name: image?.document_name,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						}
					}
				),
				mtc_attachment: details?.mtcattached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							//uid: image.id,
							name: image?.document_name,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						}
					}
				)
			})
			setState(prevState => !prevState);
		}
	}, [POLYMERSLURRYTAB.read, polymerSlurryid, setState])

	const onFinish = (data: any) => {
		const payload = {
			...data,
			invoice_date: dayjs(data?.invoice_date).format(CONSTANT?.POST_DATE_FORMAT),
			receipt_date: dayjs(data?.receipt_date).format(CONSTANT?.POST_DATE_FORMAT),
		}
		const formData = dataToFormDataConverter(payload)
		try {
			setLoading(true)
			dispatch(createRecord(Number(localStorage.getItem('projectId')), formData))
				.then(() => {
					setLoading(false)
					dispatch(setTab(6))
					form.resetFields()
					return navigate(`/projects/raw-material`)
				})
				.catch((error: any) => {
					setLoading(false)
					assignErrorToInput(form, error?.STATUS)
				})
		} catch (error) {
			console.error('Error submitting data:', error)
		}
	}


	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleCancel = () => {
		dispatch(setTab(6))
		navigate(`/projects/raw-material`)
	}

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes(
						`/projects/raw-material/add-polymer-slurry`
					)
						? AddPolymerslurryTab('Add Polymer Slurry')
						: AddPolymerslurryTab('View Polymer Slurry')
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>

			<Form
				className="formLayout"
				form={form}
				onFinish={onFinish}
				onValuesChange={validateForm}
				style={{ position: "relative" }}
			>
				<div className="formHeading">
					<h3>
						{location.pathname.includes('/add-polymer-slurry')
							? 'Add Polymer Slurry'
							: 'View Polymer Slurry'}
					</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Text
								label="Name of Supplier"
								name="supplier_name"
								rules={rules.dynamicFields()}
								disabled={polymerSlurryid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Invoice No."
								name="invoice_number"
								rules={rules.dynamicFields()}
								disabled={polymerSlurryid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								label="Invoice Date"
								name="invoice_date"
								rules={rules.dateFields()}
								disabled={polymerSlurryid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Total Weight"
								name="total_weight"
								rules={rules.numberFields()}
								disabled={polymerSlurryid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								label="Date of Receipt of Polymer Slurry"
								name="receipt_date"
								rules={rules.dateFields()}
								disabled={polymerSlurryid ? true : false}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={true}
								label="Attach Invoice "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-polymer-slurry") ? true : false
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="mtc_attachment"
								required={true}
								label="Attach MTC "
								fileList={form.getFieldValue('mtc_attachment')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-polymer-slurry") ? true : false
								}
							/>
						</Col>
					</Row>
				</div>
				<div className="steelBtn polyBtn">
					<Button
						htmlType="submit"
						loading={loading}
						className="secondaryBtn"
						disabled={disabled}
					>
						Submit
					</Button>
					<Button
						className="secondaryBtn cancelBtn"
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	)
}

export default AddPolymer
