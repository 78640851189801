import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { DepartmentBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { Button } from "antd";
import { deleteRecord, fetchList, refreshGrid, setDepartmentData, setPerPageSizes, setRead } from "./utils/slice";
import AddComponent from "./components/Forms/AddComponent";
import EditComponent from "./components/Forms/EditComponent";
import DeleteModal from "../../../components/Modals/DeleteModal";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import WarningModal from "../../../components/Modals/WarningModal";

const moduleName = "Department";

const Department: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const dispatch: AppDispatch = useDispatch();
  const { DEPARTMENT, AUTH } = useSelector((state: RootState) => state);
  const [addForm, setAddForm] = useState<any>(false);
  const [editForm, setEditForm] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>();
  const [deleteModal, setDeleteModal] = useState<any>(false)
	const [openModal, setOpenModal] = useState(false)

  const onRowClick = (data?: any) => {
    dispatch(setDepartmentData(data.data));
    const count_keys = Object.entries(data?.data)?.filter((value: any) => {
      if (value[0].search("count") != -1) {
        return value
      }
    });
    const result = count_keys?.every((data: any) => {
      return data[1] == 0
    })

    if (result == true) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }

  };

  useEffect(() => {
    dispatch(fetchList());
    dispatch(setDepartmentData(null));
  }, []);

  const handleClick = () => {
    setAddForm(true);
  };

  const closeModal = () => {
    setAddForm(false);
    setEditForm(false);
    dispatch(refreshGrid)
    dispatch(setDepartmentData(null))
  };

  const closeDeleteModal = () => {
    setDeleteModal(false)
    dispatch(refreshGrid)
    dispatch(setDepartmentData(null))
  }

  return (
    <>
      {
        !AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
          <ToolBar breadcrumbs={DepartmentBreadcrumb}>
            <>
              {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
                <CreateButton
                  action={() => handleClick()}
                  permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                />
              }
              <RecordPerPage
                defaultValue={DEPARTMENT.perPageSize + " per page"}
                onChange={(perPageSize: number) => {
                  dispatch(setPerPageSizes(perPageSize));
                }}
              />{" "}
            </>
          </ToolBar>
          <ContentBox>
            <AddComponent
              titleName={moduleName}
              open={addForm}
              close={closeModal}
            />

            <EditComponent
              titleName={moduleName}
              open={editForm}
              close={closeModal}
            />
            {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`)&&
            <DeleteModal
              title={moduleName.toLowerCase()}
              deleteValues={DEPARTMENT.departmentData}
              callApi={deleteRecord}
              close={() => closeDeleteModal()}
              open={deleteModal}
            />}
             <WarningModal 
							title={moduleName} 
							close={() => {setOpenModal(false); dispatch(refreshGrid);dispatch(setDepartmentData(null))}} 
							open={openModal} />
              <div className="listWrapper">
            <div className="list">
              <Listing
                moduleName={moduleName}
                rowData={DEPARTMENT.rowData}
                onRowClick={onRowClick}
              />
            </div>
            <div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
              <div className="actionBtn">
                {checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
                  <Button
                    onClick={() => {
                      dispatch(setRead(DEPARTMENT.departmentData));
                      setEditForm(true);
                    }}
                    disabled={DEPARTMENT.departmentData ? false : true}
                    className={DEPARTMENT.departmentData ? "activeBtn" : "disableBtn"}
                  >
                    Edit
                    <SVGIcon icon="edit" />
                  </Button>}
                {

                  checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
                      <Button
                        onClick={() => {
                          dispatch(setRead(DEPARTMENT.departmentData));
                          disabled == true ? setOpenModal(true) : setDeleteModal(true)
                        }}
                        disabled={DEPARTMENT.departmentData ? false : true}
                        className={DEPARTMENT.departmentData ? "deleteBtn" : "disableDelBtn disableBtn"}
                      >
                        Delete
                        <SVGIcon icon="delete" />

                      </Button>
                }
              </div>
            </div>
            </div>
          </ContentBox></> : <RestrictedAccessPage />}
    </>
  );
};

export default Department;
