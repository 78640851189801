import React, { useEffect, useState } from "react";
import { Button, Form, Steps } from "antd";
import FirstStep from "./elements/FirstStep";
import SecondStep from "./elements/SecondStep";
import ThirdStep from "./elements/ThirdStep";
import {
	createDynamicRecord,
	// createPlanning,
	createRecord,
	// readPlanning,
	// getDynamicFieldType,
	readRecord,
	setDepartmentList,
	setDepartmentTypeList,
	// setUserPaverList,
	// setUserPlanList,
	// updatePlanning,
	updateRecord,
} from "../../utils/slice";
import { assignErrorToInput } from "../../../../../store/api";
import { AppDispatch } from "../../../../../store/app";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dataToFormDataConverter, validateFields } from "../../../../../config/Global";
import { setDesignConsultantList } from "../../../DesignConsultant/utils/slice";
import { setPmcList } from "../../../Pmc/utils/slice";
import { setContractorList } from "../../../Contractor/utils/slice";
import { setDesignCodeList } from "../../../DesignCode/utils/slice";
import dayjs from "dayjs";
import { setTab } from "../../../CreateProjectDashboard/utils/slice";

const FormComponent: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const [current, setCurrent] = useState(0);
	const [multipleSection, setMultipleSection] = useState<any>({})
	const [isMultiple, setIsMultiple] = useState(false)
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const { step } = useParams();
	const [saving, setSaving] = useState(false);
	const data: any = localStorage.getItem('projectId')
	const pro_id: any = parseInt(data)
	// const tab: any = localStorage.getItem("tab")
	//const depId: any = localStorage.getItem("depId")

	const validateForm = () => {
		validateFields(form, setDisabled);

	};
	const onSubmitFirstStep = (data: any) => {
		// tab!=2 ?
		// dispatch(setTab(data.department_id - 1)): dispatch(setTab(2))
		dispatch(setTab(data.department_id - 1))
		setSaving(true)
		if (location.pathname.includes("/read") && pro_id) {
			data.work_order_date = dayjs(data?.work_order_date).format('YYYY-MM-DD')
			data.actual_start_date = dayjs(data?.actual_start_date).format('YYYY-MM-DD')
			// if(tab==2){
			// 	{
			// 		data.planning_date=dayjs(data?.planning_date).format("YYYY-MM-DD")
			// 		data.department_id=3
			// 		data.department_type_id=2
			// 	}
			// }
			// tab!=2?
			dispatch(updateRecord(pro_id, data, current))
				.then(() => {
					setSaving(false)
					localStorage.setItem('projectId', pro_id)
					next();
					navigate(`/projects/read/${current + 1}`);
				})
				.catch((err: any) => {
					setSaving(false)
					assignErrorToInput(form, err?.STATUS);
				})
				// :dispatch(updatePlanning(pro_id,data))
				// .then(() => {
				// 	setSaving(false)
				// 	localStorage.setItem('projectId', pro_id)
				// 	//next();
				// 	navigate(`/projects/project-dashboard`);
				// })
				// .catch((err: any) => {
				// 	setSaving(false)
				// 	assignErrorToInput(form, err?.STATUS);
				// })
		} else {
			data.work_order_date = dayjs(data?.work_order_date).format('YYYY-MM-DD')
			data.actual_start_date = dayjs(data?.actual_start_date).format('YYYY-MM-DD')
			// if(tab==2){
			// 	{
			// 		data.planning_date=dayjs(data?.planning_date).format("YYYY-MM-DD")
			// 		data.department_id=3
			// 		data.department_type_id=2
			// 	}
			// }
			dispatch(
				// tab==2?createPlanning(data):createRecord(data))
				createRecord(data))
				.then((data: any) => {
					setSaving(false)
					localStorage.setItem('projectId', data.insert_id.id)
					next();
					// tab==2?navigate(`/projects`):
					navigate(`/projects/create/${data.insert_id.id}/${current + 1}`);
				})
				.catch((err: any) => {
					setSaving(false)
					assignErrorToInput(form, err?.STATUS);
				});
		}
	};

	const onSubmitSecondStep = (data: any) => {
		setDisabled(true)
		const formData = dataToFormDataConverter(data);
		if (location.pathname.includes("/read") && pro_id) {
			dispatch(updateRecord(pro_id, formData, current))
				.then(() => {
					localStorage.setItem('projectId', pro_id)
					next();
					navigate(`/projects/read/${current + 1}`);
					setSaving(false)
					setDisabled(false)
				})
				.catch((err: any) => {
					setSaving(false)
					setDisabled(false)
					assignErrorToInput(form, err.NOTIFICATION);
				});

		} else {
			setSaving(true)
			dispatch(createDynamicRecord(formData))
				.then((data: any) => {
					localStorage.setItem('projectId', data.project_id)
					next();
					navigate(`/projects/create/${data.project_id}/${current + 1}`);
					setSaving(false)
					setDisabled(false)
				})
				.catch((err: any) => {
					setSaving(false)
					setDisabled(false)
					assignErrorToInput(form, err.NOTIFICATION);
				});
		}
	};

	const onSubmitThirdStep = (data: any) => {
		setDisabled(true)
		if (location.pathname.includes("/read")) {
			//let isEditedFile: any = false
			// data["28"] = data?.["28"]?.map((file: any) => {
			// 	if (file?.originFileObj && file?.originFileObj) {
			// 		isEditedFile = true
			// 		return file;
			// 	} else {
			// 		return file?.url;
			// 	}
			// });
			//!isEditedFile && delete data?.["28"]
			const formData = dataToFormDataConverter(data);
			dispatch(updateRecord(pro_id, formData, current))
				.then(() => {
					setSaving(false)
					setDisabled(false)
					navigate(`/projects/project-dashboard`);
					next();
				})
				.catch((err: any) => {
					setSaving(false)
					setDisabled(false)
					assignErrorToInput(form, err.NOTIFICATION);
				});
		} else {
			setSaving(true)
			const formData = dataToFormDataConverter(data);
			dispatch(createDynamicRecord(formData))
				.then(() => {
					setSaving(false)
					setDisabled(false)
					navigate(`/projects`);
					next();
				})
				.catch((err: any) => {
					setSaving(false)
					setDisabled(false)
					assignErrorToInput(form, err.NOTIFICATION);
				});
		}
	};

	const steps = [
		{
			title: <h1>01</h1>,
			content: (
				<FirstStep
					onSubmit={onSubmitFirstStep}
					form={form}
					formId={"FirstStep"}
					onChange={validateForm}
				/>
			),
		},
		{
			title: <h1>02</h1>,
			content: (
				<SecondStep
					onSubmit={onSubmitSecondStep}
					form={form}
					formId={"SecondStep"}
					onChange={validateForm}
				/>
			),
		},
		{
			title: <h1>03</h1>,
			content: (
				<ThirdStep
					onSubmit={onSubmitThirdStep}
					form={form}
					formId={"ThirdStep"}
					onChange={validateForm}
					multipleSection={multipleSection}
					setMultipleSection={setMultipleSection}
					isMultiple={isMultiple}
					setIsMultiple={setIsMultiple}
				/>
			),
		},
	];

	const next = () => {
		setCurrent(current + 1);
	};
	const items = steps.map((item) => ({ key: item.title, title: item.title }));

	useEffect(() => {
		step && setCurrent(parseInt(step));

		pro_id && localStorage.setItem('projectId', pro_id);
		validateForm();
	}, [location.pathname]);

	useEffect(() => {
		pro_id &&
			// tab!=2?
			dispatch(readRecord(pro_id, null))
				.then((data: any) => {
					dispatch(setDepartmentList(data.data.department));
					dispatch(setPmcList(data.data.pmc_name))
					dispatch(setDesignConsultantList(data.data.design_consultant))
					dispatch(setContractorList(data.data.contractor))
					dispatch(setDepartmentTypeList(data.data.departmentType));
					dispatch(setDesignCodeList(data.data.design_code))
					localStorage.setItem('depId', data.data.department_id)
					form.setFieldsValue({
						department_id: data.data.department_id,
						department_type_id: data.data.department_type_id,
						project_name: data.data.project_name,
						design_consultant_id: data.data.design_consultant_id,
						design_code_id: data.data.design_code_id,
						actual_start_date: dayjs(data.data.actual_start_date),
						work_order_date: dayjs(data.data.work_order_date),
						pmc_id: data.data.pmc_id,
						contractor_id: data.data.contractor_id,
					});
				})
				.catch(() => {
					//
				})
				// :dispatch(readPlanning(pro_id,null)).then((data: any) => {
				// 	dispatch(setUserPlanList([data.data.amc_username_at_plan_site]))
				// 	dispatch(setUserPaverList([data.data.amc_username_at_paver_site]))
				// 	form.setFieldsValue({
				// 		zone_id: data.data.zone_id,
				// 		type_of_project: data.data.type_of_project,
				// 		planning_date: dayjs(data.data.planning_date),
				// 		roaster: data.data.roaster,
				// 		name_of_agency: data.data.name_of_agency,
				// 		plant_location: data.data.plant_location,
				// 		name_of_road:data.data.name_of_road,
				// 		planned_quantity: data.data.planned_quantity,
				// 		type_of_material: data.data.type_of_material,
				// 		name_of_pmc: data.data.name_of_pmc,
				// 		name_of_engg_pmc_plan_site: data.data.name_of_engg_pmc_plan_site,
				// 		name_of_engg_pmc_paver_site: data.data.name_of_engg_pmc_paver_site,
				// 		name_of_engg_amc_plan_site: data.data.amc_username_at_plan_site.id,
				// 		name_of_engg_amc_paver_site: data.data.amc_username_at_paver_site.id,
				// 		designation_of_engg_amc_plan_site: data.data.designation_of_engg_amc_plan_site,
				// 		designation_of_engg_amc_paver_site: data.data.designation_of_engg_amc_paver_site,
				// 		mobile_number_amc_plant_site: data.data.mobile_number_amc_plant_site,
				// 		mobile_number_amc_paver_site: data.data.mobile_number_amc_paver_site,
				// 	});
				// })
				.catch(() => {
					//
				})
		validateForm()
	}, [pro_id]);

	return (
		<>
			{
			// tab!=2 &&
			 <Steps current={current} items={items} className="primarySteps" />}
			<div className={
				// tab==2 ? `planningContainer`:
				`stepContainer`}>
				{steps[current]?.content}
				<div className="stepBtn">
					{location.pathname.includes("/read")
						? current < steps.length - 1 && (
							<>
								{
									current != 0 && <Button
										className="secondaryBtn"
										onClick={() => {
											navigate(`/projects/read/${current - 1}`)
										}}
									>
										Previous
									</Button>
								}
								<Button
									className="secondaryBtn"
									htmlType="submit"
									form={current == 0 ? "FirstStep" : current == 1 ? "SecondStep" : "ThirdStep"}
									disabled={disabled}
									loading={saving}
								>
									{/* {tab==2?"Save":"Save & Next"} */}
									{"Save & Next"}
								</Button>
								{/* <Button
									className="secondaryBtn"
									//htmlType="submit"
									//form={current == 0 ? "FirstStep" : "SecondStep"}
									onClick={() => {
										navigate(`/projects/read/${current + 1}`)
									}}
								>
									Next
								</Button> */}

							</>
						)
						: current < steps.length - 1 && (
							<>
								<Button
									className="secondaryBtn"
									htmlType="submit"
									form={current == 0 ? "FirstStep" : "SecondStep"}
									disabled={disabled}
									loading={saving}
								>
									{/* {tab==2?"Save":"Save & Next"} */}
									{"Save & Next"}
								</Button>
							</>
						)}
					{location.pathname.includes("/read")
						? current === steps.length - 1 && (
							<>
								<Button
									className="secondaryBtn"
									onClick={() => {
										navigate(`/projects/read/${current - 1}`)
									}}
								>
									Previous
								</Button>
								<Button
									className="secondaryBtn"
									htmlType="submit"
									form="ThirdStep"
									disabled={disabled}
									loading={saving}
								>
									Save & Back
								</Button>
								{/* <Button
									className="secondaryBtn"
									// htmlType="submit"
									// form="ThirdStep"
									onClick={() => {
										setTimeout(() => {
											handleViewClick();
										}, 5);
									}}
								>
									Back
								</Button> */}

							</>
						)
						: current === steps.length - 1 &&
						location.pathname.includes("/read") === false && (
							<>
								<Button
									className="secondaryBtn"
									htmlType="submit"
									form="ThirdStep"
									disabled={disabled}
									loading={saving}
								>
									Save
								</Button>
							</>
						)}
				</div>
			</div>
		</>
	);
};
export default FormComponent;
