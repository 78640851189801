import { Button, Divider } from "antd";
import React from "react";
import { ReactComponent as ReportIcon } from "../../../../assets/images/icon/ReportIcon.svg";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../../config/Global";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/app";
import { setTab } from "../../RawMaterial/utils/slice";

const TableDataComponent: React.FC<any> = ({ rowData }) => {
	const navigate = useNavigate()
	const { AUTH } = useSelector((state: RootState) => state);
	const dispatch: AppDispatch = useDispatch();

	//raw-material report navigation
	const handleViewReport = (label?: any) => {
		if (label == "steel") {
			navigate(`/projects/raw-material/steel-report`)
			dispatch(setTab(0))
		} else if (label == "cement") {
			navigate(`/projects/raw-material/cement-report`)
			dispatch(setTab(1))
		} else if (label == "coarse_aggregate") {
			navigate(`/projects/raw-material/coarse-aggregate-report`)
			dispatch(setTab(2))
		} else if (label == "fine_aggregate") {
			navigate(`/projects/raw-material/fine-aggregate-report`)
			dispatch(setTab(3))
		} else if (label == "concrete_admixture") {
			navigate(`/projects/raw-material/concrete-mixture-report`)
			dispatch(setTab(4))
		} else if (label == "binding_wire") {
			navigate(`/projects/raw-material/binding-wire-report`)
			dispatch(setTab(5))
		}

	}

	return (
		<div className="consolidTable">
			<div className="formHeading">
				<h3>Consolidated Reports</h3>
			</div>
			<table>
				<thead>
					<tr>
						<td colSpan={2}>
							<div className="listHeader">
								Raw Material
							</div>
						</td>
						<td>

							No. of Test Required

						</td>
						<td colSpan={3}>
							<tr style={{ textAlign: "center" }} className="testRow">
								<td colSpan={3}>
									No. of Test Performed
								</td>
							</tr>
							<tr className="testSubRow">
								<td>NABL Lab</td>
								<td>Goverment Lab</td>
								<td>On-site Lab</td>
							</tr>
						</td>
						<td>

							No. of Passed Test

						</td>

						<td colSpan={2}>
							<div className="listHeader">
								No. of Failed Test
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					{rowData?.map((data?: any, index?: any) => {
						return (
							<>
								<tr className="subHeadingWrap" key={index}>
									<td colSpan={10}>
										<div className="subRowHeading">
											{" "}
											<span>
												{data?.pro_name
													.split("_")
													.join(" ")
													.replace(/_/g, " ")
													.replace(/\b\w/g, (char?: any) => char.toUpperCase())}
											</span>
											{
												checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) && <span style={{ display: "flex", alignItems: "center" }}>
													{
														data?.data[0]?.name !== null && <><Divider type="vertical" /><Button className="actionBtn" onClick={() => handleViewReport(data?.pro_name)}>
															<ReportIcon />
															Reports
														</Button></>
													}
												</span>
											}
										</div>
									</td>
								</tr>

								{data?.data?.map((data1: any, index?: any) => {
									return (
										<>
											<tr key={index} className={data1?.name !== null ? "bodyRowHeading" : "bodyRowHeadingBorder"}>
												<td data-label="Raw Material" className="firstRow" colSpan={2} style={{ textAlign: "left", padding: "10px 10px 10px 65px" }}>
													{data1?.name ? data1?.name : "N/A"}
												</td>
												<td data-label="No. of Test Required">{data1?.no_of_test_required}</td>
												<td data-label="No. of Test Performed - NABL Lab">
													{data1?.lab?.NABL ? parseInt(data1?.lab?.NABL) : 0}
												</td>
												<td data-label="No. of Test Performed - Goverment Lab  ">
													{data1?.lab?.Goverment ? parseInt(data1?.lab?.Goverment) : 0}
												</td>
												<td data-label="No. of Test Performed - Onsite Lab">
													{data1?.lab?.OnSite ? parseInt(data1?.lab?.OnSite) : 0}
												</td>
												<td data-label="No. of Passed Test" className={data1?.no_of_test_passed > 0 ? "approved" : ""}>
													<div><span>{data1?.no_of_test_passed}</span></div></td>
												<td data-label="No. of Failed Test" className={data1?.no_of_test_failed > 0 ? "failed" : ""}>
													<div><span>{data1?.no_of_test_failed}</span></div></td>
											</tr>
										</>
									);
								})}
							</>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default TableDataComponent;
