import { ColDef } from "ag-grid-community";
import {
	idColumn,
} from "../../../../utils/commonColumns";
import dayjs from "dayjs";
import { CONSTANT } from "../../../../config/Constant";


const nameColumn: ColDef = {
	field: "name",
	headerName: "Element Name",
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
};
const approvedColumn: ColDef = {
	field: "approved_by_state_rmb_design_circle_no",
	headerName: "Approved by State R&B Design circle (No.)",
	filter: 'agNumberColumnFilter',
	tooltipValueGetter: (params: any) => params.data.approved_by_state_rmb_design_circle_no,
}

const categoryStartDate: ColDef = {
	field: 'category_start_date',
	headerName: 'Start Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.category_start_date).format(CONSTANT.DATE_FORMAT)
	},
}

const categoryEndDate: ColDef = {
	field: 'category_end_date',
	headerName: 'End Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.category_end_date).format(CONSTANT.DATE_FORMAT)
	},
}
 const columnDefs: ColDef[] = [
	idColumn,
	nameColumn,
	approvedColumn,
	categoryStartDate,
	categoryEndDate
];

export const drainageColumnDefs: ColDef[] = [
	idColumn,
	nameColumn,
	categoryStartDate,
	categoryEndDate
];
export  default columnDefs;
