import { validations } from "../../../../config/validations/validations";

export const rules = {
	selectFirstFields: (field?: string) => [validations.required.select(field)],
	dynamicFields: (field?: string) => [
		validations.required.text(field),
		validations.minMax()
	],
	dynamicNumbricField: (field?: any) => [
		validations.required.other(field),
		validations.pattern.numeric(),
		// validations.minMax(
		//   1, 10
		// )
	],
	dynamicNumbricField1: (field?: any) => [
		validations.required.other(field),
		validations.pattern.numeric(),
	],
};
