import React, { useEffect } from "react";
import { Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { getCategorytList, setCategoryList } from "../../../Category/utils/slice";


interface FormComponentProps {
	form: any
	id: string
	handleSubmit: any
	onValuesChange: any
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const { CATEGORY } = useSelector((state: RootState) => state);

	useEffect(() => {
		dispatch(getCategorytList());
	}, []);

	useEffect(() => {
		if (editValues) {
			dispatch(setCategoryList([{id:editValues?.raw_material_type_id,category_name:editValues?.category_name}]))
			form.setFieldsValue({
				raw_material_type_id: editValues.raw_material_type_id,
				name: editValues.name,
				expected_value: editValues.expected_value,
				code: editValues.code
			});
		}
	}, [editValues, form])

	const onFinish = (data: any) => {
		handleSubmit(data);
	};
	
	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={25}>
					<InputBox.Text
						label="Name"
						name="name"
						rules={rules.dynamicFields()}
						onChange={onValuesChange}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Text
						label="Expected Value"
						name="expected_value"
						rules={rules.optionalFields}
						onChange={onValuesChange}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Select
						className="select textField"
						label="Category"
						name="raw_material_type_id"
						options={{
							list: CATEGORY.categoryList,
							valueKey: "id",
							textKey: "category_name",
						}}
						disabled={editValues}
						rules={rules.selectFields()}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Text
						label="Code"
						name="code"
						rules={rules.optionalFields}
						onChange={onValuesChange}
					/>
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;