import { ColDef } from 'ag-grid-community'
import {
	idColumn,
} from '../../../../../utils/commonColumns'
import { ValueFormatterParams } from 'ag-grid-community';

const roasterColumn: ColDef = {
	field: 'roster',
	headerName: 'Roaster',
	filter: "agSetColumnFilter",
	filterParams: {
		values: [1, 2],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Day" && value === 1) || (filter === "Night" && value === 2)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Day" : params.value === 2 ? "Night" : params.value;
		},
	},
	tooltipValueGetter: (params: any) => {
		if (params.data.roster == 1) {
			return "Day"
		} else {
			return "Night"
		}
	},
	cellRenderer: (props: any) => {
		if (props.data.roster == 1) {
			return "Day"
		} else {
			return "Night"
		}
	}
}
const timeColumn: ColDef = {
	field: 'time',
	headerName: 'Time',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.time,
}
const nameOfEngineerColumn: ColDef = {
	field: 'name_of_engineer',
	headerName: 'Name Of Engineer',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name_of_engineer,
}
const mobNumberColumn: ColDef = {
	field: 'mobile_number',
	headerName: 'Mobile Number',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.mobile_number,
}

const columnDefs: ColDef[] = [
	idColumn,
	roasterColumn,
	timeColumn,
	nameOfEngineerColumn,
	mobNumberColumn
]
export default columnDefs
