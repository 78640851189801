import React from "react";
import { Layout } from "antd";
import moment from "moment";

const FooterView: React.FC = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
    <div>
    © {moment().format("Y")} <span>{process.env.REACT_APP_APP_NAME ?? ""}</span> (All rights reserved)

    </div>
    {/* <div>
      <Link to={"/"}>Terms of Use</Link> | <Link to={"/"}>Privacy Policy</Link>
    </div> */}
    </Layout.Footer>
  );
};

export default FooterView;
