import React from "react";
import { Button, ModalProps } from "antd";
import CommonModal from "./CommonModal";

interface DeleteModalProps extends ModalProps {
  title?: string;
  close: any;
  open?:any
  message?:any;
}

const WarningModal: React.FC<DeleteModalProps> = ({
  title,
  close,
  open,
  message
}) => {

  return  (
    <CommonModal
    className="warningModal"
    title={`Delete ${title}`}
    open={open}
    onCancel={close}
    footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
            Close
        </Button>,]}>
    <div className="deleteNote">
    {message?message:"It cannot be deleted as it is currently in use"}
    </div>
</CommonModal>
  ) ;
};
export default WarningModal;
