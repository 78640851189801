import { Button, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AppDispatch } from "../../../../../../store/app";
import { useDispatch } from "react-redux";
import { createRecord, getDynamicReinforcement } from "../../../utils/slice";
import { assignErrorToInput } from "../../../../../../store/api";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import {
  dataToFormDataConverter,
  validateFields,
} from "../../../../../../config/Global";
import DynamicField from "./DynamicField";

interface ComponentProps {
  form: any;
  dynamicReinforcement: any;
  onChange: any;
  handleSubmit: any;
}

const FirstStep: React.FC<ComponentProps> = ({
  dynamicReinforcement,
}: ComponentProps) => {
  const navigate = useNavigate();
  const { mappingId, structureId, mName } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getDynamicReinforcement(mappingId));
  }, [getDynamicReinforcement, mappingId]);

  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const pro_id: any = localStorage.getItem("projectId");
  const [formGroups, setFormGroups] = useState<any[]>([]);

  useEffect(() => {
    form.resetFields();
    if (dynamicReinforcement?.data) {
      const groups: any = {};
      dynamicReinforcement.data.forEach((item: any) => {
        if (!groups[item.qa_params_group_id]) {
          groups[item.qa_params_group_id] = [];
        }
        groups[item.qa_params_group_id].push(item);
      });
      setFormGroups(groups);
    }

    if (dynamicReinforcement?.reinforcement_data) {
      dynamicReinforcement.reinforcement_data.filter((val: any, index: any) => {
        if (
          dynamicReinforcement.data[index].input_type == "Date Picker" ||
          dynamicReinforcement.data[index].input_type == "Date"
        ) {
          form.setFieldValue(
            [`${val.qa_params_id}`, "value"],
            dayjs(val?.input_value)
          );
        } else {
          form.setFieldValue(
            [`${val.qa_params_id}`, "value"],
            val?.input_value
          );
        }

        form.setFieldValue(
          [`${val.qa_params_id}`, "remark"],
          val?.remark_notes
        );
        const list = val?.project_document?.map(
          (image: { id: any; document: any }) => {
            return {
              // uid: image.id,
              name: image?.document,
              status: "done",
              url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
              thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
            };
          }
        );
        form.setFieldValue([`${val.qa_params_id}`, val.qa_params_id], list);
      });
    }
  }, [dynamicReinforcement]);

  const handleCancel = () => {
    navigate(`/projects/${structureId}/${mName}`);
  };

  //format date
  const formatDate = (date: any) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  // Function to traverse the nested data structure and format dates
  const formatDatesInData = (data: any) => {
    for (const key in data) {
      if (typeof data[key] === "object" && data[key] !== null) {
        if (data[key].value && data[key].value.$isDayjsObject) {
          data[key].value = formatDate(data[key].value);
        } else {
          formatDatesInData(data[key]);
        }
      }
    }
  };

  const onFinish = (data: any) => {
    formatDatesInData(data);
    const payload = {
      project_id: parseInt(pro_id ?? ""),
      qa_category_id: 10,
      data: data,
      qa_params_value_mapping_id: parseInt(mappingId ?? ""),
    };

    const formData = dataToFormDataConverter(payload);
    setLoading(true);
    setDisable(true);
    dispatch(createRecord(payload.qa_params_value_mapping_id, formData))
      .then(() => {
        setLoading(false);
        setDisable(false);
        navigate(`/projects/${structureId}/${mName}`);
      })
      .catch((error: any) => {
        setLoading(false);
        setDisable(false);
        assignErrorToInput(form, error?.STATUS);
      });
  };

  const validateForm = () => {
    validateFields(form, setDisable);
  };

  return (
    dynamicReinforcement?.data && (
      <>
        <Form
          className="Concform"
          form={form}
          onFinish={onFinish}
          onValuesChange={validateForm}
        >
          {Object.entries(formGroups).map(
            ([groupId, groupItems]: [string, any[]]) => (
              <div key={groupId}>
                <div className="projectTitle">
                  <h2>
                    <span>{groupItems[0]?.qa_params_group_name}</span>
                  </h2>
                </div>
                <Row gutter={30}>
                  {groupItems.map((item: any, index: number) => (
                    <DynamicField
                      key={index}
                      item={item}
                      dynamicReinforcement={dynamicReinforcement}
                      form={form}
                      projectDocumentImages={item.project_document || []}
                    />
                  ))}
                </Row>
              </div>
            )
          )}
          <Row justify={"end"}>
            <Button
              htmlType="submit"
              className="secondaryBtn"
              disabled={disable}
              loading={loading}
            >
              Save
            </Button>
            <Button className="secondaryBtn" onClick={handleCancel}>
              Back
            </Button>
          </Row>
        </Form>
      </>
    )
  );
};

export default FirstStep;
