const baseUrl = '/v1/admin/department'

export const apiUrls = {
    list: `${baseUrl}/list`,
    create: `${baseUrl}/create`,
    update: (id: number): string => `${baseUrl}/${id}/edit`,
    delete: (id: number): string => `${baseUrl}/${id}/delete`,
    read: (id: number): string => `${baseUrl}/${id}/read`,
    //   changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
    //   dynamicFieldType:  `${baseUrl}/dynamic-field-type`,
    // departmentList: `${baseUrl}/department`,
    // departmentTypeList: (departmentId: number): string =>
    //     `${baseUrl}/${departmentId}/department-type`,
    //   dynamicFieldSave:  `${baseUrl}/dynamic-field-save`,
}
