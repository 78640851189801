import React, { useRef, useState } from 'react'
import { Button, Segmented } from 'antd'
import Steel from './Steel';
import { ReactComponent as ArrowIcon } from '../../../../../assets/images/icon/arrowIcon.svg';
import Cement from './Cement';
import CoarseAggregate from './CoarseAggregate';
import FineAggregates from './FineAggregate';
import ConcreteAdmixture from './ConcreteAdmixture';
import BindingWire from './Binding Wire';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../store/app';
import { setTab } from '../../utils/slice';
import PolymerSluryTab from './PolymerSluryTab';

const TabElements: React.FC<any> = ({ moduleInfo }) => {
	const dispatch: AppDispatch = useDispatch();
	const { RAWMATERIAL } = useSelector((state: RootState) => state);
	const [total, setTotal] = useState(0)
	const contentWrapper = useRef<HTMLDivElement | null>(null);
	const deptType: any = localStorage.getItem("department_id")
	const items = [

		{
			value: 0,
			label: (
				<span>
					Steel
				</span>
			)
		},
		{
			value: 1,
			label: (
				<span>
					Cement
				</span>
			)
		},
		{
			value: 2,
			label: (
				<span>
					Coarse Aggregate
				</span>
			)
		},
		{
			value: 3,
			label: (
				<span>
					Fine Aggregate
				</span>
			)
		},
		{
			value: 4,
			label: (
				<span>
					Concrete Admixture
				</span>
			)
		},
		{
			value: 5,
			label: (
				<span>
					Binding Wire
				</span>
			)
		},
		{
			value: 6,
			label: (
				<span>
					Polymer Slurry
				</span>
			)
		},
	]
	const sideScroll = (
		element: HTMLDivElement,
		speed: number,
		distance: number,
		step: number
	) => {
		let scrollAmount = 0;
		const slideTimer = setInterval(() => {
			element.scrollLeft += step;
			scrollAmount += Math.abs(step);
			if (scrollAmount >= distance) {
				clearInterval(slideTimer);
			}
		}, speed);
	};
	// do not remove this code 
	// start
	// const handlePrev = () => {
	//     const newTab = (Number(RAWMATERIAL?.tab) - 1 + items.length) % items.length;
	//     dispatch(setTab(newTab));
	//     if (contentWrapper.current) {
	//         sideScroll(contentWrapper.current, 20, 70, -10);
	//     }
	// };

	// const handleNext = () => {
	//     const newTab = (Number(RAWMATERIAL?.tab) + 1) % items.length;
	//     dispatch(setTab(newTab));

	//     if (contentWrapper.current) {
	//         sideScroll(contentWrapper.current, 20, 70, 10);
	//     }
	// };
	// end
	const handlePrev = () => {
		dispatch(setTab((Number(RAWMATERIAL?.tab) - 1)))
		if (contentWrapper.current) {
			sideScroll(contentWrapper.current, 20, 70, -10);
		}

	}
	const handleNext = () => {
		setTotal((prev: any) => Number(prev))
		if (items.length === total + 1) {
			dispatch(setTab((Number(0))))
		}
		else {
			dispatch(setTab((Number(RAWMATERIAL?.tab) + 1)))
		}
		if (contentWrapper.current) {
			sideScroll(contentWrapper.current, 20, 70, 10);
		}

	}
	// do not remove this code 
	// start
	// const handleScroll = () => {
	//     if (contentWrapper.current) {
	//         const scrollLeft = contentWrapper.current.scrollLeft;
	//         const tabWidth = contentWrapper.current.offsetWidth / items.length;
	//         const newTab = Math.floor(scrollLeft / tabWidth);
	//         dispatch(setTab(newTab));
	//     }
	// };
	// useEffect(() => {
	//     if (contentWrapper.current) {
	//         contentWrapper.current.addEventListener('scroll', handleScroll);
	//     }

	//     return () => {
	//         if (contentWrapper.current) {
	//             contentWrapper.current.removeEventListener('scroll', handleScroll);
	//         }
	//     };
	// }, []);
	// end

	return (
		<>
			<div className="headerTabs">
					<Button onClick={handlePrev} className='leftArrow' disabled={RAWMATERIAL?.tab.toString() == "0" ? true : false}><ArrowIcon /></Button>
					<Segmented
						options={items.filter((item) => !(deptType == 5 && (item.value === 5 || item.value === 6)))}
						value={RAWMATERIAL?.tab}
						onChange={(tab: any) => {
							dispatch(setTab(tab))
						}} ref={contentWrapper} />
					<Button onClick={handleNext} className='rightArrow' disabled={RAWMATERIAL?.tab.toString() == "6" ? true : false}><ArrowIcon /></Button>
				</div>
			<div className='tabElementWrapper'>
			
			
					{
						RAWMATERIAL?.tab == 0 && (
							<Steel permissionPrefix={moduleInfo} />
						)
					}
					{
						RAWMATERIAL?.tab == 1 && (
							<Cement permissionPrefix={moduleInfo} />

						)
					}
					{
						RAWMATERIAL?.tab == 2 && (
							<CoarseAggregate permissionPrefix={moduleInfo} />
						)
					}
					{
						RAWMATERIAL?.tab == 3 && (
							<FineAggregates permissionPrefix={moduleInfo} />
						)
					}
					{
						RAWMATERIAL?.tab == 4 && (
							<ConcreteAdmixture permissionPrefix={moduleInfo} />
						)
					}
					{
						RAWMATERIAL?.tab == 5 && (
							<BindingWire permissionPrefix={moduleInfo} />
						)
					}
					{
						RAWMATERIAL?.tab == 6 && (
							<PolymerSluryTab permissionPrefix={moduleInfo} />
						)
					}
				</div>

		</>

	)
}

export default TabElements;
