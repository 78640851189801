const baseUrl = "/v1/admin/reinforcement";

export const apiUrls = {
  list: `${baseUrl}`,
  // reinforcementData: `${baseUrl}/reinforcement/data`,
  reinforcementData: (id: number): string => `${baseUrl}/${id}/data`,
  create: (id: number): string => `${baseUrl}/${id}/create`,
  update: (id: number): string => `${baseUrl}/${id}/update`,
  delete: (id: number): string => `${baseUrl}/${id}/delete`,
  details: (id: number): string => `${baseUrl}/${id}/details`,
  bulkUpdate: `${baseUrl}/bulk-update`,
  changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
};
