import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Form, InputNumber, Row } from "antd";
import { InputBox } from "../../../../components/AntdAddons";
import { createRecord, qacategory } from "../utils/slice";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { rules } from "./rules";
import { validateFields } from "../../../../utils/commonFunctions";
import { CONSTANT } from "../../../../config/Constant";
import dayjs from "dayjs";
import { blockInvalidChar } from "../../../../config/Global";

interface AddProps {
	close: () => void;
	visible?: boolean;
}

const StructureElement: React.FC<AddProps> = ({ visible, close }) => {
	const dispatch: AppDispatch = useDispatch();
	const STRUCTURE = useSelector((state: RootState) => state.STRUCTURE);
	const [totalNoValue, setTotalNoValue] = useState<number>(0);
	const [submittedNo, setSubmittedNo] = useState<number>(0);
	const [approvedNo, setApprovedNo] = useState<number>(0);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [saving, setSaving] = useState(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const dep_id: any = localStorage.getItem("department_id");
	const handleStartDateChange = (date: any) => {
		setStartDate(date);
	};

	const handleEndDateChange = (date: any) => {
		setEndDate(date);
	};

	const handleClose = () => {
		form.resetFields();
		close();
		setTotalNoValue(0);
		setSubmittedNo(0);
		setApprovedNo(0);
	};
	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const validateMax = (maxValue: number) => (_: any, value: number) => {
		if (value > maxValue) {
			return Promise.reject(
				`The value cannot be greater than Total Nos. ${maxValue}`
			);
		}
		return Promise.resolve();
	};

	const validateMax1 = (maxValue: number) => (_: any, value: number) => {
		if (value > maxValue) {
			return Promise.reject(
				`The value cannot be greater than Submitted No. ${maxValue}`
			);
		}
		return Promise.resolve();
	};

	useEffect(() => {
		form.setFieldsValue({
			approval_pending_by_state_rmb_design_circle_no:
				submittedNo && approvedNo ? submittedNo - approvedNo : "",
			submission_pending_by_design_consultant_no:
				totalNoValue && submittedNo ? totalNoValue - submittedNo : "",
		});
	}, [submittedNo, approvedNo, totalNoValue]);

	const handleSave = async (data: any) => {
		setSaving(true);
		try {
			await form.validateFields();
			dispatch(
				createRecord({
					...data,
					project_id: STRUCTURE.projectId,
					category_start_date: dayjs(startDate)?.format("YYYY-MM-DD"),
					category_end_date: dayjs(endDate)?.format("YYYY-MM-DD"),
				})
			)
				.then(() => {
					setSaving(false);
					handleClose();
				})
				.catch(() => {
					setSaving(false);
					// Handle error
				});
		} catch (error) {
			setSaving(false);
			console.error("Error submitting data:", error);
		}
	};

	return (
		<>
			<Drawer
				title="Add Structural Element"
				placement="right"
				open={visible}
				footer={[
					<div key={1}>
						<Button
							className="secondaryBtn"
							htmlType="submit"
							form="UserAdd"
							disabled={disabled}
							loading={saving}
						>
							Save
						</Button>
						<Button className="cancelBtn" onClick={handleClose}>
							Cancel
						</Button>
					</div>,
				]}
				rootClassName="structureDrawer"
				width="50%"
				onClose={() => {
					handleClose();
				}}
			>
				<Form
					form={form}
					onFinish={handleSave}
					id="UserAdd"
					onValuesChange={validateForm}
				>
					<Row gutter={25}>
						<Col span={12}>
							<InputBox.Select
								required
								label="Elements"
								name="qa_categories_id"
								onFocus={() => {
									dispatch(qacategory(STRUCTURE.projectId));
								}}
								options={{
									list: STRUCTURE.categoryData?.map(
										(category: { id: any; name: any }) => ({
											id: category.id,
											name: category.name,
										})
									),
								}}
								rules={rules.selectFirstFields()}
							/>
						</Col>
						{
							dep_id != 5 ?
								<>
									<Col span={12}>
										<InputBox.Text
											type="number"
											label="Total Nos"
											name="total_no"
											rules={[
												...rules.dynamicNumbricField(),
												{
													validator: (rule, value) => {
														if (value.length < 1 || value.length > 10) {
															return Promise.reject("The input must be a minimum of 1 and a maximum of 10.");
														}
														if (value > 0) {
															return Promise.resolve();
														}
														return Promise.reject("Value must be greater than 0");
													},
												},
											]}
											onChange={(e) => setTotalNoValue(Number(e.target.value))}
											onKeyDown={blockInvalidChar}
										/>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Submitted in State R&B Design circle (No.)"
											name="submitted_in_state_rmb_design_circle_no"
											rules={[
												{ validator: validateMax(totalNoValue) },
												...rules.dynamicNumbricField1(),
											]}
										>
											<InputNumber
												onChange={(value) => setSubmittedNo(Number(value))}
												onKeyDown={(event) => {
													const isNumericKey = /^[0-9]+$/.test(event.key);
													const isAllowedKey =
														event.key === "Backspace" ||
														event.key === "Tab" ||
														event.key === "Delete";

													if (!isNumericKey && !isAllowedKey) {
														event.preventDefault();
													}
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Approved by State R&B Design circle (No.)"
											name="approved_by_state_rmb_design_circle_no"
											rules={[
												{ validator: validateMax1(submittedNo) },
												...rules.dynamicNumbricField1(),
											]}
										>
											<InputNumber
												onChange={(value) => setApprovedNo(Number(value))}
												onKeyDown={(event) => {
													const isNumericKey = /^[0-9]+$/.test(event.key);
													const isAllowedKey =
														event.key === "Backspace" ||
														event.key === "Tab" ||
														event.key === "Delete";

													if (!isNumericKey && !isAllowedKey) {
														event.preventDefault();
													}
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<InputBox.Text
											disabled
											label="Approval Pending by State R&B Design Circle (No.)"
											name="approval_pending_by_state_rmb_design_circle_no"
										/>
									</Col>
									<Col span={12}>
										<InputBox.Text
											disabled
											label="Submission Pending by State R&B Design Consultant (No.)"
											name="submission_pending_by_design_consultant_no"
										/>
									</Col>
								</>
								: ''}
						<Col span={12}>
							<Form.Item
								className="dateInStructure"
								// label="Start Date:"
								name="startDate"
								// style={{ marginTop: "30px" }}
								rules={[
									{
										required: true,
										message: "Please select start date.",
									},
								]}
							>
								<InputBox.DatePicker
									label="Start Date:"
									onChange={handleStartDateChange}
									format={CONSTANT.DATE_FORMAT}
									className="labelHidden"
									placeholder="Start Date"
									required
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								// label="End Date:"
								//style={{ marginTop: "30px" }}""
								className="dateInStructure"
								name="endDate"
								rules={[
									{
										required: true,
										message: "Please select end date.",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												dayjs(getFieldValue("startDate")).format(
													"YYYY-MM-DD"
												) <= dayjs(value).format("YYYY-MM-DD")
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												"End date must be equal to or after start date."
											);
										},
									}),
								]}
							>
								<InputBox.DatePicker
									label="End Date:"
									onChange={handleEndDateChange}
									format={CONSTANT.DATE_FORMAT}
									className="labelHidden endDateStructure"
									placeholder="End Date"
									required
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Drawer>
		</>
	);
};

export default StructureElement;
