import { ColDef } from 'ag-grid-community'
import { idColumn } from '../../../../../utils/commonColumns'
// import { createdAtColumn, idColumn } from '../../../../../../utils/commonColumns';
// import { CONSTANT } from '../../../../../../config/Constant';


const NameOfAgencyColumn: ColDef = {
    field: 'name_of_agency',
    headerName: 'Name Of Agency',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.name_of_agency,
}
const LocationColumn: ColDef = {
    field: 'location_of_plant',
    headerName: 'Location Of Plant',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.location_of_plant,
}
const VehicleNumberColumn: ColDef = {
    field: 'vehicle_number',
    headerName: 'Vehicle Number',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.vehicle_number,
}
const WorkCompletedColumn: ColDef = {
    field: 'work_completed_on',
    headerName: 'Work Completed On',
    filter: 'agTextColumnFilter',
    cellRenderer: (props: any) => {
		if (props.data.work_completed_on == 1) {
			return "Paver"
		} else if (props.data.work_completed_on == 2) {
			return "Pacth"
		}
	}
}
const NetWeightColumn: ColDef = {
    field: 'net_weight',
    headerName: 'Net Weight (MT)',
    filter: 'agTextColumnFilter',
    tooltipValueGetter: (params: any) => params.data.net_weight,
}


const columnDefs: ColDef[] = [
   idColumn,
   NameOfAgencyColumn,
   LocationColumn,
   VehicleNumberColumn,
   WorkCompletedColumn,
   NetWeightColumn
]
export default columnDefs
