import React from "react";
import ViewButton from "./Buttons/ViewButton";
import EditButton from "./Buttons/EditButton";
import DeleteButton from "./Buttons/DeleteButton";
import { ButtonProps } from "../Buttons/interface/ButtonInterface";
import LockButton from "./Buttons/LockButton";
// import { useSelector } from "react-redux";
// import { RootState } from "../../store/app";
// import StructureButton from "./Buttons/ProjectDashboardButton";
import ProjectDashboardButton from "./Buttons/ProjectDashboardButton";
import ReportButton from "./Buttons/ReportButton";

interface ActionButtonsProps {
  data: any;
  view?: ButtonProps;
  edit?: ButtonProps;
  reportButton?: ButtonProps;
  deleteButton?: ButtonProps;
  dashboardButton?: ButtonProps;
  projectDashboardButton?: ButtonProps;
  lock?: ButtonProps;
  structureButton?: ButtonProps;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  data,
  lock,
  view,
  deleteButton,
  projectDashboardButton,
  reportButton,
  edit,
}) => {
  // const Auth = useSelector((state: RootState) => state.AUTH);

  return (
    <div className="action-column">
      {lock?.action && (
        <LockButton
          data={data?.data}
          permission={true} //checkPrivileges(Auth.userDetail, lock.permissionKey)
          {...lock}
        />
      )}
      {view?.action && (
        <ViewButton
          data={data?.data}
          permission={true} //checkPrivileges(Auth.userDetail, view.permissionKey)
          {...view}
        />
      )}
      {edit?.action && (
        <EditButton
          data={data?.data}
          permission={true} //checkPrivileges(Auth.userDetail, edit.permissionKey)
          {...edit}
        />
      )}
      {deleteButton?.action && (
        <DeleteButton
          data={data?.data}
          permission={true} // checkPrivileges(Auth.userDetail,deleteButton.permissionKey)
          {...deleteButton}
        />
      )}
      {projectDashboardButton?.action && (
        // <StructureButton
        //   data={data?.data}
        //   permission={true} //checkPrivileges(Auth.userDetail, view.permissionKey)
        //   {...dashboardButton}
        // />
        <ProjectDashboardButton
          data={data?.data}
          permission={true} //checkPrivileges(Auth.userDetail, view.permissionKey)
          {...projectDashboardButton}
        />
      )}
            {reportButton?.action && (
      
        <ReportButton
          data={data?.data}
          permission={true} //checkPrivileges(Auth.userDetail, view.permissionKey)
          {... reportButton}
        />
      )}
    </div>
  );
};

export default ActionButtons;
