const baseUrl = '/v1/admin/project'
const baseUrl1 = '/v1/admin/qa-params'
export const apiUrls = {
    categoriesList: (id: number): string => `${baseUrl}/${id}/deptwise-qa-category`,
    list: `${baseUrl1}/list`,
    create: `${baseUrl1}/create`,
    groupList: `/v1/admin/qaparamsgroups/list`,
    inputList: `/v1/admin/qa-params/select-type-list`,
    read: (id: number): string => `${baseUrl1}/${id}/read`,
    update: (id: number): string => `${baseUrl1}/${id}/edit`,
    delete: (id: number): string => `${baseUrl}/${id}/delete`,
    details: (id: number): string => `${baseUrl}/${id}/details`,
    sequence:`${baseUrl1}/change-sequence-list`,
    changeSequence:`${baseUrl1}/change-sequence-edit`,
    changeStatus: `${baseUrl1}/change-status`
}