import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
//import Config from "../../../../config/Config";

interface InitialState {
	isLoading: boolean;
	rowData: any;
	pageNumber:number;
	searchInput:string;
	perPage:number;
	tab : any
}

const initialState = {
	isLoading: false,
	rowData: null,
	pageNumber:1,
	searchInput:'',
	perPage:10,
	tab : 0
} as InitialState;

const createProjectDashboard = createSlice({
	name: "CREATEDASHBOARDPROJECT",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setSearch: (state, action: PayloadAction<any>) => {
			state.searchInput = action?.payload;
		},
		setPageNumber:(state, action: PayloadAction<any>) => {
			state.pageNumber = action?.payload;
		},
		setPerPage:(state, action: PayloadAction<any>) => {
			state.perPage = action?.payload;
		},
		setTab:(state, action: PayloadAction<any>) => {
			state.tab = action?.payload;
		},
		// for project managment 
		// setProjectList: (state, action: PayloadAction<any>) => {
		// 	state.rowData = action?.payload;
		// }
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setSearch,
	setPageNumber,
	setPerPage,
	setTab
	// setProjectList,
} = createProjectDashboard.actions;

/** For Listing:Start */
export const fetchList = (payload: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.list,payload);
		// dispatch(setRowData(response.data.recordData.Project_details));
		// dispatch(setProjectList(response.data.recordData.Project_details))
		dispatch(setRowData(response.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const deleteRecord =
  (id: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.post(apiUrls.delete, { "id" :id,"is_delete" : "true"});
      dispatch(success(response.data));

	//   const payload = {
	// 	deptId: getState().CREATEDASHBOARDPROJECT.tab+1,
	// 	status: 0,
	// 	search: getState().CREATEDASHBOARDPROJECT.searchInput,
	// 	page: getState().CREATEDASHBOARDPROJECT.pageNumber,
	// 	perPage: getState().CREATEDASHBOARDPROJECT.perPage
	// }
	//   dispatch(fetchList(payload))
      return Promise.resolve(response.data);
    } catch (error: any) {
      dispatch(failure(error.data));
      return Promise.reject(error.data);
    }
};

export const fetchPlanningList = (payload: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.planningList,payload);
		// dispatch(setRowData(response.data.recordData.Project_details));
		// dispatch(setProjectList(response.data.recordData.Project_details))
		dispatch(setRowData(response.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

const CreateProjectDashboardSliceReducer = createProjectDashboard.reducer;
export default CreateProjectDashboardSliceReducer;
