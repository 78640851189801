import React from "react";
import { Form, DrawerProps, Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	setCementData,
	updateRecord,
} from "../../utils/slice";
import { AppDispatch, RootState } from "../../../../../store/app";
import { validateFields } from "../../../../../config/Global";
import FormComponent from "./FormComponent";
import { assignErrorToInput } from "../../../../../store/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface EditDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean;
	close: () => void;
}

const EditComponent: React.FC<EditDrawerProps> = ({
	titleName,
	open,
	close,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const { read } = useSelector((state: RootState) => state.CEMENTBRAND);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [saving, setSaving] = React.useState<boolean>(false);

	const drawerClose = () => {
		dispatch(setCementData(null));
		setDisabled(true)
		close();
		form.resetFields();
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleSubmit = (data: any) => {
		setSaving(true);
		setDisabled(true)
		dispatch(updateRecord(read.id, data))
			.then(() => {
				drawerClose();
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false), setDisabled(false));
	};

	return (
		<Modal
			centered
			open={open}
			className="commanModal"
			title={`Edit ${titleName}`}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={drawerClose}
			destroyOnClose
			footer={
				<>
					<div className="signBtn">
						<Button
							htmlType="submit"
							className="secondaryBtn"
							disabled={disabled}
							form="editForm"
							loading={saving}
						>
							Submit
						</Button>
						<Button onClick={drawerClose} className="cancelBtn">
							Cancel
						</Button>
					</div>
				</>
			}
		>
			<FormComponent
				form={form}
				id="editForm"
				handleSubmit={handleSubmit}
				onValuesChange={validateForm}
				editValues={read}
			/>
		</Modal>
	);
};

export default EditComponent;
