import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { apiUrls } from "../utils/apiUrls";

interface InitialState {
	isLoading: boolean;
	rowData: any;
}

const initialState = {
	isLoading: false,
	rowData: null,
} as InitialState;

const Progress = createSlice({
	name: "PROGRESS",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
} = Progress.actions;

/** For Listing:Start */
export const fetchList = (payload: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.list,payload);
		dispatch(setRowData(response.data.recordData));
		dispatch(success());
		return Promise.resolve(response.data.recordData);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

const ProgressSliceReducer = Progress.reducer;
export default ProgressSliceReducer;
