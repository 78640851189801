import { Card, Col, Divider, Row } from 'antd';
import React, { useEffect } from 'react';
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import ToolBar from '../../../components/ToolBar';
import ContentBox from '../../../components/ContentBox/ContentBox';
import { ProgressBreadcrumb } from '../../../config/BreadcrumbConfig';
import { fetchList, setRowData } from './utils/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/app';
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes';
import { checkPrivileges } from '../../../config/Global';
import PageSpinner from '../../../components/PageSpinner/PageSpinner';
import dayjs from 'dayjs';
import { CONSTANT } from '../../../config/Constant';
import { InputBox } from '../../../components/AntdAddons';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);
const Progress: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { PROGRESS, AUTH } = useSelector((state: RootState) => state);
	const [saving, setSaving] = React.useState<boolean>(false);
	const pro_id = localStorage.getItem("projectId")
	const project_start_date: any = localStorage.getItem("start_date")

	useEffect(() => {
		dispatch(setRowData(null))
		const payload = {
			// actual_start_date
			projectId: pro_id,
			end_date: dayjs().format(CONSTANT.POST_DATE_FORMAT)
		}
		dispatch(fetchList(payload))
	}, []);

	const options = {
		indexAxis: "y" as const,
		elements: {
			bar: {
				borderRadius: 10,
				borderWidth: 4,
				barPercentage: 30,
			},
		},
		responsive: true,
		maintainAspectRatio: false,


		tooltips: {
			enabled: false
		},

		plugins: {
			legend: {
				position: "bottom" as const,
				display: false
			}
		},
		scales: {
			x: {
				title: {
					display: false,
					grid: {
						display: false,
					},
				},
			},
			y: {
				display: true,
				grid: {
					display: false,
				},
				title: {
					display: false,
				},
			},
		},
	};
	const progressData: any = []
	if (PROGRESS?.rowData) {

		Object.keys(PROGRESS?.rowData).forEach(function (key) {
			progressData.push(PROGRESS?.rowData[key]);
		});
	}

	useEffect(() => {
		PROGRESS?.rowData !== null ? setSaving(false) : setSaving(true);
	}, []);

	const handleChartChange = (date: any) => {
		const payload = {
			projectId: pro_id,
			end_date: date.format(CONSTANT.POST_DATE_FORMAT)
		}
		dispatch(fetchList(payload))
	}

	useEffect(() => {
		//
	}, [PROGRESS.rowData])

	const disabledDate = (current: any) => {
		const currentDate = dayjs(current).valueOf();
		return !(currentDate >= dayjs(project_start_date).valueOf() && currentDate <= dayjs().valueOf());
	};

	return (
		<div className='progressWrap'>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) &&
					<><ToolBar breadcrumbs={ProgressBreadcrumb()}>
						<div className="progressBtnWrap">
							<InputBox.DatePicker defaultValue={dayjs()} name="end_date"
								onChange={(date) => handleChartChange(date)} format={CONSTANT.DATE_FORMAT}
								disabledDate={disabledDate}
							/>
						</div>
					</ToolBar>{
							saving == false && PROGRESS.rowData == null ? (
								<PageSpinner />
							) : <ContentBox>
								{
									progressData?.length > 0 ? <div className='progressChartLayout'>
										<Row>
											{progressData?.map((item: any, index: number) => {
												const completedPercentage = item?.perChart?.currentWork
												const goalPercentage = item?.perChart?.expected
												const totalPercentage = item?.perChart?.goal
												const reachedTotal = completedPercentage === totalPercentage && goalPercentage === totalPercentage;
												return (<Col key={index} span={24}>
													<Card className='chartCard'>
														<div className="cardHeader">
															<h4>{item?.chart?.datasets[0]['label']}</h4>
														</div>
														{
															<>
																<div className='progressBarTotal' key={index}>
																	<div className='startDateProgress'>
																	</div>
																	<div className='completed' style={{ width: `${completedPercentage}%` }}>
																		<div className='completedLine'>
																			{reachedTotal ? "" : <span className='completedNumber'>
																				{completedPercentage}%</span>}

																		</div>
																	</div>
																	<div className='goalProgress' style={{ width: `${goalPercentage}%` }}>
																		<div className='goalProgressLine'>
																			{reachedTotal ? "" :
																				<span className='goalProgressNumber'>{goalPercentage}%</span>
																			}
																		</div>
																	</div>
																	<div className='totalWorkProgress'>
																		<div className='totalWorkCount'>
																			<span className='endDate'></span>

																		</div>
																	</div>
																</div>
																<div className='progressInfo'>
																	<div className='completedDateInfo'>
																		<span className='date'>Start Date:</span>
																		<span className='dataInfo'>{dayjs(item?.perChart?.start_date).format(CONSTANT.DATE_FORMAT)}</span>
																	</div>
																	{reachedTotal ? '' :
																		<div className='completedInfo'>
																			<span className='complatedCircle circle' style={{ backgroundColor: "rgb(239 68 68)" }}></span>
																			<span className='dataInfo'>Completed</span>
																		</div>
																	}
																	<div className='completedInfo'>
																		<span className='complatedCircle circle' style={{ backgroundColor: "rgb(255 153 0)" }}></span>
																		<span className='dataInfo'>Goal</span>
																	</div>
																	<div className='completedInfo'>
																		<span className='complatedCircle circle' style={{ backgroundColor: "rgb(35 209 93)" }}></span>
																		<span className='dataInfo'>{reachedTotal ? 'Total & Completed' : 'Total'} ({totalPercentage}%)</span>
																	</div>

																	<div className='completedDateInfo'>
																		<span className='date'>End Date:</span>
																		<span className='dataInfo'>{dayjs(item?.perChart?.end_date).format(CONSTANT.DATE_FORMAT)}</span>
																	</div>
																</div>
															</>
														}
														<Divider />
														<div className="cardContent">
															<div id="graphContainer">
																<Bar
																	data={item?.chart}
																	options={options}
																	style={{ width: "50%", height: "50%" }}
																/>
															</div>
														</div>
													</Card>
												</Col>)
											})}
										</Row>
									</div> : <div className='noDataProgress'>
										<h3>No data</h3>
									</div>
								}
							</ContentBox>
						}
					</>
			}
		</div>
	)
}

export default Progress;
