import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { apiUrls } from "./apiUrls";

interface InitialState {
  isLoading: boolean;
  rowData: any;
  read: any;
  dynamicPolymerSlurrydata: any;
  viewData: any;
}

const initialState = {
  isLoading: false,
  rowData: null,
  read: null,
  dynamicPolymerSlurrydata: null,
  viewData: null,
} as InitialState;

const PolymerSlurrySlice = createSlice({
  name: "POLYMERSLURRY",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
    },
    failure: (state) => {
      state.isLoading = false;
    },
    setDynamicPolymerSlurryData: (state, action: PayloadAction<any>) => {
      state.dynamicPolymerSlurrydata = action?.payload;
    },
  },
});

export const {
  start,
  success,
  failure,
  setDynamicPolymerSlurryData,
} = PolymerSlurrySlice.actions;


export const getDynamicPolymerSlurryData =
  (mappingId: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.post(apiUrls.PolymerSlurryData(mappingId));
      dispatch(setDynamicPolymerSlurryData(response.data));
      dispatch(success());
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.reject(error.data);
    }
  };

export const createRecord =
  (id: number, action: any): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.post(apiUrls.create(id), action);
      dispatch(success(response.data));
      // dispatch(fetchList())
      return Promise.resolve(response.data);
    } catch (error: any) {
      dispatch(failure(error.data));
      return Promise.reject(error.data);
    }
  };

const PolymerSlurrySliceReducer = PolymerSlurrySlice.reducer;
export default PolymerSlurrySliceReducer;
