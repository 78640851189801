import React from "react";
import { Col } from "antd";
// import { useState } from "react";
import { InputBox } from "../../../../../../components/AntdAddons";
import { rules } from "../rules";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { setCategory, setCategory1 } from "../../../utils/slice";

const DynamicField: React.FC<any> = ({
  item,
  dynamicConcreat,
  form,
  categoryData,
}) => {
  //   const [radioSet, setRadioSet] = useState<any>("2");
  const parseData = JSON.parse(item.data) ?? null;
  const dispatch: AppDispatch = useDispatch();
  const CONCRETING = useSelector((state: RootState) => state.CONCRETING);

  const handleSelectChange = (value: string) => {
    const categoryDatas = categoryData.find(
      (category: { id: any; name: any }) =>
        category.id === value
    );
    dispatch(setCategory(categoryDatas));
    if (CONCRETING?.categoryData1 !== undefined) {
      if (
        parseFloat(CONCRETING?.categoryData1) <
          parseFloat(categoryDatas?.min_slump_mm) ||
        parseFloat(CONCRETING?.categoryData1) >
          parseFloat(categoryDatas?.max_slump_mm)
      ) {
        form.setFieldValue([`34`, "remark"], null);
        form.setFields([
          {
            name: [`34`, "remark"],
            errors: ["It is required."],
          },
        ]);
      }
    }
  };

  const validateValueLessThanMatchingCategory = (
    rule: any,
    value: any,
    callback: any
  ) => {
    if (value !== undefined && parseFloat(value)) {
      dispatch(setCategory1(value));
    }
    if (
      value !== undefined &&
      parseFloat(value) >= parseFloat(CONCRETING?.categoryData?.min_slump_mm) &&
      parseFloat(value) <= parseFloat(CONCRETING?.categoryData?.max_slump_mm)
    ) {
      callback();
    } else {
      if (CONCRETING?.categoryData?.min_slump_mm !== undefined) {
        if(categoryData.length > 0 && form.getFieldValue(27)){
          callback(
            `Value must be grater than ${CONCRETING?.categoryData?.min_slump_mm} and less than ${CONCRETING?.categoryData?.max_slump_mm}`
          );
        }else{
          callback();
        }
      } else {
        callback();
      }
    }
  };
  function combineTextAreaRules() {
    const numericRules = rules.numericFields();
    const customRule = {
      validator: validateValueLessThanMatchingCategory,
    };

    return [...numericRules, customRule];
  }

  return (
    <Col span={12} className="commanCol">
      <div className="commanQue">
        {item.input_type === "Radio Button" && (
          <InputBox.Radio
            className="radioGap"
            label={`${item.params_label_full_text}`}
            name={[`${item.id}`, "value"]}
            rules={rules.dynamicFields()}
            options={{
              list: item.input_options ? JSON.parse(item.input_options) : [],
              valueKey: "id",
              textKey: "name",
            }}
            disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
          />
        )}

        {item.input_type === "Drop Down" && (
          <InputBox.Select
            label={`${item.params_label_full_text}`}
            name={[`${item.id}`, "value"]}
            options={{
              list: item.input_options ? JSON.parse(item.input_options) : [],
              valueKey: "id",
              textKey: "name",
            }}
            rules={rules.dynamicFields()}
            disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
          />
        )}

        {(item.input_type === "Date" || item.input_type === "Date Picker") && (
          <InputBox.DatePicker
            label={`${item.params_label_full_text}`}
            name={[`${item.id}`, "value"]}
            disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
          />
        )}

        {(item.input_type === "Text Box" || item.input_type === "TextBox") && (
          <InputBox.Text
            name={[`${item.id}`, "value"]}
            // className="textAreaResize"
            label={`${item.params_label_full_text}`}
            maxLength={200}
            rules={rules.dynamicFields()}
            disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
          />
        )}

        {item.params_key === "is_mix_design_approved" && (
          <InputBox.Select
            value={null}
            required
            name={[`${item.id}`, "remark"]}
            options={{
              list: categoryData.map(
                (category: { id: any; name: any }) => ({
                  id: category.id,
                  name: category.name,
                })
              ),
            }}
            onChange={handleSelectChange}
            rules={rules.selectFields()}
            disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
          />
        )}

        {parseData.remark_display === 1 &&
          item.params_key !== "is_mix_design_approved" &&
          item.params_key === "are_slump_values_of_concrete_checked?" && (
            <InputBox.TextArea
              rows={2}
              name={[`${item.id}`, "remark"]}
              rules={combineTextAreaRules()}
              disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
            />
          )}

        {parseData.remark_display == 1 &&
          item.params_key !== "is_mix_design_approved" &&
          item.params_key !== "are_slump_values_of_concrete_checked?" &&
          (parseData.type == "number" ? (
            <InputBox.Text
              name={[`${item.id}`, "remark"]}
              className="textAreaResize"
              rules={rules.numberFields}
              disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
            />
          ) : (
            item.params_key !== "are_slump_values_of_concrete_checked?" && (
              <InputBox.TextArea
                rows={2}
                name={[`${item.id}`, "remark"]}
                rules={rules.dynamicFields()}
                disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
              />
            )
          ))}

        {parseData.photo_required == 1 && (
          <div>
            <InputBox.Upload
              name={[`${item.id}`, "file"]}
              required={true}
              label="Upload Document"
              fileList={form.getFieldValue([`${item.id}`, item.id])}
              maxCount={5}
              disabled={dynamicConcreat?.concreting_data !== null && dynamicConcreat?.concreting_data.length > 0}
            />
          </div>
        )}
      </div>
    </Col>
  );
};

export default DynamicField;
