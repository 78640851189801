import React from "react";
import { ReactComponent as SyncBtn } from "../assets/images/icon/refresh.svg";
import { ReactComponent as EditBtn } from "../assets/images/icon/edit.svg";
import { ReactComponent as DeleteBtn } from "../assets/images/icon/deleteIcon.svg";
import { ReactComponent as Map } from "../assets/images/icon/map.svg";
import { ReactComponent as MapSolid } from "../assets/images/icon/map-solid.svg";
import { ReactComponent as Close } from "../assets/images/icon/close.svg";
import { ReactComponent as View } from "../assets/images/icon/view.svg";
import { ReactComponent as Filter } from "../assets/images/icon/filter.svg";
import { ReactComponent as DocExcel } from "../assets/images/icon/doc_exel.svg";
import { ReactComponent as PrintReport } from "../assets/images/icon/print.svg";
import { ReactComponent as PDF } from "../assets/images/icon/pdf.svg";
import { ReactComponent as RightArrrow } from "../assets/images/icon/right_arrow.svg";
import { ReactComponent as DocCSV } from "../assets/images/icon/CSV_file.svg";
import { ReactComponent as History } from "../assets/images/icon/history.svg";
import { ReactComponent as RightTick } from "../assets/images/icon/rightTick.svg";
import { ReactComponent as ChangePass } from "../assets/images/icon/change_password.svg";
import { ReactComponent as Watch } from "../assets/images/icon/watch.svg";
import { ReactComponent as FacebookSolid } from "../assets/images/icon/facebook.svg";
import { ReactComponent as TwitterSolid } from "../assets/images/icon/twitter.svg";
import { ReactComponent as Structure } from "../assets/images/icon/stucture.svg";
import { ReactComponent as QcIcon } from "../assets/images/icon/QC.svg";
import { ReactComponent as MenuOptionIcon } from "../assets/images/icon/menuOptionIcon.svg";
import { ReactComponent as ConcreatingIcon } from "../assets/images/icon/concreting.svg";
import { ReactComponent as CubeTestingIcon } from "../assets/images/icon/cubeTesting.svg";
import { ReactComponent as ReinforcementIcon } from "../assets/images/icon/reinforcment.svg";
import { ReactComponent as PolymerSlurryIcon } from "../assets/images/icon/polymerSlurry.svg";
interface IconProps {
  icon: string; //make the clear type to make switch
  color?: string;
  width?: number;
  height?:number;
}

const SVGIcon = ({ icon, color = "#696b71", width = 14 }: IconProps) => {
  // In this case you have to think about the switch and types in typescript.
  const Icons: any = {
    refresh: <SyncBtn fill={color} width={width} height={width} />,
    close: <Close fill={color} width={width} height={width} />,
    edit: <EditBtn fill={color} width={width} height={width} />,
    delete: <DeleteBtn fill={color} width={width} height={width} />,
    view: <View fill={color} width={width} height={width} />,
    map: <Map fill={color} width={width} height={width} />,
    mapSolid: <MapSolid fill={color} width={width} height={width} />,
    filter: <Filter fill={color} width={width} height={width} />,
    DocExcel: <DocExcel fill={color} width={width} height={width} />,
    DocCSV: <DocCSV fill={color} width={width} height={width} />,
    PrintReport: <PrintReport fill={color} width={width} height={width} />,
    PDF: <PDF fill={color} width={width} height={width} />,
    right_arrow: <RightArrrow fill={color} width={width} height={width} />,
    history: <History fill={color} width={width} height={width} />,
    rightTick: <RightTick fill={color} width={width} height={width} />,
    changePass: <ChangePass fill={color} width={width} height={width} />,
    watch: <Watch fill={color} width={width} height={width} />,
    structure: <Structure fill={color} width={width} height={width} />,
    FacebookSolid: <FacebookSolid fill={color} width={width} height={width} />,
    TwitterSolid: <TwitterSolid fill={color} width={width} height={width} />,
    QcIcon: <QcIcon fill={color} width={width} height={width} />,
    menuIcon: <MenuOptionIcon fill={color} width={width} />,
    ConcreatingIcon: <ConcreatingIcon fill={color} width={width}  height={width}/>,
    CubeTestingIcon: <CubeTestingIcon fill={color} width={width} />,
    ReinforcementIcon : <ReinforcementIcon fill={color} width={width} className="reinforcementIcon"/>,
    PolymerSlurryIcon : <PolymerSlurryIcon fill={color} width={width}/>,


  };
  return Icons[icon];
};

export default SVGIcon;



