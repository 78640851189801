import React from "react";
import { Button } from "antd";
import { ButtonProps } from "../../Buttons/interface/ButtonInterface";
import { RootState } from "../../../store/app";
import { useSelector } from "react-redux";
import { checkPrivileges } from "../../../config/Global";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";

const CreateButton: React.FC<ButtonProps> = ({
  action,
  permissionKey,
  permission,
  name = "Add",
}) => {
  const Auth = useSelector((state: RootState) => state.AUTH);

  const permitted = permissionKey
    ? checkPrivileges(Auth.userDetail, permissionKey)
    : permission;

  return permitted ? (
    <Button className="secondaryBtn" onClick={action}>
      {name}
      <span className="btnIcon">
        <PlusIcon />
      </span>
    </Button>
  ) : (
    <></>
  );
};

export default CreateButton;
