import { createSlice } from '@reduxjs/toolkit'
import type { Action, PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../../../../store/api'
import { AppThunk, RootState } from '../../../../../../../store/app'
import { apiUrls } from './apiUrls'
import Config, {
	getServerListPayload
} from '../../../../../../../config/Config'
import { GridOptions, GridReadyEvent } from 'ag-grid-community'
import { IServerSideGetRowsParams } from 'ag-grid-community'
import { Dispatch } from 'react'

interface InitialState {
	isLoading: boolean
	rowData: any
	reportRowData: any
	read: any
	readReport: any
	testTypeList: any
	perPageSize: any
	reportPerPageSize: any
	grid: any
	reportId: any
	location: any
	projectId: any
	concreteMixId: any
	concreteReport: any
	nameLaboratoryList: any
	dateRange:any
}

const initialState = {
	isLoading: false,
	rowData: null,
	reportRowData: null,
	read: null,
	readReport: null,
	testTypeList: null,
	perPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	reportPerPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	grid: null,
	reportId: null,
	location: null,
	projectId: null,
	concreteMixId: null,
	concreteReport: null,
	nameLaboratoryList: null,
	dateRange:null
} as InitialState

const ConcreteAdmixture = createSlice({
	name: 'Concrete Admixture',
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true
		},
		success: (state) => {
			state.isLoading = false
		},
		failure: (state) => {
			state.isLoading = false
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setPerPageSize: (state, action: PayloadAction<any>) => {
			state.perPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.perPageSize))
				state.grid.api.setCacheBlockSize(state.perPageSize)
			}
		},
		setReportPerPageSize: (state, action: PayloadAction<any>) => {
			state.reportPerPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.reportPerPageSize))
				state.grid.api.setCacheBlockSize(state.reportPerPageSize)
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.grid = action.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setResetRead: (state) => {
			state.read = initialState
		},
		setTestTypeList: (state, action: PayloadAction<any>) => {
			state.testTypeList = action?.payload
		},
		setReportRowData: (state, action: PayloadAction<any>) => {
			state.reportRowData = action?.payload
		},
		setReadReport: (state, action: PayloadAction<any>) => {
			state.readReport = action.payload
		},
		setResetReadReport: (state) => {
			state.readReport = initialState
		},
		setReportId: (state, action: PayloadAction<any>) => {
			state.reportId = action?.payload
		},
		setLocation: (state, action: PayloadAction<any>) => {
			state.location = action?.payload
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload
		},
		setConcreteMixId: (state, action: PayloadAction<any>) => {
			state.concreteMixId = action?.payload
		},
		setConcreteReport: (state, action: PayloadAction<any>) => {
			state.concreteReport = action?.payload
		},
		setNameLaboratoryList: (state, action: PayloadAction<any>) => {
			state.nameLaboratoryList = action?.payload
		},
		setDateRange: (state, action: PayloadAction<any>) => {
			state.dateRange = action?.payload
		}
	},
})

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setResetRead,
	setPerPageSize,
	setReportPerPageSize,
	setGrid,
	setTestTypeList,
	setReportRowData,
	setReadReport,
	setResetReadReport,
	setReportId,
	setProjectId,
	setConcreteMixId,
	setLocation,
	setConcreteReport,
	setNameLaboratoryList,
	setDateRange
} = ConcreteAdmixture.actions

export const setPerPage =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			getState().CONCRETEADMIXTURE.location != '/concrete-mixture-report'
				? dispatch(setPerPageSize(size))
				: dispatch(setReportPerPageSize(size))
			dispatch(setUpGrid(getState().CONCRETEADMIXTURE.grid))
		}

const fetchList = async (id: any, payload?: any): Promise<any> => {
	return await api.post(apiUrls.list(id), payload).then(({ data }) => {
		return data
	})
}

export const createRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.create(id), action)
				dispatch(success(response.data))
				dispatch(setUpGrid(getState().FINEAGGREGATE.grid))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id))
				dispatch(setRead(response.data.data[0]))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

export const setUpGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params))
				const data = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				)
				params.api.setServerSideDatasource(data)
			} catch (error: any) {
				//
			}
		}

const changeFilterAndSort = (params: any, dispatch: any, getState: any) => {
	params
	getState().CONCRETEADMIXTURE.location != '/concrete-mixture-report' ?
		dispatch(setConcreteMixId(null)) :
		dispatch(setConcreteReport(null))
}

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch, getState)
			const payload = getServerListPayload(params)
			const reportPayload = {
				...payload,
				start_date: getState().CONCRETEADMIXTURE.dateRange?.start_date,
				end_date:  getState().CONCRETEADMIXTURE.dateRange?.end_date ,
			}
			getState().CONCRETEADMIXTURE.location != '/concrete-mixture-report'
				? fetchList(getState().CONCRETEADMIXTURE.projectId, payload).then(
					(data) => {
						params.success({
							rowData: data?.rows,
							rowCount: data?.count,
						})
						if (data.count <= 0) {
							params.api.showNoRowsOverlay()
						} else {
							params.api.hideOverlay()
						}
					}
				)
				: fetchReportList(
					getState().CONCRETEADMIXTURE?.reportId,
					reportPayload
				).then((data) => {
					params.success({
						rowData: data?.rows,
						rowCount: data?.count,
					})
					if (data.count <= 0) {
						params.api.showNoRowsOverlay()
					} else {
						params.api.hideOverlay()
					}
				})
		},
	}
}

const fetchReportList = async (id?: any, payload?: any): Promise<any> => {
	const f_payload = { ...payload, ...id }
	return await api.post(apiUrls.reportList, f_payload).then(({ data }) => {
		return data
	})
}
export const createReport =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.createReport(id), action)
				dispatch(success(response.data))
				dispatch(setUpGrid(getState().CONCRETEADMIXTURE.grid))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const resetRead = (): AppThunk<any> => (dispatch) => {
	try {
		dispatch(start())
		dispatch(setRead(null))
		dispatch(success())
		return Promise.resolve()
	} catch (error: any) {
		dispatch(failure())
		return Promise.reject(error.data)
	}
}

export const readReport =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.readReport(id))
				dispatch(setReadReport(response.data.data[0]))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

export const getConcreteAdmixtureType =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.testType(id))
				dispatch(setTestTypeList(response?.data?.data))
				dispatch(success())
				return Promise.resolve(response.data.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
		}

export const getNameOfLaboratory = (labId:any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.get(apiUrls.nameOfLaboratory)
			const laboratoryName = response.data.data?.filter((item?: any) => {
				if(item?.laboratory_type == labId){
					return item
				}
			});
			dispatch(setNameLaboratoryList(laboratoryName))
			dispatch(success())
			return Promise.resolve(response.data.data)
		} catch (error: any) {
			dispatch(failure())
			return Promise.reject(error)
		}
	}

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().CONCRETEADMIXTURE.projectId, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().CONCRETEADMIXTURE.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const deleteReport =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.deleteReport, { id: id, project_id: getState().CONCRETEADMIXTURE.projectId, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().CONCRETEADMIXTURE.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};
		export const getDateRangeData = (): AppThunk<any> =>
		async (dispatch, getState) => {
	
			dispatch(setUpGrid(getState().CONCRETEADMIXTURE.grid))
		}
const ConcreteAdmixtureSliceReducer = ConcreteAdmixture.reducer
export default ConcreteAdmixtureSliceReducer
