import React from "react";
import { Switch } from "antd";

const SwitchCellRenderer = (props: any) => {
	const {
		data,
		context: { setStatusData, changeStatusPermission = true },
	} = props;

	return (
		<div>
			{data?.first_name ? (
				<>
					{data?.role_name ? <Switch
						checked={data?.status == 1}
						disabled={changeStatusPermission && data?.role_name == "Super Admin"}
						onClick={() => {
							setStatusData(data);
						}}
					/> : <Switch
						checked={data?.status == 1}
						disabled
						onClick={() => {
							setStatusData(data);
						}}
					/>}
				</>
			) : <Switch
				checked={data?.status == 1}
				disabled={data?.qa_params_value_mapping_id ? data?.status == 1 ? false : true : !changeStatusPermission}
				onClick={() => {
					// setStatusData({ id: data?.id, status: Number(!data?.is_active), name: data });
					setStatusData(data);
				}}
			/>}
		</div>
	);
};
export default SwitchCellRenderer;
