import { ColDef } from 'ag-grid-community'
import {
    idColumn,
    // actionColumn,
    updatedAtColumn,
} from '../../../../../utils/commonColumns'

const departmentColumn: ColDef = {
    field: 'project_name',
    headerName: 'Project Attribute Name',
    filter: 'agTextColumnFilter' ,
    tooltipValueGetter: (params: any) => params.data.project_name,

}

const columnDefs: ColDef[] = [
    idColumn,
    departmentColumn,
    updatedAtColumn,
    // actionColumn,
]
export default columnDefs
