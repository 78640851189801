import { formRules } from "../../../../../config/validations/formRules";
import { validations } from "../../../../../config/validations/validations";

export const rules: any = {
  name: () => formRules.name(),
  email: (field?: string) => [
    validations.required.text(field),
    validations.email(field),
    {
      max: 70,
      message: "Email should contain maximum 70 characters.",
    },
  ],
  mobileNumber: () => formRules.phone(),
  roleId: (field?: string) => [validations.required.select(field)],
  password: () => [
    //validations.required.text(field),
    validations.pattern.password(),
  ],
  dynamicFields: (field?: string) => [
    validations.required.text(field),
    {
      min: 2,
      message: "It must have at least 2 characters.",
    },
    {
      max: 50,
      message: "Maximum length is 50 characters.",
    },
    // {
    //     pattern:/^(?!\s+$).+/,
    //     message:"It is required"
    // }
  ],
  panCard: (field?: any) => [
    validations.required.text(field),
    validations.pattern.pan,
    {
      min: 10,
      max: 10,
      message: "It must have 10 characters.",
    },
  ],
  aadharCard: (field?: any) => [
    validations.required.text(field),
    validations.pattern.aadhar,
    {
      min: 12,
      max: 12,
      message: "It must have 12 digits.",
    },
  ]
};
