import { ColDef } from "ag-grid-community";
import { dateFormatter } from "../config/Global";
import { renderNA } from "./commonFunctions";
import dateComparator from "./dateComparator";
import DisabledContext from "antd/es/config-provider/DisabledContext";

export const idColumn: ColDef = {
	field: "id",
	// headerName: "# ID",
	pinned: "left",
	// filter: 'agNumberColumnFilter',
	maxWidth: 100,
	minWidth: 100,
	width:100,
	headerName: 'Sr. No.',
	// filter: false,
	filter: DisabledContext,
	floatingFilterComponentParams: {
		suppressFilterButton: true,
	},
	suppressMenu: true,
	sortable: false,
	cellRenderer: (params: any) => {
		const serialNumber = params.node.rowIndex + 1
		return serialNumber
	},
	tooltipValueGetter: (params: any) => params.data.id,
};

export const nameColumn: ColDef = {
	field: "name",
	headerName: "Name",
	cellRenderer: (props: any) => {
		return renderNA(props.data.name);
	},
	tooltipValueGetter: (params: any) => params.data.name,
};

export const isActiveColumn: ColDef = {
	field: "is_active",
	headerName: "Is Active?",
	filter: "agSetColumnFilter",
	cellRenderer: (props: any) => {
		return renderNA(props.data.name);
	},
	filterParams: {
		values: (params: any) => {
			const data = ["Active", "Deactivate"];
			params.success(data);
		},
		buttons: ["apply", "reset"],
		closeOnApply: true,
	},
};

export const actionColumn: ColDef = {
	field: "action",
	headerName: "Actions",
	type: "actionColumn",
	sortable: false,
	filter: false,
	width: 150,
	minWidth: 150,
	pinned: "right",
};

export const createdAtColumn: ColDef = {
	field: "created_at",
	headerName: "Created At",
	sortable: true,
	width: 200,
	minWidth: 200,
	filter: "agDateColumnFilter",
	cellRenderer: (props: any) => {
		return props.data.created_at ? dateFormatter(props.data.created_at.toLocaleString()) : "N/A";
	},
	filterParams: {
		buttons: ["apply", "reset"],
		inRangeInclusive: true,
		suppressAndOrCondition: true,
		comparator: dateComparator,
		browserDatePicker: true,
	},
	// tooltipValueGetter: (params: any) => params.data.created_at,
};
export const updatedAtColumn: ColDef = {
	field: "updated_at",
	headerName: "Updated At",
	sortable: true,
	width: 200,
	minWidth: 200,
	filter: "agDateColumnFilter",
	cellRenderer: (props: any) => {
		return props.data.updated_at ? dateFormatter(props.data.updated_at.toLocaleString()) : "N/A";
	},
	filterParams: {
		buttons: ["apply", "reset"],
		inRangeInclusive: true,
		suppressAndOrCondition: true,
		comparator: dateComparator,
		browserDatePicker: true,
	},
	// tooltipValueGetter: (params: any) => params.data.updated_at,
};
