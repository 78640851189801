import React, { useState } from "react";
import { Form } from "antd";
import FormComponent from "./FormComponent";
import { validateFields } from "../../../../../config/Global";
import { createRecord } from "../../utils/slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/app";
import { useNavigate } from "react-router-dom";

const AddQuestionPage: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [saving, setSaving] = useState(false);
	const [inputData, setInputData] = useState([])
	const selectInputType = form.getFieldValue("input_type") && [1, 2].includes(form.getFieldValue("input_type")) && inputData.length === 0 
	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleSave = async (data: any) => {
		data.input_option = inputData
		data.answer_required = data?.answer_required ? data?.answer_required : false
		data.remark_required = data?.remark_required ? data?.remark_required : false
		setSaving(true)
		setDisabled(true)
		try {
			await form.validateFields();
			dispatch(createRecord(data))
				.then(() => {
					setSaving(false)
					setDisabled(false)
					setInputData([])
					navigate("/master-data/question-master")
				})

				.catch(() => {
					setSaving(false)
					setDisabled(false)
				});
		} catch (error) {
			setSaving(false)
			setDisabled(false)
			console.error("Error submitting data:", error);
		}
	};

	return (
		<>

			<FormComponent
				form={form}
				id={"UserAdd"}
				onValueChange={validateForm}
				handleSubmit={handleSave}
				saving={saving}
				disabled={disabled || selectInputType}
				setInputData={setInputData}
				inputData={inputData}
			/>

		</>
	);
};

export default AddQuestionPage;
