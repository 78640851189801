import { Form, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { getDynamicFieldType, readRecord } from "../../../utils/slice";
import DynamicForm from "./DynamicForm";
import { FormInstance } from "antd/lib/form";

interface ComponentProps {
	onSubmit: any;
	form: FormInstance;
	formId: string;
	onChange: any;
}
const SecondStep: React.FC<ComponentProps> = ({
	onSubmit,
	form,
	formId,
	onChange,
}: ComponentProps) => {
	const PROJECT = useSelector((state: RootState) => state.PROJECT);
	const dispatch: AppDispatch = useDispatch();
	const id  = localStorage.getItem("projectId")
	const projectAttributesGroupId = 1;
	const onFinish = (data: any) => {
		onSubmit({
			...data,
			project_attributes_group_id: projectAttributesGroupId,
			project_id: id,
		});
	};

	const setDynamicField = () => {
		dispatch(
			getDynamicFieldType({
				project_id: id,
				project_attributes_group_id: projectAttributesGroupId,
			})
		)
			.then(() => {
				if (location.pathname.includes("/read")) {
					dispatch(
						readRecord(id, {
							project_attributes_group_id: projectAttributesGroupId,
						})
					)
						.then((readData: any) => {
							readData?.data?.project_attr_value?.map((item: any) =>
								form.setFieldValue(
									[item.project_attributes_id,"value"],
									item.attribute_value
								)
							);
							onChange();
						})
						.catch((error: any) => error);
				}
			})
			.catch((error: any) => error);
	};

	useEffect(() => {
		setDynamicField()
	}, []);

	useEffect(() => {
		//
	}, [PROJECT.dynamicFieldType]);

	return (
		<>
			<Form
				form={form}
				onFinish={onFinish}
				id={formId}
				onChange={onChange}
				className="secondStep"
			>
				<Row gutter={10}>
					<DynamicForm
						dynamicFieldType={PROJECT.dynamicFieldType}
						onChange={onChange}
						form={form}
						imageData={[]}
					/>
				</Row>
			</Form>
		</>
	);
};
export default SecondStep;
