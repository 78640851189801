import React, { useEffect } from "react";
//import { Form } from "antd";
import { InputBox } from "../../../../../../../components/AntdAddons";

import { FormInstance } from "antd/lib/form";
import { AppDispatch, RootState } from "../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { getBindingWireDropdown, getBindingWireType } from "../utils/slice";
import { blockInvalidChar } from "../../../../../../../config/Global";

interface TableComponentProps {
    form: FormInstance;
}
const TableComponent: React.FC<TableComponentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const { BINDINGWIRE } = useSelector((state: RootState) => state)
    const validatevalue = (rule?: any, value?: any) => {
            if (value == 0) 
            {
                return Promise.reject('Minimum 1 test required');
            } else if (value?.toString().length > 4) 
            {
                return Promise.reject("Maximum 4 digit allowed");
            } else if(value.toString().includes('.'))
            {
                return Promise.reject("Decimal digits not allowed");
            }  
            return Promise.resolve();
    };
    

    useEffect(() => {
        if (!BINDINGWIRE.bindingWireTestingNameList) {
            dispatch(getBindingWireDropdown());
            dispatch(getBindingWireType(6));
        }
    }, []);

    useEffect(() => {
        location.pathname.includes("/add-binding-wire") && (
        setTimeout(()=>{
            BINDINGWIRE.bindingWireTestingNameList &&
            BINDINGWIRE.bindingWireTestingNameList.map((field: any) => {
                BINDINGWIRE?.testTypeList?.map((data?: any, subIndex?: number) => {
                form.getFieldsValue();
                form.setFieldValue(
                  [
                    "pending_drs",
                    field.id?.toString(),
                    "test_types",
                    subIndex,
                    "test_type_value",
                  ],
                  1
                );
                form.setFieldValue(
                  [
                    "pending_drs",
                    field.id?.toString(),
                    "test_types",
                    subIndex,
                    "mst_test_type_id",
                  ],
                  data?.id
                );
              });
            });
        },500))
      }, [BINDINGWIRE.bindingWireTestingNameList,BINDINGWIRE.testTypeList]);
    


    return (
        <>
          
                <>

                    <div className='testTable'>
                        <h2>No. of Test Required</h2>
                        <table>
                            <div>
                                <thead>
                                    <tr>
                                        <td rowSpan={2} width="7%">Quality</td>
                                        <td colSpan={6} style={{ textAlign: "center" }}>No. Of Test required As Per QAP</td>

                                    </tr>
                                    <tr className="subTitle">

                                        {BINDINGWIRE?.testTypeList?.map((data: any) => {
                                            return (
                                                <td width="3%" key={data?.id}>
                                                    {data?.name}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                {BINDINGWIRE.bindingWireTestingNameList &&
                                BINDINGWIRE.bindingWireTestingNameList.map((field: any) => {
                                return (
                                    <tr key={field?.id}>
                                    <td style={{ textAlign: "center" }}>{field.name}</td>
                                    
                                    {BINDINGWIRE?.testTypeList?.map(
                                        (data: any, subIndex: number) => {
                                        return (
                                            <td key={data?.id}>
                                            <InputBox.Text
                                                name={[
                                                "pending_drs",
                                                field.id?.toString(),
                                                "test_types",
                                                subIndex,
                                                "mst_test_type_id",
                                                ]}
                                                initialValue={data.id}
                                                hidden
                                            />
                                            <InputBox.Text
                                                type="Number"
                                                name={[
                                                "pending_drs",
                                                field.id?.toString(),
                                                "test_types",
                                                subIndex,
                                                "test_type_value",
                                                ]}
                                                min={1}
                                                required
                                                onKeyDown={blockInvalidChar}
                                                disabled={
                                                    location.pathname.includes("/view-binding-wire")
                                                        ? true
                                                        : false
                                                }
                                                rules={[
                                                {
                                                    validator: validatevalue,
                                                },
                                                {
                                                    required:true,
                                                    message:"It is required."
                                                }
                                               
                                                ]}
                                                
                                                
                                            />
                                            </td>
                                        );
                                        }
                                    )}
                                    </tr>
                                );
                                })}
                                </tbody>
                            </div>
                        </table>
                    </div>
                </>

           
        </>
    );
};
export default TableComponent;
