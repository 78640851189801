import { validations } from '../../../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	numberFields: (field?: any) => [
		validations.pattern.decimal(2),
		validations.required.text(field),

	],
	dateFields: (field?: string) => [
		validations.required.select(field),
	],
	FileUpload: (field?: any) => [
		validations.required.text(field),
	],
	requiredField: (field?: any) => [
		validations.required.text(field),
	],
	selectFields: (field?: any) => [
		validations.required.select(field),
	],

}
