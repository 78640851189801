import React, { useEffect } from "react";
import { Button, Form } from "antd";
// import { useNavigate } from "react-router-dom";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LoginRequest } from "./Validation";
import { useDispatch, useSelector } from "react-redux";
import { doLogin } from "../../../store/AuthSlice";
import { AppDispatch, RootState } from "../../../store/app";
import { Link, useNavigate } from "react-router-dom";
import { stringDecryption } from "../../../config/Global";
import { assignErrorToInput } from "../../../store/api";
import { ReactComponent as Email } from "../../../assets/images/icon/email.svg";

const LoginForm: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const Auth = useSelector((state: RootState) => state.AUTH);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const handleSubmit = (data: any) => {
		dispatch(doLogin(data))
			.then(() => {
				navigate("/projects");
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			});
	};

	useEffect(() => {
		let rememberMe: any = localStorage.getItem("remember_me");
		if (rememberMe) {
			rememberMe = JSON.parse(stringDecryption(rememberMe));
			form.setFieldsValue({
				username: rememberMe.email,
				password: rememberMe.password,
				remember: true,
			});
		}
	}, [form]);

	return (
		<div className="loginForm" >
			<div className="loginFormWrap">
				<div className="formTitle">
					<h2>Sign In</h2>
					<span>Welcome Ahmedabad Municipal Corporation</span>
				</div>
				<FormBox form={form} onFinish={handleSubmit}>
					<div>
						<InputBox.Text
							name="username"
							placeholder="example@domain.com"
							rules={LoginRequest().email("It")}
							addonAfter={<Email />}
						/>
					</div>
					<div>
						<InputBox.Password
							name="password"
							placeholder="xxxxxxx"
							rules={LoginRequest().password("It")}
						/>
					</div>
					<div
						className="mt-10 font-medium login_extra_fields"
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						<Link to={"/forgot-password"}>Forgot Password?</Link>
					</div>
					<div className="text-center signBtn">
						<Button
							loading={Auth.isLoading}
							type="primary"
							htmlType="submit"
							style={{ background: "#3e97ff" }}
						>
							Sign In
						</Button>
					</div>
				</FormBox>
			</div>
		</div>
	);
};

export default LoginForm;
