import React, { useEffect, useState } from "react";
import ContentBox from "../../../../../../../../components/ContentBox/ContentBox";
import Listing from "./Listing/Listing";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/images/icon/plusIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import ToolBar from "../../../../../../../../components/ToolBar";
import { CoarseReportBreadcrumb } from "../../../../../../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { deleteReport, getDateRangeData, setCoarseReportId, setDateRange, setId, setLocation, setReadReportData, setReportId, setReportPerPage } from "../../utils/slice";
import RecordPerPage from "../../../../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../../../../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../../../../../../config/Global";
import PageSpinner from "../../../../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../../../../components/Modals/DeleteModal";
import { InputBox } from "../../../../../../../../components/AntdAddons";
import { RangePickerProps } from "antd/es/date-picker";
import { CONSTANT } from "../../../../../../../../config/Constant";
import dayjs from "dayjs";
import { ReactComponent as RestoreIcon } from "../../../../../../../../assets/images/icon/refresh.svg";
import { setTab } from "../../../../../utils/slice";

const moduleName = "Report";

const CorseAggregateReportComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { courseId }: { projectId?: any; courseId?: any } =
		useParams();
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { COARSEAGGREGATE, AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const [selectedDate, setSelectedDate] = useState(null)

	const onRowClick = (data?: any) => {
		dispatch(setCoarseReportId(data?.data?.id));
		setDeleteData(data?.data)
	};
	const handleViewClick = () => {
		dispatch(setReadReportData(null))
		courseId ? navigate(
			`/projects/raw-material/${courseId}/${COARSEAGGREGATE.coarseReportId}/view-coarse-aggregate-report`
		) : navigate(
			`/projects/raw-material/${COARSEAGGREGATE.coarseReportId}/view-coarse-aggregate-report`
		);
	};

	const handleClick = () => {
		navigate(
			`/projects/raw-material/${courseId}/add-coarse-aggregate-report`
		);
	};
	useEffect(() => {
		dispatch(setReportId(courseId ? { id: courseId } : { project_id: localStorage.getItem('projectId') }));
		dispatch(setLocation("/coarse-aggregate-report"));
		dispatch(setId(localStorage.getItem("projectId")));
		dispatch(setTab(2))
	}, []);

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setCoarseReportId(null))
	}
	const onChangeDateRange: RangePickerProps["onChange"] = (date: any) => {
		setSelectedDate(date)
		date?dispatch(setDateRange({ start_date: dayjs(date[0]).format(CONSTANT.POST_DATE_FORMAT), end_date: dayjs(date[1]).format(CONSTANT.POST_DATE_FORMAT) })):
		dispatch(setDateRange({start_date:null,end_date:null}))
		dispatch(getDateRangeData())
	}
	const resetFilter = () => {
		dispatch(setDateRange(null))
		setSelectedDate(null)
		dispatch(getDateRangeData())
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar breadcrumbs={CoarseReportBreadcrumb(courseId ? "Raw Material Testing - Coarse Aggregate" : "Consolidate Report")}>
						<div className="listBtn">
							{courseId && checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) && <Button className="secondaryBtn" onClick={handleClick}>
								Add Report
								<span className="btnIcon">
									<PlusIcon />
								</span>
							</Button>}
							<RecordPerPage
								defaultValue={COARSEAGGREGATE.reportPerPage + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setReportPerPage(perPageSize));
								}}
							/>{" "}
						</div>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							<div className="auditListWrapper reportList">
								<div className="btnList">

									<div className="listRangePicker">
										<InputBox.DateRangePicker value={selectedDate} onChange={onChangeDateRange} />
									</div>
									<Button onClick={resetFilter}>
										<RestoreIcon height={22} width={22} />
									</Button>
								</div>
								<div className="auditList">

									<Listing
										rowData={COARSEAGGREGATE?.reportRowData}
										moduleName={moduleName}
										handleViewClick={handleViewClick}
										onRowClick={onRowClick}
									/>
								</div>

								<DeleteModal
									title={"coarse aggregate report"}
									deleteValues={deleteData}
									callApi={deleteReport}
									close={closeDeleteModal}
									open={deleteUser}
								/>
							</div>
						</div>

						<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow borderActionRow" : ""}>
							<div className="actionBtn">
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
									<Button
										onClick={handleViewClick}
										disabled={COARSEAGGREGATE.coarseReportId == null ? true : false}
										className={COARSEAGGREGATE.coarseReportId ? "activeBtn" : "disableBtn"}
									>
										View
										<SVGIcon icon="view" />
									</Button>}
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
									<Button
										onClick={() => setDeleteUser(true)}
										disabled={COARSEAGGREGATE.coarseReportId == null ? true : false}
										className={COARSEAGGREGATE.coarseReportId ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>}
							</div>
						</div>

					</ContentBox></>}
		</>
	);
};

export default CorseAggregateReportComponent;
