import React from 'react'
import { useRoutes } from 'react-router-dom'
import ModuleWrap from '../components/ModuleWrap'
import AuthLayout from '../views/layouts/AuthLayout'
import MainLayout from '../views/layouts/MainLayout'
import Login from '../views/modules/Auth/Login'
import PageNotFound from '../views/errors/PageNotFound'
import UserManagement from '../views/modules/UserManagement'
import RoleManagement from '../views/modules/RoleManagement'
import ResetPassword from '../views/modules/Auth/ResetPassword'
import StructureElement from '../views/modules/StructureElement'
import Projects from '../views/modules/Project'
import Department from '../views/modules/Department'
import DepartmentType from '../views/modules/DepartmentType'
import ProjectAttribute from '../views/modules/ProjectAttribute'
import ProjectAttributeGroup from '../views/modules/ProjectAttributeGroup'
import QAParamsGroup from '../views/modules/QAParamsGroup'
import QAElement from '../views/modules/QAElement'
import Concreting from '../views/modules/Concreting'
import Reinforcement from '../views/modules/Reinforcement'
import ProjectDashboard from '../views/modules/ProjectDashboard'
import RowMaterial from '../views/modules/RawMaterial'
import PileCapElement from '../views/modules/PileCapElement'
import AddSteelComponent from '../views/modules/RawMaterial/components/TabElements/Steel/Forms/AddSteelComponent'
import ReportComponent from '../views/modules/RawMaterial/components/Report'
import AddReportComponent from '../views/modules/RawMaterial/components/Report/Forms/AddReportComponent'
import MixDesignComponent from '../views/modules/MixDesign'
import AddMixDesignComponent from '../views/modules/MixDesign/Forms/AddMixDesignComponent'
import CubeTesting from '../views/modules/CubeTesting'
import TestReport from '../views/modules/CubeTesting/Forms/TestReport'
import AddCoarseAggregate from '../views/modules/RawMaterial/components/TabElements/CoarseAggregate/Forms/AddCoarseAggregate'
import AddCementComponent from '../views/modules/RawMaterial/components/TabElements/Cement/Forms/AddCementComponent'
import CorseAggregateReportComponent from '../views/modules/RawMaterial/components/TabElements/CoarseAggregate/elements/Report'
import AddCorseAggregateReportComponent from '../views/modules/RawMaterial/components/TabElements/CoarseAggregate/elements/Report/Forms/AddReportComponent'
import AddFine from '../views/modules/RawMaterial/components/TabElements/FineAggregate/Forms/AddFineAggregates'
import FineAggregateReportComponent from '../views/modules/RawMaterial/components/TabElements/FineAggregate/elements/Report'
import AddFineAggregateReportComponent from '../views/modules/RawMaterial/components/TabElements/FineAggregate/elements/Report/Forms/AddFineAggregateReportComponent'
import AddConcreteMixer from '../views/modules/RawMaterial/components/TabElements/ConcreteAdmixture/Forms/AddConcreteMixer'
import AddBindingWire from '../views/modules/RawMaterial/components/TabElements/Binding Wire/Forms/AddBindingWire'
import AddWireReportComponent from '../views/modules/RawMaterial/components/TabElements/Binding Wire/elements/Report'
import AddWireReport from '../views/modules/RawMaterial/components/TabElements/Binding Wire/elements/Report/Forms/AddWireReport'
import AddConcreteAdMixerReportComponent from '../views/modules/RawMaterial/components/TabElements/ConcreteAdmixture/elements/Report/Forms/AddReportComponent'
import ConcreteMixtureReportComponent from '../views/modules/RawMaterial/components/TabElements/ConcreteAdmixture/elements/Report'
import AddPolymerSlurry from '../views/modules/PolymerSlurry/Forms/AddPolymerSlurry'
import CementReportComponent from '../views/modules/RawMaterial/components/TabElements/Cement/elements/Report'
import AddCementReportComponent from '../views/modules/RawMaterial/components/TabElements/Cement/elements/Report/Forms/AddReportComponent'
import AddPolymer from '../views/modules/RawMaterial/components/TabElements/PolymerSluryTab/Forms/AddPolymer'
import CementBrand from '../views/modules/CementBrand'
import ConsolidatedReports from '../views/modules/ConsolidatedReports'
import Laboratories from '../views/modules/Laboratories'
//import CreateProjectDashboard from '../views/modules/CreateProjectDashboard'
import ForgetPassword from '../views/modules/Auth/ForgetPassword'
import Progress from '../views/modules/Progress'
import CreateProjectDashboard from '../views/modules/CreateProjectDashboard'
import DesignConsultant from '../views/modules/DesignConsultant'
import Pmc from '../views/modules/Pmc'
import Contractor from '../views/modules/Contractor'
import DesignCode from '../views/modules/DesignCode'
import QuestionMaster from '../views/modules/AddQuestionPage'
import Category from '../views/modules/Category'
import TestType from '../views/modules/TestType'
import AuditLog from '../views/modules/AuditLog'
import AddQuestionPage from '../views/modules/AddQuestionPage/components/Forms/AddComponent'
import EditComponent from '../views/modules/AddQuestionPage/components/Forms/EditComponent'
import DutyAssignment from '../views/modules/DutyAssignment'
import WorkCompletedComponent from '../views/modules/WorkCompleted'
import FormComponent from '../views/modules/WorkCompleted/components/Forms/FormComponent'


export default function Router() {
	return useRoutes(RouterConfig)
}

export const RouterConfig = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: '/login',
				element: <Login />,
			},
			{
				path: '/',
				element: <Login />,
			},
			{
				path: '/forgot-password',
				element: <ForgetPassword />,
			},
			{
				// path: '/reset/:otp',
				path: '/reset-password/:otp',
				element: <ResetPassword />,
			},
		],
	},
	{
		element: <MainLayout />,
		children: [
			{
				path: '/projects',
				element: <ModuleWrap module={CreateProjectDashboard} title={"Projects"} permissionPrefix={"PROJECTS"} />,
			},
			{
				path: '/users',
				element: <ModuleWrap module={UserManagement} title={"User"} permissionPrefix={"USER"} />,
			},
			{
				path: '/roles',
				element: <ModuleWrap module={RoleManagement} title={"Roles"} permissionPrefix={"ROLES"} />,
			},
			{
				path: '/projects/:id?/project-dashboard',
				element: <ModuleWrap module={ProjectDashboard} />,
			},
			{
				path: '/projects/create/:id?/:step?',
				element: <ModuleWrap module={Projects} />,
			},
			{
				path: '/projects/:id?/read/:step?',
				element: <ModuleWrap module={Projects} />,
			},
			{
				path: '/projects/:id?/edit/:step?',
				element: <ModuleWrap module={Projects} />,
			},
			{
				path: '/projects/:projectId?/structure',
				element: (
					<ModuleWrap module={StructureElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />
				),
			},
			// {
			//     path: '/projects/:projectId/:structureId/pile',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// {
			//     path: '/projects/:projectId/:structureId/pile/add',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// {
			//     path: '/projects/:projectId/:structureId/pile/add/:step',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// // {
			// //     path: '/projects/:projectId/:structureId/pile-cap/add/:step',
			// //     element: <ModuleWrap module={PileCapElement} />,
			// // },
			// {
			//     path: '/projects/:projectId/:structureId/qa-parameter/:mappingId/read',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// {
			//     path: '/projects/:projectId/:structureId/qa-parameter/:mappingId/read/:step?',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// {
			//     path: '/projects/:projectId/:structureId/qa-parameter/:mappingId/update',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			// {
			//     path: '/projects/:projectId/:structureId/qa-parameter/:mappingId/update/:step',
			//     element: <ModuleWrap module={PileElement} />,
			// },
			{
				path: '/projects/:projectId?/:structureId/:mName',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/add',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/projects/checklist/add',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/projects/checklist/read',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/projects/:projectId?/:structureId/qa-parameter/:mName/:mappingId/update',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/projects/:projectId?/:structureId/qa-parameter/:mName/:mappingId/read',
				element: <ModuleWrap module={PileCapElement} title={"Structure Element"} permissionPrefix={"STRUCTURE_ELEMENT"} />,
			},
			{
				path: '/master-data/department',
				element: <ModuleWrap module={Department} title={"Department"} permissionPrefix={"MST_DEPARTMENT"} />,
			},
			{
				path: '/master-data/cement',
				element: <ModuleWrap module={CementBrand} title={"Cement"} permissionPrefix={"MST_CEMENT"} />,
			},
			{
				path: '/master-data/laboratory',
				element: <ModuleWrap module={Laboratories} title={"Laboratory"} permissionPrefix={"MST_LABORATORY"} />,
			},
			{
				path: '/master-data/department-type',
				element: <ModuleWrap module={DepartmentType} title={"Department Type"} permissionPrefix={"MST_DEPARTMENT_TYPE"} />,
			},
			{
				path: '/master-data/project-attribute',
				element: (
					<ModuleWrap module={ProjectAttribute} title={"ProjectAttribute"} permissionPrefix={"MST_PROJECT_ATTRIBUTE_GROUP"} />
				),
			},
			{
				path: '/master-data/project-attribute-group',
				element: (
					<ModuleWrap
						module={ProjectAttributeGroup} title={"Project Attribute Group"} permissionPrefix={"MST_PROJECT_ATTRIBUTE_GROUP"}

					/>
				),
			},
			{
				path: '/master-data/structural-element-params',
				element: <ModuleWrap module={QAParamsGroup} title={"Structural Element Params"} permissionPrefix={"MST_STRUCTURE_ELEMENT_GROUP"} />,
			},
			{
				path: '/master-data/structural-element',
				element: <ModuleWrap module={QAElement} title={"Structural Element"} permissionPrefix={"MST_STRUCTURAL_ELEMENT"}/>,
			},
			{
				path: '/master-data/design-consultant',
				element: <ModuleWrap module={DesignConsultant} title={"Design Consultant"} permissionPrefix={"MST_DESIGN_CONSULTANT"} />,
			},
			{
				path: '/master-data/pmc',
				element: <ModuleWrap module={Pmc} title={"PMC"} permissionPrefix={"MST_PMC"} />,
			},
			{
				path: '/master-data/contractor',
				element: <ModuleWrap module={Contractor} title={"Contractor"} permissionPrefix={"MST_CONTRACTOR"} />,
			},
			{
				path: '/master-data/design-code',
				element: <ModuleWrap module={DesignCode} title={"Design Code"} permissionPrefix={"MST_DESIGN_CODE"} />
			},
			{
				path: '/master-data/category',
				element: <ModuleWrap module={Category} title={"Category"} permissionPrefix={"MST_CATEGORY"} />
			},
			{
				path: '/master-data/test-type',
				element: <ModuleWrap module={TestType} title={"Test Type"} permissionPrefix={"MST_TEST_TYPE"} />
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/:mappingId/concreting',
				element: <ModuleWrap module={Concreting} />,
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/:mappingId/reinforcement',
				element: <ModuleWrap module={Reinforcement} />,
			},
			{
				path: '/projects/:projectId?/raw-material',
				element: <ModuleWrap module={RowMaterial} title={"Raw Material"} permissionPrefix={"INVENTORY"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/add-steel',
				element: <ModuleWrap module={AddSteelComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/add-fine-aggregate',
				element: <ModuleWrap module={AddFine} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:fineAgid/view-fine-aggregate',
				element: <ModuleWrap module={AddFine} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:id/view-steel',
				element: <ModuleWrap module={AddSteelComponent} />,
			},
			{
				path: 'projects/:projectId?/raw-material/add-cement',
				element: <ModuleWrap module={AddCementComponent} />,
			},
			{
				path: 'projects/:projectId?/raw-material/:cementId/view-cement',
				element: <ModuleWrap module={AddCementComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:cementId?/cement-report',
				element: <ModuleWrap module={CementReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:cementId/add-cement-report',
				element: <ModuleWrap module={AddCementReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:cementId?/:reportId/view-cement-report',
				element: <ModuleWrap module={AddCementReportComponent} />,
			},
			{
				path: '/report',
				element: <ModuleWrap module={ReportComponent} />,
			},
			{
				path: '/add-report',
				element: <ModuleWrap module={AddReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:steelId?/steel-report',
				element: <ModuleWrap module={ReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:steelId/add-steel-report',
				element: <ModuleWrap module={AddReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:steelId?/:reportId/view-steel-report',
				element: <ModuleWrap module={AddReportComponent} />,
			},
			{
				path: '/projects/:projectId?/mix-design',
				element: <ModuleWrap module={MixDesignComponent} title={"Mix Design"} permissionPrefix={"MIXDESIGN"}/>,
			},
			{
				path: '/projects/:projectId?/mix-design/add-mixdesign',
				element: <ModuleWrap module={AddMixDesignComponent} />,
			},
			{
				path: '/projects/:projectId?/mix-design/:mixDesignId/view-mixdesign',
				element: <ModuleWrap module={AddMixDesignComponent} />,
			},
			{
				path: '/projects/:projectId?/mix-design/:mixDesignId/edit-mixdesign',
				element: <ModuleWrap module={AddMixDesignComponent} />,
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/:mappingId/add-cubetesting',
				element: <ModuleWrap module={CubeTesting} />,
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/:mappingId/:cubeTestId/test-report',
				element: <ModuleWrap module={TestReport} />,
			},
			{
				path: '/projects/:projectId?/raw-material/add-coarse-aggregate',
				element: <ModuleWrap module={AddCoarseAggregate} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:courseId/view-coarse-aggregate',
				element: <ModuleWrap module={AddCoarseAggregate} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:courseId?/coarse-aggregate-report',
				element: <ModuleWrap module={CorseAggregateReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:courseId/add-coarse-aggregate-report',
				element: <ModuleWrap module={AddCorseAggregateReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:courseId?/:reportId/view-coarse-aggregate-report',
				element: <ModuleWrap module={AddCorseAggregateReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/add-concrete-admixture',
				element: <ModuleWrap module={AddConcreteMixer} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:concreteAdid/view-concrete-admixture',
				element: <ModuleWrap module={AddConcreteMixer} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:concreteAdid?/concrete-mixture-report',
				element: <ModuleWrap module={ConcreteMixtureReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:concreteAdid/add-concrete-mixture-report',
				element: <ModuleWrap module={AddConcreteAdMixerReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:concreteAdid?/:reportId/view-concrete-mixture-report',
				element: <ModuleWrap module={AddConcreteAdMixerReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:fineAgid?/fine-aggregate-report',
				element: <ModuleWrap module={FineAggregateReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:fineAgid/add-fine-aggregate-report',
				element: <ModuleWrap module={AddFineAggregateReportComponent} />,
			}, 
			{
				path: '/projects/:projectId?/raw-material/:fineAgid?/:reportId/view-fine-aggregate-report',
				element: <ModuleWrap module={AddFineAggregateReportComponent} />,
			},
			{
				path: '/projects/:projectId?/raw-material/add-binding-wire',
				element: <ModuleWrap module={AddBindingWire} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:id/view-binding-wire',
				element: <ModuleWrap module={AddBindingWire} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:bindWiid?/binding-wire-report',
				element: <ModuleWrap module={AddWireReportComponent} title={"Report"} permissionPrefix={"REPORT"}/>,
			},
			{
				path: '/projects/:projectId?/raw-material/:bindWiid/add-binding-wire-report',
				element: <ModuleWrap module={AddWireReport} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:bindWiid?/:reportId/view-binding-wire-report',
				element: <ModuleWrap module={AddWireReport} />,
			},
			{
				path: '/projects/:projectId?/:structureId/:mName/:mappingId/add-polymer',
				element: <ModuleWrap module={AddPolymerSlurry} />,
			},
			{
				path: '/projects/:projectId?/raw-material/add-polymer-slurry',
				element: <ModuleWrap module={AddPolymer} />,
			},
			{
				path: '/projects/:projectId?/raw-material/:polymerSlurryid/view-polymer-slurry',
				element: <ModuleWrap module={AddPolymer} />,
			},
			{
				path: '/projects/:projectId?/consolidate-report',
				element: <ModuleWrap module={ConsolidatedReports} title={"Consolidate Report"} permissionPrefix={"CONSOLIDATE_REPORT"}/>,
			},
			// New project dashboard 
			// {navigate("/projects");
			//     path: '/create-project',
			//     element: <ModuleWrap module={CreateProjectDashboard} permission={''} />,
			// },
			{
				path: '/projects/:projectId?/progress',
				element: <ModuleWrap module={Progress} title={"Progress"} permissionPrefix={"PROGRESS_REPORT"}/>,
			},
			{
				path: 'master-data/question-master',
				element: <ModuleWrap module={QuestionMaster} title={"Question"} permissionPrefix={"MST_QUESTION_MASTER"} />,
			},
			{
				path: '/audit-log',
				element: <ModuleWrap module={AuditLog} title={"Audit Log"} permissionPrefix={"AUDIT_LOG"} />,
			},
			{
				path:"/add-question",
				element:<ModuleWrap module={AddQuestionPage} title={"Add Question"} permissionPrefix={"MST_QUESTION_MASTER"} />,
			},
			{
				path:"/:questionId/edit-question",
				element:<ModuleWrap module={EditComponent} title={"Edit Question"} permissionPrefix={"MST_QUESTION_MASTER"} />,
			},
			{
				path: '/projects/duty-assignment',
				element: <ModuleWrap module={DutyAssignment} title={"Duty Assignment"} permissionPrefix={"DUTY_ASSIGNMENT"} />,
			},
			{
				path: '/projects/work-completed',
				element: <ModuleWrap module={WorkCompletedComponent} title={"Work Completed"} permissionPrefix={"WORKCOMPETED"} />,
			},
			{
				path: '/projects/work-completed/add',
				element: <ModuleWrap module={FormComponent} title={"Work Completed"} permissionPrefix={"WORKCOMPETED"} />,
			},
			{
				path: '/projects/work-completed/:workId/view',
				element: <ModuleWrap module={FormComponent} title={"Work Completed"} permissionPrefix={"WORKCOMPETED"} />,
			},
			
		],
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
]
