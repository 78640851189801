/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, FormInstance, Input, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";
import { ReactComponent as InfoIcon } from "../../../../../assets/images/icon/infoIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { imageDelete, nodeDelete } from "../../utils/slice";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icon/plusIcon.svg";
import { ReactComponent as RemoveClick } from "../../../../../assets/images/icon/remove-minus.svg";

interface ComponentProps {
	dynamicFieldType: any;
	onChange: any;
	form: FormInstance;
	checkValue: boolean;
	current: any;
	fileList?: any;
	setFileList?: any;
	setDisableSubmit?: any;
	multipleSection?: any;
	setMultipleSection?: any;
	isMultiple?: any;
	radioButton?: any;
	setRadioButton?: any;
	catRadioButton?: any;
	setCatRadioButton?: any;
	typeOfSelect?: any;
	SetTypeOfSelect?: any;
	multipleRadio?: any
	setMultipleRadio?: any;
	requiredRadio?: any
	setRequiredRadio?: any
}
const DynamicForm: React.FC<ComponentProps> = ({
	dynamicFieldType,
	onChange,
	form,
	checkValue,
	current,
	setDisableSubmit,
	multipleSection, setMultipleSection,
	isMultiple,
	radioButton, setRadioButton,
	catRadioButton, setCatRadioButton,
	typeOfSelect, SetTypeOfSelect,
	multipleRadio, setMultipleRadio,
	requiredRadio, setRequiredRadio
}: ComponentProps) => {
	let form_att: any;
	const PILE_CAP = useSelector((state: RootState) => state.PILE_CAP);

	const dispatch: AppDispatch = useDispatch();
	const pro_id: any = localStorage.getItem("projectId");

	//can't remove this code
	// const [value, setValue] = useState<string>('');
	// const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if (
	// 		(!/[\d.]/.test(event.key) && event.key !== 'Backspace') ||
	// 		(event.key === '.' && value.includes('.'))
	// 	) {
	// 		event.preventDefault();
	// 	}

	// 	setTimeout(() => {
	// 		const numericValue = value.replace(/[^\d.]/g, '');
	// 		setValue(numericValue);
	// 	}, 0);
	// };

	useEffect(() => {
		//
	}, [setCatRadioButton, catRadioButton])


	const handleAddMultiple = (data?: any) => {
		// data.id = multipleSection?.length > 0 ? multipleSection[multipleSection?.length - 1]?.id + 1 : 1
		// setMultipleSection([...multipleSection, data])

		const newId = multipleSection?.[data.qa_param_id]?.length > 0
			? multipleSection[data.qa_param_id][multipleSection[data.qa_param_id].length - 1].id + 1
			: 1;

		const updatedMultipleSection = {
			...multipleSection,
			[data.qa_param_id]: [
				...(multipleSection?.[data.qa_param_id] ?? []),
				{ ...data, id: newId }
			]
		};
		data && setMultipleSection(updatedMultipleSection);

		onChange()
	}

	const handleRemoveMultiple = (current: any, id: any, itemId?: any) => {
		const list: any = []
		const node_data: any = {}
		multipleSection?.[itemId]?.map((d?: any) => {
			if (id == d?.id) {
				d?.att_json?.map((data?: any) => {
					form.getFieldValue([current, `${itemId}`, "file", `${d?.id}`, `${data?.id}`]) != undefined && list.push(form.getFieldValue([current, `${itemId}`, "file", `${d?.id}`, `${data?.id}`]))
				})
				node_data.node_id = d?.node_id
				node_data.qa_params_value_mapping_id = d?.qa_params_value_mapping_id
			}
		})

		const images = list[0]?.map((d?: any) => d?.uid)
		node_data.images_id = images
		node_data.project_id = pro_id
		node_data.type = 2
		node_data.node_id && dispatch(nodeDelete(node_data))

		const data = multipleSection?.[itemId]?.filter((d?: any) => d?.id != id)
		multipleSection?.[itemId]?.map((d: any) => {
			if (id == d?.id) {
				d?.att_json?.map((data: any) => {
					form.setFieldValue([current, `${itemId}`, "value", d?.id, `${data?.id}`], "")
					form.setFieldValue([current, `${itemId}`, "file", d?.id, `${data?.id}`], [])
				})
			}
		})
		setMultipleSection({
			...multipleSection, [`${itemId}`]: [
				...data
			]
		});
		//onChange()
	}
	// useEffect(() => {
	// 	//
	// }, [multipleSection, setMultipleSection])

	return (
		<>
			{dynamicFieldType &&
				dynamicFieldType.map((item: any, index: number) => {
					form_att = JSON.parse(item.data);
					if (item.input_type === "Drop Down") {
						// if(location.pathname.includes("/add")){
						//   form.setFieldValue([
						//     `${current}`,
						//     `${item.id}`,
						//     ["file"],
						//   ],[])
						// }
						return (
							<Col
								span={12}
								key={`${item.attribute_key}-${index}`}
								className="commanCol"
							>
								<div className="commanQue dpdQuestion">
									<InputBox.Select
										label={`${item.params_label_short_text}`}
										name={[`${current}`, `${item.id}`, "value"]}
										disabled={location.pathname.includes("/read")}
										options={{
											list: item.input_options
												? JSON.parse(item.input_options)
												: [],
											valueKey: "id",
											textKey: "name",
										}}
										rules={
											location.pathname.includes("/read")
												? [{ required: false }]
												: rules.selectFields(
													undefined,
													dynamicFieldType,
													item,
													form
												)
										}
										onChange={() => {
											onChange();
										}}
										initialValue={null}
									/>
									<Tooltip title={item.params_label_full_text}>
										<div className="infoIcon listIcon">
											<InfoIcon />
										</div>
									</Tooltip>
								</div>
								{form_att.photo_required == 1 && (
									<InputBox.Upload
										name={[`${current}`, `${item.id}`, ["file"]]}
										required={true}
										label="Upload Document"
										fileList={form.getFieldValue([
											`${current}`,
											`${item.id}`,
											["file"],
										])}
										maxCount={5}
										disabled={
											location.pathname.includes("/read") ? true : false
										}
										onRemove={(e) => {
											e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
										}}
										onChange={
											onChange
										}
									/>
								)}
								{form_att.remark_display == 1 &&
									(form_att?.type == "number" ? (
										<InputBox.Text
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.numberFields
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									) : (
										<InputBox.TextArea
											rows={4}
											maxLength={200}
											//label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.dynamicFields(
															undefined,
															dynamicFieldType,
															item,
															form
														)
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									))}
							</Col>
						);
					}
					if (item.input_type === "Date" || item.input_type === "Date Picker") {
						return (
							<Col
								span={12}
								key={`${item.attribute_key}-${index}`}
								className="commanCol"
							>
								<div className="commanQue">
									<InputBox.DatePicker
										label={`${item.params_label_short_text}`}
										name={[`${current}`, `${item.id}`, "value"]}
										disabled={location.pathname.includes("/read")}
										rules={
											location.pathname.includes("/read")
												? [{ required: false }]
												: checkValue
													? rules.dateFields(
														undefined,
														dynamicFieldType,
														item,
														form
													)
													: [{ required: false }]
										}
										// onChange={(date: any) => form.setFieldValue([`${item.id}`, 'value'], date.format(CONSTANT.POST_DATE_FORMAT))}
										onChange={() => {
											onChange();
										}}
										initialValue={null}
									/>
									<Tooltip title={item.params_label_full_text}>
										<div className="infoIcon listIcon">
											<InfoIcon />
										</div>
									</Tooltip>
								</div>
								{form_att.photo_required == 1 && (
									<InputBox.Upload
										name={[`${current}`, `${item.id}`, ["file"]]}
										required={true}
										label="Upload Document"
										fileList={form.getFieldValue([
											`${current}`,
											`${item.id}`,
											["file"],
										])}
										maxCount={5}
										disabled={
											location.pathname.includes("/read") ? true : false
										}
										onRemove={(e) => {
											e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
										}}
										onChange={
											onChange
										}
									/>
								)}
								{form_att.remark_display == 1 &&
									(form_att?.type == "number" ? (
										<InputBox.Text
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.numberFields
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									) : (
										<InputBox.TextArea
											rows={4}
											maxLength={200}
											//label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.dynamicFields(
															undefined,
															dynamicFieldType,
															item,
															form
														)
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									))}
							</Col>
						);
					}
					if (item.input_type === "Text Box" || item.input_type === "TextBox") {
						if (form_att?.required_if == dynamicFieldType[index - 1]?.params_key && radioButton == "1") {
							const file = form.getFieldValue([`${current}`, `${item.id}`, "file"])
							file?.map((data: any) => {
								if (data?.uid && !data?.size) {
									dispatch(imageDelete(data?.uid))
								}
							})
							form.setFieldValue([`${current}`, `${item.id}`, "value"], null)
							form.setFieldValue([`${current}`, `${item.id}`, "remark"], null)
							form.setFieldValue([`${current}`, `${item.id}`, "file"], [])
							return (<></>)
						}
						// `${item.params_label_short_text}` == 'Pile No'
						//     ? rules.dynamicFields()
						//     : undefined
						else {
							return (
								<Col
									span={12}
									key={`${item.attribute_key}-${index}`}
									className="commanCol"
								>
									<div className="commanQue">
										<InputBox.Text
											type={`${form_att.type}`}
											label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "value"]}
											disabled={
												location.pathname.includes("/read")
													? true
													: location.pathname.includes("/update")
														? form_att.first_textbox_disabled
														: false
											}
											maxLength={100}
											rules={
												// location.pathname.includes("/read")
												// 	? [{ required: false }]
												// 	: checkValue
												rules.multipleField(
													undefined,
													dynamicFieldType,
													form_att,
													form
												)
												// : [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
										<Tooltip title={item.params_label_full_text}>
											<div className="infoIcon listIcon">
												<InfoIcon />
											</div>
										</Tooltip>
									</div>
									{form_att.remark_display == 1 &&
										(form_att?.type == "number" ? (
											<InputBox.Text
												type={`${form_att.type}`}
												//label={`${item.params_label_short_text}`}
												name={[`${current}`, `${item.id}`, "remark"]}
												disabled={location.pathname.includes("/read")}
												rules={
													location.pathname.includes("/read")
														? [{ required: false }]
														: checkValue
															? rules.numberFields
															: [{ required: false }]
												}
												onChange={() => {
													onChange();
												}}
												initialValue={null}
											/>
										) : form_att?.type == "text" && form_att?.remarks_required_text_area ? (
											<InputBox.TextArea
												rows={4}
												//label={`${item.params_label_short_text}`}
												name={[`${current}`, `${item.id}`, "remark"]}
												disabled={location.pathname.includes("/read")}
												rules={
													location.pathname.includes("/read")
														? [{ required: false }]
														: checkValue
															? rules.dynamicFields(
																undefined,
																dynamicFieldType,
																item,
																form
															)
															: [{ required: false }]
												}
												onChange={() => {
													onChange();
												}}
												initialValue={null}
												maxLength={200}
											/>
										) : <InputBox.Text
											//type={`${form_att.type}`}
											//label={`${item.params_label_short_text}`}

											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											maxLength={100}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.dynamicFields(
															undefined,
															dynamicFieldType,
															form_att,
															form
														)
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>)}
									{form_att.photo_required == 1 && (
										<InputBox.Upload
											name={[`${current}`, `${item.id}`, ["file"]]}
											required={true}
											//label="Upload Document"
											fileList={form.getFieldValue([
												`${current}`,
												`${item.id}`,
												["file"],
											])}
											maxCount={5}
											disabled={
												location.pathname.includes("/read") ? true : false
											}
											onRemove={(e) => {
												e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
											}}
											onChange={
												onChange
											}
										/>
									)}
								</Col>
							);
						}
					}
					if (item.input_type === "Textarea") {
						// const rule = rules.dynamicFields()
						// `${item.params_label_short_text}` == 'Pile No'
						//     ? rules.dynamicFields()
						//     : undefined
						return (
							<Col
								span={12}
								key={`${item.attribute_key}-${index}`}
								className="commanCol"
							>
								<InputBox.TextArea
									rows={4}
									maxLength={200}
									label={`${item.params_label_short_text}`}
									name={[`${current}`, `${item.id}`, "value"]}
									disabled={location.pathname.includes("/read")}
									rules={
										location.pathname.includes("/read")
											? [{ required: false }]
											: checkValue
												? rules.dynamicFields(
													undefined,
													dynamicFieldType,
													item,
													form
												)
												: [{ required: false }]
									}
									onChange={() => {
										onChange();
									}}
									initialValue={null}
								/>
								{form_att.remark_display == 1 &&
									(item.data?.type == "number" ? (
										<InputBox.Text
											type={`${form_att.type}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.numberFields
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									) : form_att?.remarks_required_text_area === "true" ? (
										<InputBox.TextArea
											rows={4}
											maxLength={200}
											//label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "value"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.dynamicFields(
															undefined,
															dynamicFieldType,
															item,
															form
														)
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									) : null)}
							</Col>
						);
					}
					if (item.input_type === "Radio Button") {
						return (
							<Col
								span={12}
								key={`${item.attribute_key}-${index}`}
								className="commanCol"
							>
								<div className="commanQue">
									<InputBox.Radio
										label={`${item.params_label_short_text}`}
										name={[`${current}`, `${item.id}`, "value"]}
										disabled={location.pathname.includes("/read")}
										options={{
											list: item.input_options
												? JSON.parse(item.input_options)
												: [],
											valueKey: "id",
											textKey: "name",
										}}
										rules={
											location.pathname.includes("/read")
												? [{ required: false }]
												: checkValue
													? rules.radioButton(
														undefined,
														// dynamicFieldType,
														item
														// form
													)
													: [{ required: false }]
										}
										onChange={(e) => {
											onChange();
											if (item?.params_key == "overall_progress_is_as_per_schedule") {
												setRadioButton(e.target.value)
											} else if (item?.params_key == "identification_of_damage_line_or_manhole") {
												setMultipleRadio(e.target.value)
											}

										}}
										initialValue={"2"}
									/>
									<Tooltip title={item.params_label_full_text}>
										<div className="infoIcon listIcon">
											<InfoIcon />
										</div>
									</Tooltip>
								</div>
								{form_att.photo_required == 1 && (
									<InputBox.Upload
										name={[`${current}`, `${item.id}`, ["file"]]}
										required={true}
										//label="Upload Document"
										fileList={form.getFieldValue([
											`${current}`,
											`${item.id}`,
											["file"],
										])}
										maxCount={5}
										disabled={
											location.pathname.includes("/read") ? true : false
										}
										onRemove={(e) => {
											e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
										}}
										onChange={
											onChange
											// const image = form.getFieldValue([
											// 	`${current}`,
											// 	`${item.id}`,
											// 	["file"],
											// ])
											// if (image.length > 0) {
											// 	setDisableSubmit(false)
											// } else {
											// 	setDisableSubmit(true)
											// }
											// onChange()
											// form.validateFields().then(() => {
											// 	setDisableSubmit(false)
											// }).catch(() => {
											// 	setDisableSubmit(true)
											// })
											//}
										}
									/>
								)}
								{form_att.remark_display == 1 &&
									(form_att?.type == "number" ? (
										<>
											<InputBox.Text
												hidden={true}
												name={[`${current}`, `${item.id}`, "max"]}
												initialValue={1}
											/>
											<Form.Item
												noStyle
												shouldUpdate={(prevValues, currentValues) =>
													prevValues.max !== currentValues.max
												}
											>
												{({ getFieldValue }) => (
													<InputBox.Text
														name={[`${current}`, `${item.id}`, "remark"]}
														//maxLength={getFieldValue("max")}
														maxLength={100}
														disabled={location.pathname.includes("/read")}
														// onKeyDown={handleKeyDown}
														rules={
															location.pathname.includes("/read")
																? [{ required: false }]
																: checkValue
																	? rules.numberDynamicFields(
																		undefined,
																		PILE_CAP?.read?.[`${current}`],
																		item,
																		form
																	)
																	: [{ required: false }]
														}
														onChange={() => {
															onChange();
														}}
														initialValue={null}
													/>
												)}
											</Form.Item>
										</>
									) : form_att?.type == "text" && !form_att?.remarks_required_text_area ? (
										<InputBox.Text
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: rules.dynamicFields(
														undefined,
														dynamicFieldType,
														item,
														form
													)
											}
											onChange={() => {
												onChange();
											}}
											//maxLength={200}
											maxLength={100}
											initialValue={null}
										/>
									) : form_att?.type == "Drop Down" ?
										<InputBox.Select
											//label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											options={{
												list: form_att?.option
													? form_att?.option
													: [],
												valueKey: "id",
												textKey: "name",
											}}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: rules.selectFields(
														undefined,
														dynamicFieldType,
														item,
														form
													)
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
										: (
											<InputBox.TextArea
												rows={4}
												maxLength={200}
												name={[`${current}`, `${item.id}`, "remark"]}
												disabled={location.pathname.includes("/read")}
												rules={
													location.pathname.includes("/read")
														? [{ required: false }]
														: checkValue
															? rules.dynamicFields(
																undefined,
																dynamicFieldType,
																item,
																form
															)
															: [{ required: false }]
												}
												onChange={() => {
													onChange();
												}}
												initialValue={null}
											/>
										))}
							</Col>
						);
					}
					if (item.input_type === "Checkbox") {
						return (
							<Col
								span={6}
								key={`${item.attribute_key}-${index}`}
								className="commanCol"
							>
								<div className="commanQue">
									<InputBox.Checkbox
										label={`${item.params_label_short_text}`}
										name={[`${current}`, `${item.id}`, "value"]}
										disabled={location.pathname.includes("/read")}
										options={{
											list: item.input_options
												? JSON.parse(item.input_options)
												: [],
											valueKey: "id",
											textKey: "name",
										}}
										onChange={() => {
											onChange();
										}}
										initialValue={null}
									/>
									<Tooltip title={item.params_label_full_text}>
										<div className="infoIcon">
											<InfoIcon />
										</div>
									</Tooltip>
								</div>
								{form_att.remark_display == 1 &&
									(item.data?.type == "number" ? (
										<InputBox.Text
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.numberFields
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									) : (
										<InputBox.TextArea
											rows={4}
											maxLength={200}
											// label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											rules={
												location.pathname.includes("/read")
													? [{ required: false }]
													: checkValue
														? rules.dynamicFields(
															undefined,
															dynamicFieldType,
															item,
															form
														)
														: [{ required: false }]
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>
									))}
							</Col>
						);
					}
					if (item?.input_type == "Remark" && form_att?.remark_display == 1) {
						return (<>
							<Col span={12} className="remarkCol">
								<div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
									{form_att.remark_display == 1 &&
										(item.data?.type == "number" ? (
											<InputBox.Text
												label={`${item.params_label_short_text}`}
												name={[`${current}`, `${item.id}`, "remark"]}
												disabled={location.pathname.includes("/read")}
												rules={
													rules.multipleField(
														undefined,
														dynamicFieldType,
														form_att,
														form
													)
												}
												onChange={() => {
													onChange();
												}}
												initialValue={null}
												maxLength={100}
											/>
										) : item.data?.type == "text" && item?.data?.remarks_required_text_area == true ? (
											<InputBox.TextArea
												rows={4}
												label={`${item.params_label_short_text}`}
												name={[`${current}`, `${item.id}`, "remark"]}
												disabled={location.pathname.includes("/read")}
												rules={
													rules.multipleField(
														undefined,
														dynamicFieldType,
														form_att,
														form
													)
												}
												onChange={() => {
													onChange();
												}}
												initialValue={null}
												maxLength={200}
											/>
										) : <InputBox.Text
											//type={`${form_att.type}`}
											label={`${item.params_label_short_text}`}
											name={[`${current}`, `${item.id}`, "remark"]}
											disabled={location.pathname.includes("/read")}
											maxLength={100}
											rules={
												rules.multipleField(
													undefined,
													dynamicFieldType,
													form_att,
													form
												)
											}
											onChange={() => {
												onChange();
											}}
											initialValue={null}
										/>)}
									<Tooltip title={item.params_label_full_text}>
										<div className="infoIcon">
											<InfoIcon />
										</div>

									</Tooltip>
								</div>

								{form_att.photo_required == 1 && (
									<InputBox.Upload
										name={[`${current}`, `${item.id}`, ["file"]]}
										required={true}
										//label="Upload Document"
										fileList={form.getFieldValue([
											`${current}`,
											`${item.id}`,
											["file"],
										])}
										maxCount={5}
										disabled={
											location.pathname.includes("/read") ? true : false
										}
										onRemove={(e) => {
											e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
										}}
										onChange={
											onChange
										}
									/>
								)}
							</Col>
						</>)

					}
					if (item?.input_type == "Single") {
						// const attr = form_att?.multiple
						const attr = form_att
						if (attr[0].display?.key == dynamicFieldType[index - 1]?.params_key && catRadioButton == 1 && attr[0].display?.key == "has_the_plot_possession_been_cleared") {
							attr?.map((att?: any, indx?: any) => {
								form.setFieldValue([current, `${item.id}`, "value", "1", `${att.id}`], "")
							})
							return (<></>)
						}
						else {
							return <>
								{
									<>
										<Col span={18} key={`${item.attribute_key}-${index}`}
										>
											<div className="plusIconWrap">
												<label>{item?.params_label_short_text}
												</label>
												<Tooltip title={item.params_label_full_text}>
													<div className="infoIcon">
														<InfoIcon />
													</div>
												</Tooltip>
											</div>
											<Row className="form-control-main">
												{
													attr?.map((att?: any, indx?: any) => {
														if (att?.type == "Text") {
															if (att?.required_if?.key == item?.params_key && typeOfSelect != 6 && att?.required_if?.key == "type_of_encroachment") {
																form.setFieldValue([current, `${item.id}`, "value", "1", `${att.id}`], "")
																return (<></>)
															}
															else {
																return (
																	<>
																		<Col key={`${att.name}-${index}`} span={6}>
																			<InputBox.Text
																				maxLength={100}
																				label={`${att?.name}`}
																				name={[current, `${item.id}`, "value", "1", `${att.id}`]}
																				disabled={location.pathname.includes("/read")}
																				required={att?.required}
																				rules={
																					// location.pathname.includes("/read")
																					// 	? [{ required: false }]
																					att?.required_if?.key == item?.params_key && att?.required_if?.key == "is_the_network_healthy" && requiredRadio == 1 ? [{required:false}] :rules.multipleField(
																			undefined,
																			dynamicFieldType,
																			att,
																			form
																			)
																				}
																			// onChange={() => {
																				// 	onChange()
																				// }}
																				/>
																		</Col>&nbsp;&nbsp;&nbsp;
																	</>
																);
															}
														} else if (att?.type == "Drop Down") {
															return (
																<>
																	<Col key={`${att.name}-${index}`} span={6}>
																		<InputBox.Select
																			label={`${att?.name}`}
																			name={[current, `${item.id}`, "value", "1", `${att.id}`]}
																			disabled={location.pathname.includes("/read")}
																			options={{
																				list: att?.option
																					? att?.option
																					: [],
																				valueKey: "id",
																				textKey: "name",
																			}}
																			onChange={(e) => {
																				if (item?.params_key == "type_of_encroachment") {
																					SetTypeOfSelect(e)
																				}
																			}}
																			rules={
																				// location.pathname.includes("/read")
																				// 	? [{ required: false }]
																				rules.multipleField(
																					undefined,
																					dynamicFieldType,
																					att,
																					form
																				)
																			}
																			initialValue={null}
																		/>
																	</Col>
																</>
															)
														} else if (att?.type == "Radio Button") {
															return (
																<>
																	<Col key={`${att.name}-${index}`} span={4}>
																		<InputBox.Radio
																			label={`${att.name}`}
																			name={[current, `${item.id}`, "value", "1", `${att.id}`]}
																			disabled={location.pathname.includes("/read")}
																			required={att?.required}
																			rules={
																				rules.multipleField(
																					undefined,
																					dynamicFieldType,
																					att,
																					form
																				)
																			}
																			options={{
																				list: att?.options,
																				valueKey: "id",
																				textKey: "name",
																			}}
																			initialValue={"2"}
																			onChange={(e) => {
																				if (item?.params_key == "has_the_plot_possession_been_cleared") {
																					setCatRadioButton(e.target.value)
																				} else if (item?.params_key == "is_the_network_healthy") {
																					setRequiredRadio(e.target.value)
																				}
																			}}
																		// onChange={() => {
																		// 	onChange()
																		// }}
																		/>
																	</Col>
																</>
															);
														} else if (att?.type == "File") {
															return (
																<>
																	<Col key={`${att.name}-${index}`} span={10}>
																		<InputBox.Upload
																			label={`${att.name}`}
																			name={[current, `${item.id}`, "value", "1", `${att.id}`]}
																			disabled={location.pathname.includes("/read")}
																			required={att?.required}
																			fileList={form.getFieldValue([current, `${item.id}`, "value", "1", `${att.id}`]) ? form.getFieldValue([current, `${item.id}`, "value", "1", `${att.id}`]) : []}
																			maxCount={2}
																			onRemove={(e) => {
																				e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
																			}}
																		// onChange={
																		// 	onChange
																		// }
																		/>
																	</Col>
																</>
															);
														}
													})
												}
											</Row>
										</Col></>
								}</>
						}
					}
					if (item?.input_type === "Multiple") {
						const cnt = 0;
						const attr_json = JSON.parse(item.data)
						if ((multipleSection?.[`${item.id}`]?.length <= 0 || multipleSection?.[`${item.id}`]?.length == undefined)) {
							handleAddMultiple({ qa_param_id: item?.id?.toString(), id: parseInt(multipleSection?.[`${item.id}`] ? multipleSection?.[`${item.id}`]?.length : cnt), att_json: attr_json, type: "add" })
						}
						const remove_count = multipleSection?.[`${item.id}`]?.map((itm: any) => {
							if (itm?.qa_param_id == item?.id) {
								return itm?.qa_param_id
							}
						})?.filter((data: any) => data != undefined)
						if (attr_json[0]?.required_if_yes == dynamicFieldType[index - 1]?.params_key && form.getFieldValue([`${current}`, `${dynamicFieldType[index - 1]?.id}`, "value"]) == 2) {
							multipleSection?.[`${item.id}`]?.map((d: any, indx: any) => {
								form_att?.map((att: any, index?: any) => {
									form.setFieldValue([current, `${item.id}`, "value", `${d?.id}`, `${att.id}`], "")
								})
								indx != 0 && handleRemoveMultiple(current, d?.id, item.id)
							})
							return (<></>)
						}
						else {
							return <>
								{
									<>
										<Col span={18} key={`${item.attribute_key}-${index}`}
										>
											<div className="plusIconWrap">
												<label>{item?.params_label_short_text}
													&nbsp;
													<Button className={(location.pathname.includes("/read") || multipleSection?.[`${item.id}`]?.length >= 5) ? "disabledAddIcon" : "addIcon"}
														onClick={() => { handleAddMultiple({ qa_param_id: item?.id?.toString(), id: parseInt(multipleSection ? multipleSection?.length : cnt), att_json: attr_json, type: "add" }) }}
														disabled={(location.pathname.includes("/read") || multipleSection?.[`${item.id}`]?.length >= 5)}
													><PlusIcon /></Button>
												</label>
												<Tooltip title={item.params_label_full_text}>
													<div className="infoIcon">
														<InfoIcon />
													</div>
												</Tooltip>
											</div>

											{
												multipleSection?.[`${item.id}`]?.map((d: any, indx: any) => {
													if (d?.qa_param_id == item?.id) {
														return (
															<>
																<Row className="costSection" gutter={10} key={d?.id}>
																	{
																		form_att?.map((att: any, index?: any) => {
																			if (att?.type == "Text") {

																				return (
																					<>
																						<Col key={`${att.name}-${index}`} span={4}>
																							<InputBox.Text
																								maxLength={40}
																								label={`${att?.name}`}
																								name={[current, `${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																								disabled={location.pathname.includes("/read")}
																								required={att?.required}
																								rules={
																									// location.pathname.includes("/read")
																									// 	? [{ required: false }]
																									rules.multipleField(
																										undefined,
																										dynamicFieldType,
																										att,
																										form
																									)
																								}
																							// onChange={() => {
																							// 	onChange()
																							// }}
																							/>
																						</Col>
																					</>
																				);
																			} else if (att?.type == "Radio Button") {
																				//form.getFieldValue([current, `${item.id}`, "value", `${d?.id}`, `${att.id}`])!=null && form.setFieldValue([current, `${item.id}`, "value", `${d?.id}`, `${att.id}`], "2")
																				return (
																					<>
																						<Col key={`${att.name}-${index}`} span={4}>
																							<InputBox.Radio
																								label={`${att.name}`}
																								name={[current, `${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																								disabled={location.pathname.includes("/read")}
																								required={att?.required}
																								rules={
																									rules.multipleField(
																										undefined,
																										dynamicFieldType,
																										att,
																										form
																									)
																								}
																								options={{
																									list: att?.option,
																									valueKey: "id",
																									textKey: "name",
																								}}
																								initialValue={"2"}
																							// onChange={() => {
																							// 	onChange()
																							// }}
																							/>
																						</Col>
																					</>
																				);
																			}
																			else if (att?.type == "File") {
																				return (
																					<>
																						<Col key={`${att.name}-${index}`} span={10}>
																							<InputBox.Upload
																								label={`${att.name}`}
																								name={[current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]}
																								disabled={location.pathname.includes("/read")}
																								required={att?.required}
																								fileList={form.getFieldValue([current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]) ? form.getFieldValue([current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]) : []}
																								maxCount={2}
																								// disabled={
																								// 	location.pathname.includes("/read") ? true : false
																								// }
																								// rules={
																								// 	// location.pathname.includes("/read")
																								// 	// 	? [{ required: false }]
																								// 	rules.multipleField(
																								// 		undefined,
																								// 		dynamicFieldType,
																								// 		att,
																								// 		form
																								// 	)
																								// }
																								onRemove={(e) => {
																									e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
																								}}
																							// onChange={
																							// 	onChange
																							// }
																							/>
																						</Col>
																					</>
																				);
																			}
																		})
																	}
																	<Col span={1} className="costRemoveIcon">
																		<Button className={remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0 ? "disableRemove" : "removeIcon"}
																			onClick={() => handleRemoveMultiple(current, d?.id, item.id)} disabled={location.pathname.includes("/read") ? location.pathname.includes("/read") : remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0}>
																			<RemoveClick />
																		</Button>
																	</Col>

																</Row>
															</>
														)
													}

												})
											}
										</Col>
									</>
								}</>
						}
						//} 
					}
					if (item?.input_type === "Single_Multiple") {
						const attr = form_att?.multiple
						const attr_json = form_att?.data?.[0]
						const cnt = 0;
						if ((multipleSection?.[`${item.id}`]?.length <= 0 || multipleSection?.[`${item.id}`]?.length == undefined)) {
							handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection?.[`${item.id}`] ? multipleSection?.[`${item.id}`]?.length : cnt), att_json: attr, type: "add" })
							// } else if (multipleSection?.length > 0 && location.pathname.includes("/add")) {
							// 	if (multipleSection?.map((itm: any) => itm?.qa_param_id)?.includes(item?.id)) {
							// 		//
							// 	} else {
							// 		handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection ? multipleSection?.length : cnt), att_json: attr, type: "add" })
							// 	}
							// }else if((multipleSection?.length <= 0 || multipleSection?.length > 0) && (location.pathname.includes("/read") || location.pathname.includes("/update")) && isMultiple){
							// 	if (multipleSection?.map((itm: any) => itm?.qa_param_id)?.includes(item?.id)) {
							// 		//
							// 	} else {
							// 		handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection ? multipleSection?.length : cnt), att_json: attr, type: "add" })
							// 	}
						}
						const remove_count = multipleSection?.[`${item.id}`]?.map((itm: any) => {
							if (itm?.qa_param_id == item?.id) {
								return itm?.qa_param_id
							}
						})?.filter((data: any) => data != undefined)
						return <>
							{
								<>
									<Col span={18} key={`${item.attribute_key}-${index}`}
									>
										<div className="plusIconWrap">
											<label>{item?.params_label_short_text}
												&nbsp;
												<Button className={(location.pathname.includes("/read") || multipleSection?.[`${item.id}`]?.length >= 5) ? "disabledAddIcon" : "addIcon"}
													onClick={() => { handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection ? multipleSection?.length : cnt), att_json: attr, type: "add" }) }}
													disabled={(location.pathname.includes("/read") || multipleSection?.[`${item.id}`]?.length >= 5)}
												><PlusIcon /></Button>
											</label>
											<Tooltip title={item.params_label_full_text}>
												<div className="infoIcon">
													<InfoIcon />
												</div>
											</Tooltip>
										</div>

										{
											multipleSection?.[`${item.id}`]?.map((d: any, indx: any) => {
												if (d?.qa_param_id == item?.id) {
													return (
														<>
															<Row className="costSection" gutter={10} key={d?.id}>
																{
																	attr?.map((att: any, index?: any) => {
																		if (att?.type == "Text") {

																			return (
																				<>
																					<Col key={`${att.name}-${index}`} span={4}>
																						<InputBox.Text
																							maxLength={40}
																							label={`${att?.name}`}
																							name={[current, `${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																							disabled={location.pathname.includes("/read")}
																							required={att?.required}
																							rules={
																								// location.pathname.includes("/read")
																								// 	? [{ required: false }]
																								rules.multipleField(
																									undefined,
																									dynamicFieldType,
																									att,
																									form
																								)
																							}
																						// onChange={() => {
																						// 	onChange()
																						// }}
																						/>
																					</Col>
																				</>
																			);
																		} else if (att?.type == "Radio Button") {
																			//form.getFieldValue([current, `${item.id}`, "value", `${d?.id}`, `${att.id}`])!=null && form.setFieldValue([current, `${item.id}`, "value", `${d?.id}`, `${att.id}`], "2")
																			return (
																				<>
																					<Col key={`${att.name}-${index}`} span={4}>
																						<InputBox.Radio
																							label={`${att.name}`}
																							name={[current, `${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																							disabled={location.pathname.includes("/read")}
																							required={att?.required}
																							rules={
																								rules.multipleField(
																									undefined,
																									dynamicFieldType,
																									att,
																									form
																								)
																							}
																							options={{
																								list: att?.option,
																								valueKey: "id",
																								textKey: "name",
																							}}
																							initialValue={"2"}
																						// onChange={() => {
																						// 	onChange()
																						// }}
																						/>
																					</Col>
																				</>
																			);
																		}
																		else if (att?.type == "File") {
																			return (
																				<>
																					<Col key={`${att.name}-${index}`} span={10}>
																						<InputBox.Upload
																							label={`${att.name}`}
																							name={[current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]}
																							disabled={location.pathname.includes("/read")}
																							required={att?.required}
																							fileList={form.getFieldValue([current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]) ? form.getFieldValue([current, `${item.id}`, "file", `${d?.id}`, `${att.id}`]) : []}
																							maxCount={2}
																							// disabled={
																							// 	location.pathname.includes("/read") ? true : false
																							// }
																							// rules={
																							// 	// location.pathname.includes("/read")
																							// 	// 	? [{ required: false }]
																							// 	rules.multipleField(
																							// 		undefined,
																							// 		dynamicFieldType,
																							// 		att,
																							// 		form
																							// 	)
																							// }
																							onRemove={(e) => {
																								e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
																							}}
																						// onChange={
																						// 	onChange
																						// }
																						/>
																					</Col>
																				</>
																			);
																		}
																	})
																}
																<Col span={1} className="costRemoveIcon">
																	<Button className={remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0 ? "disableRemove" : "removeIcon"}
																		onClick={() => handleRemoveMultiple(current, d?.id, item.id)} disabled={location.pathname.includes("/read") ? location.pathname.includes("/read") : remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0}>
																		<RemoveClick />
																	</Button>
																</Col>
															</Row>
														</>
													)
												}

											})
										}
										<Row>{attr_json?.remark_display == 1 && multipleSection?.[`${item.id}`]?.length >= 1 &&
											(attr_json?.type == "number" ? (
												<InputBox.Text
													type={`${form_att.type}`}
													maxLength={100}
													//label={`${item.params_label_short_text}`}
													name={[[current, `${item.id}`, "remark"]]}
													disabled={location.pathname.includes("/read")}
													// rules={
													// 	location.pathname.includes("/read")
													// 		? [{ required: false }]
													// 		: checkValue
													// 			? rules.numberFields
													// 			: [{ required: false }]
													// }
													rules={
														rules.multipleField(
															undefined,
															dynamicFieldType,
															attr_json,
															form
														)
													}
													onChange={() => {
														onChange();
													}}
													initialValue={null}
												/>
											) : (
												<InputBox.TextArea
													rows={4}
													//label={`${item.params_label_short_text}`}
													name={[`${current}`, `${item.id}`, "remark"]}
													disabled={location.pathname.includes("/read")}
													// rules={
													// 	location.pathname.includes("/read")
													// 		? [{ required: false }]
													// 		: checkValue
													// 			? rules.dynamicFields(
													// 				undefined,
													// 				dynamicFieldType,
													// 				item,
													// 				form
													// 			)
													// 			: [{ required: false }]
													// }
													rules={
														rules.multipleField(
															undefined,
															dynamicFieldType,
															attr_json,
															form
														)
													}
													onChange={() => {
														onChange();
													}}
													initialValue={null}
													maxLength={200}
												/>
											))}</Row>
										{attr_json?.photo_required == 1 && multipleSection?.length >= 1 && (
											<InputBox.Upload
												name={[`${current}`, `${item.id}`, ["file"]]}
												required={true}
												label="Upload Document"
												fileList={form.getFieldValue([
													`${current}`,
													`${item.id}`,
													["file"],
												])}
												maxCount={5}
												disabled={
													location.pathname.includes("/read") ? true : false
												}
												onRemove={(e) => {
													e && !e.size && e?.uid && dispatch(imageDelete(e?.uid));
												}}
												onChange={
													onChange
												}
											/>
										)}
									</Col>
								</>
							}</>
						//} 
					}
				})}
		</>
	);
};
export default DynamicForm;
