import React from "react";
import FirstStep from "./elements/FirstStep";


const FormComponent: React.FC<any> = () => {
  return (
    <>
      <FirstStep
        onChange={undefined}
        handleSubmit={undefined} form={undefined}/>
    </>
  );
};
export default FormComponent;
