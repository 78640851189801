const baseUrl = "/v1/admin/cement-inventory";
const lab = "/v1/admin/laboratory"
const deleteUrl = `/v1/admin`
export const apiUrls = {
  create: (id: number): string => `${baseUrl}/${id}/create`,
  read: (id: number): string => `${baseUrl}/${id}/read`,
  list: (id: number): string => `${baseUrl}/${id}/list`,
  delete: `${deleteUrl}/row-material-inventory/delete`,
	deleteReport:`${deleteUrl}/row-material-report/delete`,
  createReport: (id: any): string => `/v1/admin/cement-test-perform/${id}/create`,
  reportList: `/v1/admin/cement-test-perform/list`,
  readReport: (id: any): string => `/v1/admin/cement-test-perform/${id}/read`,
  getSteelDiameter: `/v1/admin/cement-test-perform/getSteelDiameter`,
  testType: (id: any): string => `/v1/admin/steel-inventory/${id}/getTestType`,
  laboratory: `${lab}/laboratorylist`,

};
