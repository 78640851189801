import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import {
	PileCapElementBreadcrumb,
	AddPileCapElementBreadcrumb,
	AddCheckList,
} from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import PileListing from "./components/PileListing/Listing";
import PierListing from "./components/PierListing/Listing";
import PierCapListing from "./components/PierCapListing/Listing";
import BearingListing from "./components/BearingListing/Listing";
import RTWallListing from "./components/RTWallListing/Listing";
import BoxGirderSpanListing from "./components/BoxGirderSpanListing/Listing";
import GriderListing from "./components/GriderListing/Listing";
import SolidSlab from "./components/SolidSlab/Listing";
import DrainageListing from "./components/DrainageListing/Listing";
import { Button, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
	readRecord,
	setPerPageSize,
	setProjectId,
	setRead,
	setSelectedData,
} from "./utils/slice";
import FormComponent from "./components/Form/FormComponent";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";
import { checkPrivileges, structureElementBreadcrumbName, structureElementBreadcrumbUrl } from "../../../config/Global";
import { setDynamicCubeTestingData } from "../CubeTesting/utils/slice";
import { setDynamicPolymerSlurryData } from "../PolymerSlurry/utils/slice";

const moduleName = "Pile Cap Element";

const PileCapElement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	
	const dispatch: AppDispatch = useDispatch();
	const { PILE_CAP, AUTH } = useSelector((state: RootState) => state);
	const [formVisible, setFormVisible] = useState<any>(false);
	const navigate = useNavigate();
	const { structureId, mappingId } = useParams();
	const pro_id: any = localStorage.getItem('projectId')
	const name = structureElementBreadcrumbName(structureId)
	const endUrl = structureElementBreadcrumbUrl(structureId)
	const depId = localStorage.getItem('department_id')
	const department_id: any = localStorage.getItem('department_id')
	const msg = depId == "1" ? `You reached the maximum numbers of ${name.toLowerCase()} that are approved by state R&D design.` :`You reached the maximum numbers of ${name.toLowerCase()}.`
	const disabled = () => {
		if (depId == "1") {
			if (PILE_CAP.count.total_approved === PILE_CAP.count.total_count)
				return true
		}
		else if (depId == '5') {
			if (PILE_CAP.count.total_count >= 1 && (structureId == "19" || structureId == "23"|| structureId == "24"|| structureId == "25")){
				return true
			}	
		}
		else {
			return false
		}
	}

	const navigatePage = () => {
		// if (location.pathname.includes("/checklist")) {
		// 	navigate(`/projects/checklist/add`)
		// } else {
		navigate(`/projects/${structureId}/${endUrl}/add`);
		//}
	};
	const handleClick = () => {
		// dispatch(setSelectedData(null));
		// showModal
		dispatch(setRead(null))
		setFormVisible(true);
		navigatePage();
		localStorage.removeItem("mappingId");
		localStorage.removeItem("isTouched");
	};

	const getDetails = (data: any) => {
		const readPayload = {
			project_id: pro_id,
			qa_category_id: structureId,
			qa_params_value_mapping_id: data?.qa_params_value_mapping_id,
		};
		dispatch(readRecord(readPayload)).then((res: any) => {
			if (res.resultData) {
				navigate(
					`/projects/${structureId}/qa-parameter/${endUrl}/${data.qa_params_value_mapping_id}/update`
				);
			}
		});
		dispatch(setSelectedData(null));
	};

	const handleViewClick = (data: any) => {
		const readPayload = {
			project_id: pro_id,
			qa_category_id: structureId,
			qa_params_value_mapping_id: data?.qa_params_value_mapping_id,
		};
		dispatch(readRecord(readPayload)).then((res: any) => {
			if (res.resultData) {
				navigate(
					`/projects/${structureId}/qa-parameter/${endUrl}/${data.qa_params_value_mapping_id}/read`
				);
			}
		});
		dispatch(setSelectedData(null));
	};

	useEffect(() => {

		if (pro_id && structureId) {
			dispatch(
				setProjectId({ project_id: pro_id, qa_category_id: structureId })
			);
			// dispatch(
			// 	fetchList({
			// 		project_id: projectId,
			// 		qa_category_id: structureId,
			// 	})
			// ).catch((error: any) => error);
		}
	}, [pro_id, structureId]);
	// useEffect(() => {
	// 	if (location.pathname.includes("/checklist")) {
	// 		dispatch(
	// 			setProjectId({ project_id: pro_id, qa_category_id: structureId })
	// 		);
	// 	}
	// }, [pro_id])

	useEffect(() => {
		const locationPath = location.pathname.split("/");

		if (locationPath.length > 4 || location.pathname.includes("/read")) {
			setFormVisible(true);
		} else if (location.pathname.includes("/checklist")) {
			setFormVisible(true)
		} else {
			setFormVisible(false);
		}
		localStorage.removeItem("isTouched");
	}, [location.pathname]); ``

	const onRowClick = (data?: any) => {
		dispatch(setSelectedData(data?.data));
		dispatch(setDynamicCubeTestingData([]))
	};

	const handleReiClick = (data: any) => {
		navigate(
			`/projects/${structureId}/${endUrl}/${data?.qa_params_value_mapping_id}/reinforcement`
		);
		dispatch(setSelectedData(null));
	};
	const handleConClick = (data: any) => {
		navigate(
			`/projects/${structureId}/${endUrl}/${data?.qa_params_value_mapping_id}/concreting`
		);
		dispatch(setSelectedData(null));
	};
	const handleCubeClick = (data: any) => {
		//data
		// navigate("/projects/pile/reinforcement")
		localStorage.setItem("pileNo", data?.input_value);
		navigate(
			`/projects/${structureId}/${endUrl}/${data?.qa_params_value_mapping_id}/add-cubetesting`
		);
		dispatch(setSelectedData(null));
		dispatch(setDynamicCubeTestingData([]))
	};

	const handlepolyClick = (data: any) => {
		localStorage.setItem("pileNo", data?.input_value);
		navigate(
			`/projects/${structureId}/${endUrl}/${data?.qa_params_value_mapping_id}/add-polymer`
		);
		dispatch(setSelectedData(null));
		dispatch(setDynamicPolymerSlurryData([]))
	};
	// const [isModalOpen, setIsModalOpen] = useState(false);

	// const showModal = () => {
	// 	setIsModalOpen(true);
	// };

	// const handleCancel = () => {
	// 	setIsModalOpen(false);
	// };

	return (
		<div className="structureElementWrapper">
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar
						breadcrumbs={
							location.pathname.includes(`/projects/${structureId}`) ? location.pathname == `/projects/${structureId}/${endUrl}`
								? PileCapElementBreadcrumb(name)
								: location.pathname ==
									`/projects/${structureId}/qa-parameter/${endUrl}/${mappingId}/update`
									? AddPileCapElementBreadcrumb(
										structureId,
										`Edit ${name}`,
										name,
										endUrl
									)
									: location.pathname ==
										`/projects/${structureId}/qa-parameter/${endUrl}/${mappingId}/read`
										? AddPileCapElementBreadcrumb(
											structureId,
											`View ${name}`,
											name,
											endUrl
										)
										: AddPileCapElementBreadcrumb(
											structureId,
											`Add ${name}`,
											name,
											endUrl
										) : AddCheckList(localStorage.getItem("isCheckListFill") == "No_data" ? "Add" : "Read")
						}
					>
						{formVisible ? null : (
							<>
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
									<Tooltip
										title={
											disabled()
												? msg
												: ""
										}
									>
										<Button
											className="secondaryBtn pileBtn"
											onClick={handleClick}
											disabled={disabled()}

										>
											{/* {`Add ${name}`} */}
											Add <span className="btnIcon">
												<PlusIcon />
											</span>
										</Button>

									</Tooltip>

								}
								<RecordPerPage
									defaultValue={PILE_CAP.perPage + " per page"}
									onChange={(perPageSize: number) => {
										dispatch(setPerPageSize(perPageSize));
									}}
								/>
							</>
						)}
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							{formVisible ? (
								<FormComponent />
							) : (
								<>
									{
										parseInt(department_id) == 1 && <div>
											<h4 className="pileCount">
												{`Total Nos of ${name}`} :{" "}
												<span>
													{PILE_CAP.count.total_count} / {PILE_CAP.count.total_approved}
												</span>
											</h4>
										</div>
									}

									{structureId === "1" ? (
										<div className="structureList">
											<PileListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Pile Element"}
											/>
										</div>


									) : structureId === "2" ? (
										<div className="structureList">
											<Listing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={moduleName}
											/>
										</div>

									) : structureId === "3" ? (
										<div className="structureList">
											<PierListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Pier Element"}
											/>
										</div>

									) : structureId === "4" ? (
										<div className="structureList">
											<PierCapListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Pier Cap Element"}
											/>
										</div>

									) : structureId === "5" ? (
										<div className="structureList">
											<BearingListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Bearing Element"}
											/>
										</div>

									) : structureId === "6" ? (
										<div className="structureList">
											<GriderListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Grider(PSC or Cast in situ)+Deck Slab Element"}
											/>
										</div>

									) : structureId === "7" ? (
										<div className="structureList">
											<BoxGirderSpanListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Box Girder Span/Voided Grider(Obligatory) Element"}
											/>
										</div>

									) : structureId === "8" ? (
										<div className="structureList">
											<RTWallListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"RT Wall Element"}
											/>
										</div>

									) : structureId === "9" ? (
										<div className="structureList">
											<SolidSlab
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Solid Slab Element"}
											/>
										</div>

									) : (
										<div className="structureList">
											<DrainageListing
												moduleName={moduleName}
												handleViewClick={handleViewClick}
												handleEditClick={getDetails}
												//  handleDeleteClick={setDeleteValue}
												rowData={PILE_CAP.rowData}
												onRowClick={onRowClick}
												moduleInfo={"Drainage Element"}
											/>
										</div>

									)}

								</>
							)}
							{!formVisible ? (
								<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) ? "actionRow" : ""}>
									<div className="actionBtn">
										{
											checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
											<Button
												onClick={() => {
													getDetails(PILE_CAP.selectedData);
												}}
												disabled={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_submit === 0 && PILE_CAP?.selectedData?.status == 1
														? false
														: true
												}
												className={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_submit === 0 && PILE_CAP?.selectedData?.status == 1
														? "activeBtn"
														: "disableBtn"
												}
											>
												Edit
												<SVGIcon icon="edit" />
											</Button>}
										{
											checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
											<Button
												onClick={() => {
													handleViewClick(PILE_CAP.selectedData);
												}}
												disabled={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_submit === 1
														? false
														: true
												}
												className={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_submit === 1
														? "activeBtn"
														: "disableBtn"
												}
											>
												View <SVGIcon icon="view" width={25} />
											</Button>}
										{
											department_id==1 && <>
											<Button
											disabled={
												PILE_CAP.selectedData &&
													PILE_CAP.selectedData?.is_submit !== 0 && PILE_CAP?.selectedData?.status == 1
													? false
													: true
											}
											className={
												PILE_CAP.selectedData &&
													PILE_CAP.selectedData?.is_submit !== 0 && PILE_CAP?.selectedData?.status == 1
													? "activeBtn"
													: "disableBtn"
											}
											onClick={() => handleReiClick(PILE_CAP.selectedData)}
										>
											Reinforcement <SVGIcon icon="ReinforcementIcon" width={25} />
										</Button>
										<Tooltip
											title={
												PILE_CAP.selectedData?.is_reinforcement == 0
													? "Please add reinforcement first"
													: ""
											}
										>
											<Button
												disabled={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_reinforcement !== 0 && PILE_CAP?.selectedData?.status == 1
														? false
														: true
												}
												onClick={() => handleConClick(PILE_CAP.selectedData)}
												className={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_reinforcement !== 0 && PILE_CAP?.selectedData?.status == 1
														? "activeBtn tooltipBtn"
														: "disableBtn tooltipBtn"
												}
											>
												Concreting{" "}
												<SVGIcon icon="ConcreatingIcon" width={28} height={28} />
											</Button>
										</Tooltip>
										<Tooltip
											title={
												PILE_CAP.selectedData?.is_concreating === 0
													? "Please add concreating  first"
													: ""
											}
										>
											<Button
												disabled={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_concreating !== 0 && PILE_CAP?.selectedData?.status == 1
														? false
														: true
												}
												onClick={() => handleCubeClick(PILE_CAP.selectedData)}
												className={
													PILE_CAP.selectedData &&
														PILE_CAP.selectedData?.is_concreating !== 0 && PILE_CAP?.selectedData?.status == 1
														? "activeBtn tooltipBtn"
														: "disableBtn tooltipBtn"
												}
											>
												Cube Testing <SVGIcon icon="CubeTestingIcon" width={25} />
											</Button>
										</Tooltip>
											</>
										}
										{PILE_CAP.selectedData && structureId === "1" &&
											PILE_CAP.selectedData?.is_wet_pile == 1 && (
												<Button
													onClick={() => handlepolyClick(PILE_CAP.selectedData)}
													className={
														PILE_CAP.selectedData &&
															PILE_CAP.selectedData?.is_wet_pile == 1 && PILE_CAP.selectedData?.is_submit == 1 && PILE_CAP?.selectedData?.status == 1
															? "activeBtn"
															: "disableBtn"
													}
													disabled={
														PILE_CAP.selectedData &&
															PILE_CAP.selectedData?.is_wet_pile == 1 && PILE_CAP.selectedData?.is_submit == 1 && PILE_CAP?.selectedData?.status == 1
															? false
															: true
													}
												>
													Polymer Slurry Report{" "}
													<SVGIcon icon="PolymerSlurryIcon" width={25} />
												</Button>
											)}
									</div>

								</div>
							) : (
								""
							)}
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</div>
	);
};

export default PileCapElement;
