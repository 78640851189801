import React, { useEffect } from "react";
import { Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";


interface FormComponentProps {
	form: any
	id: string
	handleSubmit: any
	onValuesChange: any
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {

	useEffect(() => {
		if (editValues) {
			form.setFieldsValue(editValues);
		}
	}, [editValues, form]);

	const onFinish = (data: any) => {
		handleSubmit(data);
	};

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={25}>
					<InputBox.Text
						label="Name"
						name="name"
						rules={rules.dynamicFields()}
						onChange={onValuesChange}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Text
						label="Email"
						name="email"
						rules={rules.EmailField()}
						onChange={onValuesChange}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.Text
						label="Mobile Number"
						type="number"
						name="mobile_no"
						rules={rules.mobileFields()}
						onChange={onValuesChange}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.TextArea
						label="Address of Contractor"
						name="address_of_contractor"
						rules={rules.desConAddress()}
						onChange={onValuesChange}
					/>
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;