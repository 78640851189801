import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	selectFields: (field?: any) => [
		validations.required.select(field),
	],
	optionalFields: [validations.minMax()]

}