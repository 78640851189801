import React, { useCallback, useState } from "react";
import { Button, ModalProps } from "antd";
import CommonModal from "./Modals/CommonModal";
import { useAppDispatch } from "../store/app";
import { dataToFormDataConverter } from "../config/Global";

interface ChangeStatusModalProps extends ModalProps {
  titleName: string;
  close: any;
  data: any;
  callApi?: any;
  className?: any;
  message?: any;
  //keyName?: any 
}

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  titleName,
  close,
  data,
  callApi,
  className,
  message,
  //keyName,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState<boolean>(false);

  const onYesClick = useCallback(() => {
    setSaving(true);
    const isActive = data?.status == 1 ? "0" : "1"
    className?data.status=isActive:''
    const formData=dataToFormDataConverter(data)
    dispatch(callApi(data?.id, className?formData:isActive))
      .then(() => {
        close();
      }).catch(() => {
        setSaving(false)
        close()
        // assignErrorToInput(form, error?.STATUS);
      })
      .finally(() => setSaving(false))
  }, [data]);

  return data ? (
    <CommonModal
      className={className ? "deleteModal" : "commanModal"}
      title={`${data?.status ? `Deactivate ${titleName}` : `Activate ${titleName}`}`}
      open={data ? true : false}
      onCancel={close}
      footer={[
        <Button
          key="1"
          htmlType="button"
          loading={saving}
          onClick={onYesClick}
          className={className ? "secondaryBtn deleteBtn" : "secondaryBtn"}
        >
          Yes
        </Button>,
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          No
        </Button>,

      ]}
      {...rest}
    >
      <div className="deleteNote">
        {message?message:<>Are you sure you want to  {data?.status ? "deactivate" : "activate"} <span>{titleName.toLowerCase()}?</span></>}
      </div>
    </CommonModal>
  ) : (
    <></>
  );
};
export default ChangeStatusModal;
