import React from "react";
import { Spin } from "antd";
import { ReactComponent as Loader } from "../../assets/images/icon/miniLoader.svg";

const PageSpinner: React.FC = () => (

	<div className={"fullscreen__spinner__wrapper"}>
		<Spin size={"large"}
			indicator={
				<>
					<Loader className="loadingSpinner"/>
				</>
			}
		/>
	</div>
);

export default PageSpinner;
