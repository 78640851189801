import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
//import Config from "../../../../config/Config";

interface InitialState {
  isLoading: boolean;
  rowData: any;
}

const initialState = {
  isLoading: false,
  rowData: null,
} as InitialState;

const consolidatedSlice = createSlice({
  name: "CONSOLIDATEDREPORTS",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
    },
    failure: (state) => {
      state.isLoading = false;
    },
    setRowData: (state, action: PayloadAction<any>) => {
      state.rowData = action?.payload;
    },
  },
});

export const {
  start,
  success,
  failure,
  setRowData,
} = consolidatedSlice.actions;

// export const getDynamicFieldType =
//   (payload: any): AppThunk<any> =>
//   async (dispatch) => {
//     try {
//       dispatch(start());
//       const response = await api.post(apiUrls.dynamicFieldType, payload);
//       dispatch(setDynamicFieldType(response.data.data));
//       dispatch(success());
//       return Promise.resolve(response.data);
//     } catch (error: any) {
//       dispatch(failure());
//       return Promise.reject(error.data);
//     }
//   };
/** For Listing:Start */
export const fetchList = (id:number): AppThunk<any> => async (dispatch) => {
  dispatch(start());
  // const modifiedAction = {
  //   project_id: projectId, // Your static project_id value
  // };
  try {
    dispatch(start());
    const response = await api.get(apiUrls.list(id));
    dispatch(setRowData(response.data.recordData));
    dispatch(success());
    return Promise.resolve(response.data);
  } catch (error: any) {
    dispatch(failure());
    return Promise.reject(error.data);
  }
};


const ConsolidatedSliceSliceReducer = consolidatedSlice.reducer;
export default ConsolidatedSliceSliceReducer;
