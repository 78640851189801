import React from "react";
import LoginForm from "./LoginForm";
import Logo from "../../../assets/images/Logo.png";
import LoginBg from "../../../assets/images/bg.png";
import LoginDesign from "../../../assets/images/LoginDesign.png";

const Login: React.FC = () => {
  return (
    <section className="loginSection">
      <div className="loginWrap" style={{ backgroundImage: `url(${LoginBg})` }}>
        <div className="loginLogo">
          <div className="logo">
           <img src={Logo} alt="Logo" />
          </div>
          {/* <div className="loginBanner"  style={{backgroundImage:`url(${LoginDesign})`}}>
         
          </div> */}
        </div>
        <LoginForm />
        <div className="loginDesign">
        <img src={LoginDesign} alt="Logo" />
        </div>
      </div>
    </section>
  );
};

export default Login;
