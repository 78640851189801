import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

interface FormComponentProps {
  form: any;
  id: string;
  handleSubmit: any;
  onValuesChange: any;
  editValues?: any;
}

const FormComponent: React.FC<FormComponentProps> = ({
  form,
  id,
  handleSubmit,
  onValuesChange,
  editValues,
}) => {
  useEffect(() => {
    if (editValues) {
      form.setFieldsValue(editValues);
    }
  }, [editValues, form]);

  const onFinish = (data: any) => {
    handleSubmit(data);
  };


  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const [additionalInputs, setAdditionalInputs] = useState<string[]>([]);
  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    form.resetFields();
  };

  const handleAddInput = () => {
    if (selectedOption) {
      setAdditionalInputs([...additionalInputs, selectedOption]);
    }
  };

  const handleRemoveInput = (index: number) => {
    const updatedInputs = [...additionalInputs];
    updatedInputs.splice(index, 1);
    setAdditionalInputs(updatedInputs);
  };

  return (
    <>
      <FormBox form={form} id={id} onFinish={onFinish} onValuesChange={onValuesChange}>
        <Row gutter={25}>
          <InputBox.Text label="Project Attribute" />
          <InputBox.Select
            label="Project Attribute Type"
            options={{
              list: [
                { id: 1, name: "Radio Button", value: "radio" },
                { id: 2, name: "Checkbox", value: "checkbox" },
                { id: 3, name: "Text", value: "text" },
                { id: 4, name: "Dropdown", value: "dropdown" },
              ],
            }}
            onChange={handleOptionChange}
          />
        </Row>
        {selectedOption && (

          <div className="addProjectAttr">
            <InputBox.Text />
            <button onClick={handleAddInput}><FontAwesomeIcon icon={faPlusCircle} /></button>
          </div>

        )}
        {additionalInputs.map((input, index) => (
          <div key={index} className="addProjectAttr">
            <InputBox.Text />
            <button onClick={() => handleRemoveInput(index)}><FontAwesomeIcon icon={faMinusCircle} /></button>
          </div>


        ))}
      </FormBox>
    </>
  );
};

export default FormComponent;