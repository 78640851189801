import React from "react";
import { Button } from "antd";
import { ReactComponent as ReportIcon } from "../../../assets/images/icon/ReportIcon.svg";
import { ButtonProps } from "../../Buttons/interface/ButtonInterface";

const ReportButton: React.FC<ButtonProps> = ({
  action,
  data,
  permission,
  ...rest
}) => {
  if (!permission) {
    return null;
  }

  return (
    <Button
      type="text"
      title={"Report"}
      className="editIcon reportIcon"
      onClick={() => action(data)}
      {...rest}
    >
      <ReportIcon />
    </Button>
  );
};

export default ReportButton;
