const baseUrl = '/v1/admin/qaparamsgroups'

export const apiUrls = {
    list: `${baseUrl}/list`,
    create: `${baseUrl}/create`,
    update: (id: number): string => `${baseUrl}/${id}/edit`,
    delete: (id: number): string => `${baseUrl}/${id}/delete`,
    read: (id: number): string => `${baseUrl}/${id}/read`,

}
