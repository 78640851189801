import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { StructureElementBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./Listing/Listing";
import { Button } from "antd";
import AddComponent from "./Form/AddComponent";
import EditComponent from "./Form/EditComponent";
import { deleteRecord, refreshGrid, setPerPageSize, setProjectId, setSelectedData, setupGrid } from "./utils/slice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../StructureElement/utils/slice";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import { checkPrivileges, structureElementBreadcrumbUrl } from "../../../config/Global";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../components/Modals/DeleteModal";

const moduleName = "Structural Element";

const StructureElement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { STRUCTURE, AUTH } = useSelector((state: RootState) => state);
	const [addDrawer, setAddDrawer] = useState<boolean>(false);
	const [editForm, setEditForm] = useState<any>(false);
	const [deleteUser, setDeleteUser] = useState(false)
	const qaCatId = STRUCTURE?.selectedData?.qa_categories_id
	const endUrl = structureElementBreadcrumbUrl(qaCatId?.toString())
	// const endUrl = qaCatId === 1 ? "pile" : qaCatId === 2 ? "pile-cap" : qaCatId === 3 ? "pier" : qaCatId === 4 ? "pier-cap" : qaCatId === 5 ? "bearing" : qaCatId === 6 ? "grider" : qaCatId === 7 ? "box_girder_span" : qaCatId === 8 ? "rt_wall" : qaCatId === 9 ? "solid_slab" : "";
	const pro_id: any = localStorage.getItem('projectId')

	const showDrawer = () => {
		setAddDrawer(true);
	};

	const closeAddDrawer = () => {
		dispatch(refreshGrid())
		setAddDrawer(false);
		setEditForm(false);
		dispatch(setSelectedData(null))
		dispatch(setupGrid(STRUCTURE.agGrid))
	};

	useEffect(() => {
		if (pro_id) {
			dispatch(setProjectId(pro_id));
		}
	}, []);

	const onRowClick = (data?: any) => {
		dispatch(setSelectedData(data?.data))
	};
	const navigate = useNavigate();

	const navigatePage = () => {
		if (endUrl) {
			navigate(
				`/projects/${qaCatId}/${endUrl}`
			);
		} else {
			navigate(
				`/projects/${qaCatId}`
			);
		}
		dispatch(setSelectedData(null))
	};

	const closeDeleteModal = () => {
		dispatch(refreshGrid())
		setDeleteUser(false)
		dispatch(setSelectedData(null))
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={StructureElementBreadcrumb()}>
						{
							checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
							<Button
								className="secondaryBtn"
								onClick={() => {
									showDrawer();
								}}
							>
								Add
								<span className="btnIcon">
									<PlusIcon />
								</span>
							</Button>}
						<RecordPerPage
							defaultValue={STRUCTURE.perPage + " per page"}
							onChange={(perPageSize: number) => {
								dispatch(setPerPageSize(perPageSize));
							}}
						/>{" "}
					</ToolBar>
					<ContentBox>
						{STRUCTURE.projectId &&
							<div className="list">
								<Listing
									moduleName={moduleName}
									rowData={STRUCTURE.rowData}
									onRowClick={onRowClick}
								/>
							</div>
						}
						<AddComponent visible={addDrawer} close={closeAddDrawer} />
						<EditComponent
							key={STRUCTURE?.details?.id}
							visible={editForm}
							close={closeAddDrawer}
						/>
						<DeleteModal
							title={"Structural Element"}
							deleteValues={STRUCTURE.selectedData}
							callApi={deleteRecord}
							close={closeDeleteModal}
							open={deleteUser}
						/>
						<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
							<div className="actionBtn">
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
									<Button onClick={() => { dispatch(setDetails(STRUCTURE.selectedData)); setEditForm(true) }} className={STRUCTURE.selectedData ? "activeBtn" : "disableBtn"} disabled={STRUCTURE.selectedData ? false : true}>
										Edit<SVGIcon icon="edit" />
									</Button>}
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
									<Button onClick={navigatePage} disabled={STRUCTURE.selectedData ? false : true} className={STRUCTURE.selectedData ? "activeBtn" : "disableBtn"}>
										Details <SVGIcon icon="QcIcon" width={25} />
									</Button>}
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
									<Button onClick={() => setDeleteUser(true)} disabled={STRUCTURE.selectedData ? false : true} className={STRUCTURE.selectedData ? "deleteBtn" : "disableDelBtn disableBtn"}>
										Delete <SVGIcon icon="delete" width={25} />
									</Button>}
							</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</>
	);
};

export default StructureElement;
