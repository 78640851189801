import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import { ReactComponent as CloudIcon } from "../../../assets/images/icon/cloudIcon.svg";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import {useSelector } from "react-redux";
import { RootState } from "../../../store/app";
import { validateFields } from "../../../config/Global";
import ChangeStatusModal from "../../../components/ChangeStatus";
//import { assignErrorToInput } from "../../../../store/api";
interface AddProps {
	titleName?: any;
	close?: any;
	data?: any;
	callApi?: any;
}

const ApproveModal: React.FC<AddProps> = ({ titleName, close, data, callApi }) => {
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState(true)
	const [statusData, setStatusData] = useState<any>(null);
	const { PILE_CAP } = useSelector((state: RootState) => state);

	const handleClose = () => {
		form.setFieldValue('approval_latter', [])
		close();
	};

	const handleSubmit = (data?: any) => {
		data.id=PILE_CAP?.selectedData?.id
		data.status=PILE_CAP?.selectedData?.status
		setDisabled(true)
		handleClose()
		setStatusData(data)
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};
	return (
		<>
			<Modal
				className="approveModal"
				open={data ? true : false}
				onCancel={handleClose}
				footer={
					<>
						<Button
							className="secondaryBtn"
							htmlType="submit"
							form="ApprovalAdd"
							disabled={disabled}
						>
							Submit
						</Button>
						<Button className="cancelBtn" onClick={handleClose}>
							Cancel
						</Button>
					</>
				}
			>
				<FormBox onFinish={handleSubmit} form={form} id="ApprovalAdd" onValuesChange={validateForm}>
					<CloudIcon />
					<InputBox.Upload
						name='approval_latter'
						required={true}
						label="Please Attach Letter of Approval Here."
						fileList={form.getFieldValue('approval_latter')}
						maxCount={10}
					/>
				</FormBox>
			</Modal>
			<ChangeStatusModal
				titleName={titleName}
				data={statusData}
				close={() => setStatusData(null)}
				className={"warningModal"}
				message={"Are you sure want to make it inactive? This action is irreversible."}
				callApi={callApi}
			/>
		</>
	);
};

export default ApproveModal;
