import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../config/Config";
import { GridOptions, GridReadyEvent } from "ag-grid-community";
import { IServerSideGetRowsParams } from "ag-grid-community";

interface InitialState {
  isLoading: boolean;
  rowData: any;
  read: any;
  perPageSize: any;
  grid: any;
  auditData: any;
  selectedUser: any;
  filterByDate: any;
  selectedUserId:any;
  gridApi:any
}

const initialState = {
  isLoading: false,
  rowData: null,
  read: null,
  perPageSize: Config.grid.local.gridOptions?.paginationPageSize,
  grid: null,
  auditData: null,
  selectedUser: null,
  filterByDate: null,
  selectedUserId:null,
  gridApi:null
} as InitialState;

const AuditLogSlice = createSlice({
  name: "DEPARTMENT",
  initialState,
  reducers: {
    start: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
    },
    failure: (state) => {
      state.isLoading = false;
    },
    setRowData: (state, action: PayloadAction<any>) => {
      state.rowData = action?.payload;
    },
    setPerPage: (state, action: PayloadAction<any>) => {
      state.perPageSize = action.payload;
      if (state.grid) {
        state.grid.api.paginationSetPageSize(Number(state.perPageSize));
        state.grid.api.setCacheBlockSize(state.perPageSize);
      }
    },
    setGrid: (state, action: PayloadAction<any>) => {
      state.grid = action.payload;
    },
    setRead: (state, action: PayloadAction<any>) => {
      state.read = action?.payload;
    },
    setAuditData: (state, action: PayloadAction<any>) => {
      state.auditData = action?.payload;
    },
    setSelectedUser: (state, action: PayloadAction<any>) => {
      state.selectedUser = action?.payload;
    },
    setFilterByDate: (state, action: PayloadAction<any>) => {
      state.filterByDate = action?.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<any>) => {
      state.selectedUserId = action?.payload;
    },
    setGridApi: (state, action: PayloadAction<any>) => {
      state.gridApi = action?.payload;
    },
  },
});

export const {
  start,
  success,
  failure,
  setRowData,
  setRead,
  setPerPage,
  setGrid,
  setAuditData,
  setSelectedUser,
  setFilterByDate,
  setSelectedUserId,
  setGridApi
} = AuditLogSlice.actions;

export const setPerPageSize =
  (size: number): AppThunk<any> =>
    async (dispatch) => {
      dispatch(setPerPage(size));
    };
/** For Listing:Start */
export const fetchList = async (payload?: any, user?: any, filterDate?: any): Promise<any> => {
  const f_payload = filterDate=="Invalid Date"?{ ...payload, user_id: user }:{ ...payload, user_id: user, date: filterDate }
  return await api.post(apiUrls.list, f_payload).then(({ data }) => {
    setRowData(data.rows)
    return data;
  });
};

const changeFilterAndSort = (params: any) => {
  params;
};

export const setupGrid =
  (params: GridReadyEvent): AppThunk<any> =>

    async (dispatch, getState: () => RootState) => {
      try {
        dispatch(setGrid(params));
        dispatch(setGridApi(params.api))
        dispatch(setPerPage(getState().AUDIT_LOG.perPageSize))
        const dataSource = await createDataSource(
          getState,
          Config.grid.server.gridOptions
        );
        params.api.setServerSideDatasource(dataSource);
        var gridApi = params.api;
				gridApi.deselectAll();
      } catch (error: any) {
        //
      }
    };

const createDataSource = (
  getState: () => RootState,
  gridOptions?: GridOptions
) => {
  return {
    gridOptions,
    getRows: (params: IServerSideGetRowsParams) => {
      changeFilterAndSort(params.request);
      const payload = getServerListPayload(params);
      fetchList(payload, getState().AUDIT_LOG.selectedUserId, getState().AUDIT_LOG?.filterByDate).then(data => {
        params.success({ rowData: data?.rows, rowCount: data?.count });
        if (data.count <= 0) {
          params.api.showNoRowsOverlay();
        } else {
          params.api.hideOverlay();
        }
      });
    },
  };
};

export const setFilterData =
  (): AppThunk<any> =>

    async (dispatch, getState: () => RootState) => {
      dispatch(setupGrid(getState().AUDIT_LOG.grid))
    }

export const getUserList = (): AppThunk<any> => async (dispatch) => {
  try {
    dispatch(start());
    const response = await api.post(apiUrls.userList);
    dispatch(setSelectedUser(response.data.data));
    dispatch(success());
    return Promise.resolve(response.data);
  } catch (error: any) {
    dispatch(failure());
    return Promise.reject(error.data);
  }
};
export const refreshGrid = (): AppThunk<any> =>
	async (dispatch, getState) => {
		dispatch(setupGrid(getState().AUDIT_LOG.grid));
	}
const AuditLogSliceReducer = AuditLogSlice.reducer;
export default AuditLogSliceReducer;
