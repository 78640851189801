import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../../../components/AntdAddons";
import ToolBar from "../../../../../../../../../components/ToolBar";
import { AddCoarseReportBreadcrumb } from "../../../../../../../../../config/BreadcrumbConfig";
import { useNavigate, useParams } from "react-router-dom";
import { CONSTANT } from "../../../../../../../../../config/Constant";
import { AppDispatch, RootState } from "../../../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import {
	createReport,
	getCoarseTestTypes,
	getLaboratoryName,
	getQualityList,
	readReport,
	setCoarseReportId,
	setReportId,
} from "../../../utils/slice";
import { assignErrorToInput } from "../../../../../../../../../store/api";
import { dataToFormDataConverter, validateFields } from "../../../../../../../../../config/Global";
import { rules } from "./rules";
import dayjs from "dayjs";

const AddCorseAggregateReportComponent: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const { COARSEAGGREGATE } = useSelector((state: RootState) => state);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(true)
	const [testType, setTestType] = useState<any>([])
	const [, setState] = useState(false);
	const {
		courseId,
		reportId,
	}: { projectId?: any; courseId?: any; reportId?: any } = useParams();
	const laboratoryList = form.getFieldValue("laboratory_type")
	const handleSubmit = (data?: any) => {
		setDisabled(true)
		setLoading(true)
		const obj = data?.observed_value
		delete data?.observed_value
		delete data?.test_type_id
		delete data?.expected_value
		const payload = {
			...data,
			date_of_testing: dayjs(data?.date_of_testing).format(
				CONSTANT.POST_DATE_FORMAT
			),
			project_id: localStorage.getItem('projectId'),
			mst_cement_inventory_id: courseId,
			observed_value: JSON.stringify(Object.values(obj))
		};

		const formdata = dataToFormDataConverter(payload);
		try {
			form.validateFields();
			dispatch(createReport(courseId, formdata))
				.then(() => {
					setLoading(false);
					setTimeout(() => {
						form.resetFields();
						dispatch(setCoarseReportId(null))
						navigate(
							`/projects/raw-material/${courseId}/coarse-aggregate-report`
						);
					}, 1000);
				})
				.catch((error: any) => {
					setDisabled(true)
					setLoading(false)
					assignErrorToInput(form, error.STATUS);
				});
		} catch (error) {
			console.error("Error submitting data:", error);
		}
	};
	useEffect(() => {
		if (location.pathname.includes("/view-coarse-aggregate-report")) {
			dispatch(setReportId(courseId))
			dispatch(getQualityList());
			dispatch(getCoarseTestTypes(3));
		}
	}, []);
	useEffect(() => {
		if (reportId) {
			dispatch(readReport(reportId)).then(() => {
				//
			});
		}
	}, []);
	useEffect(() => {
		location.pathname.includes("/view-coarse-aggregate-report") &&
			COARSEAGGREGATE?.readReportData && COARSEAGGREGATE?.readReportData?.map((data?: any) => {
				if (data?.id == reportId) {
					COARSEAGGREGATE.coarseTestType?.filter((item?: any) => {
						if (item?.id == data?.test_type_id) {
							setTestType([{ ...item }])
						}
					})
					form.setFieldValue([`observed_value`, `${data?.test_type_id}`, "observed_value"], data?.observed_value)
					form.setFieldsValue({
						coarse_aggregate_cmt_id: data?.coarse_aggregate_cmt_id,
						test_type_id: data?.test_type_id,
						laboratory_type: data?.laboratory_type,
						laboratory_name: data?.laboratory_original_name,
						test_passed: data?.test_passed,
						date_of_testing: dayjs(data?.date_of_testing),
						attach_report: data?.attached_documnent?.map(
							(image: { id: any; document_name: any }) => {
								return {
									// uid: image.id,
									name: image?.document_name,
									status: "done",
									url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
									thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								};
							}
						),
						data_sheet: data?.data_sheet_documnent?.map(
							(image: { id: any; document_name: any }) => {
								return {
									// uid: image.id,
									name: image?.document_name,
									status: "done",
									url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
									thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								};
							}
						)
					});
					setState(prevState => !prevState);
				}
			});
	}, [COARSEAGGREGATE?.readReportData, setState]);

	const handleCancel = () => {
		dispatch(setCoarseReportId(null))
		courseId ? navigate(
			`/projects/raw-material/${courseId}/coarse-aggregate-report`
		) : navigate(
			`/projects/raw-material/coarse-aggregate-report`
		);
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};
	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes("/add-coarse-aggregate-report")
						? AddCoarseReportBreadcrumb(courseId, "Add")
						: AddCoarseReportBreadcrumb(courseId, "View")
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<div className="formHeading">
				<h3>
					{location.pathname.includes("/add-coarse-aggregate-report")
						? "Add "
						: "View "}{" "}
					Report
				</h3>
			</div>
			<Form
				className="addReportForm"
				form={form}
				id="addForm"
				onFinish={handleSubmit}
				onValuesChange={validateForm}
			>
				<div>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								className="select textField"
								name="coarse_aggregate_cmt_id"
								onFocus={() => dispatch(getQualityList())}
								options={{
									list: COARSEAGGREGATE?.coarseQualityList,
									valueKey: "id",
									textKey: "name",
								}}
								label="Select Quality"
								mode={location.pathname.includes("/add-coarse-aggregate-report") ? "multiple" : undefined}
								allowClear
								rules={rules.selectFields()}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								name="laboratory_type"
								label="Laboratory Type"
								rules={rules.radioButton()}
								onChange={(e) => {
									dispatch(getLaboratoryName(e.target.value))
									form.setFieldValue('laboratory_name', [])
								}}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report")
								}
								options={{
									list: [
										{
											id: 1,
											value: "NABL Lab",
											name: "NABL Lab",
										},
										{
											id: 2,
											value: "Government lab",
											name: "Government Lab",
										},
										{
											id: 3,
											value: "On-site Lab",
											name: "On-site Lab",
										},
									]
								}}
							/></Col>
						{laboratoryList &&
							<Col span={12}>
								<InputBox.Select
									label="Name of Laboratory"
									name="laboratory_name"
									className="select textField"
									rules={rules.selectFields()}
									disabled={
										location.pathname.includes("/view-coarse-aggregate-report")
									}
									options={{
										list: COARSEAGGREGATE.laboratoryName,
										valueKey: "id",
										textKey: "laboratory_name",
									}}
								/>
							</Col>
						}
						<Col span={12}>
							<InputBox.DatePicker
								label="Date of Testing"
								name="date_of_testing"
								rules={rules.dateFields()}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report")
								}
								label="All Tests Passed As Per Code :"
								name="test_passed"
								rules={rules.radioButton()}
								options={{
									list: [
										{
											id: 1,
											value: "yes",
											name: "Yes",
										},
										{
											id: 2,
											value: "no",
											name: "No",
										},
									],
								}}
							/>
						</Col>
					</Row>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Select
								className="select textField"
								name="test_type_id"
								mode={location.pathname.includes("/add-coarse-aggregate-report") ? "multiple" : undefined}
								allowClear
								onFocus={() => dispatch(getCoarseTestTypes(3))}
								options={{
									list: COARSEAGGREGATE?.coarseTestType,
									valueKey: "id",
									textKey: "name",
								}}
								onChange={(d: any) => {
									d?.map((item?: any) => {
										COARSEAGGREGATE?.coarseTestType?.filter((data: any) => {
											if (item == data?.id) {
												setTestType([...testType, data])
											}
										});
									})
								}}
								onDeselect={(data?: any) => {
									const d = testType?.filter((item: any) => {
										if (data == item?.id) {
											form.setFieldValue([`observed_value`, `${item?.id}`, "observed_value"], "")
										}
										return data !== item?.id
									})
									setTestType(d)
								}
								}
								onClear={() => setTestType([])}
								label="Test Type"
								rules={rules.selectFields()}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report")
										? true
										: false
								}
							/>
						</Col>
						<Col span={12}></Col>
						{
							testType && testType?.map((data?: any) => {
								form.setFieldValue([`expected_value`, `${data?.id}`], data?.expected_value ? data?.expected_value : "N/A")
								form.setFieldValue([`observed_value`, `${data?.id}`, "test_type_id"], data?.id)
								return <>
									<InputBox.Text
										name={[`observed_value`, `${data?.id}`, "test_type_id"]}
										label={`Observed Value - ${data.name}`}
										disabled={
											location.pathname.includes("/view-steel-report")
										}
										className="textField"
										hidden
									/>
									<Col span={12}>
										<InputBox.Text
											name={[`observed_value`, `${data?.id}`, "observed_value"]}
											label={`Observed Value - ${data.name}`}
											disabled={
												location.pathname.includes("view-coarse-aggregate-report")
											}
											rules={rules.dynamicFields()}
											className="textField"
										//Don't remove this code
										// rules={[
										//   {
										//     validator: validatevalue,
										//   },
										//   {
										//     required:true,
										//     message:"It is required."
										//   }
										// ]}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Text
											name={[`expected_value`, `${data?.id}`]}
											label={`Expected Value - ${data.name}`}
											disabled={true}
											className="textField"
										/>
									</Col>
								</>
							})
						}
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="attach_report"
								required={true}
								label="Attach Report "
								fileList={form.getFieldValue('attach_report')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report") ? true : false
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="data_sheet"
								required={true}
								label="Data Sheet "
								fileList={form.getFieldValue('data_sheet')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-coarse-aggregate-report") ? true : false
								}
							/>
						</Col>

					</Row>

					<Row>
						<div className="ReportBtn">
							<Button
								className="secondaryBtn"
								htmlType="submit"
								form="addForm"
								loading={loading}
								disabled={disabled}
							>
								Submit
							</Button>
							<Button className="secondaryBtn cancelBtn" onClick={handleCancel}>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	);
};

export default AddCorseAggregateReportComponent;
