import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";


interface FormComponentProps {
	form: any
	id: string
	handleSubmit: any
	onValuesChange: any
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {


	useEffect(() => {
		if (editValues) {
			form.setFieldsValue(editValues);
		}
	}, [editValues, form]);

	const onFinish = (data: any) => {
		handleSubmit(data);
	};

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={20}>
					<Col span={10}>
						<InputBox.Text
							label="Time :"
							name="time"
							rules={rules.dynamicFields()}
						/>
					</Col>
					<Col span={10}>
						<InputBox.Radio
							name="roster"
							label="Roster :"
							options={{
								list: [
									{
										id: 1,
										value: "day",
										name: "Day",
									},
									{
										id: 2,
										value: "night",
										name: "Night",
									},
								],
							}}

							rules={rules?.radioButton()}
						/>
					</Col>
				</Row>
				<Row>
					<InputBox.Text
						label="Name of Engineer :"
						name="name_of_engineer"
						rules={rules?.dynamicFields()}
					/>
				</Row>
				<Row>
					<InputBox.Text
						label="Mobile Number :"
						name="mobile_number"
						rules={rules?.mobileNumber()}
					/>
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;
