import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'
const pmcNameColumn: ColDef = {
	field: 'name',
	headerName: 'Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
}

const pmcMobileNoColumn: ColDef = {
	field: 'mobile_no',
	headerName: 'Mobile No',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.mobile_no,
}
const pmcEmailColumn: ColDef = {
	field: 'email',
	headerName: 'Email',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.email,
}
const pmcAddressColumn: ColDef = {
	field: 'address_of_pmc',
	headerName: 'Address',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.address_of_pmc,
}
const columnDefs: ColDef[] = [
	idColumn,
	pmcNameColumn,
	pmcEmailColumn,
	pmcMobileNoColumn,
	pmcAddressColumn,
	createdAtColumn,
	updatedAtColumn
]
export default columnDefs
