import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const desConNameColumn: ColDef = {
	field: 'name',
	headerName: 'Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
}

const desConMobileNoColumn: ColDef = {
	field: 'mobile_no',
	headerName: 'Mobile No',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.mobile_no,
}
const desConEmailColumn: ColDef = {
	field: 'email',
	headerName: 'Email',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.email,
}
const desConAddressColumn: ColDef = {
	field: 'address_of_design_consultant',
	headerName: 'Address',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.address_of_design_consultant,
}
const columnDefs: ColDef[] = [
	idColumn,
	desConNameColumn,
	desConEmailColumn,
	desConMobileNoColumn,
	desConAddressColumn,
	createdAtColumn,
	updatedAtColumn
]
export default columnDefs
