import React from "react";
import ToolBar from "../../../components/ToolBar";
import { ConcretingBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import FormComponent from "./components/Form/FormComponent";
import { useParams } from "react-router-dom";
import { structureElementBreadcrumbName, structureElementBreadcrumbUrl } from "../../../config/Global";

const moduleName = "Concreting";
const Concreting: React.FC<any> = () => {

	const { structureId } = useParams();
	const name =structureElementBreadcrumbName(structureId)
	const endUrl =structureElementBreadcrumbUrl(structureId)

	return (
		<div className="mainDetailsWrapper">

			<ToolBar
				breadcrumbs={
					location.pathname ==
						`/projects/${structureId}/${endUrl}`
						? ConcretingBreadcrumb(structureId, name, endUrl)
						: ConcretingBreadcrumb(structureId, name, endUrl)
				}
			>
			</ToolBar>
			<ContentBox>
				<FormComponent moduleName={moduleName} />
			</ContentBox>
		</div>
	);
};

export default Concreting;


