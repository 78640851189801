import React, { useEffect, useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import Config from '../../../config/Config'
import { Link, useLocation } from 'react-router-dom'
import LogoComponent from '../Components/LogoComponent'
import BgRepeat from '../../../assets/images/app-sidebar-bg-repeat.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store/app'
import FooterView from './FooterView'
import { setTab } from '../../modules/CreateProjectDashboard/utils/slice'

interface AppSidebarViewProps {
	collapsed: boolean
}

const SidebarView: React.FC<AppSidebarViewProps> = ({ collapsed }) => {
	const AUTH = useSelector((state: RootState) => state.AUTH)
	const dispatch: AppDispatch = useDispatch();
	const location = useLocation()
	const [menu, setMenu] = useState<any>([])
	const [openMenu, setOpenMenu] = useState<any>([])
	const [parentPage, setParentPage] = useState<string>()
	const AppMenu = (
		menu_item: any,
		open: any = [],
		parentKey: any = null
	): any => {
		return menu_item
			? Object.keys(menu_item)
				.sort((a, b) =>
					menu_item[a].sequence_no > menu_item[b].sequence_no
						? 1
						: menu_item[b].sequence_no > menu_item[a].sequence_no
							? -1
							: 0
				)
				.map((key) => {
					const item: any = menu_item[key]
					if (item.children) {
						return {
							key: item.id + '',
							url: item.path,
							label: item.label,
							icon: <img src={`${process.env.REACT_APP_API_URL}${item?.web_img}`} />,
							children: AppMenu(
								item.children,
								open,
								item.id + ''
							),
						}
					} else {
						if (
							location.pathname == item.path &&
							openMenu.length <= 0
						) {
							if (open.length === 0) {
								parentKey
									? open.push(parentKey)
									: open.push(item.path)
							}
							setOpenMenu(open)
						}

						return {
							key: item.path,
							url: item.path,
							label: <Link to={item.path} className='linkClass'>{item.label}</Link>,
							icon: <img src={`${process.env.REACT_APP_API_URL}${item?.web_img}`} />,
						}
					}
				})
			: null
	}

	useEffect(() => {
		const datas = ["Dashboard", "Users", "Roles", "Master Data", "Audit Logs", "Project Management"]
		const menus = AUTH.userDetail?.menu?.filter((data: any) => datas.includes(data?.label))
		const menuItemsTemp = AppMenu(menus)
		setMenu(menuItemsTemp)
		setParentPage(`/${location?.pathname?.split('/')[1]}`)
	}, [AUTH.userDetail, location])

	const onClick: MenuProps['onClick'] = () => {
		dispatch(setTab(0));
	};
	return (
		<Layout.Sider
			collapsed={collapsed}
			width={Config.sidebar_width}
			className="main__page__appsidebar"
			style={{ backgroundImage: `url(${BgRepeat})` }}
		>
			<LogoComponent collapsed={collapsed} />
			<Menu
				onClick={onClick}
				mode="inline"
				theme="dark"
				activeKey={parentPage || location.pathname}
				items={menu}
				openKeys={openMenu}
				onOpenChange={(openKeys) => {
					setOpenMenu(openKeys)
				}}
			/>
			<FooterView />
		</Layout.Sider>
	)
}

export default SidebarView
