import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../../../store/app'
import { rules } from './rules'
import { fetchList, getDepartmentSubList } from "../../utils/slice";
import { getDepartmentList } from "../../utils/slice";

interface FormComponentProps {
	form: any
	id: string
	handleSubmit: any
	onValuesChange: any
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {
	const dispatch: AppDispatch = useDispatch()
	const { QA_ELEMENT } = useSelector((state: RootState) => state);
	const [dept, setDept] = useState()


	useEffect(() => {
		dispatch(fetchList())
		dispatch(getDepartmentSubList())
		dispatch(getDepartmentList());
	}, [])


	useEffect(() => {
		if (editValues) {
			setDept(editValues?.department_id)
			form.setFieldsValue({
				department_id: editValues.department_id,
				name: editValues.name,
				department_sub_type_id: editValues?.department_sub_type_id
			});
		}
	}, [editValues, form])

	const onFinish = (data: any) => {
		handleSubmit(data)
	}

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={25}>
					<InputBox.Select
						label="Name of Department"
						name="department_id"
						required
						options={{
							list: QA_ELEMENT.departmentList,
							valueKey: "id",
							textKey: "department_name",
						}}
						rules={rules.selectFields()}
						onChange={(e: any) => {
							form.setFieldValue("department_sub_type_id", null)
							setDept(e)
						}}
					/>
					{
						dept == 5 &&
						<InputBox.Select
							label="Department Sub Type"
							name="department_sub_type_id"
							required
							options={{
								list: QA_ELEMENT.subDepartmentList,
								valueKey: "id",
								textKey: "type_name",
							}}
							rules={rules.selectFields()}
						/>
					}
					<InputBox.Text
						label="Name"
						name="name"
						rules={rules.dynamicFields()}
						onChange={onValuesChange}
					/>
					<InputBox.Checkbox
						label="Is Display Mix Design"
						name="is_display"
					/>
				</Row>
			</FormBox>
		</>
	)
}

export default FormComponent
