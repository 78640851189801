/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { CreateProjectDashboardBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import DepartmentTabs from "./components/DepartmentTabs";
import { Button, Form, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import { fetchList, fetchPlanningList, setPageNumber, setSearch } from "./utils/slice";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";
import { checkPrivileges } from "../../../config/Global";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { ReactComponent as RestoreIcon } from "../../../assets/images/icon/refresh.svg";
import { TYPE_DEPT_DASHBOARD } from "../../../config/Constant";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import dayjs from "dayjs";
import { debounce } from "lodash";

const CreateProjectDashboard: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const [form1]=Form.useForm()
	//const [page, setPage] = useState(1);
	const { AUTH, CREATEDASHBOARDPROJECT } = useSelector(
		(state: RootState) => state
	);

	const [saving, setSaving] = useState(false);
	const [refreshClick, setRefreshClick] = useState(false);
	// const [tab, setTab] = useState(0);
	const depProjects = CREATEDASHBOARDPROJECT.rowData;
	const pageNumber = CREATEDASHBOARDPROJECT.pageNumber;
	const [finalData, setFinalData] = useState(null);

	const perPage = CREATEDASHBOARDPROJECT.perPage;
	const handleChange = (value: string) => {
		//
	};
	const tab = CREATEDASHBOARDPROJECT.tab;
	//type dahsboard = {[key: any] : any}
	const bridgeData: any = localStorage.getItem("finalBridgeData");
	let finalBridgeData: any = bridgeData ? JSON.parse(bridgeData) : [];
	const buildingData: any = localStorage.getItem("finalBuildingData");
	let finalBuildingData: any = buildingData ? JSON.parse(buildingData) : [];
	const roadData: any = localStorage.getItem("finalRoadData");
	let finalRoadData: any = roadData ? JSON.parse(roadData) : [];
	const waterData: any = localStorage.getItem("finalWaterData");
	let finalWaterData: any = waterData ? JSON.parse(waterData) : [];
	const drainageData: any = localStorage.getItem("finalDrainageData");
	let finalDrainageData: any = drainageData ? JSON.parse(drainageData) : [];

	const handleClick = () => {
		navigate("/projects/create");
	};

	const restoreClick = () => {
		setRefreshClick(true);
		form1.setFieldValue("search","")
		dispatch(setSearch(""))
		const localStorageKeys = [
			`final${TYPE_DEPT_DASHBOARD.Bridge}Data`,
			`final${TYPE_DEPT_DASHBOARD.Building}Data`,
			`final${TYPE_DEPT_DASHBOARD.Road}Data`,
			`final${TYPE_DEPT_DASHBOARD.Water}Data`,
			`final${TYPE_DEPT_DASHBOARD.Drainage}Data`,
		];
		const localStorageCountKeys = [
			`count${TYPE_DEPT_DASHBOARD.Bridge}`,
			`count${TYPE_DEPT_DASHBOARD.Building}`,
			`count${TYPE_DEPT_DASHBOARD.Road}`,
			`count${TYPE_DEPT_DASHBOARD.Water}`,
			`count${TYPE_DEPT_DASHBOARD.Drainage}`,
		];
		localStorage.removeItem(localStorageKeys[tab]);
		localStorage.removeItem(localStorageCountKeys[tab]);
		getDataRestore()
		dispatch(setPageNumber(1));

	};

	useEffect(() => {
		dispatch(setPageNumber(1));
	}, [tab])

	const handleSearch = debounce(async(event: any) => {
		if (event.target.value === "") {
			restoreClick()
		} else {
			await dispatch(setPageNumber(1))
			getDataRestore({ search: event.target.value });
			dispatch(setSearch(event.target.value));
		}
	},500)

	useEffect(() => {
		if (location.pathname == "/projects") {
			localStorage.removeItem("projectId");
			localStorage.removeItem("projectName");
			localStorage.removeItem("startDate");
		}
	}, []);

	// on hard refresh get localstorage data, on page change and on tab change
	useEffect(() => {
		if (tab === 0 && bridgeData) {
			getData();
		}
		if (tab === 1 && buildingData) {
			getData();
		}
		if (tab === 2 && roadData) {
			getData();
		}
		if (tab === 3 && waterData) {
			getData();
		}
		if (tab === 4 && drainageData) {
			getData();
		}
	}, [tab, pageNumber]);

	// on restore
	const getDataCommonRestore = (type: string, payload: any, data: any) => {
		// Fetch data and update storage
		dispatch(
			// tab == 2 ? fetchPlanningList(payload) : fetchList(payload)).then((response: any) => {
			// const DepartmentData = tab == 2 ? response?.rows : response?.recordData?.Project_details
			fetchList(payload)).then((response: any) => {
				const DepartmentData = response?.recordData?.Project_details
			setSaving(false);
			data.push({
				data: DepartmentData,
				pageNumber: "1",
				tab: tab,
			});
			localStorage.setItem(`final${type}Data`, JSON.stringify(data));
			localStorage.setItem(`count${type}`, JSON.stringify(response?.count));
			setFinalData(DepartmentData?.length > 0
				? JSON.parse(JSON.stringify(DepartmentData))
				: null
			);

		});

	};

	const getDataRestore = (filter?: any) => {
		const payload = {
			deptId: tab + 1,
			status: 0,
			search: "",
			page: 1,
			perPage: perPage,
			...filter
		};
		setSaving(true);
		setFinalData(null);
		//setPageNumber(1)

		if (tab == 0) {
			finalBridgeData = []
			getDataCommonRestore(
				`${TYPE_DEPT_DASHBOARD.Bridge}`,
				payload,
				finalBridgeData);
		}
		if (tab == 1) {
			finalBuildingData = []
			getDataCommonRestore(
				`${TYPE_DEPT_DASHBOARD.Building}`,
				payload,
				finalBuildingData);
		}

		if (tab == 2) {
			finalRoadData = []
			getDataCommonRestore(
				`${TYPE_DEPT_DASHBOARD.Road}`,
				{ ...payload, department_sub_type_id: 2 },
				finalRoadData);
		}

		if (tab == 3) {
			finalWaterData = []
			getDataCommonRestore(
				`${TYPE_DEPT_DASHBOARD.Water}`,
				payload,
				finalWaterData);
		}
		if (tab == 4) {
			finalDrainageData = []
			getDataCommonRestore(
				`${TYPE_DEPT_DASHBOARD.Drainage}`,
				payload,
				finalDrainageData
			);
		}
	};

	const getDataCommon = (type: string, payload: any, data: any) => {
		let currentItem: any;
		const exists =
			data?.filter((item: any) => {
				if (item.pageNumber == pageNumber && item.tab == tab) {
					currentItem = item;
					return true;
				}
			}).length > 0;
		if (!exists) {
			dispatch(
				// tab == 2 ? fetchPlanningList(payload) : fetchList(payload)).then((response: any) => {
				// const DepartmentData = tab == 2 ? response?.rows : response?.recordData?.Project_details
				fetchList(payload)).then((response: any) => {
					const DepartmentData = response?.recordData?.Project_details
				setSaving(false);
				data.push({
					data: DepartmentData,
					pageNumber: pageNumber.toString(),
					tab: tab,
				});
				localStorage.setItem(`final${type}Data`, JSON.stringify(data));
				localStorage.setItem(`count${type}`, JSON.stringify(response?.count));
				setFinalData(
					DepartmentData?.length > 0
						? JSON.parse(JSON.stringify(DepartmentData))
						: null
				);
			});
		} else {
			if (pageNumber == currentItem?.pageNumber) {
				setSaving(false);
				setFinalData(data ? currentItem?.data : null);
			}
		}
	};

	const getData = () => {
		const payload = {
			deptId: tab + 1,
			status: 0,
			search: CREATEDASHBOARDPROJECT.searchInput,
			page: pageNumber,
			perPage: perPage,
		};
		setSaving(true);
		setFinalData(null);
		if (tab == 0) {
			getDataCommon(
				`${TYPE_DEPT_DASHBOARD.Bridge}`,
				payload,
				finalBridgeData);
		}
		if (tab == 1) {
			getDataCommon(
				`${TYPE_DEPT_DASHBOARD.Building}`,
				payload,
				finalBuildingData
			);
		}

		if (tab == 2) {
			getDataCommon(
				`${TYPE_DEPT_DASHBOARD.Road}`,
				{ ...payload, department_sub_type_id: 2 },
				finalRoadData);
		}

		if (tab == 3) {
			getDataCommon(
				`${TYPE_DEPT_DASHBOARD.Water}`,
				payload,
				finalWaterData);
		}
		if (tab == 4) {
			getDataCommon(
				`${TYPE_DEPT_DASHBOARD.Drainage}`,
				payload,
				finalDrainageData
			);
		}
	};

	// const handleDateChange = (date: any) => {
	// 	const d = dayjs(date)?.format("YYYY-MM-DD");
	// 	getDataRestore(d != "Invalid Date" && { "planning_date": d })
	// }
	// const handleZoneChange = (id: any) => {
	// 	getDataRestore({ "zone_id": id })
	// }
	return (
		<>
			{!AUTH?.userDetail ? (
				<PageSpinner />
			) : (
				checkPrivileges(
					AUTH.userDetail,
					`${moduleInfo.permissionPrefix}_INDEX`
				) && (
					<>
						<ToolBar breadcrumbs={CreateProjectDashboardBreadcrumb}>
							{/* <div className="9jdiableBtnWrap"></div> */}
							<div className="projectInfo">
								{/* {
									tab == 2 && <>
										<div>
											<InputBox.DatePicker onChange={(date) => handleDateChange(date)} />
										</div>
										<div className="projectList">
											<InputBox.Select
												allowClear
												placeholder={"Search Zone"}
												options={{
													list: [
														{
															id: 1,
															value: "nz",
															name: "NZ",
														},
														{
															id: 2,
															value: "wz",
															name: "WZ",
														},
														{
															id: 3,
															value: "sz",
															name: "SZ",
														},
														{
															id: 4,
															value: "ez",
															name: "EZ",
														},
														{
															id: 5,
															value: "nwz",
															name: "NWZ",
														},
														{
															id: 6,
															value: "swz",
															name: "SWZ",
														},
														{
															id: 7,
															value: "cz",
															name: "CZ",
														},
													],
												}}
												onChange={(e) => handleZoneChange(e)}
											/>
										</div>
									</>
								} */}
								<div className="projectList">
									<Select
										defaultValue="On Going"
										style={{ width: 120 }}
										onChange={handleChange}
										options={[
											{ value: "going", label: "On Going" },
											{ value: "completed", label: "Completed" },
										]}
									/>
								</div>
								<div className="newProjectWrap">
									<div className="projectSearch">
										<FormBox form={form1}>
											<InputBox.Text
											name={"search"}
											placeholder="Search"
											addonBefore
											onChange={(e:any) => {handleSearch(e)}}
											/>
										</FormBox>
									</div>
									{checkPrivileges(
										AUTH.userDetail,
										`${moduleInfo.permissionPrefix}_CREATE`
									) && (
											<Button className="secondaryBtn" onClick={handleClick}>
												{
												// CREATEDASHBOARDPROJECT?.tab == 2 ? "Add Planning" : 
												"Add Project"}
												<PlusIcon className="plusIcon" />
											</Button>
										)}
									<div className="refreshIcon">
										<Button onClick={restoreClick}>
											<RestoreIcon />
										</Button>
									</div>
								</div>
							</div>
						</ToolBar>
						<ContentBox>
							<div className="departmentWrap">
								<DepartmentTabs
									perPage={perPage}
									saving={saving}
									setSaving={setSaving}
									moduleInfo={moduleInfo}
									finalData={finalData}
									setFinalData={setFinalData}
									tab={tab}
									restoreClick={restoreClick}
									form1={form1}
								/>
							</div>
						</ContentBox>
					</>
				)
			)}
		</>
	);
};

export default CreateProjectDashboard;
