import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { validations } from "../../../config/validations/validations";

export const LoginRequest = (): RequestProps => ({
	email: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		{ type: "email", message: Message().email(field) },
	],
	password: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		validations.pattern.password(),
		{ required: true, message: Message().regex.password(field) },],
	newPassword: (field: any) => [
		validations.pattern.password(),
		{ required: true, message: Message().required.text(field) },	
		({ getFieldValue }: any) => ({
			validator(_: any, value: any) {
				if (!value || getFieldValue("current_password") !== value) {
					return Promise.resolve();
				}
				return Promise.reject(
					"New Password should be different from Current Password."
				);
			},
		}),
	],
	confirmPassword: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}
				return Promise.reject('New Password and Confirm Password does not match.');
			},
		}),
	],
});
