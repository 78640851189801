import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from './api'
import { AppThunk } from './app'
import { stringEncryption } from '../config/Global'
import API_URL from '../config/ApiUrl'
import { TYPE_DEPT_DASHBOARD } from '../config/Constant'

interface AuthState {
	appLoading: boolean
	initialData: object | null
	isLoading: boolean
	userDetail: any
	token: string | null
}

const initialState = {
	appLoading: true,
	initialData: {},
	isLoading: false,
	userDetail: null,
	token: null,
} as AuthState

const AuthSlice = createSlice({
	name: 'AUTH',
	initialState,
	reducers: {
		setAppLoading: (state, action: PayloadAction<boolean>) => {
			state.appLoading = action.payload
		},
		setInitialData: (state, action: PayloadAction<any>) => {
			state.initialData = action.payload
		},
		setUserToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload
		},
		setUserDetail: (state, action: PayloadAction<any>) => {
			state.userDetail = action.payload
		},
		resetAuthStore: () => {
			return initialState // Reset the state to the initial state
		},
		start: (state) => {
			state.isLoading = true
		},
		success: (state, action: PayloadAction<any>) => {
			state.isLoading = false
			localStorage.setItem('token', action.payload.token)
		},
		failure: (state) => {
			state.isLoading = false
		},
	},
})

export const {
	start,
	success,
	failure,
	resetAuthStore,
	setAppLoading,
	setInitialData,
	setUserDetail,
	setUserToken,
} = AuthSlice.actions

// Async action to fetch todos from the API
export const doLogin =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(API_URL.LOGIN, action)
				if (action?.remember) {
					const string = JSON.stringify({
						email: action.username,
						password: action.password,
					})
					localStorage.setItem('remember_me', stringEncryption(string))
				}
				dispatch(success(response.data))
				dispatch(setUserToken(response.data.token))
				dispatch(setUserOptions())

				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
		}

// Async action to fetch sync from the API
export const initialAppOptions = (): AppThunk<any> => async (dispatch) => {
	try {
		const token = localStorage.getItem('token') ?? null
		let initialData = localStorage.getItem('initialData') ?? null

		if (token) {
			dispatch(setUserToken(token))
			dispatch(setUserOptions())
		}

		if (!initialData) {
			const response = await api.post(API_URL.COMMON.SYNC)
			localStorage.setItem('initialData', JSON.stringify(response.data))
			initialData = response.data
		} else {
			initialData = JSON.parse(initialData)
		}
		dispatch(setInitialData(initialData))
		dispatch(setAppLoading(false))

		return Promise.resolve(initialData)
	} catch (error: any) {
		dispatch(setAppLoading(false))
		return Promise.reject(error.data)
	}
}

export const setUserOptions = (): AppThunk<any> => async (dispatch) => {
	try {
		const response = await api.get(API_URL.ME)
		dispatch(setAppLoading(false))
		dispatch(setUserDetail(response.data.user))
		return Promise.resolve(response.data)
	} catch (error: any) {
		dispatch(setAppLoading(false))
		dispatch(setUserToken(null))
		localStorage.removeItem('token')
		return Promise.reject(error.data)
	}
}

export const doLogout = (): AppThunk<any> => async (dispatch) => {
	try {
		const response = await api.get(API_URL.LOGOUT)
		dispatch(setUserToken(null))
		dispatch(setUserDetail(null))
		dispatch(setInitialData(null))
		localStorage.removeItem('token')
		localStorage.removeItem(`final${TYPE_DEPT_DASHBOARD.Bridge}Data`)
		localStorage.removeItem(`count${TYPE_DEPT_DASHBOARD.Bridge}`)
		localStorage.removeItem(`final${TYPE_DEPT_DASHBOARD.Building}Data`)
		localStorage.removeItem(`count${TYPE_DEPT_DASHBOARD.Building}`)
		localStorage.removeItem(`final${TYPE_DEPT_DASHBOARD.Road}Data`)
		localStorage.removeItem(`count${TYPE_DEPT_DASHBOARD.Road}`)
		localStorage.removeItem(`final${TYPE_DEPT_DASHBOARD.Water}Data`)
		localStorage.removeItem(`count${TYPE_DEPT_DASHBOARD.Water}`)
		localStorage.removeItem(`final${TYPE_DEPT_DASHBOARD.Drainage}Data`)
		localStorage.removeItem(`count${TYPE_DEPT_DASHBOARD.Drainage}`)
		localStorage.removeItem("tab")
		localStorage.removeItem("projectName")
		localStorage.removeItem("projectId")
		localStorage.removeItem("start_date")
		localStorage.removeItem("department_id")
		localStorage.removeItem("department_type_id")
		localStorage.removeItem("isCheckListFill")
		localStorage.removeItem("startDate")
		initialAppOptions()
		return Promise.resolve(response.data)
	} catch (error: any) {
		return Promise.reject(error.data)
	}
}

export const forgotPassword =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(API_URL.FORGOT_PASSWORD, action)
				dispatch(success(response.data))

				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
		}

export const updatePassword =
	(otp: string, action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(API_URL.RESET_PASSWORD(otp), action)
				dispatch(success(response.data))

				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
		}

// For current user
export const changePassword =
	( action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(API_URL.CHANGE_PASSWORD,action)
				dispatch(success(response.data))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
}

const AuthSliceReducer = AuthSlice.reducer
export default AuthSliceReducer
