import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const testTypeNameColumn: ColDef = {
	field: 'name',
	headerName: 'Test Type',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
}
const categoryNameColumn: ColDef = {
	field: 'category_name',
	headerName: 'Category',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.category_name,
}
const columnDefs: ColDef[] = [
	idColumn,
	categoryNameColumn,
	testTypeNameColumn,
	createdAtColumn,
	updatedAtColumn
]
export default columnDefs
