import React, { useEffect, useState } from "react";
import Listing from "./Listing/Listing";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../../assets/images/icon/plusIcon.svg";
import {
	deleteRecord,
	//getSteelDiameterList,
	//read,
	setId,
	setLocation,
	setPerPageSize,
	setRead,
	setSteelId,
} from "./utils/slice";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/app";
import RecordPerPage from "../../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../../utils/SVGIcon";
import { ReactComponent as ReportIcon } from "../../../../../../assets/images/icon/ReportIcon.svg";
import RestrictedAccessPage from "../../../../../errors/RestrictedAccessPage";
import { checkPrivileges } from "../../../../../../config/Global";
import PageSpinner from "../../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../../components/Modals/DeleteModal";

const Steel: React.FC<any> = ({ permissionPrefix }) => {
	const moduleName = "Steel";
	const dispatch: AppDispatch = useDispatch();
	const { STEELDIAMETER, AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(setId(localStorage.getItem("projectId")));
		dispatch(setLocation(""));
	}, []);

	const onRowClick = (data?: any) => {
		dispatch(setSteelId(data?.data?.id));
		setDeleteData(data?.data)
	};

	const reportData = () => {
		navigate(
			`/projects/raw-material/${STEELDIAMETER?.steelId}/steel-report`
		);
	};
	const handleView = () => {
		dispatch(setRead(null))
		//dispatch(read(STEELDIAMETER?.steelId));
		navigate(
			`/projects/raw-material/${STEELDIAMETER?.steelId}/view-steel`
		);
	};

	const handleClick = () => {
		setRead(null);
		navigate(`/projects/raw-material/add-steel`);
	};

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setSteelId(null))
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_INDEX`) ? <>
					<div className="rawListWrapper">
						<div className="rawList">
							<div className="btnWrap">
								{
									checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_CREATE`) &&
									<Button className="secondaryBtn steelBtn" onClick={handleClick}>
										Add
										<span className="btnIcon">
											<PlusIcon />
										</span>
									</Button>}
								<RecordPerPage
									defaultValue={STEELDIAMETER.perPage + " per page"}
									onChange={(perPageSize: number) => {
										dispatch(setPerPageSize(perPageSize));
									}}
								/>{" "}
							</div>
							<Listing
								rowData={STEELDIAMETER.rowData}
								moduleName={moduleName}
								handleReportClick={reportData}
								handleViewClick={handleView}
								onRowClick={onRowClick}
							/>
							<DeleteModal
								title={"steel"}
								deleteValues={deleteData}
								callApi={deleteRecord}
								close={closeDeleteModal}
								open={deleteUser}
							/>
						</div>
						<div className={checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}}_DELETE`) || checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) ? "actionRow rawmaterialActionRow" : ""}>
						<div className="actionBtn">
							{
								checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DETAILS`) &&
								<Button
									onClick={handleView}
									disabled={STEELDIAMETER?.steelId ? false : true}
									className={STEELDIAMETER?.steelId ? "activeBtn" : "disableBtn"}
								>
									View
									<SVGIcon icon="view" />
								</Button>}
							{checkPrivileges(AUTH.userDetail, `${permissionPrefix.permissionPrefix}_DELETE`) &&
								<div>
									<Button
										disabled={STEELDIAMETER?.steelId ? false : true}
										onClick={() => setDeleteUser(true)}
										className={STEELDIAMETER?.steelId ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>
								</div>}
							{
								checkPrivileges(AUTH.userDetail, `REPORT_INDEX`) &&
								<Button
									disabled={STEELDIAMETER?.steelId ? false : true}
									onClick={reportData}
									className={STEELDIAMETER?.steelId ? "activeBtn" : "disableBtn"}
								>
									Report
									<ReportIcon />
								</Button>}
						</div>
					</div>
					</div>
				</> : <RestrictedAccessPage />}
		</>
	);
};

export default Steel;
