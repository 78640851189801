import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const contractorNameColumn: ColDef = {
	field: 'name',
	headerName: 'Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
}

const contractorMobileNoColumn: ColDef = {
	field: 'mobile_no',
	headerName: 'Mobile No',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.mobile_no,
}
const contractorEmailColumn: ColDef = {
	field: 'email',
	headerName: 'Email',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.email,
}
const contractorAddressColumn: ColDef = {
	field: 'address_of_contractor',
	headerName: 'Address',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.address_of_contractor,
}
const columnDefs: ColDef[] = [
	idColumn,
	contractorNameColumn,
	contractorEmailColumn,
	contractorMobileNoColumn,
	contractorAddressColumn,
	createdAtColumn,
	updatedAtColumn
]
export default columnDefs
