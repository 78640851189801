/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormInstance } from "antd";
import { Regex } from "../../../../../config/Validators";
import { validations } from "../../../../../config/validations/validations";

export const rules = {
	dynamicFields: (
		field?: string,
		dynamicFieldType?: any,
		item?: any,
		form?: FormInstance
	) => {
		const attributeJson: any = item?.data ? JSON.parse(item?.data) : {};
		let requiredValidation: any = attributeJson.required
			? validations.required.text(field)
			: {};
		if (attributeJson?.required_if) {
			requiredValidation = {};

			const requiredField = dynamicFieldType?.filter(
				(item: any) => item.attribute_key === attributeJson?.required_if
			);

			if (requiredField) {
				if (
					requiredField[0]?.id &&
					form?.getFieldValue(requiredField[0]?.id) &&
					form?.getFieldValue(requiredField[0]?.id) !== ""
				) {
					requiredValidation = validations.required.text(field);
				} else {
					requiredValidation = {};
				}
			}
		}

		return [
			requiredValidation,
			//validations.minMax()
		];
	},
	numberFields: [
		// validations.required.text(field),
		validations.pattern.decimal(2),
		{
			pattern: Regex.digits,
			message: "only digits value allowed here",
		},
	],
	dynamicNumbricField: (field?: any) => [
		validations.required.text(field),
		validations.pattern.decimal(2),
	],
	numberDynamicFields: (
		field?: any,
		dynamicFieldType?: any,
		item?: any,
		form?: FormInstance
	) => {
		const attributeJson: any = item?.data ? JSON.parse(item?.data) : {};
		const requiredValidation: any = attributeJson.remarks_required_text_area
			? validations.required.text(field)
			: {};
		// if (attributeJson?.remarks_required_text_area) {
		// 	requiredValidation = {}
		// const requiredField = dynamicFieldType && Object.values(dynamicFieldType).filter((item: any) => item.data.remarks_required_text_area === attributeJson?.remarks_required_text_area);
		// if (requiredField){

		// 	console.log(form?.getFieldValue(['6', requiredField[0]?.qa_params_id]) , form?.getFieldsValue())
		// 	if (requiredField[0]?.qa_params_id && form?.getFieldValue(requiredField[0]?.qa_params_id) && form?.getFieldValue(requiredField[0]?.qa_params_id) !=='') {
		// 		requiredValidation = validations.required.text(field)
		// 	} else {
		// 		requiredValidation = {}
		// 	}
		// }
		// }

		return [
			requiredValidation,
			validations.pattern.decimal(2),
			{
				pattern: Regex.twoDecimal,
				message: "only digits value allowed here",
			},
		];
	},

	dateFields: (field?: any,
		dynamicFieldType?: any,
		item?: any,
		form?: FormInstance) => {
		const attributeJson: any = item?.data ? JSON.parse(item?.data) : {};
		const requiredValidation: any = attributeJson.required
			? validations.required.other(field)
			: {};
			return [
				requiredValidation,]
	},

	selectFirstFields: (field?: any) => [validations.required.text(field)],
	selectFields: (
		field?: string,
		dynamicFieldType?: any,
		item?: any,
		form?: FormInstance
	) => {
		// const attributeJson: any = item?.attribute_json
		// 	? JSON.parse(item?.attribute_json)
		// 	: {};
		// const requiredValidation: any = attributeJson.required
		// 	? validations.required.text(field)
		// 	: {};

		const attributeJson: any = item?.input_options ? JSON.parse(item?.input_options) : {}
		const requiredValidation: any = attributeJson.map((i: any, index: any) => ({
			id: index,
			name: i?.name,
		}))
			? validations.required.select(field)
			: {}

		// if (attributeJson?.required_if) {
		// 	requiredValidation = {}

		// 	const	requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === attributeJson?.required_if)
		// 	if (requiredField){
		// 		if (requiredField[0]?.id && form?.getFieldValue(requiredField[0]?.id) && form?.getFieldValue(requiredField[0]?.id) !=='') {
		// 			requiredValidation = validations.required.text(field)
		// 		} else {
		// 			requiredValidation = {}
		// 		}
		// 	}
		// }
		return [requiredValidation];
	},
	radioButton: (field?: any, item?: any,) => {
		const attributeJson: any = item?.input_options ? JSON.parse(item?.input_options) : {}
		const requiredValidation: any = attributeJson?.map((i: any, index: any) => ({
			id: index+1,
			name: i.name,
		}))
			? validations.required.other(field)
			: {}
		return [requiredValidation]
	}
	,
	multipleField: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {
		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		const requiredValidation: any = item.required ? validations.required.text(field) : {};
		return [
			requiredValidation,
		]
	},
};
