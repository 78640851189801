import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
// import ActionButtons from "../../../../../components/ActionButtons";
// import { setupGrid } from "../";
import columnDefs from './columnDefs'
import ActionButtons from '../../../../../components/ActionButtons'

const Listing: React.FC<any> = ({
    moduleName,
    handleViewClick,
    handleDeleteClick,
    handleEditClick,
    rowData,
}) => {
    const ActionRenderer = (props: any) => {
        props
        return (
            <>
                <ActionButtons
                    data={props}
                    view={{
                        action: handleViewClick,
                        permissionKey: `${moduleName.toUpperCase()}_DETAILS`,
                    }}
                    edit={{
                        action: handleEditClick,
                        permissionKey: `${moduleName.toUpperCase()}_UPDATE`,
                    }}
                    deleteButton={{
                        action: handleDeleteClick,
                        permissionKey: `${moduleName.toUpperCase()}_DELETE`,
                    }}
                />
            </>
        )
    }

    return (
        <>
            <AgGridWrapper
                components={{
                    ActionRenderer,
                }}
                rowData={rowData}
                columnDefs={columnDefs}
            />
        </>
    )
}

export default Listing
