import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../../../components/AntdAddons";
import ToolBar from "../../../../../../../../../components/ToolBar";
import { AddConcreteAdmixtureReportBreadcrumb } from "../../../../../../../../../config/BreadcrumbConfig";
import { useNavigate, useParams } from "react-router-dom";
import { rules } from "./rules";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../../../store/app";
import { createReport, getConcreteAdmixtureType, getNameOfLaboratory, readReport, setResetReadReport } from "../../../utils/slice";
import { dataToFormDataConverter, validateFields } from "../../../../../../../../../config/Global";
import { assignErrorToInput } from "../../../../../../../../../store/api";
import { CONSTANT } from "../../../../../../../../../config/Constant";

const AddConcreteAdMixerReportComponent: React.FC = () => {
	const CONCRETEADMIXTURE = useSelector((state: RootState) => state.CONCRETEADMIXTURE);
	const dispatch: AppDispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [testType, setTestType] = useState<any>([])
	const [, setState] = useState(false);
	const { concreteAdid, reportId }: { projectId?: any; concreteAdid?: any; reportId?: any } =
		useParams();
	const navigate = useNavigate();
	const laboratoryList = form.getFieldValue("laboratory_type")

	const handleSubmit = (data?: any) => {
		const obj = data?.observed_value
		delete data?.observed_value
		delete data?.test_type_id
		delete data?.expected_value
		const payload = {
			...data,
			date_of_testing: dayjs(data?.date_of_testing).format(CONSTANT?.POST_DATE_FORMAT),
			project_id: localStorage.getItem('projectId'),
			mst_concrete_admixture_inventory_id: concreteAdid,
			observed_value: JSON.stringify(Object.values(obj))

		};
		const formdata = dataToFormDataConverter(payload);
		try {
			form.validateFields();
			setLoading(true)
			setDisabled(true)
			dispatch(createReport(concreteAdid, formdata))
				.then(() => {
					setLoading(false)
					setDisabled(false)
					setTimeout(() => {
						form.resetFields();
						navigate(
							`/projects/raw-material/${concreteAdid}/concrete-mixture-report`
						);
					}, 500);
				})
				.catch((err: any) => {
					setLoading(false)
					setDisabled(false)
					assignErrorToInput(form, err.STATUS);
				});
		} catch (error) {
			console.error("Error submitting data:", error);
		}
	};


	useEffect(() => {
		if (location.pathname.includes("/view-concrete-mixture-report")) {
			dispatch(setResetReadReport())
			form.resetFields()
			dispatch(getConcreteAdmixtureType(5))
			dispatch(readReport(reportId));
		}
	}, []);

	useEffect(() => {
		if (location.pathname.includes("/view-concrete-mixture-report")) {
			const details = CONCRETEADMIXTURE.readReport
			CONCRETEADMIXTURE.testTypeList?.filter((item?: any) => {
				if (item?.id == details?.test_type_id) {
					setTestType([{ ...item }])
				}
			})
			form.setFieldValue([`observed_value`, `${details?.test_type_id}`, "observed_value"], details?.observed_value)
			form.setFieldsValue({
				mst_concrete_admixture_inventory_id: details?.mst_concrete_admixture_inventory_id,
				test_type_id: details?.test_type_id,
				laboratory_type: details?.laboratory_type,
				laboratory_name: details?.laboratory_original_name,
				test_passed: details?.test_passed,
				date_of_testing: dayjs(details?.date_of_testing),
				attach_report: details?.attached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				),
				data_sheet: details?.data_sheet_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				)
			})
			setState(prevState => !prevState);
		}
	}, [CONCRETEADMIXTURE?.readReport, reportId, setState]);

	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleClick = () => {
		concreteAdid ? navigate(`/projects/raw-material/${concreteAdid}/concrete-mixture-report`) : navigate(`/projects/raw-material/concrete-mixture-report`)
	}

	return (
		<>
			<ToolBar
				breadcrumbs={location.pathname.includes(`/projects/raw-material/${concreteAdid}/add-concrete-mixture-report`) ?
					AddConcreteAdmixtureReportBreadcrumb(concreteAdid, "Add Report") :
					AddConcreteAdmixtureReportBreadcrumb(concreteAdid, "View Report")
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<div className="formHeading">
				<h3>
					{location.pathname.includes("/add-concrete-mixture-report")
						? "Add Report "
						: "View Report"}
				</h3>
			</div>
			<Form className="addReportForm" onFinish={handleSubmit} form={form} onValuesChange={validateForm}>
				<div>
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Radio
								name="laboratory_type"
								label="Laboratory Type"
								rules={rules.radioButton()}
								onChange={(e) => {
									dispatch(getNameOfLaboratory(e.target.value))
									form.setFieldValue('laboratory_name', [])
								}
								}
								disabled={
									location.pathname.includes("/view-concrete-mixture-report")
								}
								options={{
									list: [
										{
											id: 1,
											value: "NABL Lab",
											name: "NABL Lab",
										},
										{
											id: 2,
											value: "Government lab",
											name: "Government Lab",
										},
										{
											id: 3,
											value: "On-site Lab",
											name: "On-site Lab",
										},
									]
								}}
							/></Col>
						{laboratoryList &&
							<Col span={12}>
								<InputBox.Select
									className="select textField"
									label="Name of Laboratory"
									name="laboratory_name"
									options={{
										list: CONCRETEADMIXTURE.nameLaboratoryList,
										valueKey: "id",
										textKey: "laboratory_name",
									}}
									disabled={
										location.pathname.includes("/view-concrete-mixture-report")
									}
									rules={rules.selectFields()} />
							</Col>}
						<Col span={12}>
							<InputBox.DatePicker
								label="Date of Testing"
								name="date_of_testing"
								format={CONSTANT.DATE_FORMAT}
								disabled={
									location.pathname.includes("/view-concrete-mixture-report")
								}
								rules={rules.dateFields()}
							/>
						</Col>

						<Col span={12}>
							<InputBox.Radio
								label="All Tests Passed As Per Code :"
								name="test_passed"
								disabled={
									location.pathname.includes("/view-concrete-mixture-report")
								}
								rules={rules.radioButton()}
								options={{
									list: [
										{
											id: 1,
											value: "yes",
											name: "Yes",
										},
										{
											id: 2,
											value: "no",
											name: "No",
										},
									],
								}}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Select
								className="select textField"
								name="test_type_id"
								mode={location.pathname.includes("/add-concrete-mixture-report") ? "multiple" : undefined}
								allowClear
								onFocus={() => dispatch(getConcreteAdmixtureType(5))}
								options={{
									list: CONCRETEADMIXTURE.testTypeList,
									valueKey: "id",
									textKey: "name",
								}}
								label="Test Type"
								disabled={
									location.pathname.includes("/view-concrete-mixture-report")
								}
								rules={rules.selectFields()}
								onChange={(d: any) => {
									d?.map((item?: any) => {
										CONCRETEADMIXTURE?.testTypeList?.filter((data: any) => {
											if (item == data?.id) {
												setTestType([...testType, data])
											}
										});
									})
								}}
								onDeselect={(data?: any) => {
									const d = testType?.filter((item: any) => {
										if (data == item?.id) {
											form.setFieldValue([`observed_value`, `${item?.id}`, "observed_value"], "")
										}
										return data !== item?.id
									})
									setTestType(d)
								}
								}
								onClear={() => setTestType([])}
							/>
						</Col>
						<Col span={12} />
					</Row>
					<Row gutter={40}>
						{
							testType && testType?.map((data?: any) => {
								form.setFieldValue([`expected_value`, `${data?.id}`], data?.expected_value ? data?.expected_value : "N/A")
								form.setFieldValue([`observed_value`, `${data?.id}`, "test_type_id"], data?.id)
								return <>
									<InputBox.Text
										name={[`observed_value`, `${data?.id}`, "test_type_id"]}
										label={`Observed Value - ${data.name}`}
										disabled={
											location.pathname.includes("/view-concrete-mixture-report")
										}
										className="textField"
										hidden
									/>
									<Col span={12}>
										<InputBox.Text
											name={[`observed_value`, `${data?.id}`, "observed_value"]}
											label={`Observed Value - ${data.name}`}
											disabled={
												location.pathname.includes("/view-concrete-mixture-report")
													? true
													: false
											}
											rules={rules.dynamicFields()}
											className="textField"
										//Don't remove this code
										// rules={[
										//   {
										//     validator: validatevalue,
										//   },
										//   {
										//     required:true,
										//     message:"It is required."
										//   }
										// ]}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Text
											name={[`expected_value`, `${data?.id}`]}
											label={`Expected Value - ${data.name}`}
											disabled={true}
											className="textField"
										/>
									</Col>
								</>
							})
						}
					</Row>
					<Row gutter={40}>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="attach_report"
								required={true}
								label="Attach Report "
								fileList={form.getFieldValue('attach_report')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-concrete-mixture-report") ? true : false
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="data_sheet"
								required={true}
								label="Data Sheet "
								fileList={form.getFieldValue('data_sheet')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-concrete-mixture-report") ? true : false
								}
							/>
						</Col>
					</Row>

					<Row>
						<div className="ReportBtn">
							<Button
								className="secondaryBtn"
								htmlType="submit"
								loading={loading}
								disabled={disabled}
							>
								Submit
							</Button>
							<Button
								className="secondaryBtn cancelBtn"
								onClick={handleClick}
							>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	);
};

export default AddConcreteAdMixerReportComponent;
