import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const categoryNameColumn: ColDef = {
	field: 'name',
	headerName: 'Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,
}

const columnDefs: ColDef[] = [
	idColumn,
	categoryNameColumn,
	createdAtColumn,
	updatedAtColumn
]
export default columnDefs
