const baseUrl = "/v1/admin/user";

export const apiUrls = {
  list: `${baseUrl}`,
  create: `${baseUrl}/create`,
  update: (id: number): string => `${baseUrl}/${id}/update`,
  delete: `${baseUrl}/delete`,
  details: (id: number): string => `${baseUrl}/${id}/details`,
  bulkUpdate: `${baseUrl}/bulk-update`,
  changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
  role: `/v1/admin/roles`,
  id_proof: `${baseUrl}/proof-list`,
  firm_type: `${baseUrl}/firm-type`,
  pmc: `/v1/admin/pmc/pmclist`,
  design: `/v1/admin/design-consultant/designconsultantlist`,
  contractor: `/v1/admin/contractor/contractorlist`,
  restore: `${baseUrl}/restore-user`,
  getProjects:`${baseUrl}/get-project`
};
