import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../components/ToolBar";
import { Report } from "../../../../../config/BreadcrumbConfig";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import Listing from "./Listing/Listing";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icon/plusIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RestoreIcon } from "../../../../../assets/images/icon/refresh.svg";
import {
	deleteReport,
	getDateRangeData,
	setDateRange,
	setId,
	setLocation,
	setReadReportData,
	setReportId,
	setReportPerPage,
	setSteelReportId,
} from "../TabElements/Steel/utils/slice";
import RecordPerPage from "../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../../../config/Global";
import PageSpinner from "../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../components/Modals/DeleteModal";
import { InputBox } from "../../../../../components/AntdAddons";
import dayjs from "dayjs";
import { CONSTANT } from "../../../../../config/Constant";
import { RangePickerProps } from "antd/es/date-picker";
import { setTab } from "../../utils/slice";

const moduleName = "Report";

const ReportComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const navigate = useNavigate();
	const {
		//projectId,
		steelId,
	}: { projectId?: any; steelId?: any; reportId?: any } = useParams();
	const dispatch: AppDispatch = useDispatch();
	const { STEELDIAMETER, AUTH } = useSelector((state: RootState) => state);
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const [selectedDate, setSelectedDate] = useState(null)


	const onRowClick = (data?: any) => {
		dispatch(setSteelReportId(data?.data?.id))
		setDeleteData(data?.data)
	};
	const handleViewClick = () => {
		dispatch(setReadReportData(null))
		steelId ? navigate(
			`/projects/raw-material/${steelId}/${STEELDIAMETER?.steelReportId}/view-steel-report`
		) : navigate(
			`/projects/raw-material/${STEELDIAMETER?.steelReportId}/view-steel-report`
		);
	};

	const handleClick = () => {
		navigate(`/projects/raw-material/${steelId}/add-steel-report`);
	};

	useEffect(() => {
		dispatch(setReportId(steelId ? { id: steelId } : { project_id: localStorage.getItem('projectId') }));
		dispatch(setLocation("/steel-report"));
		dispatch(setId(localStorage.getItem("projectId")));
		dispatch(setTab(0))
		//dispatch(fetchReportList(steelId))
	}, []);

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setSteelReportId(null))
	}

	const onChangeDateRange: RangePickerProps["onChange"] = (date: any) => {
		setSelectedDate(date)
		date?dispatch(setDateRange({ start_date: dayjs(date[0]).format(CONSTANT.POST_DATE_FORMAT), end_date: dayjs(date[1]).format(CONSTANT.POST_DATE_FORMAT) })):
		dispatch(setDateRange({start_date:null,end_date:null}))
		dispatch(getDateRangeData())
	}
	const resetFilter = () => {
		dispatch(setDateRange(null))
		setSelectedDate(null)
		dispatch(getDateRangeData())
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar breadcrumbs={
						steelId ? Report("Raw Material Testing-Steel") : Report("Consolidate Report")
					}>

						<div className="listBtn">
							{steelId &&
								checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
								<Button className="secondaryBtn" onClick={handleClick}>
									Add
									<span className="btnIcon">
										<PlusIcon />
									</span>
								</Button>}
							<RecordPerPage
								defaultValue={STEELDIAMETER.reportPerPage + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setReportPerPage(perPageSize));
								}}
							/>{" "}
						</div>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							<div className="auditListWrapper reportList">
								<div className="btnList">

									<div className="listRangePicker">
										<InputBox.DateRangePicker value={selectedDate} onChange={onChangeDateRange} allowClear={true} />
									</div>
									<Button onClick={resetFilter}>
										<RestoreIcon height={22} width={22} />
									</Button>
								</div>
								<div className="auditList">

									<Listing
										rowData={STEELDIAMETER?.reportRowData}
										moduleName={moduleName}
										handleViewClick={handleViewClick}
										onRowClick={onRowClick}
									/>
								</div>

								<DeleteModal
									title={"steel report"}
									deleteValues={deleteData}
									callApi={deleteReport}
									close={closeDeleteModal}
									open={deleteUser}
								/>
							</div>
							<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow borderActionRow" : ""}>
								<div className="actionBtn">
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
										<Button
											onClick={handleViewClick}
											disabled={STEELDIAMETER?.steelReportId == null ? true : false}
											className={STEELDIAMETER?.steelReportId ? "activeBtn" : "disableBtn"}
										>
											View
											<SVGIcon icon="view" />
										</Button>}
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
										<Button
											onClick={() => setDeleteUser(true)}
											disabled={STEELDIAMETER?.steelReportId == null ? true : false}
											className={STEELDIAMETER?.steelReportId ? "deleteBtn" : "disableDelBtn disableBtn"}
										>
											Delete
											<SVGIcon icon="delete" />
										</Button>}
								</div>
							</div>
						</div>
					</ContentBox></>}
		</>
	);
};

export default ReportComponent;
