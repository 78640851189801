import { ColDef } from 'ag-grid-community'
import dayjs from 'dayjs';
import { createdAtColumn, idColumn } from '../../../../../../../utils/commonColumns';

// const DataEntryColumn: ColDef = {
// 	field: 'created_at',
// 	headerName: 'Date of Data Entry',
// 	filter: 'agDateColumnFilter',
// 	cellRenderer: (data?: any) => {
// 		return dayjs(data.data.created_at).format('DD-MM-YYYY')
// 	},

// }
const Supplier: ColDef = {
	field: 'supplier_name',
	headerName: 'Name of Supplier',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.supplier_name,


}
const InvoiceNumber: ColDef = {
	field: 'invoice_number',
	headerName: 'Invoice Number ',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.invoice_number,
}
const InvoiceDate: ColDef = {
	field: 'invoice_date',
	headerName: 'Invoice Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.invoice_date).format('DD-MM-YYYY')
	},
	// tooltipValueGetter: (params: any) => params.data.invoice_date,
}

const columnDefs: ColDef[] = [
	idColumn,
	Supplier,
	InvoiceNumber,
	InvoiceDate,
    createdAtColumn,
	//actionColumn,
]
export default columnDefs
