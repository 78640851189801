const baseUrl = '/v1/admin/departmenttype'

export const apiUrls = {
    list: `${baseUrl}/list`,
    create: `${baseUrl}/create`,
    update: (id: number): string => `${baseUrl}/${id}/edit`,
    delete: `${baseUrl}/delete`,
    read: (id: number): string => `${baseUrl}/${id}/read`,
    departmentList:  `/v1/admin/project/department`,
}
