import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import Config, { getServerListPayload } from "../../../../config/Config";
import { apiUrls } from "./apiUrls";


interface InitialState {
	isLoading: boolean;
	agGrid: any;
	perPage: any;
	details: any;
	dynamicReinforcement: any;
}

const initialState = {
	isLoading: false,
	agGrid: null,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	details: null,
	dynamicReinforcement: null
} as InitialState;

const ReinforcementSlice = createSlice({
	name: "Reinforcement",
	initialState,
	reducers: {
		start: state => {
			state.isLoading = true;
		},
		success: state => {
			state.isLoading = false;
		},
		failure: state => {
			state.isLoading = false;
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action?.payload;
		},
		setDynamicReinforcement: (state, action: PayloadAction<any>) => {
			state.dynamicReinforcement = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action?.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setDetails: (state, action: PayloadAction<any>) => {
			state.details = action?.payload;
		},
	},
});

export const { start, success, failure, setGrid, setPerPage, setDetails, setDynamicReinforcement } =
	ReinforcementSlice.actions;

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().USER.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				const dataSource = await createDataSource(
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
			} catch (error: any) {
				//
			}
		};

const changeFilterAndSort = (params: any) => {
	params;
};

const createDataSource = (
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request);
			const payload = getServerListPayload(params);
			fetchList(payload).then(data => {
				params.success({ rowData: data?.rows, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};


export const createRecord =

	(qa_params_value_mapping_id: any, action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.create(qa_params_value_mapping_id), action)
				dispatch(success(response.data))

				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

/** For Listing:End */

export const getDynamicReinforcement =
	(mappingId: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.reinforcementData(mappingId));
				dispatch(setDynamicReinforcement(response.data));
				dispatch(success());
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};


const ReinforcementSliceReducer = ReinforcementSlice.reducer;
export default ReinforcementSliceReducer;
