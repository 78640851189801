import React from "react";
import { Form, DrawerProps, Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAuditData } from "../../utils/slice";
import { AppDispatch, RootState } from "../../../../../store/app";
// import { validateFields } from "../../../../../config/Global";
import FormComponent from "./FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface ViewDrawerProps extends DrawerProps {
  open: boolean
  close: () => void;
}

const ViewComponent: React.FC<ViewDrawerProps> = ({ open, close
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { read } = useSelector((state: RootState) => state.AUDIT_LOG);
  const [form] = Form.useForm();

  const drawerClose = () => {
    close();
    form.resetFields();
    dispatch(setAuditData(null))
  };


  return (
    <Modal
      centered
      open={open}
      className="commanModal auditModal"
      title={`View Log`}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      onCancel={drawerClose}

      destroyOnClose
      // onOk={handleOk}
      footer={
        <>
          <div className="signBtn">
            <Button onClick={drawerClose} className='cancelBtn'>
              Cancel
            </Button>

          </div>
        </>
      }
    >


      <FormComponent
        form={form}
        id="viewForm"
        editValues={read}
      />

    </Modal>
  );
};

export default ViewComponent;
